import React, { useState, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BSelect, DateTimePickerForm } from "form-builder";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import Loader from "../../Components/Loader";
import Pagination from "../../Components/Pagination";
import ExportModal from "./ExportModal";
import {
  fetchMonthlyReportListAction,
  onInputResetAction,
  showExportMonthlyReportModal,
  showErrorToast,
  onFormResetAction,
  updateValueAction,
} from "../../Store/Actions";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";

import ToggleModal from "./ToggleModal";

import { useTranslation } from "react-i18next";
import moment from "moment";
import { AgGridTable } from "../../Components/AgGridTable";
import HelperFns from "../../Helpers/HelperFns";
import { BarsIconButton } from "../../Components/IconButtonWithTooltip";

const dataTableRef = "monthlyReport";
const MonthlyReport = ({ customReport = false }) => {
  const [isToggleModalVissible, setIsToggleModalVissible] = useState(false);
  const gridRef = useRef();
  const [dateFilter, setDateFilter] = useState({
    fromVar: "from_month_year",
    toVar: "to_month_year",
    pickerVar: "month",
    formatVar: "MM-yyyy",
    requestFormatVar: "yyyy-MM",
    formName: "monthlyReportColumns",
  });

  const { fromVar, toVar, pickerVar, formatVar, requestFormatVar, formName } =
    dateFilter;

  // Redux Hooks
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.userProfile);
  const companyDepartments = useSelector(
    (state) => state.super.allDepartmentsByCompany
  );
  const allPositions = useSelector((state) => state.super.allPositions);
  const extraEmployeesFilter = useSelector(
    (state) => state.super.extraEmployeesFilter
  );
  const tableState = useSelector((state) => state.super[dataTableRef]);
  const isColumnActive = useSelector(
    (state) => state.monthlyReport?.[formName]
  );

  const { t } = useTranslation();

  useEffect(() => {
    if (customReport) {
      return;
    }
    dispatch(
      fetchMonthlyReportListAction({
        ref: dataTableRef,
        [dataTableRef]: { ...tableState, customReport: customReport },
      })
    );

    return () => {
      dispatch(onInputResetAction(dataTableRef, "from_month_year"));
      dispatch(onInputResetAction(dataTableRef, "to_month_year"));
      dispatch(onInputResetAction(dataTableRef, "from_date"));
      dispatch(onInputResetAction(dataTableRef, "to_date"));
      dispatch(onInputResetAction(dataTableRef, "custom_report"));
      dispatch(onInputResetAction(dataTableRef, "user_id"));
      dispatch(onInputResetAction(dataTableRef, "office_id"));
      dispatch(onInputResetAction(dataTableRef, "department_id"));
      dispatch(onInputResetAction(dataTableRef, "position_id"));
    };
  }, [customReport]);

  useEffect(() => {
    if (customReport) {
      setDateFilter({
        fromVar: "from_date",
        toVar: "to_date",
        pickerVar: "date",
        formatVar: "DD-MM-yyyy",
        requestFormatVar: "yyyy-MM-DD",
        formName: "customReportColumns",
      });
      dispatch(
        updateValueAction(
          dataTableRef,
          "from_date",
          user?.company?.getMonthBoundaries?.from
        )
      );
      dispatch(
        updateValueAction(
          dataTableRef,
          "to_date",
          user?.company?.getMonthBoundaries?.to
        )
      );
      if (tableState?.from_date) {
        dispatch(
          fetchMonthlyReportListAction({
            ref: dataTableRef,
            [dataTableRef]: { ...tableState, customReport: customReport },
          })
        );
      }
    } else {
      setDateFilter({
        fromVar: "from_month_year",
        toVar: "to_month_year",
        pickerVar: "month",
        formatVar: "MM-yyyy",
        requestFormatVar: "yyyy-MM",
        formName: "monthlyReportColumns",
      });
    }
  }, [customReport]);

  const handleFilter = () => {
    dispatch(
      fetchMonthlyReportListAction({
        ref: dataTableRef,
        [dataTableRef]: {
          ...tableState,
          customReport,
          pagination: {
            ...tableState?.pagination,
            currentPage: 1,
          },
        },
      })
    );
  };

  const handleFilterOffice = () => {
    if (tableState?.user_id) {
      dispatch(onInputResetAction(dataTableRef, "user_id"));
    } else {
      dispatch(
        fetchMonthlyReportListAction({
          ref: dataTableRef,
          [dataTableRef]: {
            ...tableState,
            customReport,
            pagination: {
              ...tableState?.pagination,
              currentPage: 1,
            },
          },
        })
      );
    }
  };

  const useDidFilterUpdate = useDidUpdateEffect(handleFilter, [
    tableState?.from_month_year,
    tableState?.to_month_year,
    tableState?.from_date,
    tableState?.to_date,
    tableState?.custom_report,
    tableState?.user_id,
    tableState?.department_id,
    tableState?.position_id,
  ]);

  const useDidOfficeFilterUpdate = useDidUpdateEffect(handleFilterOffice, [
    tableState?.office_id,
  ]);

  const handlePaginate = (page = tableState?.pagination?.currentPage) => {
    dispatch(
      fetchMonthlyReportListAction({
        ref: dataTableRef,
        [dataTableRef]: {
          ...tableState,
          customReport,
          pagination: {
            ...tableState?.pagination,
            currentPage: page,
          },
        },
      })
    );
  };

  // Total value grid row when user is filtered
  const totalRow = useMemo(() => {
    const totalDuration = (row) => {
      const durations = tableState?.data?.map((item) => item[row]);
      let totalMilliSeconds;
      if (
        row === "total_hours_secs" ||
        row === "expected_hours_secs" ||
        row === "total_checkIn_hours_secs" ||
        row === "total_hours_with_out_over_time_sec"
      ) {
        totalMilliSeconds = durations.reduce((acc, curr) => {
          return +acc + +curr;
        }, 0);
        return totalMilliSeconds;
      } else {
        totalMilliSeconds = durations.reduce((acc, curr) => {
          const currentMs = moment.duration(curr).asMilliseconds();
          return acc + currentMs;
        }, 0);
        
        const hours = Math.trunc(moment.duration(totalMilliSeconds).asHours());
        const minutesSeconds = moment.utc(totalMilliSeconds).format("mm:ss");
        if (hours <= 0 && minutesSeconds === "00:00") {
          return "00:00:00";
        }
        if (hours.toString().length === 1) {
          return `0${hours}:${minutesSeconds}`;
        }
        return `${hours}:${minutesSeconds}`;
      }
    };

    return {
      name: "Total",
      emp_code: "----",
      month_year: null,
      department: "----",
      position: "----",
      holidays: tableState?.data?.reduce(
        (acc, curr) => +acc + +curr?.holidays,
        0
      )?.toFixed(2),
      weekends: tableState?.data?.reduce(
        (acc, curr) => +acc + +curr?.weekends,
        0
      )?.toFixed(2),
      attended_days: tableState?.data?.reduce(
        (acc, curr) => +acc + +curr?.attended_days,
        0
      )?.toFixed(2),
      ontime_total: tableState?.data?.reduce(
        (acc, curr) => +acc + +curr?.ontime_total,
        0
      )?.toFixed(2),
      ontime_normal: tableState?.data?.reduce(
        (acc, curr) => +acc + +curr?.ontime_normal,
        0
      )?.toFixed(2),
      ontime_half: tableState?.data?.reduce(
        (acc, curr) => +acc + +curr?.ontime_half,
        0
      )?.toFixed(2),
      late_total: tableState?.data?.reduce(
        (acc, curr) => +acc + +curr?.late_total,
        0
      )?.toFixed(2),
      late_normal: tableState?.data?.reduce(
        (acc, curr) => +acc + +curr?.late_normal,
        0
      )?.toFixed(2),
      late_half: tableState?.data?.reduce(
        (acc, curr) => +acc + +curr?.late_half,
        0
      )?.toFixed(2),
      late_duration: totalDuration("late_duration"),
      absent_total: tableState?.data?.reduce(
        (acc, curr) => +acc + +curr?.absent_total,
        0
      )?.toFixed(2),
      normal_leaves: tableState?.data?.reduce(
        (acc, curr) => +acc + +curr?.normal_leaves,
        0
      )?.toFixed(2),
      emergency_leaves: tableState?.data?.reduce(
        (acc, curr) => +acc + +curr?.emergency_leaves,
        0
      )?.toFixed(2),
      sick_leaves: tableState?.data?.reduce(
        (acc, curr) => +acc + +curr?.sick_leaves,
        0
      )?.toFixed(2),
      unpaid_leaves: tableState?.data?.reduce(
        (acc, curr) => +acc + +curr?.unpaid_leaves,
        0
      )?.toFixed(2),
      half_days: tableState?.data?.reduce(
        (acc, curr) => +acc + +curr?.half_days,
        0
      )?.toFixed(2),
      total_checkIn_hours_secs: totalDuration("total_checkIn_hours_secs"),
      permissions: tableState?.data?.reduce(
        (acc, curr) => +acc + +curr?.permissions,
        0
      )?.toFixed(2),
      permissions_time: totalDuration("permissions_time"),
      breaks: tableState?.data?.reduce((acc, curr) => +acc + +curr?.breaks, 0),
      breaks_time: totalDuration("breaks_time"),
      total_early_sign_out_sec: totalDuration("total_early_sign_out_sec"),
      total_penalties_as_days: tableState?.data?.reduce(
        (acc, curr) => +acc + +curr?.total_penalties_as_days,
        0
      )?.toFixed(2),
      total_penalties_as_fixed_amount: tableState?.data?.reduce(
        (acc, curr) => +acc + +curr?.total_penalties_as_fixed_amount,
        0
      )?.toFixed(2),
      total_penalties_as_hours: tableState?.data?.reduce(
        (acc, curr) => +acc + +curr?.total_penalties_as_hours,
        0
      )?.toFixed(2),
      expected_hours_secs: totalDuration("expected_hours_secs"),
      total_hours_secs: totalDuration("total_hours_secs"),
      total_hours_with_out_over_time_sec: totalDuration("total_hours_with_out_over_time_sec"),
      total_over_time_hours: totalDuration("total_over_time_hours"),
      overtime_days: tableState?.data?.reduce(
        (acc, curr) => +acc + +curr?.overtime_days,
        0
      )?.toFixed(2),
      accepted_overtime_hours: totalDuration("accepted_overtime_hours"),
      mocked_location_in_count: tableState?.data?.reduce((acc, curr) => +acc + +curr?.mocked_location_in_count, 0),
      mocked_location_out_count: tableState?.data?.reduce((acc, curr) => +acc + +curr?.mocked_location_out_count, 0),
      spoofed_sign_in_image_count: tableState?.data?.reduce((acc, curr) => +acc + +curr?.spoofed_sign_in_image_count, 0),
      spoofed_sign_out_image_count: tableState?.data?.reduce((acc, curr) => +acc + +curr?.spoofed_sign_out_image_count, 0),
    };
  }, [tableState?.data]);

  // Rows to be rendered in the grid
  const gridRowData = useMemo(() => {
    if (tableState?.user_id && tableState?.data?.length > 0) {
      return [...tableState?.data, totalRow];
    }

    return tableState?.data;
  }, [tableState?.data, totalRow, tableState?.user_id]);

  const columnDefs = useMemo(() => {
    return [
      // {
      //   headerValueGetter: () => t("name"),
      //   field: "name",
      //   minWidth: 220,
      //   pinned: user?.lng === "ar-EG" ? "right" : "left",
      //   lockPinned: true,
      //   valueFormatter: (cell) => (cell.value ? cell.value : null),
      // },
      {
        headerValueGetter: () =>
          isColumnActive?.emp_code ? t("employee") : null,
        pinned: user?.lng === "ar-EG" ? "right" : "left",
        hide: true,
        children: [
          {
            headerValueGetter: () => t("name"),
            field: "name",
            minWidth: 220,
            valueFormatter: (cell) => (cell.value ? cell.value : null),
            pinned: user?.lng === "ar-EG" ? "right" : "left",
          },
          {
            headerValueGetter: () => t("employee code"),
            field: "emp_code",
            minWidth: 100,
            hide: isColumnActive?.emp_code ? false : true,
            pinned: user?.lng === "ar-EG" ? "right" : "left",
            valueFormatter: (cell) => (cell.value ? cell.value : "---"),
          },
        ],
      },
      {
        headerValueGetter: () => t("Month"),
        field: "month_year",
        hide:
          isColumnActive?.month_year &&
            tableState?.from_month_year < tableState?.to_month_year &&
            !customReport
            ? false
            : true,
        valueFormatter: (cell) =>
          cell?.value ? moment(cell?.value, "MM-YYY").format("MMMM") : "----",
      },
      {
        headerValueGetter: () => t("office"),
        field: "office",
        minWidth: 200,
        hide: isColumnActive?.office ? false : true,
        valueFormatter: (cell) => (cell.value ? cell.value : null),
      },
      {
        headerValueGetter: () => t("department"),
        field: "department",
        minWidth: 200,
        hide: isColumnActive?.department ? false : true,
        valueFormatter: (cell) => (cell.value ? cell.value : null),
      },
      {
        headerValueGetter: () => t("position"),
        field: "position",
        minWidth: 200,
        hide: isColumnActive?.position ? false : true,
        valueFormatter: (cell) => (cell.value ? cell.value : null),
      },

      {
        headerValueGetter: () => t("holidays"),
        field: "holidays",
        hide: isColumnActive?.holidays ? false : true,
        valueFormatter: (cell) => (cell.value ? cell.value : 0),
      },
      {
        headerValueGetter: () => t("Days Off"),
        field: "weekends",
        hide: isColumnActive?.weekends ? false : true,
        valueFormatter: (cell) => (cell.value ? cell.value : 0),
      },
      {
        headerValueGetter: () => t("workdays"),
        hide: isColumnActive?.attended_days ? false : true,
        headerClass: "workdays_header_group",
        children: [
          {
            headerValueGetter: () => t("attended"),
            field: "attended_days",
            hide: isColumnActive?.attended_days ? false : true,
          },
          {
            headerValueGetter: () => t("ontime"),
            headerClass: "ontime_header_group",
            hide:
              isColumnActive?.attended_days && isColumnActive?.ontime
                ? false
                : true,
            children: [
              {
                headerValueGetter: () => t("total"),
                field: "ontime_total",
                hide:
                  isColumnActive?.ontime && isColumnActive?.ontime_total
                    ? false
                    : true,
                valueFormatter: (cell) => (cell.value ? cell.value : 0),
              },
              {
                headerValueGetter: () => t("normal"),
                field: "ontime_normal",
                hide:
                  isColumnActive?.ontime && isColumnActive?.ontime_normal
                    ? false
                    : true,
                valueFormatter: (cell) => (cell.value ? cell.value : 0),
              },
              {
                headerValueGetter: () => t("half-days"),
                field: "ontime_half",
                hide:
                  isColumnActive?.ontime && isColumnActive?.ontime_half
                    ? false
                    : true,
                valueFormatter: (cell) => (cell.value ? cell.value : 0),
              },
            ],
          },
          {
            headerValueGetter: () => t("late"),
            headerClass: "late_header_group",
            hide:
              isColumnActive?.attended_days && isColumnActive?.late
                ? false
                : true,
            children: [
              {
                headerValueGetter: () => t("total"),
                field: "late_total",
                hide:
                  isColumnActive?.late && isColumnActive?.late_total
                    ? false
                    : true,
                valueFormatter: (cell) => (cell.value ? cell.value : 0),
              },
              {
                headerValueGetter: () => t("normal"),
                field: "late_normal",
                hide:
                  isColumnActive?.late && isColumnActive?.late_normal
                    ? false
                    : true,
                valueFormatter: (cell) => (cell.value ? cell.value : 0),
              },
              {
                headerValueGetter: () => t("half-days"),
                field: "late_half",
                hide:
                  isColumnActive?.late && isColumnActive?.late_half
                    ? false
                    : true,
                valueFormatter: (cell) => (cell.value ? cell.value : 0),
              },
              {
                headerValueGetter: () => t("duration"),
                field: "late_duration",
                hide:
                  isColumnActive?.late && isColumnActive?.late_duration
                    ? false
                    : true,
                valueFormatter: (cell) => (cell.value ? cell.value : 0),
              },
            ],
          },
        ],
      },
      {
        headerValueGetter: () => t("absent"),
        field: "absent_total",
        hide: isColumnActive?.absent_total ? false : true,
        valueFormatter: (cell) => (cell.value ? cell.value : 0),
      },
      {
        headerValueGetter: () => t("leaves"),
        headerClass: "leaves_header_group",
        hide:
          isColumnActive?.leaves &&
            (isColumnActive?.normal_leaves ||
              isColumnActive?.emergency_leaves ||
              isColumnActive?.sick_leaves ||
              isColumnActive?.unpaid_leaves ||
              isColumnActive?.half_days)
            ? false
            : true,
        children: [
          {
            headerValueGetter: () => t("normal"),
            field: "normal_leaves",
            hide:
              isColumnActive?.leaves && isColumnActive?.normal_leaves
                ? false
                : true,
            valueFormatter: (cell) => (cell.value ? cell.value : 0),
          },
          {
            headerValueGetter: () => t("emergency"),
            field: "emergency_leaves",
            hide:
              isColumnActive?.leaves && isColumnActive?.emergency_leaves
                ? false
                : true,
            valueFormatter: (cell) => (cell.value ? cell.value : 0),
          },
          {
            headerValueGetter: () => t("sick"),
            field: "sick_leaves",
            hide:
              isColumnActive?.leaves && isColumnActive?.sick_leaves
                ? false
                : true,
            valueFormatter: (cell) => (cell.value ? cell.value : 0),
          },
          {
            headerValueGetter: () => t("unpaid"),
            field: "unpaid_leaves",
            hide:
              isColumnActive?.leaves && isColumnActive?.unpaid_leaves
                ? false
                : true,
            valueFormatter: (cell) => (cell.value ? cell.value : 0),
          },
          {
            headerValueGetter: () => t("half-days"),
            field: "half_days",
            hide:
              isColumnActive?.leaves && isColumnActive?.half_days
                ? false
                : true,
            valueFormatter: (cell) => (cell.value ? cell.value : 0),
          },
        ],
      },
      {
        headerValueGetter: () => t("Check-in Hours"),
        headerClass: "checkin_header_group",
        hide: isColumnActive?.total_checkIn_hours_secs ? false : true,
        children: [
          {
            headerValueGetter: () => t("duration"),
            minWidth: 120,
            field: "total_checkIn_hours_secs",
            hide: isColumnActive?.total_checkIn_hours_secs ? false : true,
            valueFormatter: (cell) => HelperFns.secToTime(cell.value),
          },
        ],
      },
      {
        headerValueGetter: () => t("permissions"),
        headerClass: "permissions_header_group",
        hide:
          isColumnActive?.permissionsColumn &&
            (isColumnActive?.permissions || isColumnActive?.permissions_time)
            ? false
            : true,
        children: [
          {
            headerValueGetter: () => t("permissions"),
            field: "permissions",
            hide:
              isColumnActive?.permissionsColumn && isColumnActive?.permissions
                ? false
                : true,
            valueFormatter: (cell) => (cell.value ? cell.value : 0),
          },
          {
            headerValueGetter: () => t("duration"),
            field: "permissions_time",
            hide:
              isColumnActive?.permissionsColumn &&
                isColumnActive?.permissions_time
                ? false
                : true,
            valueFormatter: (cell) => (cell.value ? cell.value : 0),
          },
        ],
      },
      {
        headerValueGetter: () => t("breaks"),
        headerClass: "breaks_header_group",
        hide:
          isColumnActive?.breaksColumn &&
            (isColumnActive?.breaks || isColumnActive?.breaks_time)
            ? false
            : true,
        children: [
          {
            headerValueGetter: () => t("number"),
            field: "breaks",
            hide:
              isColumnActive?.breaksColumn && isColumnActive?.breaks
                ? false
                : true,
            valueFormatter: (cell) => (cell.value ? cell.value : 0),
          },
          {
            headerValueGetter: () => t("duration"),
            field: "breaks_time",
            hide:
              isColumnActive?.breaksColumn && isColumnActive?.breaks_time
                ? false
                : true,
            valueFormatter: (cell) => (cell.value ? cell.value : 0),
          },
        ],
      },
      {
        headerValueGetter: () => t("penalties"),
        headerClass: "breaks_header_group",
        hide:
          !isColumnActive?.total_early_sign_out_time &&
          !isColumnActive?.total_penalties_as_days &&
          !isColumnActive?.total_penalties_as_fixed_amount &&
          !isColumnActive?.total_penalties_as_hours,
        children: [
          {
            headerValueGetter: () => t("total early sign out time"),
            field: "total_early_sign_out_sec",
            width: 160,
            hide: isColumnActive?.total_early_sign_out_time ? false : true,
          },
          {
            headerValueGetter: () => t("total penalties as days"),
            field: "total_penalties_as_days",
            width: 160,
            hide: isColumnActive?.total_penalties_as_days ? false : true,
          },
          {
            headerValueGetter: () => t("total penalties as fixed amount"),
            field: "total_penalties_as_fixed_amount",
            width: 160,
            hide: isColumnActive?.total_penalties_as_fixed_amount
              ? false
              : true,
          },
          {
            headerValueGetter: () => t("total penalties as hours"),
            field: "total_penalties_as_hours",
            width: 160,
            hide: isColumnActive?.total_penalties_as_hours ? false : true,
          },
        ],
      },
      {
        headerValueGetter: () => t("work hours"),
        headerClass: "work_hours_header_group",
        hide:
          isColumnActive?.workHours &&
            (isColumnActive?.total_hours_secs ||
              isColumnActive?.expected_hours_secs)
            ? false
            : true,
        children: [
          {
            headerValueGetter: () => t("expected work hrs"),
            field: "expected_hours_secs",
            width: 120,
            hide:
              isColumnActive?.workHours && isColumnActive?.expected_hours_secs
                ? false
                : true,
            valueFormatter: (cell) => HelperFns.secToTime(cell.value),
          },
          {
            headerValueGetter: () => t("total hrs worked"),
            field: "total_hours_secs",
            width: 120,
            hide:
              isColumnActive?.workHours && isColumnActive?.total_hours_secs
                ? false
                : true,
            valueFormatter: (cell) => HelperFns.secToTime(cell.value),
          },
          {
            headerValueGetter: () => t("total hrs without overtime"),
            field: "total_hours_with_out_over_time_sec",
            width: 120,
            hide:
              isColumnActive?.workHours &&
                isColumnActive?.total_hours_with_out_over_time_sec
                ? false
                : true,
            valueFormatter: (cell) => {
              return HelperFns.secToTime(cell.value);
            },
          },
        ],
      },
      {
        headerValueGetter: () => t("total overtime hours"),
        field: "total_over_time_hours",
        width: 160,
        hide: isColumnActive?.total_over_time_hours ? false : true,
      },
      {
        headerValueGetter: () => t("accepted overtime"),
        headerClass: "overtime_header_group",
        hide:
          isColumnActive?.overtime &&
            (isColumnActive?.overtime_days ||
              isColumnActive?.accepted_overtime_hours)
            ? false
            : true,
        children: [
          {
            headerValueGetter: () => t("days"),
            field: "overtime_days",
            hide:
              isColumnActive?.overtime && isColumnActive?.overtime_days
                ? false
                : true,
            valueFormatter: (cell) => (cell.value ? cell.value : 0),
          },
          {
            headerValueGetter: () => t("duration"),
            field: "accepted_overtime_hours",
            hide:
              isColumnActive?.overtime &&
                isColumnActive?.accepted_overtime_hours
                ? false
                : true,
            valueFormatter: (cell) => (cell.value ? cell.value : 0),
          },
        ],
      },
      {
        headerValueGetter: () => t("Mocked Location Count"),
        headerClass: "mocked_location_header_group",
        children: [
          {
            headerValueGetter: () => t("sign in"),
            field: "mocked_location_in_count",
            width: 120,
            hide: isColumnActive?.mocked_location_in_count ? false : true,
            valueFormatter: (cell) => (cell.value ? cell.value : null),
          },
          {
            headerValueGetter: () => t("sign out"),
            field: "mocked_location_out_count",
            width: 120,
            hide: isColumnActive?.mocked_location_out_count ? false : true,
            valueFormatter: (cell) => (cell.value ? cell.value : null),
          },
        ],
      },
      {
        headerValueGetter: () => t("Spoofed image count"),
        headerClass: "spoofed_image_header_group",
        children: [
          {
            headerValueGetter: () => t("sign in"),
            field: "spoofed_sign_in_image_count",
            width: 120,
            hide: isColumnActive?.spoofed_sign_in_image_count ? false : true,
            valueFormatter: (cell) => (cell.value ? cell.value : null),
          },
          {
            headerValueGetter: () => t("sign out"),
            field: "spoofed_sign_out_image_count",
            width: 120,
            hide: isColumnActive?.spoofed_sign_out_image_count ? false : true,
            valueFormatter: (cell) => (cell.value ? cell.value : null),
          },
        ],
      },
    ];
  }, [tableState, isColumnActive, user?.lng, t]);

  useEffect(() => {
    gridRef?.current?.api?.sizeColumnsToFit({
      defaultMinWidth: 118,
    });
  }, [tableState, isColumnActive, user?.lng, t]);

  return (
    <>
      <div className="content payroll_wrapper_style monthly-report-style no-padding-first-child position-relative">
        <div className="d-xl-flex align-items-center justify-content-between gap-20 mb-3">
          <div className="d-flex flex-1 gap-10">
            <BSelect
              formName={dataTableRef}
              name="office_id"
              optionLabel="name"
              optionValue="id"
              options={user?.offices}
              keepDefaultStyle
              isClearable
              placeholder={t("all offices")}
              icon="office"
              rootStyle="flex-1"
              skipLocalization
            />
            <BSelect
              formName={dataTableRef}
              name="department_id"
              optionLabel="name"
              optionValue="id"
              options={companyDepartments}
              keepDefaultStyle
              isClearable
              placeholder={t("all departments")}
              icon="department"
              rootStyle="flex-1"
            />
            <BSelect
              reducer="super"
              name="position_id"
              formName={dataTableRef}
              options={allPositions}
              keepDefaultStyle
              optionLabel="name"
              optionValue="id"
              isClearable
              placeholder={t("all positions")}
              icon="person"
              rootStyle="flex-1"
            />
            <BSelect
              reducer="super"
              name="user_id"
              formName={dataTableRef}
              options={extraEmployeesFilter}
              keepDefaultStyle
              optionLabel="name"
              optionValue="id"
              isClearable
              placeholder={t("select_employee")}
              icon="person"
              rootStyle="flex-1"
            />
          </div>

          <div className="d-flex justify-content-between align-align-items-center gap-5">
            <DateTimePickerForm
              name={fromVar}
              formName={dataTableRef}
              label="from"
              picker={pickerVar}
              format={formatVar}
              hasIcon
              labelStyle="label-style mb-0 custom-date-label-style"
              containerStyle="container-style-default mr-sm-0 mr-md-4 flex-nowrap py-0 d-flex mb-2 mb-md-0"
              rootStyle="att-datepicker-style"
              requestFormat={requestFormatVar}
              datePickerContainer=" "
            />

            <DateTimePickerForm
              name={toVar}
              formName={dataTableRef}
              label="to"
              picker={pickerVar}
              format={formatVar}
              hasIcon
              labelStyle="label-style mb-0 custom-date-label-style"
              containerStyle="container-style-default flex-nowrap mr-0 mr-md-2 py-0  d-flex"
              rootStyle="att-datepicker-style"
              requestFormat={requestFormatVar}
              datePickerContainer=" "
            />
            <div className="d-flex align-items-center gap-5">
              <HasPrivileges
                reqireMain={[Privilages.EXPORT_ATTENDANCE_MONTHLY_REPORT]}
                allowBP
                avalibleOnExpire={false}
                altExpireChildren={
                  <button
                    className="btn py-0 monthly-export mx-2"
                    type="button"
                    disabled
                  >
                    <FontAwesomeIcon className="mr-2" icon={faFileExport} />
                    {t("export")}
                  </button>
                }
              >
                <button
                  className="btn py-0 monthly-export text-nowrap mx-2"
                  type="button"
                  onClick={() =>
                    dispatch(
                      showExportMonthlyReportModal({
                        ref: "exportMonthlyReportModal",
                      })
                    )
                  }
                >
                  <FontAwesomeIcon className="mr-2" icon={faFileExport} />
                  {t("export")}
                </button>
              </HasPrivileges>

              <BarsIconButton onClick={() => setIsToggleModalVissible(true)} />
            </div>
          </div>
        </div>

        {tableState?.isLoading ? (
          <div className="loader_wrapper_style position-absolute">
            <Loader />
          </div>
        ) : null}

        <AgGridTable
          key={user?.lng}
          ref={gridRef}
          className="monthlyreport-table"
          columnDefs={columnDefs}
          rowData={gridRowData}
          enableSorting={true}
          tableState={tableState}
          tableFetcherAction={fetchMonthlyReportListAction}
          dataTableRef={dataTableRef}
          gridOptions={{
            enableRtl: user?.lng === "ar-EG" ? true : false,
          }}
        />

        <Pagination
          tableRef={dataTableRef}
          styleWraper=""
          onPaginate={handlePaginate}
          reducer="super"
        />

        {/* Start of Toggled Modal */}
        <ToggleModal
          isModalVissible={isToggleModalVissible}
          toggleModal={() => setIsToggleModalVissible(!isToggleModalVissible)}
          reportFormName={formName}
        />
        {/* End of Toggled Modal  */}

        {/* Start of Export Modal */}
        <ExportModal
          column={tableState?.orderBy?.column}
          order={tableState?.orderBy?.order}
        />
        {/* End of Export Modal  */}
      </div>
    </>
  );
};

export default MonthlyReport;
