import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";

import {
  onInputChangeAction,
  showPenaltyModal,
  fetchPenaltiesSuccess,
  fetchPenaltiesEmployeesFilter,
} from "../../Store/Actions";
import moment from "moment";
import Constants from "../../Helpers/Constants";
import HelperFns, { showToast } from "../../Helpers/HelperFns";
import Privilages from "../../Constants/Privilages";
import { penaltiesQuery } from "../../Graphql/query";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import { applyPenaltiesMutation } from "../../Graphql/mutation";
import CanViewEmployeeProfile from "../../Helpers/HOC/CanViewEmployeeProfile";

import { Link } from "react-router-dom";
import Loader from "../../Components/Loader";
import AntSwitch from "../../Components/AntSwitch";
import DataTable from "react-data-table-component";
import { BSelect, DateTimePickerForm, RadioboxForm } from "form-builder";
import Pagination from "../../Components/Pagination";
import { faCoins } from "@fortawesome/free-solid-svg-icons";
import EditPenaltyModal from "../../Components/EditPenaltyModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EditIconButton } from "../../Components/IconButtonWithTooltip";
import { ExportButton } from "../../Components/Buttons";
import MainModal from "../../Components/MainModal";
import { CheckboxBooleanForm } from "form-builder";

const dataTableRef = "penaltiesList";

const exportModalInitState = { isOpen: false, data: null };
const EXPORT_PENALTIES = gql`
  query exportPenaltiesQuery($input: penaltyColumns) {
    export_penalties_report(input: $input) {
      file
      status
      message
    }
  }
`;

const Penalties = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Local State
  const [exportModalState, setExportModalState] =
    React.useState(exportModalInitState);

  // Reducer State
  const penaltiesList = useSelector((state) => state.super[dataTableRef]);
  const penaltiesEmployeesFilter = useSelector(
    (state) => state.super.penaltiesEmployeesFilter
  );

  // Constants
  const PenaltyFilterInput = {
    applied: penaltiesList?.applied,
    deducted_from: penaltiesList?.deducted_from,
    skip_zero_penalties: penaltiesList?.skip_zero_penalties,
    cause: penaltiesList?.cause ? penaltiesList?.cause : null,
    emp_id: penaltiesList?.emp_id ? penaltiesList?.emp_id : null,
    dateRange: {
      to: penaltiesList?.to,
      from: penaltiesList?.from,
    },
  };

  // Server State
  const {
    data: penaltiesData,
    loading: fetchPenaltiesLoading,
    refetch: refetchPenaltiesData,
  } = useQuery(penaltiesQuery, {
    onError: (err) => showToast("error", err?.message),
    onCompleted: (data) => {
      dispatch(
        fetchPenaltiesSuccess(dataTableRef, data?.users_penalties_by_role)
      );
      dispatch(fetchPenaltiesEmployeesFilter(data?.employees));
    },
    notifyOnNetworkStatusChange: true,
    variables: {
      route: "penalties_list",
      rows: penaltiesList?.pagination?.perPage || 30,
      page: penaltiesList?.pagination?.currentPage || 1,
      input: PenaltyFilterInput,
    },
  });

  /* ↓ State Effects ↓ */
  useEffect(() => {
    let input = {
      applied: penaltiesList?.applied,
      deducted_from: penaltiesList?.deducted_from,
      skip_zero_penalties: penaltiesList?.skip_zero_penalties,
      cause: penaltiesList?.cause ? penaltiesList?.cause : null,
      emp_id: penaltiesList?.emp_id ? penaltiesList?.emp_id : null,
      dateRange: {
        to: penaltiesList?.to,
        from: penaltiesList?.from,
      },
    };
    refetchPenaltiesData({ input, page: 1 });
  }, [
    penaltiesList?.applied,
    penaltiesList?.deducted_from,
    penaltiesList?.skip_zero_penalties,
    penaltiesList?.cause,
    penaltiesList?.emp_id,
    penaltiesList?.from,
    penaltiesList?.to,
  ]);

  /* ↓ Helpers ↓ */

  // Mutations
  const [applyPenalty, { loading: isApplyPenaltyLoading }] = useMutation(
    applyPenaltiesMutation,
    {
      onError: (err) =>
        showToast(
          "error",
          err?.graphQLErrors?.[0]?.extensions?.reason ?? err?.message
        ),
      onCompleted: () => {
        showToast("success");
        refetchPenaltiesData();
      },
    }
  );

  const columns = [
    {
      name: t("employee"),
      wrap: true,
      sortable: false,
      grow: 1.5,
      cell: (row) => (
        <CanViewEmployeeProfile
          allowBP
          directManger={row?.employee?.user?.manager?.id}
          copiedManagers={row?.employee?.user?.copied_managers?.map(
            (cp) => cp?.id
          )}
          altChildren={
            <div className="col pl-0">{row?.employee?.user?.name}</div>
          }
        >
          <div className="col pl-0">
            <Link
              className="employee-name"
              to={`/employees/employee-profile/${row?.employee?.user?.id}`}
            >
              {row?.employee?.user?.name}
            </Link>
          </div>
        </CanViewEmployeeProfile>
      ),
    },
    {
      name: t("date"),
      wrap: true,
      selector: "date",
      sortable: false,
      grow: 1.2,
      cell: (row) => <>{moment(row?.date).format("DD - MM - YYYY")}</>,
    },
    {
      name: t("cause"),
      wrap: true,
      selector: "full_cause",
      sortable: false,
      grow: 1.25,
      cell: (row) => <>{t(row?.full_cause)}</>,
    },
    // {
    //   name: t("Violation"),
    //   wrap: true,
    //   selector: "violation",
    //   sortable: false,
    //   grow: 1.2,
    // },
    {
      name: t("value"),
      wrap: true,
      selector: "quantity",
      sortable: false,
      grow: 1,
      cell: (row) => {
        return (
          <>
            <span className="panlties-balance-list-value">
              {row?.quantity + " "}
            </span>
            {row?.quantity_unit?.toLowerCase() === "fixed_amount" ? (
              <FontAwesomeIcon icon={faCoins} />
            ) : (
              t(row?.quantity_unit ?? "days")
            )}
          </>
        );
      },
    },
    {
      name: t("from"),
      wrap: true,
      selector: "deducted_from",
      sortable: false,
      grow: 1,
      cell: (row) => <>{t(row?.deducted_from?.toLowerCase())}</>,
    },
    {
      name: t("Applied"),
      wrap: true,
      selector: "apply_flag",
      sortable: false,
      grow: 1,
      cell: (row) =>
        row?.canApply ? (
          <HasPrivileges
            reqireMain={[Privilages.APPLY_REVERT_PENALTIES]}
            allowBP
            altChildren={
              <AntSwitch
                inputProps={{ "aria-label": "Switch demo" }}
                checked={row?.apply_flag}
                disabled
                color="primary"
              />
            }
            avalibleOnExpire={false}
            altExpireChildren={
              <AntSwitch
                inputProps={{ "aria-label": "Switch demo" }}
                checked={row?.apply_flag}
                disabled
                color="primary"
              />
            }
          >
            <AntSwitch
              inputProps={{ "aria-label": "Switch demo" }}
              checked={row?.apply_flag}
              onChange={() => applyPenalty({ variables: { id: row.id } })}
              color="primary"
            />
          </HasPrivileges>
        ) : null,
    },
    {
      cell: ({ date, sign_in_out, employee, ...penalty }) =>
        penalty.deducted_from === "Salary" ? (
          <EditIconButton
            onClick={() =>
              dispatch(
                showPenaltyModal({
                  formName: "editPenaltyModal",
                  ref: "editPenaltyModalActions",
                  data: {
                    date,
                    penalties: [penalty],
                    balance: employee.user.balance,
                    employeeName: employee.user.name,
                    sign_in_time: sign_in_out.sign_in_time,
                    sign_out_time: sign_in_out.sign_out_time,
                  },
                })
              )
            }
          />
        ) : null,
    },
  ];

  return (
    <div className="penalties_wrapper_style">
      {isApplyPenaltyLoading ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : null}

      <div className="row mb-0 align-items-baseline my-2 my-lg-0">
        <div className="col-12 col-lg-6">
          <RadioboxForm
            formName={dataTableRef}
            options={Constants.penaltiesStatusFilter?.map(
              ({ label, value }) => ({
                label: t(label),
                value,
              })
            )}
            label="penalties"
            name="applied"
            type="radio"
            containerStyle="d-flex"
            labelStyle="label-style"
            optionsContainerStyle="optionsContainerStyle"
            optionItemStyle="optionItemStyle"
            optionInputStyle=" "
            optionLabelStyle="optionLabelStyle"
          />
        </div>

        <div className="col-12 col-lg-6 d-flex justify-content-between align-items-center">
          <RadioboxForm
            formName={dataTableRef}
            options={Constants.deductedFromStatusFilter?.map(
              ({ label, value }) => ({
                label: t(label),
                value,
              })
            )}
            label="deducted from"
            name="deducted_from"
            type="radio"
            containerStyle="d-flex"
            labelStyle="label-style"
            optionsContainerStyle="optionsContainerStyle"
            optionItemStyle="optionItemStyle"
            optionInputStyle=" "
            optionLabelStyle="optionLabelStyle"
          />
          <ExportButton
            onClick={() =>
              setExportModalState({
                isOpen: true,
                data: { PenaltyFilterInput },
              })
            }
          />
        </div>
      </div>

      <div className="row mb-3 align-items-baseline">
        <div className="col-12 col-lg-6">
          <div className="row align-items-baseline">
            <div className="col-12 col-lg-5 my-2 my-lg-0">
              <BSelect
                name="emp_id"
                formName={dataTableRef}
                options={penaltiesEmployeesFilter}
                keepDefaultStyle
                optionLabel="name"
                optionValue="id"
                containerStyle=""
                isClearable
                placeholder={t("select_employee")}
                icon="person"
              />
            </div>

            <div className="d-flex align-items-center col-12 col-lg-7 my-2 my-lg-0">
              <label>{t("Show penalties with 0 amount")}</label>
              <AntSwitch
                className="mx-2"
                name="skip_zero_penalties"
                checked={penaltiesList.skip_zero_penalties}
                onChange={(event, value) => {
                  let e = {
                    target: {
                      name: event.target.name,
                      value,
                    },
                  };
                  dispatch(onInputChangeAction(dataTableRef, e));
                }}
              />
            </div>
          </div>
        </div>

        <div className="col-12 col-lg-6">
          <div className="row align-items-baseline">
            <div className="col-12 col-lg-6 my-2 my-lg-0">
              <BSelect
                name="cause"
                optionLabel="label"
                optionValue="value"
                options={Constants.causeFilterOptions}
                formName={dataTableRef}
                keepDefaultStyle
                containerStyle=""
                isClearable
                placeholder={t("select cause")}
                icon="type"
              />
            </div>

            <div className="col-12 col-lg-3 px-lg-0 my-2 my-lg-0">
              <DateTimePickerForm
                name="from"
                placeholder="from"
                formName={dataTableRef}
                requestFormat="YYYY-MM-DD"
              />
            </div>

            <div className="col-12 col-lg-3 my-2 my-lg-0">
              <DateTimePickerForm
                name="to"
                placeholder="to"
                formName={dataTableRef}
                requestFormat="YYYY-MM-DD"
              />
            </div>
          </div>
        </div>
      </div>

      <DataTable
        noDataComponent={<div className="p-4"> {t("no_records")} </div>}
        className="cards_table"
        columns={columns}
        data={penaltiesList.data}
        noHeader
        persistTableHead
        paginationComponent={() => (
          <Pagination
            styleWraper=""
            reducer="super"
            tableRef={dataTableRef}
            onPaginate={(page = penaltiesList.pagination.currentPage) => {
              refetchPenaltiesData({ page });
            }}
          />
        )}
        pagination={true}
        paginationServer={true}
        progressPending={fetchPenaltiesLoading}
        progressComponent={<Loader />}
      />

      {exportModalState?.isOpen ? (
        <ExportModal
          data={exportModalState.data}
          onClose={() => setExportModalState(exportModalInitState)}
        />
      ) : null}
      <EditPenaltyModal refetchList={refetchPenaltiesData} />
    </div>
  );
};

export default Penalties;

const ExportModal = ({ data, onClose }) => {
  const { t } = useTranslation();

  // Local State
  const [input, setInput] = React.useState({
    employee: false,
    date: false,
    cause: false,
    value: false,
    from: false,
    apply: false,
    position: false,
    department: false,
    office: false,
  });

  // Server State
  const [exportPenalties, { loading: exportLoading }] =
    useLazyQuery(EXPORT_PENALTIES);

  /* ↓ Helpers ↓ */

  const handleChange = (e) => {
    setInput((prev) => ({ ...prev, [e.target.name]: e.target.checked }));
  };

  const handleExport = () => {
    exportPenalties({
      variables: {
        input: {
          ...input,
          PenaltyFilterInput: data?.PenaltyFilterInput,
        },
      },
      onCompleted: ({ export_penalties_report = {} }) => {
        onClose();
        HelperFns.downloadFile(export_penalties_report?.file);
        showToast("success", export_penalties_report?.message);
      },
      onError: () => {
        showToast("error");
      },
    });
  };

  return (
    <MainModal
      isOpen
      toggle={onClose}
      btnOnClick={handleExport}
      btnSubmitLoading={exportLoading}
      modalTitle={t("export penalties")}
    >
      {Object.keys(input).map((key) => (
        <CheckboxBooleanForm
          key={key}
          name={key}
          options={[t(key)]}
          checked={input[key]}
          onChange={handleChange}
          setWithValue
        />
      ))}
    </MainModal>
  );
};
