import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useLazyQuery, useQuery } from "@apollo/client";
import { manualPhoneVerificationMutation } from "../../Graphql/mutation";
import Privilages from "../../Constants/Privilages";
import { systemUsersListQuery } from "../../Graphql/query";
import { openSwalConfirm, showToast } from "../../Helpers/HelperFns";
import IconButtonWithTooltip from "../../Components/IconButtonWithTooltip";
import Loader from "../../Components/Loader";
import DataTable from "react-data-table-component";
import Pagination from "../../Components/Pagination";
import { InputForm } from "form-builder";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import { faUserCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MainModal from "../../Components/MainModal";
import { Checkbox, FormControlLabel, IconButton, InputAdornment, OutlinedInput } from "@mui/material";
import { Visibility, VisibilityOff, VpnKey } from "@mui/icons-material";

// list paginator init state constants
const paginationInitState = {
  total: 20,
  perPage: 20,
  lastPage: 1,
  lastItem: 20,
  firstItem: 1,
  currentPage: 1,
  hasMorePages: false,
};

const ManualVerificiations = (props) => {
  const { t } = useTranslation();

  // Local State
  const [pagination, setPagination] = useState(paginationInitState);
  const [filters, setFilters] = useState({
    phone: "",
  });



  // handle fetch system users
  const handleFetchUsersSystemList = (page, phone) => {
    fetchSystemUsersList({
      variables: {
        input: { phone: phone ?? filters?.phone },
        first: 20,
        page: page ?? (pagination?.currentPage || 1),
      },
    });
  };

  // Server State

  useEffect(() => {
    handleFetchUsersSystemList();

    return () => {

    }
  }, [])

  // Start of handle fetch system users query
  const [
    fetchSystemUsersList,
    { data: systemUsersData, loading: listLoading, refetch },
  ] = useLazyQuery(systemUsersListQuery, {
    notifyOnNetworkStatusChanged: true,
    onError(err) {
      // error toaster
      showToast(
        "error",
        err?.graphQLErrors[0]?.extensions?.reason ||
        err?.graphQLErrors[0]?.message ||
        err?.message
      );
    },
    onCompleted: (data) => {
      // set data table pagination
      setPagination(data?.system_users?.paginatorInfo);
    },
  });
  // End of handle fetch system users query



  // handle phone verifcation function
  const handlePhoneVerifiication = (userId) => {
    openSwalConfirm((willVerfiy) => {
      if (willVerfiy) {
        // handleVerfiyPhone(userId);
      }
    });
  };

  // Constants
  const columns = [
    {
      name: t("name"),
      wrap: true,
      selector: "name",
      sortable: false,
      grow: 1,
    },
    {
      name: t("phone number"),
      wrap: true,
      selector: "phone",
      sortable: false,
      grow: 1,
      cell: (row) => (
        <div>
          {row?.phone_country_code ? (
            <>({row?.phone_country_code})</>
          ) : (
            "------------"
          )}{" "}
          {row?.phone}
        </div>
      ),
    },
    {
      name: t("company"),
      wrap: true,
      sortable: false,
      grow: 1,
      cell: (row) => (
        <div>{row?.company?.name ? row?.company?.name : "------------"}</div>
      ),
    },
    {
      name: t("verified by"),
      wrap: true,
      sortable: false,
      grow: 1,
      cell: (row) => (
        <div>
          {row?.phone_verified
            ? row?.phone_verified_by !== null
              ? row?.phone_verified_by?.name
              : t("by own user")
            : "------------"}
        </div>
      ),
    },
    {
      name: t("user status"),
      wrap: true,
      sortable: false,
      grow: 1,
      cell: (row) => (
        <div>
          {row?.active ? t("active") : t("suspended")}
        </div>
      ),
    },
    {
      name: t("has temporary password"),
      wrap: true,
      sortable: false,
      grow: 1,
      cell: (row) => (
        <div>
          {row?.has_temporary_password ? t("yes") : t("no")}
        </div>
      ),
    },
    {
      name: t("actions"),
      wrap: true,
      selector: "action",
      grow: 0.5,
      cell: (row) => (
        <div className="cards_table_actions">
          <HasPrivileges
            reqireMain={[
              Privilages.SUPER_PRIVILEGE,
              Privilages.ADMIN_MANUAL_VERIFY_EMPLOYEE,
            ]}
          >
            {row?.phone_verified ? (
              <IconButtonWithTooltip
                label="Phone verified"
                onClick={() => handleToogleModal(true, row)}
                icon={
                  <FontAwesomeIcon
                    size="lg"
                    icon={faUserCheck}
                    color="#27b40c"
                    {...props?.iconProps}
                  />
                }
              />
            ) : (
              <IconButtonWithTooltip
                label="Verify Phone"
                onClick={() => handleToogleModal(true, row)}
                icon={
                  <FontAwesomeIcon
                    size="md"
                    icon={faUserCheck}
                    color="#8997a4"
                    {...props?.iconProps}
                  />
                }
              />
            )}
          </HasPrivileges>
        </div>
      ),
      ignoreRowClick: true,
    },
  ];

  /* ↓ Helpers ↓ */

  // handle filters on change function
  const handleFiltersChange = (e) => {
    setFilters((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    handleFetchUsersSystemList(1, e?.target?.value)
  };

  // handle paginate function
  const handlePaginate = (page = pagination.currentPage) => {
    setPagination((prev) => ({ ...prev, currentPage: page }));
    handleFetchUsersSystemList(page);

  };

  const [modalData, setModalData] = useState({
    isOpen: false,
    user: null
  });

  const handleToogleModal = (isOpen = false, user = null) => {
    setModalData({
      isOpen,
      user
    })
  }

  return (
    <>
      {/* Filters */}
      <InputForm
        name="phone"
        type="number"
        icon="search"
        rootStyle="my-3"
        containerStyle=" "
        value={filters?.phone}
        onChange={handleFiltersChange}
        placeholder={t("search phone")}
      />

      {/* List */}
      <DataTable
        noHeader
        columns={columns}
        data={systemUsersData?.system_users?.data || []}
        className="cards_table mt-4"
        progressPending={listLoading}
        progressComponent={<Loader />}
        pagination
        paginationServer
        paginationComponent={() => (
          <Pagination
            styleWraper=""
            onPaginate={handlePaginate}
            customPaginator={pagination}
          />
        )}
      />
      {modalData?.isOpen ?
        <SetManualTemporaryPasswordModal refetch={refetch} toggle={handleToogleModal} {...modalData} />
        : null}
    </>
  );
};

export default ManualVerificiations;




const SetManualTemporaryPasswordModal = (props) => {
  const { t } = useTranslation();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [validationMsg, setValidationMsg] = useState("");

  const [tempPasssowrd, setTempPasssowrd] = useState(false);

  const handleChange = (e) => {
    setTempPasssowrd(e?.target?.checked);
  }

  const { isOpen, user } = props;

  const dismiss = () => {
    props.toggle();
    setPassword("")
    setConfirmPassword("")
    setValidationMsg("")
    setShowPassword(false);
    setShowConfirmPassword(false);
    setTempPasssowrd(false);
  }

  const [manualPhoneVerification, { loading }] = useMutation(
    manualPhoneVerificationMutation,
    {
      variables: {
        id: user?.id,
        password: tempPasssowrd ? password : undefined,
      },
      onCompleted: (data) => {
        console.log(data);
        const { status, message } = data?.admin_verify_phone_number ?? {};
        if (status == "success") {
          showToast("success", message);
          props.refetch();
          dismiss();
        } else {
          showToast("error", message);
        }
      },
      onError: (err) => {
        showToast(
          "error",
          err?.graphQLErrors[0]?.extensions?.reason ||
          err?.graphQLErrors[0]?.message ||
          err?.message
        );
      },
    }
  );


  // Start of handle phone verfication mutation

  // End of handle phone verfication mutation
  const handleCloseModal = () => {
    dismiss();
  };

  const handleSubmit = () => {
    setValidationMsg("");
    // Handle Empty password
    if ((!password || !confirmPassword) && tempPasssowrd) {
      return setValidationMsg("Please Enter your password");
    }
    // Handle Not matching password
    if ((password !== confirmPassword) && tempPasssowrd) {
      return setValidationMsg("Please make sure your passwords match");
    }

    manualPhoneVerification();
  };

  const handleChangePassword = (e) => {
    setPassword(e?.target?.value ?? "");
  };
  const handleChangeConfirmPassword = (e) => {
    setConfirmPassword(e?.target?.value ?? "");
  };


  return (
    <MainModal
      isOpen={isOpen}
      toggle={handleCloseModal}
      modalTitle={`${t("set temporary password")} - ${user?.name}`}
      className="work_schedule_modal"
      btnLabel={t("save")}
      btnOnClick={handleSubmit}
      btnSubmitLoading={loading}
      size={"lg"}
    >
      <>
        <FormControlLabel
          control={
            <Checkbox checked={tempPasssowrd} onChange={handleChange} name="tempPasssowrd" />
          }
          label="set temporary password"
        />
        {tempPasssowrd ?
          <>
            <div className="my-3 d-flex flex-column login_input_wrapper_style">
              <label className="mb-2">{t("password")}</label>
              <OutlinedInput
                type={showPassword ? "text" : "password"}
                value={password}
                name="password"
                onChange={handleChangePassword}
                placeholder={t("password")}
                classes={{
                  root: "custom_outlined_input_style h-40px overflow-hidden",
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <IconButton tabIndex="-1">
                      <VpnKey />
                    </IconButton>
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setShowPassword((prev) => !prev);
                      }}
                      tabIndex="-1"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </div>
            <div className="my-3 d-flex flex-column login_input_wrapper_style">
              <label className="mb-2">{t("confirm password")}</label>
              <OutlinedInput
                type={showConfirmPassword ? "text" : "password"}
                value={confirmPassword}
                name="confirmPassword"
                onChange={handleChangeConfirmPassword}
                placeholder={t("confirm password")}
                classes={{
                  root: "custom_outlined_input_style  h-40px overflow-hidden",
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <IconButton tabIndex="-1">
                      <VpnKey />
                    </IconButton>
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setShowConfirmPassword((prev) => !prev);
                      }}
                      tabIndex="-1"
                    >
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </div>
            {validationMsg && (
              <div className="warnig-msg-style mt-2">{validationMsg}</div>
            )}
          </>
          : null}
      </>
    </MainModal>
  );
};

