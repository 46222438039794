import React, { useEffect, useState } from "react";
import MainModal from "../../Components/MainModal";
import {
  BSelect,
  InputForm,
  DateTimePickerForm,
  RadioboxForm,
} from "form-builder";
import { connect } from "react-redux";
import {
  dismissAddNewBonusModalAction,
  upsertBonusAction,
  onInputChangeAction,
} from "../../Store/Actions";
import Const from "../../Constants";
import { useTranslation } from "react-i18next";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

const formName = "AddNewBonusModal";
const formNameValidation = "AddNewBonusModalValidation";
const formServerValidation = "BonusFormServerValidation";

const GET_MODAL_OPTIONS = gql`
  query getModalOptions {
    users_by_role(
      input: { status: ["Active"], page_flag: "adjustments_bonuses_deductions" }
    ) {
      data {
        id
        name
      }
    }
    company_departments(first: 999, page: 1) {
      data {
        id
        name
      }
    }
    company_offices(first: 999, page: 1) {
      data {
        id
        name
      }
    }
  }
`;

const AddNewBonusModal = (props) => {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [options, setOptions] = useState({
    users: [],
    offices: [],
    departments: [],
  });

  
  // Server State
  const { loading } = useQuery(GET_MODAL_OPTIONS, {
    onCompleted: (res) => {
      setOptions((prev) => ({
        ...prev,
        users: res?.users_by_role?.data || [],
        offices: res?.company_offices?.data || [],
        departments: res?.company_departments?.data || [],
      }));
    },
  });

  // Constants
  const isModalVissible = props.modalData.isVissible;
  const modalMessage = props.modalData.modalMessage;
  const isLoading = props.modalData.isLoading;

  const toggleModal = () => {
    props.dismissAddNewBonusModalAction({
      ref: "AddNewBonusModalActions",
      formName,
    });
  };

  useEffect(() => {
    setIsSubmitting(false);
  }, [isModalVissible]);

  const handleSubmitBtn = () => {
    setIsSubmitting(true);

    if (!props.modalValidation.length) {
      props.upsertBonusAction({
        ref: "AddNewBonusModalActions",
        formName,
        data: props.AddNewBonusModal,
      });
    }
  };

  const handleTextArea = (e) => {
    props.onInputChangeAction(formName, e);
  };

  return (
    <MainModal
      isOpen={isModalVissible}
      btnLabelCancel="Cancel"
      btnOnCancelClick={toggleModal}
      modalTitle={
        props?.AddNewBonusModal?.id != null
          ? t("Edit Bonus")
          : t("Add New Bonus")
      }
      btnOnClick={handleSubmitBtn}
      btnSubmitLoading={isLoading}
      className="add_bonus_modal_wrapper_style"
    >
      <div>
        <div className="row">
          <div className="col-12">
            <InputForm
              label="bonus name"
              name="name"
              placeholder={t("name")}
              formName={formName}
              formNameValidation={formNameValidation}
              validationName={`input.name`}
              formServerValidation={formServerValidation}
              formSubmitting={isSubmitting}
              labelStyle="mb-2"
              containerStyle=""
              inputContainerStyle="w-100"
              validateBy="textRequired"
              icon="money"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <RadioboxForm
              formName={formName}
              name="apply_on"
              options={[
                {
                  label: "employee",
                  value: "employee",
                },
              ]}
              type="radio"
              containerStyle="my-2"
              labelStyle="label-style"
              optionsContainerStyle="optionsContainerStyle"
              optionItemStyle="optionItemStyle"
              optionInputStyle=" "
              optionLabelStyle="optionLabelStyle"
            />
            <BSelect
              isMulti={!props?.AddNewBonusModal?.id}
              formName={formName}
              formNameValidation={formNameValidation}
              formSubmitting={isSubmitting}
              validationName={`input.user_id`}
              formServerValidation={formServerValidation}
              name="user_id"
              optionLabel="name"
              optionValue="id"
              isLoading={loading}
              options={options?.users || []}
              keepDefaultStyle
              containerStyle=""
              validateBy="textRequired"
              isClearable
              icon="person"
              dependOn="apply_on"
              dependancyType="equal"
              dependancyValue={["employee", undefined, ""]}
            />

            <RadioboxForm
              formName={formName}
              name="apply_on"
              options={[
                {
                  label: "department",
                  value: "department",
                },
              ]}
              type="radio"
              containerStyle="my-2"
              labelStyle="label-style"
              optionsContainerStyle="optionsContainerStyle"
              optionItemStyle="optionItemStyle"
              optionInputStyle=" "
              optionLabelStyle="optionLabelStyle"
              dependOn="id"
              dependancyType="equal"
              dependancyValue={[undefined, null, ""]}
            />

            <BSelect
              formName={formName}
              formNameValidation={formNameValidation}
              formSubmitting={isSubmitting}
              validationName={`input.department_id`}
              formServerValidation={formServerValidation}
              name="department_id"
              optionLabel="name"
              optionValue="id"
              isLoading={loading}
              options={options?.departments || []}
              keepDefaultStyle
              containerStyle=""
              validateBy="textRequired"
              isClearable
              icon="person"
              dependOn="apply_on"
              dependancyType="equal"
              dependancyValue={["department"]}
            />
            <RadioboxForm
              formName={formName}
              name="apply_on"
              options={[
                {
                  label: "office",
                  value: "office",
                },
              ]}
              type="radio"
              containerStyle="my-2"
              labelStyle="label-style"
              optionsContainerStyle="optionsContainerStyle"
              optionItemStyle="optionItemStyle"
              optionInputStyle=" "
              optionLabelStyle="optionLabelStyle"
              dependOn="id"
              dependancyType="equal"
              dependancyValue={[undefined, null, ""]}
            />

            <BSelect
              formName={formName}
              formNameValidation={formNameValidation}
              formSubmitting={isSubmitting}
              validationName={`input.office_id`}
              formServerValidation={formServerValidation}
              name="office_id"
              optionLabel="name"
              optionValue="id"
              isLoading={loading}
              options={options?.offices || []}
              keepDefaultStyle
              containerStyle=""
              validateBy="textRequired"
              isClearable
              icon="person"
              dependOn="apply_on"
              dependancyType="equal"
              dependancyValue={["office"]}
              skipLocalization
            />
          </div>
        </div>

        <div className="row align-items-end">
          <div className="col-12 col-lg-6 mb-2 mb-lg-0">
            <BSelect
              label="type"
              formName={formName}
              formNameValidation={formNameValidation}
              formSubmitting={isSubmitting}
              validationName={`input.payment_factor_id`}
              formServerValidation={formServerValidation}
              name="payment_factor_id"
              optionLabel="label"
              optionValue="value"
              options={Const.BounsTypes}
              keepDefaultStyle
              containerStyle=""
              validateBy="textRequired"
              icon="type"
            />
          </div>

          <div className="col-12 col-lg-6">
            <InputForm
              name="value"
              label="value"
              placeholder={t("value")}
              formName={formName}
              formNameValidation={formNameValidation}
              formSubmitting={isSubmitting}
              validationName={`input.value`}
              formServerValidation={formServerValidation}
              labelStyle="mb-2"
              containerStyle=""
              inputContainerStyle="w-100"
              validateBy="textRequired"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <DateTimePickerForm
              label="payable at"
              name="payroll_at"
              labelStyle="mb-2"
              formName={formName}
              formNameValidation={formNameValidation}
              formSubmitting={isSubmitting}
              validationName={`input.payroll_at`}
              formServerValidation={formServerValidation}
              containerStyle="mb-2"
              datePickerContainer="w-100"
              validateBy="textRequired"
              hasIcon
              requestFormat="yyyy-MM-D"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <label className="mb-2 validity-label-style">
              {t("description")}
            </label>
            <textarea
              rows="4"
              name="description"
              placeholder={t("description")}
              onChange={handleTextArea}
              value={props.AddNewBonusModal?.description}
            />
          </div>
        </div>

        {/* (Start) Form server validation message */}
        <span className="warnig-msg-style">
          {props.serverVaildation[`input.description`] ?? " "}
        </span>
        {/* (End) Form server validation message */}

        {/* (Start) Error Message */}
        {modalMessage && isSubmitting && (
          <div className="warnig-msg-style">{modalMessage}</div>
        )}
        {/* (End) Error Message */}
      </div>
    </MainModal>
  );
};

const mapStateToProps = (state) => ({
  modalData: state.super.AddNewBonusModalActions,
  modalValidation: state.super[formNameValidation],
  AddNewBonusModal: state.super[formName],
  serverVaildation: state.super[formServerValidation],
});

export default connect(mapStateToProps, {
  dismissAddNewBonusModalAction,
  upsertBonusAction,
  onInputChangeAction,
})(AddNewBonusModal);
