import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from "react-router-dom";

import i18n from "i18next";
import Constants from "../../Constants";
import { makeStyles } from "tss-react/mui";
import HelperFns from "../../Helpers/HelperFns";
import Privilages from "../../Constants/Privilages";

import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";

const verticalNavbarStyles = makeStyles()(() => Constants.verticalNavbarStyles);
const horizontalNavbarStyles = makeStyles()(
  () => Constants.horizontalNavbarStyles
);

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const EmployeesTabs = (props) => {
  const { t } = useTranslation();
  const handleChange = (_event, newValue) => {
    history.push(`/employees?tab=${newValue}`);
  };
  const history = useHistory();
  let query = useQuery();
  const activeValue = query.get("tab");
  const { classes } = props?.isRouteSidebar
    ? verticalNavbarStyles()
    : horizontalNavbarStyles();
  const isRtl =
    i18n.dir(useSelector((state) => state.user.userProfile.lng)) === "rtl"
      ? true
      : false;

  const possibleValues = [
    "employees",
    "work-groups",
    "work-teams",
    "employee-actions",
    "employee-documents"
  ];

  return (
    <>
      <Tabs
        variant="scrollable"
        scrollButtons="auto"
        value={possibleValues.includes(activeValue) ? activeValue : false}
        onChange={handleChange}
        orientation={props?.isRouteSidebar ? "vertical" : "horizontal"}
        classes={{
          root: classes.root,
          indicator: classes.indicator,
          flexContainer: classes.flexContainer,
          scrollButtons: classes.scrollButtons,
        }}
      >
        {HelperFns.checkPrivileges({
          allowBP: true,
          privileges: [
            Privilages.VIEW_EMPLOYEES,
            Privilages.VIEW_EMPLOYEE_SALARY_CONFIGURATION,
            Privilages.VIEW_EMPLOYEE_ATTENDANCE_TYPE_CONFIGURATIONS,
            Privilages.VIEW_EMPLOYEE_PETTY_CASH_BALANCE_IN_EMPLOYEE_PROFILE,
            Privilages.VIEW_EMPLOYEE_DOCUMENTS,
            Privilages.VIEW_EMPLOYEE_SCHEDULES,
          ],
        }) ? (
          <Tab
            value={"employees"}
            classes={{
              root: classes.tabRoot,
              wrapper: "top-bar-label",
              selected: isRtl ? classes.selectedRtl : classes.selected,
            }}
            label={
              <div className="routeBar-text">
                <PersonOutlineIcon className="mx-2 routeBar-icon" />
                {t("employees")}
              </div>
            }
          />
        ) : null}

        {HelperFns.checkPrivileges({
          allowBP: true,
          privileges: [Privilages.VIEW_EMPLOYEE_WORK_GROUPS],
        }) ? (
          <Tab
            value={"work-groups"}
            classes={{
              root: classes.tabRoot,
              wrapper: "top-bar-label",
              selected: isRtl ? classes.selectedRtl : classes.selected,
            }}
            label={
              <div className="routeBar-text">
                <GroupWorkIcon className="mx-2 routeBar-icon" />
                {t("work groups")}
              </div>
            }
          />
        ) : null}

        {HelperFns.checkPrivileges({
          allowBP: true,
          privileges: [Privilages.VIEW_WORK_TEAMS],
        }) ? (
          <Tab
            value={"work-teams"}
            classes={{
              root: classes.tabRoot,
              wrapper: "top-bar-label",
              selected: isRtl ? classes.selectedRtl : classes.selected,
            }}
            label={
              <div className="routeBar-text">
                <PersonOutlineIcon className="mx-2 routeBar-icon" />
                {t("work teams")}
              </div>
            }
          />
        ) : null}

        {HelperFns.checkPrivileges({
          allowBP: true,
          privileges: [
            Privilages.VIEW_EMPLOYEES,
            Privilages.EDIT_USER_PRIVILEGE,
            Privilages.ADD_EDIT_DELETE_EMPLOYEES,
          ],
        }) ? (
          <Tab
            value={"employee-actions"}
            classes={{
              root: classes.tabRoot,
              wrapper: "top-bar-label",
              selected: isRtl ? classes.selectedRtl : classes.selected,
            }}
            label={
              <div className="routeBar-text">
                <PersonOutlineIcon className="mx-2 routeBar-icon" />
                {t("employee actions")}
              </div>
            }
          />
        ) : null}
        
        {HelperFns.checkPrivileges({
          allowBP: true,
          privileges: [
            Privilages.VIEW_MISSING_EXPIRED_DOCUMENT,
          ],
        }) ? (
          <Tab
            value={"employee-documents"}
            classes={{
              root: classes.tabRoot,
              wrapper: "top-bar-label",
              selected: isRtl ? classes.selectedRtl : classes.selected,
            }}
            label={
              <div className="routeBar-text">
              <FontAwesomeIcon
                icon={Icons["faFileAlt"]}
                className="mx-2 routeBar-icon"
              />
                {t("employee documents")}
              </div>
            }
          />
        ) : null}
      </Tabs>
    </>
  );
};

export default EmployeesTabs;
