import React, { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
import {
  ErrorOutline,
  Alarm,
  ArrowUpward,
  ArrowDownward,
  Home,
  Edit,
  GpsOff,
  Warning,
  FreeBreakfast,
  AccessTime,
  ErrorOutlineOutlined,
  PlaceOutlined,
} from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link } from "react-router-dom";
import CanViewEmployeeProfile from "../../Helpers/HOC/CanViewEmployeeProfile";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  showViewCheckInsOutsLocationModalAction,
  showEditHistoryModal,
  showEditAttRequestModal,
  showViewAttRequestModal,
  viewAttRequestModalAttempt,
  showPenaltyModal,
  toggleSignInBreaksModal,
  toggleLogsModal,
  adjustSignInOutIds,
} from "../../Store/Actions";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import { Spinner } from "reactstrap";
import * as REQUESTS from "../../Constants/Requests";
import CheckInsCard from "./CheckInsCard";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  InfoIconButton,
  EditIconButton,
  PreviewIconButton,
} from "../IconButtonWithTooltip";
import CheckInsModal from "./CheckInsModal";
import { useLazyQuery } from "@apollo/client";
import { GET_SIGN_IN_LOGS } from "../../Graphql/query";
import HouseOutlinedIcon from "@mui/icons-material/HouseOutlined";
import { Checkbox } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import RestoreIcon from "@mui/icons-material/Restore";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import FreeBreakfastOutlinedIcon from "@mui/icons-material/FreeBreakfastOutlined";

const InputTooltip = ({ title = "", tooltipStyle }) => {
  // To handle tooltip on click for mobile browsers
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);

  return (
    <div className={`${tooltipStyle ? tooltipStyle : "card-tooltip-style"}`}>
      <Tooltip
        arrow
        placement="top"
        open={tooltipIsOpen}
        onOpen={() => setTooltipIsOpen(true)}
        onClose={() => setTooltipIsOpen(false)}
        classes={{
          popper: "attendance_history_tooltip_style",
        }}
        title={title}
      >
        <ErrorOutline
          fontSize="inherit"
          color="inherit"
          onClick={() => setTooltipIsOpen(!tooltipIsOpen)}
        />
      </Tooltip>
    </div>
  );
};

const checkInsModalInitState = { isOpen: false, data: null };

const EmployeeSignInRecordInfo = ({
  id,
  employeeRecord,
  signOutTooltipTitle,
  durationTooltipTitle,
  durationTooltipStyle,
  isMyProfileHistory,
  isEmpProfileHistory,
  hideName = false,
  recordOrder,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [checkInsOpened, setCheckInsOpened] = useState(false);
  const [checkInsModalState, setCheckInsModalState] = useState(
    checkInsModalInitState
  );
  const {
    isToday,
    from_home,
    date,
    status,
    sign_in_time,
    sign_out_time,
    auto_signed_out,
    created_at = null,
    creator = null,
    hasPendingEditAttendanceRequests = false,
    multiple_sign_ins,
    early_late,
    early_late_time,
    employee,
    penalties,
    signInOnBehalfBy,
    signOutOnBehalfBy,
    breakInOuts = [],
    break_setting = {},
  } = employeeRecord;
  const isTablet = useMediaQuery("(max-width: 1024px)");

  const signInOutAdjustIds = useSelector(
    (state) => state?.super?.adjustSignInOutIds
  );

  const [attemptGetSignInLogs] = useLazyQuery(GET_SIGN_IN_LOGS, {
    onCompleted: (data) => {
      dispatch({ type: "STOP_LOGS_MODAL_DATA_LOADER" });
      dispatch(
        toggleLogsModal({
          isOpen: true,
          data,
          name: employeeRecord?.employee?.name,
          date: employeeRecord?.date,
        })
      );
    },
  });

  const handleOpenModal = () => {
    setCheckInsModalState({
      isOpen: true,
      data: { checkIns: employeeRecord.checkIns },
    });
  };
  const handleCloseModal = () => {
    setCheckInsModalState(checkInsModalInitState);
  };

  const statusDetails = () => {
    switch (status) {
      case "Ontime":
        return {
          title: t("ontime"),
          color: employeeRecord?.penalties?.length
            ? "red-color"
            : "green-color",
        };
      case "Absent":
        return { title: t("absent"), color: "grey-color" };
      case "Major Tardiness":
        return { title: t("major late", { tier: 2 }), color: "orange-color" };
      case "Major Tardiness1":
        return { title: t("major late", { tier: 3 }), color: "orange-color" };
      case "Major Tardiness2":
        return { title: t("major late", { tier: 4 }), color: "orange-color" };
      case "Major Tardiness3":
        return { title: t("major late", { tier: 5 }), color: "orange-color" };
      case "Major Tardiness4":
        return { title: t("major late", { tier: 6 }), color: "orange-color" };
      case "Major Tardiness5":
        return { title: t("major late", { tier: 7 }), color: "orange-color" };
      case "Major Tardiness6":
        return { title: t("major late", { tier: 8 }), color: "orange-color" };
      case "Major Tardiness7":
        return { title: t("major late", { tier: 9 }), color: "orange-color" };
      case "Major Tardiness8":
        return { title: t("major late", { tier: 10 }), color: "orange-color" };
      case "Major Tardiness9":
        return { title: t("major late", { tier: 11 }), color: "orange-color" };
      case "Major Tardiness10":
        return { title: t("major late", { tier: 12 }), color: "orange-color" };
      case "Minor Tardiness":
        return { title: t("major late", { tier: 1 }), color: "orange-color" };
      case "commission":
        return { title: "commission", color: "blue-color" };
      default:
        return {
          title: t(status),
          color: employeeRecord?.penalties?.length ? "red-color" : "blue-color",
        };
    }
  };

  const renderBorderColor = () => {
    if (employeeRecord?.getSignInConfigType?.__typename === "Exception") {
      return employeeRecord?.getSignInConfigType?.assignment?.treat_as_normal
        ? "exeptional-treat-as-normal"
        : "exeptional-dont-treat-as-normal";
    }
    return "normal";
  };

  const showEditAttBtn =
    status == "Ontime" ||
    status == "Absent" ||
    status == "Major Tardiness" ||
    status == "Major Tardiness1" ||
    status == "Major Tardiness2" ||
    status == "Major Tardiness3" ||
    status == "Major Tardiness4" ||
    status == "Major Tardiness5" ||
    status == "Major Tardiness6" ||
    status == "Major Tardiness7" ||
    status == "Major Tardiness8" ||
    status == "Major Tardiness9" ||
    status == "Major Tardiness10" ||
    status == "Minor Tardiness";

  const duration = moment.duration(
    moment(sign_out_time).diff(moment(sign_in_time))
  );
  const durationHours =
    duration.hours() < 10 ? `0${duration.hours()}` : duration.hours();
  const durationMinutes =
    duration.minutes() < 10 ? `0${duration.minutes()}` : duration.minutes();

  const signOutValue = sign_out_time
    ? moment(sign_out_time).format("hh:mm A")
    : "--------";

  // handle view sign in location modal
  const handleViewSignInLocationModal = (e) => {
    // to pervent checkin section from toggling
    e.stopPropagation();
    let data = {
      lng: employeeRecord?.sign_in_lng,
      lat: employeeRecord?.sign_in_lat,
      face: employeeRecord?.sign_in_face?.path,
      location_name: employeeRecord?.sign_in_location?.name,
      name: employeeRecord?.employee?.name,
      date: employeeRecord?.date,
      type: "sign_in",
      signinOutLocationsModal: true,
    };
    dispatch(
      showViewCheckInsOutsLocationModalAction({
        data,
        ref: "viewCheckInOutLocationModalActions",
        formName: "viewCheckInOutLocationModal",
      })
    );
  };

  // handle view sign out location modal
  const handleViewSignOutLocationModal = (e) => {
    // to pervent checkin section from toggling
    e.stopPropagation();
    let data = {
      lng: employeeRecord?.sign_out_lng,
      lat: employeeRecord?.sign_out_lat,
      face: employeeRecord?.sign_out_face?.path,
      location_name: employeeRecord?.sign_out_location?.name,
      name: employeeRecord?.employee?.name,
      date: employeeRecord?.date,
      type: "sign_out",
      signinOutLocationsModal: true,
    };
    dispatch(
      showViewCheckInsOutsLocationModalAction({
        data,
        ref: "viewCheckInOutLocationModalActions",
        formName: "viewCheckInOutLocationModal",
      })
    );
  };

  const RenderEditActions = () =>
    !isMyProfileHistory ? (
      <div className="d-flex gap-10 w-100">
        <div className="w-1e10">
          {penalties?.length && employeeRecord?.canEdit ? (
            <div
              onClick={() =>
                dispatch(
                  showPenaltyModal({
                    formName: "editPenaltyModal",
                    ref: "editPenaltyModalActions",
                    data: {
                      date,
                      penalties,
                      sign_in_time,
                      sign_out_time,
                      balance: employee.balance,
                      employeeName: employee.name,
                    },
                  })
                )
              }
            >
              <ErrorOutlineOutlined fontSize="small" />
            </div>
          ) : null}
        </div>

        <div className="w-1e10">
          {employeeRecord?.logs?.length ? (
            <div className="sign-in-logs-button-container">
              <Tooltip title={t("view logs")}>
                <div className="pointer" onClick={handleShowLogs}>
                  <RestoreIcon
                    {...(employeeRecord?.edited_by_action?.length
                      ? {
                          style: {
                            color:
                              employeeRecord?.edited_by_action ==
                              "by_permission"
                                ? "#ee0"
                                : "#009EFB",
                          },
                        }
                      : {})}
                  />
                </div>
              </Tooltip>
            </div>
          ) : null}
        </div>

        <div className="w-8e10">
          {employeeRecord?.canEdit ? (
            <div className="d-flex w-100">
              <HasPrivileges
                reqireMain={[
                  Privilages.EDIT_EMPLOYEE_ATTENDANCE,
                  Privilages.MANAGE_EMPLOYEE_ATTENDANCE,
                ]}
                allowBP
                avalibleOnExpire={false}
                altExpireChildren={<EditAttReqAction />}
                altChildren={<EditAttReqAction />}
              >
                <div className="w-50">
                  {hasPendingEditAttendanceRequests ? (
                    <HasPrivileges
                      allowBP
                      avalibleOnExpire={false}
                      reqireMain={[
                        // Privilages.VIEW_EMPLOYEE_REQUESTS,
                        // Privilages.MANAGE_EMPLOYEE_REQUESTS,
                        Privilages.APPLY_FOR_EDIT_ATTENDANCE_REQUESTS_ON_BEHALF,
                      ]}
                    >
                      <button
                        className="btn btn-outline edit_att_request_btn has-pending"
                        onClick={handleViewAttRequest}
                      >
                        {t("view request")}
                      </button>
                    </HasPrivileges>
                  ) : null}
                </div>
                {/* <EditIconButton iconProps={{ color: "#212529" }} /> */}
                <div className="pointer w-50" onClick={handleEditEmployeeBtn}>
                  <ModeEditOutlinedIcon
                    sx={{ color: "#57585A", fontSize: "20px" }}
                  />
                </div>
              </HasPrivileges>
            </div>
          ) : null}
        </div>
      </div>
    ) : showEditAttBtn &&
      employeeRecord?.work_timing?.sign_in_req &&
      employeeRecord?.canEdit ? (
      <EditAttReqAction isMyProfile />
    ) : null;

  const EditAttReqAction = ({ isMyProfile }) =>
    ["day off", "holiday"].includes(status?.toLowerCase()) ? null : (
      <>
        {hasPendingEditAttendanceRequests ? (
          <HasPrivileges
            allowBP
            avalibleOnExpire={false}
            reqireMain={
              isMyProfile
                ? [Privilages.APPLY_FOR_EDIT_ATTENDANCE_REQUESTS]
                : [
                    // Privilages.VIEW_EMPLOYEE_REQUESTS,
                    // Privilages.MANAGE_EMPLOYEE_REQUESTS,
                    Privilages.APPLY_FOR_EDIT_ATTENDANCE_REQUESTS_ON_BEHALF,
                  ]
            }
          >
            <button
              className="btn btn-outline edit_att_request_btn has-pending"
              onClick={handleViewAttRequest}
            >
              {isTablet ? (
                <VisibilityIcon className="attendance_status_icon" />
              ) : (
                t("view request")
              )}
            </button>
          </HasPrivileges>
        ) : (
          <HasPrivileges
            reqireMain={
              isMyProfileHistory
                ? [Privilages.APPLY_FOR_EDIT_ATTENDANCE_REQUESTS]
                : [
                    // Privilages.MANAGE_EMPLOYEE_REQUESTS,
                    Privilages.APPLY_FOR_EDIT_ATTENDANCE_REQUESTS_ON_BEHALF,
                  ]
            }
            avalibleOnExpire={false}
          >
            <button
              title={!employeeRecord?.canEdit ? t("can't edit") : null}
              disabled={!employeeRecord?.canEdit}
              type="button"
              className={`btn${!isTablet ? " edit_att_request_btn" : ""}`}
              onClick={handleEditAttRequest}
            >
              {isTablet ? (
                <Edit className="attendance_status_icon" />
              ) : (
                <span>{t("edit request")}</span>
              )}
            </button>
          </HasPrivileges>
        )}
      </>
    );

  const handleEditEmployeeBtn = (e) => {
    // to pervent checkin section from toggling
    const { employee, date, id } = employeeRecord;
    e.stopPropagation();
    dispatch(showEditHistoryModal({ employee, date, id }));
  };

  const handleEditAttRequest = (e) => {
    // to pervent checkin section from toggling
    e.stopPropagation();
    dispatch(showEditAttRequestModal(employeeRecord));
  };

  const handleViewAttRequest = (e) => {
    // to pervent checkin section from toggling
    e.stopPropagation();
    dispatch(showViewAttRequestModal({}));
    dispatch(
      viewAttRequestModalAttempt({
        emp_id: employeeRecord?.employee?.id,
        date: employeeRecord?.date,
        type: REQUESTS.EDIT_ATTENDANCE,
      })
    );
  };

  const handleStatusDetails = () => {
    return employeeRecord?.penalties?.length
      ? employeeRecord?.penalties
          ?.map((penalty) => t(penalty?.penalty_reason))
          ?.join(" - ")
      : statusDetails().title;
  };

  const handleShowBreaks = () => {
    dispatch(
      toggleSignInBreaksModal({
        isOpen: true,
        breakInOuts,
        break_setting,
        name: employeeRecord?.employee?.name,
        sign_in_id: employeeRecord?.id,
        date: employeeRecord?.date,
      })
    );
  };

  const handleShowLogs = () => {
    dispatch({ type: "START_LOGS_MODAL_DATA_LOADER" });
    attemptGetSignInLogs({
      variables: {
        signInId: employeeRecord?.id,
      },
    });
  };

  return (
    <>
      <div
        className={
          !(sign_in_time != null && sign_out_time == null && isToday == false)
            ? `sign-in-and-check-in-container status-${
                statusDetails().color
              } ${renderBorderColor()}`
            : `sign-in-and-check-in-container missing_sign_out_style ${renderBorderColor()}`
        }
      >
        <div
          className={`attendance-logs-card-container w-100 ${
            employeeRecord?.checkIns?.length > 0 ? "cursor-pointer" : ""
          }`}
        >
          {/* ______________________ name & date column _____________________________ */}
          <div
            className={`att_name_date_container name-date-col-width ${
              !hideName && ""
            }`}
            data-isprofile={isMyProfileHistory}
          >
            {employeeRecord?.employee?.name && !hideName ? (
              <CanViewEmployeeProfile
                allowBP
                directManger={employeeRecord?.employee?.manager?.id}
                copiedManagers={employeeRecord?.employee?.copied_managers?.map(
                  (cp) => cp?.id
                )}
                altChildren={
                  <div className="attendance_name">
                    {employeeRecord?.employee?.name.toLowerCase()}
                  </div>
                }
              >
                <Link
                  to={`/employees/employee-profile/${employeeRecord?.employee?.id}`}
                >
                  <div
                    className="attendance_name"
                    title={employeeRecord?.employee?.name}
                  >
                    {employeeRecord?.employee?.name.toLowerCase()}
                  </div>
                </Link>
              </CanViewEmployeeProfile>
            ) : (
              <div
                className={`attendance_name ${
                  (isMyProfileHistory || isEmpProfileHistory) && "d-none"
                }`}
              ></div>
            )}

            {/* DATE */}
            {recordOrder == 1 ? (
              <div className={`attendance_date`}>
                {!isTablet
                  ? moment(date).format("dddd DD-MM-YYYY")
                  : moment(date).format("ddd DD-MM-YYYY")}
              </div>
            ) : null}
          </div>
          {/* (End) Employee Name */}

          <div className="home-col-width">
            {Boolean(from_home) && (
              <HouseOutlinedIcon
                sx={{ color: "#2764AC", fontSize: 18, fontWeight: "100" }}
              />
            )}
          </div>

          <div className="checkbox-col-width">
            <HasPrivileges
              reqireMain={[
                Privilages.EDIT_EMPLOYEE_ATTENDANCE,
                Privilages.MANAGE_EMPLOYEE_ATTENDANCE,
              ]}
              allowBP
              avalibleOnExpire={false}
            >
              {!isToday && sign_in_time && !sign_out_time ? (
                <Checkbox
                  sx={{
                    margin: 0,
                    padding: 0,
                    marginRight: 1,
                  }}
                  icon={<UncheckedIcon />}
                  checkedIcon={
                    <CheckBoxIcon sx={{ color: "#009EFB", fontSize: 18 }} />
                  }
                  onChange={(e) =>
                    e.target.checked
                      ? dispatch(adjustSignInOutIds({ operation: "add", id }))
                      : dispatch(
                          adjustSignInOutIds({ operation: "remove", id })
                        )
                  }
                  checked={signInOutAdjustIds?.includes(id)}
                />
              ) : null}
            </HasPrivileges>
          </div>

          {/* (Start) shift */}
          <div className="attendance_shift_container shift-col-width">
            <span className="attendance_shift_text">
              {multiple_sign_ins?.length || recordOrder > 1
                ? recordOrder == 1
                  ? t("sign-in-index", {
                      index: 1,
                      suffix: "st",
                      order: t("1st"),
                    })
                  : recordOrder == 2
                  ? t("sign-in-index", {
                      index: 2,
                      suffix: "nd",
                      order: t("2nd"),
                    })
                  : recordOrder == 3
                  ? t("sign-in-index", {
                      index: 3,
                      suffix: "rd",
                      order: t("3rd"),
                    })
                  : t("sign-in-index", {
                      index: recordOrder + 1,
                      suffix: "th",
                    })
                : null}
            </span>
          </div>
          {/* (End) shift */}

          <div className="signin-out-col-width">
            {!sign_in_time ? (
              <div className="no_sign_in_time_status_container py-2">
                {status?.toLowerCase() === "ontime"
                  ? t("present")
                  : t(status?.toLowerCase())}
              </div>
            ) : (
              <div className="signin-out-container ">
                {/* Signin */}
                <div className="sign-in-check-in-container">
                  <div className={`circle bg-green top--3left`} />
                  <div className={`circle bg-green bottom--3left`} />
                  <div className="line" />
                  <div className="icon_time_container">
                    <ArrowDownward className="sign_in_icon" />

                    <Tooltip
                      arrow
                      placement="top"
                      title={moment(sign_in_time)
                        .subtract(
                          early_late && early_late === "Before"
                            ? early_late_time
                            : "00:00:00",
                          "HH:mm:ss"
                        )
                        .format("hh:mm:ss A")}
                    >
                      {moment(date).isSame(moment(sign_in_time), "day") ? (
                        <span className="sign_in_time">
                          {moment(sign_in_time).format("hh:mm A")}
                        </span>
                      ) : (
                        <span className="sign_in_time">
                          {moment(sign_in_time).format("DD/MM/YYYY hh:mm A")}
                        </span>
                      )}
                    </Tooltip>
                  </div>

                  <div className="sign_in_location_container">
                    {employeeRecord?.sign_in_location?.name ? (
                      <div className="d-flex gap-sm">
                        <PlaceOutlined
                          sx={{ color: "#23AAEB", fontSize: "12px" }}
                        />
                        <p
                          className="sign_in_location mb-0"
                          onClick={handleViewSignInLocationModal}
                        >
                          {employeeRecord?.sign_in_location?.name}
                        </p>
                      </div>
                    ) : employeeRecord?.sign_in_lng &&
                      employeeRecord?.sign_in_lat ? (
                      <div className="d-flex gap-sm">
                        <PlaceOutlined
                          sx={{ color: "#23AAEB", fontSize: "12px" }}
                        />
                        <p
                          className="sign_in_location mb-0"
                          onClick={handleViewSignInLocationModal}
                        >
                          {t("unspecified location")}
                        </p>
                      </div>
                    ) : null}

                    {employeeRecord?.signed_in_outside_specified_locations && (
                      <Tooltip
                        placement="top"
                        title={t("Signd In Outside Specified Locations")}
                      >
                        <div>
                          <GpsOff
                            fontSize="inherit"
                            className="unspecified_location_icon"
                          />
                        </div>
                      </Tooltip>
                    )}
                  </div>
                </div>

                {/* Signout */}
                <div className="sign-out-check-out-container">
                  {signOutTooltipTitle || Boolean(auto_signed_out) ? (
                    <Tooltip
                      placement="top"
                      title={
                        signOutTooltipTitle
                          ? signOutTooltipTitle
                          : t("Auto Sign Out")
                      }
                    >
                      <>
                        <div className="icon_time_container">
                          <ArrowUpward className="sign_out_icon" />

                          <Tooltip
                            arrow
                            placement="top"
                            title={
                              sign_out_time
                                ? moment(sign_out_time).format("hh:mm:ss A")
                                : ""
                            }
                          >
                            <span className="sign_out_time">
                              {signOutValue}
                            </span>
                          </Tooltip>
                        </div>

                        <div className="sign_out_location_container">
                          <div className="sign_out_location">
                            {t("Auto Sign Out")}
                          </div>
                        </div>
                      </>
                    </Tooltip>
                  ) : (
                    <>
                      <div className="icon_time_container">
                        <ArrowUpward className="sign_out_icon" />

                        <Tooltip
                          arrow
                          placement="top"
                          title={
                            sign_out_time
                              ? moment(sign_out_time).format("hh:mm:ss A")
                              : ""
                          }
                        >
                          <span className="sign_out_time">{signOutValue}</span>
                        </Tooltip>
                      </div>

                      <div className="sign_out_location_container">
                        {sign_out_time ? (
                          employeeRecord?.sign_out_location?.name ? (
                            <div className="d-flex gap-sm">
                              <PlaceOutlined
                                sx={{ color: "#23AAEB", fontSize: "12px" }}
                              />
                              <p
                                className="sign_out_location mb-0"
                                onClick={handleViewSignOutLocationModal}
                              >
                                {employeeRecord?.sign_out_location?.name}
                              </p>
                            </div>
                          ) : employeeRecord?.auto_signed_out ? null : employeeRecord?.sign_out_lng &&
                            employeeRecord?.sign_out_lat ? (
                            <div className="d-flex gap-sm">
                              <PlaceOutlined
                                sx={{ color: "#23AAEB", fontSize: "12px" }}
                              />
                              <p
                                className="sign_out_location mb-0"
                                onClick={handleViewSignOutLocationModal}
                              >
                                {t("unspecified location")}
                              </p>
                            </div>
                          ) : null
                        ) : null}

                        {employeeRecord?.signed_out_outside_specified_locations ? (
                          <Tooltip
                            placement="top"
                            title={t("Signd out Outside Specified Locations")}
                          >
                            <div>
                              <GpsOff
                                fontSize="inherit"
                                className="unspecified_location_icon"
                              />
                            </div>
                          </Tooltip>
                        ) : null}
                      </div>
                    </>
                  )}
                </div>

                {/* duration */}
                <div className="attendance_duration">
                  <AccessTimeOutlinedIcon
                    className="attendance_duration_icon"
                    sx={{ color: "#598cc2" }}
                  />

                  <span className="attendance_duration_time">
                    {employeeRecord?.sign_out_time &&
                    moment(employeeRecord?.work_day_hours, "HH:mm:ss").isValid()
                      ? moment(
                          employeeRecord?.work_day_hours,
                          "HH:mm:ss"
                        ).format("HH:mm")
                      : "-------"}
                  </span>

                  {(durationTooltipTitle || creator?.name) && (
                    <InputTooltip
                      title={
                        durationTooltipTitle ? (
                          durationTooltipTitle
                        ) : (
                          <>
                            {!signInOnBehalfBy?.name ? (
                              <span>
                                {t("signInOutInfo", {
                                  user: creator?.name,
                                  time: moment(created_at).format(
                                    "dddd DD-MMM-yyyy hh:mm A"
                                  ),
                                })}
                                <br />
                              </span>
                            ) : null}
                            {signInOnBehalfBy?.name ? (
                              <>
                                {t("signInOnBehalf", {
                                  user: signInOnBehalfBy?.name,
                                  time: moment(
                                    signInOnBehalfBy?.sign_in_time
                                  ).format("dddd DD-MMM-yyyy hh:mm A"),
                                  employee: employeeRecord?.employee?.name,
                                })}
                                <br />
                              </>
                            ) : (
                              ""
                            )}
                            {signOutOnBehalfBy?.name
                              ? t("signOutOnBehalf", {
                                  user: signOutOnBehalfBy?.name,
                                  time: moment(
                                    signOutOnBehalfBy?.sign_out_time
                                  ).format("dddd DD-MMM-yyyy hh:mm A"),
                                  employee: employeeRecord?.employee?.name,
                                })
                              : ""}
                          </>
                        )
                      }
                      tooltipStyle={`attendance_duration_tooltip ${durationTooltipStyle}`}
                    />
                  )}

                  {!!sign_in_time && break_setting?.allow_break ? (
                    <HasPrivileges
                      allowBP
                      reqireMain={[
                        Privilages.VIEW_BREAKS,
                        Privilages.ADD_EDIT_DELETE_BREAKS,
                      ]}
                    >
                      <Tooltip title={t("show breaks")}>
                        <button
                          className="sign-in-show-breaks-button"
                          onClick={handleShowBreaks}
                        >
                          {breakInOuts?.length ? (
                            <FreeBreakfast />
                          ) : (
                            <FreeBreakfastOutlinedIcon />
                          )}
                        </button>
                      </Tooltip>
                    </HasPrivileges>
                  ) : null}
                </div>
              </div>
            )}
          </div>

          <div className="attendance_checkin_container checkin-col-width">
            {employeeRecord?.checkIns?.length > 0 ? (
              <div className="attendance_checkin d-flex align-items-center gap-10">
                <PreviewIconButton onClick={handleOpenModal} />
                <div
                  className={`attendance_checkin_btn ${
                    checkInsOpened ? "attendance_checkin_btn_active" : ""
                  }`}
                  onClick={() => setCheckInsOpened((prev) => !prev)}
                >{`${employeeRecord?.checkIns?.length} ${t("Check-ins")}`}</div>
              </div>
            ) : null}
          </div>

          {/* (Start) Status */}
          {/* <div
            className="attendance_status"
            data-isprofile={isMyProfileHistory}
          > */}
          <div className="status-col-width d-flex justify-content-center">
            {sign_in_time ? (
              <div
                className={`attendance_status_title_container ${
                  statusDetails().color
                } _bg-${statusDetails().color}`}
                title={`${handleStatusDetails()} ${
                  employeeRecord?.day_type?.toLowerCase() !== "normal"
                    ? `(${t(employeeRecord?.day_type)})`
                    : ""
                }`}
              >
                <div className={`attendance_status_title `}>
                  {handleStatusDetails()}

                  {employeeRecord?.day_type?.toLowerCase() !== "normal" &&
                    `(${t(employeeRecord?.day_type)})`}
                </div>
                {employeeRecord?.penalties?.length > 1 ? (
                  <div className="font-weight-bold">
                    {"+" +
                      t((employeeRecord?.penalties?.length - 1).toString())}
                  </div>
                ) : null}
              </div>
            ) : (
              <>
                <div
                  className={`attendance_status_title ${statusDetails().color}`}
                />
                {employeeRecord?.penalties?.length > 1 ? (
                  <div className="font-weight-bold">
                    {"+" +
                      t((employeeRecord?.penalties?.length - 1).toString())}
                  </div>
                ) : null}
              </>
            )}
          </div>

          <div className="record-col-width">
            {employeeRecord?.attendance_configuration_conflict ? (
              <Tooltip
                arrow
                title={t(
                  "This record was not recalculated when the work timing was updated in the relevant configuration"
                )}
              >
                <ErrorOutlineOutlined
                  fontSize="small"
                  sx={{ color: "#f2cf1b" }}
                />
              </Tooltip>
            ) : null}
          </div>

          <div className="actions-col-width">
            <RenderEditActions />
          </div>
          {/* </div> */}
        </div>
      </div>

      {checkInsOpened ? (
        <CheckInsCard
          employeeRecord={employeeRecord}
          checkIns={employeeRecord?.checkIns ?? []}
          isMyProfileHistory={isMyProfileHistory}
        />
      ) : null}

      {checkInsModalState.isOpen ? (
        <CheckInsModal
          onClose={handleCloseModal}
          data={checkInsModalState.data}
        />
      ) : null}
    </>
  );
};

export default EmployeeSignInRecordInfo;

const UncheckedIcon = () => (
  <div
    style={{
      width: 16,
      height: 16,
      padding: 1.5,
    }}
  >
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "#D7DEDD",
        borderRadius: 3,
        margin: 0,
        border: 0.5,
        borderColor: "#D7DEDD",
      }}
    />
  </div>
);
