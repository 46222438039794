import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLazyQuery, gql } from "@apollo/client";
import { useTranslation } from "react-i18next";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";

import { facialReportListQuery } from "../../Graphql/query";
import { showErrorToast, showViewCheckInsOutsLocationModalAction } from "../../Store/Actions";

import {
  BSelect,
  DateTimePickerForm,
  CheckboxBooleanForm,
} from "../../Builder/Form";
import List from "./List";
import { Grid } from "@mui/material";
import { Apps, FormatListBulleted } from "@mui/icons-material";
import { OutlineIconButton } from "../../Components/IconButtonWithTooltip";
import ViewCheckInsOutsLocationMap from "../../Components/HistoryEmployeeCard/ViewCheckInsOutsLocationMap";
import { ExportButton } from "../../Components/Buttons";
import HelperFns from "../../Helpers/HelperFns";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";

// Constants
const dataTableRef = "facialRecognitionReport";
const filtersNames = ["employees", "departments", "positions", "offices"];
const tablePaginatorInitState = {
  lastItem: 20,
  total: 20,
  perPage: 30,
  currentPage: 1,
  hasMorePages: false,
  firstItem: 1,
  lastPage: 1,
};

const FacialRecognitionReport = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Local State
  const [tableData, setTableData] = React.useState([]);
  const [isListView, setIsListView] = React.useState(false);
  const [spoof, setSpoof] = React.useState({ img: false, loc: false });
  const [tableFilters, setTableFilters] = React.useState({
    [filtersNames[0]]: [],
    [filtersNames[1]]: [],
    [filtersNames[2]]: [],
    [filtersNames[3]]: [],
  });
  const [tablePaginator, setTablePaginator] = React.useState(
    tablePaginatorInitState
  );

  // Reducer State
  const tableReducer = useSelector((state) => state.super[dataTableRef]);

  // Server State
  const [getAttendanceLogs, { called, loading }] = useLazyQuery(
    facialReportListQuery,
    {
      variables: {
        isListView,
        spoofed_img: spoof?.img,
        spoofed_loc: spoof?.loc,
        perPage: tablePaginatorInitState.perPage,
      },
      onCompleted: (data) => {
        const list = isListView ? "listView" : "gridView";
        const isIncludeFilters = !Object.values(tableFilters).every(
          (arr) => arr.length
        );
        setTableData(data?.[list]?.data);
        setTablePaginator(data?.[list]?.paginatorInfo);
        isIncludeFilters &&
          setTableFilters({
            [filtersNames[0]]: data?.[filtersNames[0]]?.data,
            [filtersNames[1]]: data?.[filtersNames[1]]?.data,
            [filtersNames[2]]: data?.[filtersNames[2]]?.data,
            [filtersNames[3]]: data?.[filtersNames[3]]?.data,
          });
      },
      onError: () => {
        setTableData([]);
        setTablePaginator(tablePaginatorInitState);
      },
    }
  );

  /* ↓ State Effects ↓ */

  React.useEffect(() => {
    fetchFacialRecognitionReport();
  }, []);

  const useDidFilterUpdate = useDidUpdateEffect(() => {
    fetchFacialRecognitionReport();
  }, [
    tableReducer.to,
    tableReducer.from,
    tableReducer.offices,
    tableReducer.employees,
    tableReducer.departments,
    tableReducer.positions,
  ]);

  /* ↓ Helpers ↓ */

  const handleViewLocation = (data) => {
    dispatch(
      showViewCheckInsOutsLocationModalAction({
        data,
        ref: "viewCheckInOutLocationModalActions",
        formName: "viewCheckInOutLocationModal",
      })
    );
  };

  const fetchFacialRecognitionReport = (page = 1) => {
    const isIncludeFilters = !Boolean(
      Object.values(tableFilters).every((arr) => arr.length)
    );
    const filtersVariables = {
      to: tableReducer.to || "",
      from: tableReducer.from || "",
      emp_id: tableReducer.employees,
      office_id: tableReducer.offices,
      department_id: tableReducer.departments,
      position_id: tableReducer.positions,
    };

    getAttendanceLogs({
      variables: { isIncludeFilters, page, ...filtersVariables },
    });
  };

  const handlePaginate = (page) => {
    fetchFacialRecognitionReport(page);
  };

  const handleToggleView = () => {
    setIsListView((prev) => !prev);
  };

  const handleSpoof = (e) => {
    setSpoof((prev) => ({ ...prev, [e.target.name]: !prev[e.target.name] }));
  };

  return (
    <>
      {/* Filters */}
      <Filters
        spoof={spoof}
        isListView={isListView}
        tableFilters={tableFilters}
        handleSpoof={handleSpoof}
        handleToggleView={handleToggleView}
      />

      {/* List */}
      <List
        tableData={tableData}
        isListView={isListView}
        isLoading={!called || loading}
        tablePaginator={tablePaginator}
        handlePaginate={handlePaginate}
        onViewLocation={handleViewLocation}
      />

      {/* Modals */}
      <ViewCheckInsOutsLocationMap />
    </>
  );
};

const Filters = ({
  spoof,
  isListView,
  tableFilters,
  handleSpoof,
  handleToggleView,
}) => {
  const { t } = useTranslation();
  const tableReducer = useSelector((state) => state.super[dataTableRef]);
  const dispatch = useDispatch();



  const EXPORT_FACIAL_REPORT = gql`
    query exportFR($input:exportFacialRecognitionInput){
      exportFacialRecognitionReport(input:$input){
        file
        status
        message
      }
    }
  `
  const [exportFR, { loading, data, error }] = useLazyQuery(EXPORT_FACIAL_REPORT, {
    onCompleted(res) {
      // do someting
      if (res?.exportFacialRecognitionReport?.status == "success") {
        HelperFns.downloadFile(res?.exportFacialRecognitionReport?.file)
      } else {
        dispatch(showErrorToast(res?.exportFacialRecognitionReport?.message))
      }
    },
    onError(error) {
      dispatch(showErrorToast(error?.message))

      // do something
    }
  })

  const handleExport = () => {
    const filtersVariables = {
      filter_data: {
        emp_id: tableReducer.employees,
        office_id: tableReducer.offices,
        department_id: tableReducer.departments,
        position_id: tableReducer.positions,
        date_range: {
          from: tableReducer.from || "",
          to: tableReducer.to || "",
        },
        spoofed: spoof?.img,
        mocked_location: spoof?.loc,
        "page": "history_logs",
      }
    };
    exportFR({
      variables: {
        input: filtersVariables
      }
    })
  }

  return (
    <>
      <Grid container spacing={2}>
        {filtersNames.map((filter) => (
          <Grid key={filter} item xs={12} sm={3}>
            <BSelect
              isMulti
              name={filter}
              icon={filter == "positions" ? "person" : filter}
              keepDefaultStyle
              optionValue="id"
              optionLabel="name"
              formName={dataTableRef}
              isLoading={!tableFilters[filter].length}
              placeholder={t(`select ${filter}`)}
              options={tableFilters?.[filter] || []}
            />
          </Grid>
        ))}

        <Grid item xs={6} sm={4}>
          <DateTimePickerForm
            hasIcon
            allowClear
            name="from"
            label={t("from")}
            formName={dataTableRef}
            datePickerContainer="w-100"
            placeholder={t("select date")}
            labelStyle="date_picker_label"
            containerStyle="date_picker_container"
            inputStyle="date-picker-input-default"
            requestFormat="YYYY-MM-DD"
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <DateTimePickerForm
            hasIcon
            name="to"
            allowClear
            label={t("to")}
            formName={dataTableRef}
            datePickerContainer="w-100"
            labelStyle="date_picker_label"
            placeholder={t("select date")}
            inputStyle="date-picker-input-default"
            containerStyle="date_picker_container w-100"
            requestFormat="YYYY-MM-DD"

          />
        </Grid>
      </Grid>

      <div className="d-flex justify-content-between align-items-center mt-2">
        <div className="d-flex gap-20">
          <CheckboxBooleanForm
            name="img"
            options={["show only spoofed images"]}
            checked={spoof?.img}
            onChange={handleSpoof}
            setWithValue
          />
          <CheckboxBooleanForm
            name="loc"
            options={["show only mocked locations"]}
            checked={spoof?.loc}
            onChange={handleSpoof}
            setWithValue
          />
        </div>

        <div className="d-flex gap-20">
          <HasPrivileges allowBP reqireMain={[Privilages.EXPORT_FACIAL_RECOGNITION_REPORT]}>
            <ExportButton
              onClick={handleExport}
              loadingButton
              loading={loading}
              disabled={!tableReducer?.from || !tableReducer?.to}
              disabledLabel={t("Please select a date range to export")}
            />
          </HasPrivileges>
          {isListView ? (
            <OutlineIconButton
              label="Show grid view"
              onClick={handleToggleView}
              icon={<Apps fontSize="small" />}
            />
          ) : (
            <OutlineIconButton
              label="Show list view"
              onClick={handleToggleView}
              icon={<FormatListBulleted fontSize="small" />}
            />
          )}
        </div>
      </div>

    </>
  );
};

export default FacialRecognitionReport;
