import {
  faCircle,
  faEye,
  faPen,
  faPlus,
  faTimes,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import AntSwitch from "../../Components/AntSwitch";
import Loader from "../../Components/Loader";
import {
  showSystemRolePlanModal,
  fetchSystemPlanPage,
  toogleViewPlanPrivileges,
  updatePlanStatusAction,
  deletePlanAction,
  deleteRoleAction,
  fetchEditRoleAction,
} from "../../Store/Actions";

import PlanRoleModal from "../../Containers/PlanRoleModal";
import ViewPlanPrivileges from "../../Containers/ViewPlanPrivileges";
import swal from "sweetalert";

import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privileges from "../../Constants/Privilages";
import EditRoleModal from "../../Containers/EditRoleModal";
import { KeyboardBackspace } from "@mui/icons-material";

const ViewSystemPlan = (props) => {
  const { planId } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    props.fetchSystemPlanPage(planId);

    return () => {};
  }, []);

  const handleAddRole = () => {
    props.showSystemRolePlanModal();
  };

  const handleUpdatePlanStatus = (active) => {
    props.updatePlanStatusAction(planId, active ? "active" : "inactive");
  };

  const handleDeletePlan = () => {
    swal({
      title: t("are you sure"),
      text: t("defaut_warning_messsage"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: [t("Cancel"), t("OK")],
    }).then((willDelete) => {
      if (willDelete) {
        props.deletePlanAction(planId);
      }
    });
  };
  const handleDeleteRole = (roleId) => {
    swal({
      title: t("are you sure"),
      text: t("defaut_warning_messsage"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: [t("Cancel"), t("OK")],
    }).then((willDelete) => {
      if (willDelete) {
        props.deleteRoleAction(roleId, planId);
      }
    });
  };

  return (
    <>
      <div className="content pt-3">
        {props.loadingPlanPage || props.planActionLoading ? (
          <div className="loader_wrapper_style">
            <Loader />
          </div>
        ) : null}
        <div className="d-flex mb-3">
          <Link to={`/admin-panel?tab=plans`}>
            <div className="back-btn d-flex align-items-center position-sticky ">
              <KeyboardBackspace className="back-icon" />
              <span>{t("back")}</span>
            </div>
          </Link>
        </div>
        <div className="col-12 px-0 ">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <div className="d-flex flex-row align-items-baseline">
              <span className="main-title-style text-capitalize h3 my-0">
                {props?.systemPlan?.name}
              </span>
              <span className="mx-4 h4">
                {props.systemPlan?.is_free ? t("free") : t("paid")}
                {props.systemPlan?.trial_value
                  ? ` - ${props.systemPlan?.trial_value} days trial`
                  : null}
              </span>
            </div>
            <div className="d-flex flex-row">
              <HasPrivileges
                reqireMain={[
                  Privileges.ACTIVATE_DEACTIVATE_PLANS,
                  Privileges.SUPER_PRIVILEGE,
                ]}
                altChildren={
                  <span className="mx-2" role="button">
                    <AntSwitch
                      checked={props.systemPlan?.status === "active"}
                      disabled
                      // onChange={(e, val) => handleUpdatePlanStatus(val)}
                    />
                  </span>
                }
              >
                <span className="mx-2" role="button">
                  <AntSwitch
                    checked={props.systemPlan?.status === "active"}
                    onChange={(e, val) => handleUpdatePlanStatus(val)}
                  />
                </span>
              </HasPrivileges>
              <HasPrivileges
                reqireMain={[
                  Privileges.ADD_EDIT_DELETE_PLANS,
                  Privileges.SUPER_PRIVILEGE,
                ]}
              >
                <span
                  className="mx-2"
                  role="button"
                  onClick={() => handleDeletePlan()}
                >
                  <FontAwesomeIcon size="md" color="#ff6a6a" icon={faTimes} />
                </span>
                <Link
                  to={`/admin-panel/system-plans/edit-plan/${planId}`}
                  className="mx-2"
                  role="button"
                >
                  <FontAwesomeIcon size="md" color="#5c6974" icon={faPen} />
                </Link>
              </HasPrivileges>
            </div>
          </div>
        </div>
        <div className="bg-dark d-flex my-1" style={{ height: "2px" }} />
        <div
          className="card p-4 mt-4"
          style={{
            borderRadius: "3px",
            border: "solid 1px rgba(220, 220, 220, 0.84)",
            backgroundColor: "#fff",
          }}
        >
          <div className="d-flex flex-row flex-wrap align-items-center justify-content-between ">
            <div className="d-flex flex-row align-items-end">
              <h4>{t("active subscribers")}</h4>
              <h6 className="sub-title-style mx-4">
                {props.systemPlan?.activeSubscribtions}
              </h6>
            </div>
            <div className="d-flex flex-row flex-wrap justify-contnet-between">
              <div className="d-flex ml-5 flex-row align-items-end">
                <h4 className="text-muted">{t("offices")}</h4>
                <h6 className="sub-title-style ml-4">
                  {props.systemPlan?.features?.filter(
                    (feat) => feat?.id === "2"
                  )[0]?.limit ?? t("unlimited")}
                </h6>
              </div>
              <div className="d-flex ml-5 flex-row align-items-end">
                <h4 className="text-muted">{t("attendance profiles")}</h4>
                <h6 className="sub-title-style ml-4">
                  {props.systemPlan?.features?.filter(
                    (feat) => feat?.id === "6"
                  )[0]?.limit ?? t("unlimited")}
                </h6>
              </div>
              <div className="d-flex ml-5 flex-row align-items-end">
                <h4 className="text-muted">{t("normal work timings")}</h4>
                <h6 className="sub-title-style ml-4">
                  {props.systemPlan?.features?.filter(
                    (feat) => feat?.id === "5"
                  )[0]?.limit ?? t("unlimited")}
                </h6>
              </div>
              <div className="d-flex ml-5 flex-row align-items-end">
                <h4 className="text-muted">{t("employees")}</h4>
                <h6 className="sub-title-style ml-4">
                  {props.systemPlan?.features?.filter(
                    (feat) => feat?.id === "1"
                  )[0]?.limit ?? t("unlimited")}
                </h6>
              </div>
            </div>
          </div>
          <hr />

          <HasPrivileges
            reqireMain={[
              Privileges.VIEW_PLAN_PRIVILEGES,
              Privileges.SUPER_PRIVILEGE,
            ]}
          >
            <div className="d-flex flex-row">
              <h4>{t("package privileges")}</h4>
              <HasPrivileges
                reqireMain={[Privileges.SUPER_PRIVILEGE]}
                altChildren={
                  <button
                    className="btn btn-secondary fit-width h-25px mx-5 text-capitalize py-0"
                    onClick={() => props.toogleViewPlanPrivileges()}
                  >
                    <FontAwesomeIcon
                      size="md"
                      color="#fff"
                      icon={faEye}
                      className="mr-2"
                    />
                    {t("view privileges")}
                  </button>
                }
              >
                <button
                  className="btn btn-secondary fit-width h-25px mx-5 text-capitalize py-0"
                  onClick={() => props.toogleViewPlanPrivileges()}
                >
                  <FontAwesomeIcon
                    size="md"
                    color="#fff"
                    icon={faPen}
                    className="mr-2"
                  />
                  {t("Edit privileges")}
                </button>
              </HasPrivileges>
            </div>
          </HasPrivileges>
          <HasPrivileges
            reqireMain={[
              Privileges.VIEW_PLAN_ROLE_TEMPLATES,
              Privileges.SUPER_PRIVILEGE,
            ]}
          >
            <div className="d-flex align-items-center flex-row sign-in-config bg-white mt-3 py-0">
              <div>
                <h4 className="font-weight-bold mt-2 text-capitalize">
                  {t("roles templates")}
                </h4>
              </div>
              <HasPrivileges
                reqireMain={[
                  Privileges.ADD_EDIT_DELETE_ROLE_TEMPLATES,
                  Privileges.SUPER_PRIVILEGE,
                ]}
              >
                <button
                  className="add_new_btn_style d-flex btn ml-4"
                  onClick={handleAddRole}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </button>
              </HasPrivileges>
            </div>
          </HasPrivileges>

          <HasPrivileges
            reqireMain={[
              Privileges.VIEW_PLAN_ROLE_TEMPLATES,
              Privileges.SUPER_PRIVILEGE,
            ]}
          >
            <div className="mt-3">
              {props?.systemPlan?.roles?.map((role) => (
                <div className="d-flex flex-row col-12 col-md-6 px-0">
                  <span className="">
                    <FontAwesomeIcon
                      icon={faCircle}
                      size="xs"
                      color="#23aaeb"
                    />
                  </span>
                  <p className="mx-3">{role.name}</p>
                  <HasPrivileges
                    reqireMain={[
                      Privileges.ADD_EDIT_DELETE_ROLE_TEMPLATES,
                      Privileges.SUPER_PRIVILEGE,
                    ]}
                  >
                    <div className="ml-auto">
                      <span
                        className="mx-2"
                        role="button"
                        onClick={() =>
                          props.fetchEditRoleAction(planId, role?.id)
                        }
                      >
                        <FontAwesomeIcon
                          icon={faPen}
                          size="xs"
                          color="#27b40c"
                        />
                      </span>
                      <span
                        className="mx-2"
                        role="button"
                        onClick={() => handleDeleteRole(role?.id)}
                      >
                        <FontAwesomeIcon
                          icon={faTrash}
                          size="xs"
                          color="#ff5151"
                        />
                      </span>
                    </div>
                  </HasPrivileges>
                </div>
              ))}
            </div>
          </HasPrivileges>
        </div>
      </div>

      <PlanRoleModal />
      <EditRoleModal />
      <ViewPlanPrivileges />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.super,
    systemPlan: state.admin?.viewedPlan,
    loadingPlanPage: state.admin?.loadingPlanPage,
    planActionLoading: state.admin?.planActionLoading,
  };
};

export default connect(mapStateToProps, {
  showSystemRolePlanModal,
  fetchSystemPlanPage,
  toogleViewPlanPrivileges,
  updatePlanStatusAction,
  deletePlanAction,
  deleteRoleAction,
  fetchEditRoleAction,
})(ViewSystemPlan);
