import React from "react";
import { useDispatch } from "react-redux";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";

import Privileges from "../../Constants/Privilages";
import { showToast } from "../../Helpers/HelperFns";
import { subscriptionProfileListQuery } from "../../Graphql/query/PaymentTracking";
import { serializeSubscriptionDetails } from "../../Helpers/HelperFns/PaymentTracking";

import {
  Cached,
  ArrowBack,
  ArrowForward,
  CalendarToday,
  StopCircleOutlined,
} from "@mui/icons-material";
import {
  ChangePlanModal,
  ChangeTiersModal,
  EndSubscriptionModal,
  SubscriptionUpsertModal,
  EndSubscriptionConfirmModal,
} from "../../Components/PaymentTrackingModals";
import BlockUi from "react-block-ui";
import { Link } from "react-router-dom";
import Loader from "../../Components/Loader";
import { Tab, SvgIcon } from "@mui/material";
import DataTable from "react-data-table-component";
import { ColorButton } from "../../Components/Buttons";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PaymentsList } from "../../Containers/PaymentTrackingLists";
import { SubscriptionStatusChip } from "../../Components/PaymentTracking";
import IconButtonWithTooltip from "../../Components/IconButtonWithTooltip";

const reducer = "paymentTracking";
const modalInitState = { isOpen: false, data: null, actions: null };
const modals = [
  { name: "changePlan", Modal: ChangePlanModal },
  { name: "changeTiers", Modal: ChangeTiersModal },
  { name: "endSubscription", Modal: EndSubscriptionModal },
  { name: "addSubscription", Modal: SubscriptionUpsertModal },
  { name: "endSubscriptionConfirm", Modal: EndSubscriptionConfirmModal },
];

const SubscriptionProfile = () => {
  const theme = useTheme();
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Local State
  const [data, setData] = React.useState({});
  const [modalsState, setModalsState] = React.useState(
    modals.map(({ name }) => ({ name, ...modalInitState }))
  );

  // Reducer State

  // Server State
  const { loading, refetch } = useQuery(subscriptionProfileListQuery, {
    variables: { id },
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ data = {} }) => {
      setData(serializeSubscriptionDetails(data));
    },
    onError: (err) => {
      showToast(
        "error",
        err?.graphQLErrors?.[0]?.extensions?.reason ||
          err?.graphQLErrors?.[0]?.message ||
          err?.message
      );
    },
  });

  /* ↓ Helpers ↓ */

  const handleOpenModal = (name, data = null, actions = null) => {
    setModalsState((prev) =>
      prev.map((p) =>
        p.name === name ? { name, data, actions, isOpen: true } : p
      )
    );
  };

  const handleCloseModal = (name) => {
    setModalsState((prev) =>
      prev.map((p) => (p.name === name ? { name, ...modalInitState } : p))
    );
  };

  const handleChangePlan = () => {
    handleOpenModal("changePlan", {
      from: data?.startDate,
      company_id: data?.company?.id,
    });
  };

  const openChangeTiersModal = () => {
    handleOpenModal("changeTiers", {
      from: data?.startDate,
      company_id: data?.company?.id,
      currentTier: data?.currentTier,
      ratePerPeriod: data?.ratePerPeriod,
    });
  };

  const openEndSubscriptionConfirmModal = () => {
    handleOpenModal("endSubscriptionConfirm");
  };

  const openAddSubscriptionModal = (additionalData = {}, actions = null) => {
    handleOpenModal(
      "addSubscription",
      {
        isSubscriptionProfile: true,
        ...additionalData,
      },
      actions
    );
  };

  const openEndSubscriptionModal = (additionalData) => {
    handleOpenModal("endSubscription", {
      from: data?.startDate,
      company_id: data?.company?.id,
      ...additionalData,
    });
  };

  return (
    <BlockUi tag="section" className="content pt-3" blocking={loading}>
      {/* Header */}
      <header
        style={{ borderColor: "#D6D3D3" }}
        className="d-flex align-items-center gap-10 border-bottom pb-2 mb-3"
      >
        <IconButtonWithTooltip
          href="/payment-tracking?tab=subscriptions"
          label="navigate back to subscriptions list"
          icon={theme.direction === "rtl" ? <ArrowForward /> : <ArrowBack />}
        />
        <h2 className="mb-0">{t("Subscription Details")}</h2>
      </header>

      <div className="d-flex flex-wrap align-items-center justify-content-between mb-3">
        {/* Company */}
        <div className="d-flex align-items-center gap-20">
          <HasPrivileges
            reqireMain={[
              Privileges.SUPER_PRIVILEGE,
              Privileges.VIEW_COMPANY_PROFILE,
            ]}
            altChildren={<h3 className="mb-0">{data?.company?.name}</h3>}
          >
            <Link to={`/admin-panel/company-profile/${data?.company?.id}`}>
              <h3 className="mb-0">{data?.company?.name}</h3>
            </Link>
          </HasPrivileges>

          <div className="d-flex gap-20">
            <span className="d-flex align-items-center gap-5">
              <CalendarToday sx={{ fontSize: 16 }} />
              {t("form")} <b className="dark-color">{data?.startDate}</b>
            </span>
            <span className="d-flex align-items-center gap-5">
              <CalendarToday sx={{ fontSize: 16 }} />
              {t("to")} <b className="dark-color">{data?.endDate}</b>
            </span>
          </div>
          <SubscriptionStatusChip status={data?.status} />
        </div>

        {/* Actions */}
        {data?.isEnded ? null : (
          <div className="d-flex align-items-center gap-10">
            <ColorButton
              icon={<Cached />}
              label="Change Plan"
              onClick={handleChangePlan}
              color="rgba(48, 130, 105, 1)"
            />
            <ColorButton
              icon={
                <SvgIcon>
                  <FontAwesomeIcon icon="fas fa-user-edit" />
                </SvgIcon>
              }
              label="Change Tiers"
              onClick={openChangeTiersModal}
              color="rgba(18, 147, 187, 1)"
            />
            <ColorButton
              variant="outlined"
              icon={<StopCircleOutlined />}
              label="End Subscription"
              onClick={openEndSubscriptionConfirmModal}
              color="rgba(196, 30, 30, 1)"
            />
          </div>
        )}
      </div>

      {/* Details */}
      <div
        style={{ fontWeight: 500 }}
        className="d-flex flex-wrap align-items-center justify-content-between mb-3 px-2"
      >
        <div>
          {t("plan")}
          <b className="dark-color d-block mt-2">{data?.plan}</b>
        </div>
        <div>
          {t("Billing Period")}
          <b className="dark-color d-block mt-2">{data?.billingPeriod}</b>
        </div>
        <div>
          {t("Rate Per Period")}
          <b className="dark-color d-block mt-2">{data?.ratePerPeriod}</b>
        </div>
        <div>
          {t("Payment Type")}
          <b className="dark-color d-block mt-2">{data?.paymentType}</b>
        </div>
        <div>
          {t("Credit")}
          <b className="dark-color d-block mt-2">{data?.credit}</b>
        </div>
      </div>

      {/* Lists */}
      <Lists
        loading={loading}
        refetch={refetch}
        data={{ history: data?.history, payments: data?.payments }}
      />

      {/* Modals */}
      {modals.map(({ name, Modal }, i) =>
        modalsState[i].isOpen ? (
          <Modal
            key={name}
            refetchList={refetch}
            data={modalsState[i]?.data}
            actions={modalsState[i]?.actions}
            onClose={() => handleCloseModal(name)}
            {...(name === "endSubscription" && {
              openAddSubscriptionModal,
            })}
            {...(name === "endSubscriptionConfirm" && {
              openEndSubscriptionModal,
            })}
            // {...(name === "addSubscription" && {
            //   openEndSubscriptionModal,
            // })}
          />
        ) : null
      )}
    </BlockUi>
  );
};

export default SubscriptionProfile;

const Lists = ({ data, loading, refetch }) => {
  const { t } = useTranslation();

  // Local State
  const [value, setValue] = React.useState("1");

  // Constants
  const historyColumns = React.useMemo(
    () => [
      { name: t("From"), selector: "from" },
      { name: t("to"), selector: "to" },
      { name: t("plan"), selector: "planName" },
      { name: t("Billing Period"), selector: "period" },
      { name: t("Rate Per Period"), selector: "rpp" },
      { name: t("payment Type"), selector: "paymentType" },
      { name: t("Current Tier"), selector: "currentTier" },
    ],
    []
  );

  /* ↓ Helpers ↓ */

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <TabContext value={value}>
      <div className="shadow-sm mb-2">
        <TabList onChange={handleChange}>
          <Tab label={t("History")} value="1" />
          <Tab label={t("Payments")} value="2" />
        </TabList>
      </div>
      <TabPanel value="1" className="p-0">
        <DataTable
          noHeader
          data={data?.history}
          columns={historyColumns}
          className="cards_table"
          progressPending={loading}
          progressComponent={<Loader inner />}
        />
      </TabPanel>
      <TabPanel value="2" className="p-0">
        <PaymentsList
          isSubscriptionProfile
          paymentsRef={data?.payments}
          refetchSubscriptionProfile={refetch}
        />
      </TabPanel>
    </TabContext>
  );
};
