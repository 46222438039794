import React from "react";
import { useTranslation } from "react-i18next";

import error from "../../Assets/img/error.svg";
import redCloud from "../../Assets/img/redCloud.svg";

import {
  Box,
  Card,
  CardMedia,
  Typography,
  CardActions,
  CardContent,
} from "@mui/material";
import { ColorButton } from "../../Components/Buttons";

const ErrorView = () => {
  const { t } = useTranslation();

  return (
    <Box
      component="main"
      sx={{
        display: "grid",
        minHeight: "100vh",
        textAlign: "center",
        placeItems: "center",
        position: "relative",
        overflowY: "initial",
        bgcolor: "#FFE7E7",
        ".img": {
          top: "-100px",
          position: "absolute",
          left: "calc(50% - 100px)",
        },
      }}
    >
      <Card
        sx={{
          width: 1,
          maxWidth: 550,
          overflow: "initial",
          position: "relative",
          borderRadius: "14px",
          boxShadow: "0px 0px 86px 0px #09090929",
        }}
      >
        <img
          src={error}
          width="230"
          height="230"
          className="img"
          alt=""
          aria-hidden="true"
        />
        <CardMedia
          title=""
          aria-hidden="true"
          image={redCloud}
          sx={{ height: 140 }}
        />
        <CardContent sx={{ mt: 1 }}>
          <Typography
            component="h1"
            variant="h5"
            fontWeight={500}
            gutterBottom
            textTransform="initial"
          >
            {t("That is an error!")}
          </Typography>
          <Typography
            sx={{
              mx: "auto",
              maxWidth: 463,
              color: "#898A8D",
              textTransform: "initial",
            }}
          >
            {t(
              "We can`t find the page you are looking for. Try going to back to the previous page or make sure that the URL you want is in correct spelling case."
            )}
          </Typography>
        </CardContent>
        <CardActions sx={{ justifyContent: "center", pb: 3 }}>
          <ColorButton
            href="/"
            color="#C82F28"
            variant="contained"
            label={t("go to home")}
            sx={{
              width: 150,
              fontSize: 15,
              fontWeight: 500,
              boxShadow: "0px 0px 19.1px 0px #0D84CA40",
              background:
                "linear-gradient(91.62deg, #E64942 3.84%, #C82F28 60.73%)",
            }}
          />
        </CardActions>
      </Card>
    </Box>
  );
};

export default ErrorView;
