import gql from "graphql-tag";

import { announcementFragment, paginatorFragment } from "../fragments";

/*
  Offices
*/

/*
  Departments
*/

/*
  Positions
*/

/*
  Work Timings
*/

/*
  Leave & Breaks
*/

/*
  Attendance Profiles
*/

/*
  Holidays
*/

/*
  Documents
*/

/*
  Expense Categories
*/

/*
  Access levels
*/

/*
  Announcements
*/

export const announcementsListQuery = gql`
  query announcementList(
    $page: Int
    $first: Int!
    $type: String!
    $title: String
    $status: String
  ) {
    announcements(
      page: $page
      type: $type
      first: $first
      title: $title
      status: $status
    ) {
      data {
        ...announcement
        description
        published_at
        created_at
        user {
          name
        }
      }
      paginatorInfo {
        ...paginator
      }

      # Options
    }
  }
  ${announcementFragment}
  ${paginatorFragment}
`;

export const announcementFormQuery = gql`
  query announcementForm(
    $id: ID
    $isAdminPanel: Boolean!
    $isIncludeAnnouncement: Boolean!
  ) {
    announcement(id: $id) @include(if: $isIncludeAnnouncement) {
      ...announcement
      send_to
    }

    #Options
    companies(first: 9999, input: { company_status: "active" })
      @include(if: $isAdminPanel) {
      data {
        id
        name
      }
    }
    offices: company_offices(first: 9999) @skip(if: $isAdminPanel) {
      data {
        id
        name
      }
    }
    positions: company_positions(first: 9999) @skip(if: $isAdminPanel) {
      data {
        id
        name
      }
    }
    departments: company_departments(first: 9999) @skip(if: $isAdminPanel) {
      data {
        id
        name
      }
    }
    employees: company_users(input: { page_flag: null, status: ["Active"] })
      @skip(if: $isAdminPanel) {
      data {
        id
        name
      }
    }
    managers: activeManagersAndCopiedManagers @skip(if: $isAdminPanel) {
      id
      name
    }
  }
  ${announcementFragment}
`;

// handle fetch system users query
export const systemUsersListQuery = gql`
  query fetchUserSystemList($page: Int, $first: Int, $input: UserFilterInput) {
    system_users(page: $page, first: $first, input: $input) {
      data {
        id
        name
        active
        has_temporary_password
        phone
        phone_country_code
        phone_verified
        company {
          id
          name
        }
        phone_verified_by {
          id
          name
        }
      }
      paginatorInfo {
        ...paginator
      }
    }
  }
  ${paginatorFragment}
`;
