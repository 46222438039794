import gql from "graphql-tag";

import {
  leadFragment,
  trialFragment,
  commentFragment,
  contactFragment,
  activityFragment,
  reminderFragment,
  paginatorFragment,
  tagFragment,
  employeeFragment,
} from "../fragments";

/* Leads */

export const fetchLeadQuery = gql`
  query fetchLead($id: ID) {
    lead(id: $id) {
      ...lead
      contacts {
        ...contact
      }
    }
  }
  ${leadFragment}
  ${contactFragment}
`;

export const leadsListQuery = gql`
  query leadsList(
    $input: LeadFilter
    $page: Int
    $first: Int!
    $orderBy: [QueryLeadsOrderByRelationOrderByClause!]
  ) {
    leads(args: $input, page: $page, first: $first, orderBy: $orderBy) {
      data {
        ...lead
        lead_assign_date
        nextReminder {
          type
          due_date
        }
        contacts {
          ...contact
        }
        comments {
          ...comment
        }
        activities {
          ...activity
        }
        latestActivity {
          ...activity
        }
      }
      paginatorInfo {
        ...paginator
      }
    }
    campaigns: facebook_campaigns
    tags(first: 9999) {
      data {
        ...tag
      }
    }
  }
  ${leadFragment}
  ${commentFragment}
  ${contactFragment}
  ${activityFragment}
  ${paginatorFragment}
`;
export const leadsListOptionsQuery = gql`
  query leadsListOptions {
    countries: g_countries
  }
`;

export const leadFormQuery = gql`
  query leadForm($id: ID, $isLeadSkip: Boolean!) {
    lead(id: $id) @skip(if: $isLeadSkip) {
      ...lead
      contacts {
        ...contact
      }
    }

    # Options
    industries
    countries: g_countries
    campaigns: facebook_campaigns
    tags(first: 9999) {
      data {
        ...tag
      }
    }
    users: saas_names(first: 9999) {
      data {
        id
        name
      }
    }
  }
  ${tagFragment}
  ${leadFragment}
  ${contactFragment}
`;

export const setLeadsAgentFormQuery = gql`
  query fetchActivitiesFiltersOptions {
    users: saas_names(first: 9999) {
      data {
        id
        name
      }
    }
  }
`;


export const getActiveSaasUsersMenu = gql`
  query fetchActiveSaasUsersMenu {
     users: active_saas_users_menu {
        id
        name
    }
  }
`;

/* Activities */

export const activitiesListQuery = gql`
  query activitiesList(
    $page: Int
    $to: Date
    $from: Date
    $first: Int!
    $lead_id: Int
    $type: String
    $status: String
    $f_type: String
    $assigned_to: Int
  ) {
    activities(
      to: $to
      from: $from
      page: $page
      type: $type
      first: $first
      status: $status
      f_type: $f_type
      lead_id: $lead_id
      assigned_to: $assigned_to
    ) {
      data {
        ...activity
        contact {
          ...contact
        }
        comments {
          ...comment
        }
        user {
          name
        }
      }
      paginatorInfo {
        ...paginator
      }
    }
  }
  ${commentFragment}
  ${contactFragment}
  ${activityFragment}
  ${paginatorFragment}
`;
// export const activitiesOptionsQuery = gql`
//   query activitiesListOptions {
//     users: saas_names(first: 9999, haveLeads: 1) {
//       data {
//         id
//         name
//       }
//     }
//   }
// `;

export const activityFormQuery = gql`
  query activityForm($id: ID, $isActivitySkip: Boolean!) {
    activity(id: $id) @skip(if: $isActivitySkip) {
      ...activity
      comments {
        ...comment
      }
      contact {
        ...contact
      }
    }

    # Options
    plans: plan_names(first: 9999) {
      data {
        id
        name
      }
    }
  }
  ${commentFragment}
  ${contactFragment}
  ${activityFragment}
`;

/* Reminders */

export const remindersListQuery = gql`
  query remindersList(
    $to: Date
    $page: Int
    $from: Date
    $first: Int!
    $lead_ids: [ID]
    $type: String
    $f_type: String
    $ff_type: String
    $assigned_to: ID
  ) {
    reminders(
      to: $to
      from: $from
      type: $type
      page: $page
      first: $first
      f_type: $f_type
      ff_type: $ff_type
      lead_ids: $lead_ids
      assigned_to: $assigned_to
    ) {
      data {
        ...reminder
        comments {
          ...comment
        }
        user {
          name
        }
        contact {
          ...contact
        }
      }
      paginatorInfo {
        ...paginator
      }
    }
  }
  ${commentFragment}
  ${contactFragment}
  ${reminderFragment}
  ${paginatorFragment}
`;
// export const remindersListOptionsQuery = gql`
//   query remindersListOptions {
//     users: saas_names(first: 9999, haveLeads: 1) {
//       data {
//         id
//         name
//       }
//     }
//   }
// `;

export const reminderFormQuery = gql`
  query reminderForm($id: ID, $isReminderSkip: Boolean!) {
    reminder(id: $id) @skip(if: $isReminderSkip) {
      ...reminder
      comments {
        ...comment
      }
      contact {
        ...contact
      }
    }

    # Options
    plans: plan_names(first: 9999) {
      data {
        id
        name
      }
    }
  }
  ${commentFragment}
  ${contactFragment}
  ${reminderFragment}
`;

/* Trials */

export const trialsListQuery = gql`
  query trialsList(
    $to: Date
    $page: Int
    $from: Date
    $first: Int!
    $user_id: ID
    $lead_ids: [ID]
    $status: String
    $company: String
    $assigned_to: ID
  ) {
    trials(
      to: $to
      from: $from
      page: $page
      first: $first
      status: $status
      company: $company
      user_id: $user_id
      lead_ids: $lead_ids
      assigned_to: $assigned_to
    ) {
      data {
        ...trial
        lead {
          id
        }
      }
      paginatorInfo {
        ...paginator
      }
    }
  }
  ${trialFragment}
  ${paginatorFragment}
`;
export const trialsListOptionsQuery = gql`
  query trialsListOptions {
    users: saas_names(first: 9999, haveLeads: 1) {
      data {
        id
        name
      }
    }
  }
`;

export const trialFormQuery = gql`
  query trialForm($id: ID, $isTrialSkip: Boolean!) {
    trial(id: $id) @skip(if: $isTrialSkip) {
      ...trial
      lead {
        id
        name
      }
    }

    # Options
    plans: plan_names(first: 9999) {
      data {
        id
        name
      }
    }
    managers: saas_names(first: 9999, type: "Trial") {
      data {
        id
        name
      }
    }
  }
  ${trialFragment}
`;

/* Agents */

export const agentListQuery = gql`
  query agentList($first: Int!, $page: Int!) {
    agents: saas_names(first: $first, page: $page, haveLeads: 1) {
      data {
        ...employeeProfile
        emp_multiple_sign_ins: card_details {
          sign_in_time
          sign_out_time
          sign_in_location_name
          sign_out_location_name
          status
          work_timing_name
        }
        attendance_profile {
          name
          permission_leaves_break_setting {
            annualLeaveSetting {
              allow_annual_leaves
              allowed_emergency_leaves
            }
            sickLeaveSetting {
              sick_leaves
            }
          }
          activeWorkTiming {
            overtime_permissible
            name
          }
        }
        employee {
          relevantAttendanceTypeConfiguration {
            type
            workGroup {
              name
              managers {
                id
                name
              }
            }
            workTeam {
              name
            }
          }
        }
        emp_multiple_sign_ins: card_details {
          sign_in_time
          sign_out_time
          sign_in_location_name
          sign_out_location_name
          status
          work_timing_name
        }
      }
      paginatorInfo {
        ...paginator
      }
    }
  }
  ${employeeFragment}
  ${paginatorFragment}
`;

/* Calendar */

export const calendarQuery = gql`
  query calendar($from: Date, $to: Date, $assigned_to: ID) {
    crm_calendar(from: $from, to: $to, assigned_to: $assigned_to) {
      type
      info
      date
    }
  }
`;

export const calendarEventQuery = gql`
  query calendarEvent(
    $id: ID
    $isTrial: Boolean! = false
    $isActivity: Boolean! = false
    $isReminders: Boolean! = false
  ) {
    activity(id: $id) @include(if: $isActivity) {
      status
      contact {
        name
      }
      lead {
        id
        name
        assignedTo {
          name
        }
      }
    }
    reminder(id: $id) @include(if: $isReminders) {
      status
      due_date
      contact {
        name
      }
      lead {
        id
        name
        assignedTo {
          name
        }
      }
    }
    trial(id: $id) @include(if: $isTrial) {
      subscription {
        end_date
        start_date
        limit: employees_limit
        plan {
          name
        }
        company {
          businessPartners {
            user {
              name
            }
          }
        }
      }
    }
  }
`;

/* Dashboard */

export const dashboardCRMQuery = gql`
  query dashboardCRM(
    $to: Date
    $from: Date
    $reminderAgent: ID = null
    $activityAgent: Int = null
    $activityScope: String = "All"
    $remindersScope: String = "All"
    $isReminders: Boolean! = false
    $isActivities: Boolean! = false
  ) {
    activities(
      to: $to
      from: $from
      first: 9999
      f_type: $activityScope
      assigned_to: $activityAgent
    ) @include(if: $isActivities) {
      data {
        id
        type
        date
        notes
        lead {
          id
          name
          assignedTo {
            name
          }
        }
        contact {
          ...contact
        }
      }
    }
    reminders(
      to: $to
      from: $from
      first: 9999
      ff_type: $remindersScope
      assigned_to: $reminderAgent
    ) @include(if: $isReminders) {
      data {
        id
        type
        notes
        status
        due_date
        lead {
          id
          name
          assignedTo {
            name
          }
        }
        contact {
          ...contact
        }
      }
    }
  }
  ${contactFragment}
`;

export const dashboardCRMOptionsQuery = gql`
  query dashboardCRMOptions {
    agents: saas_names(first: 9999, haveLeads: 1) {
      data {
        id
        name
      }
    }
  }
`;

/* Others */

export const getCitiesCountriesQuery = gql`
  query getCitiesCountries($country: String) {
    cities: g_cities(name: $country)
  }
`;

export const fetchLeadContactQuery = gql`
  query fetchLeadContact($id: ID) {
    lead(id: $id) {
      contacts {
        ...contact
      }
    }
  }
  ${contactFragment}
`;
