import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import {
  setDataTableSortingAction,
} from "../../Store/Actions";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";
import InboundRequests from "../../Containers/InboundRequests";
import { useQuery } from "@apollo/client";
import { inboundRequestsQuery } from "../../Graphql/query";

const inboundRequestsRef = "inboundRequestsList";

const ViewRequests = (props) => {

  const inboundRequestsList = useSelector(
    (state) => state.super.inboundRequestsList
  );

  const paginationInitState = {
    total: 20,
    perPage: 20,
    lastPage: 1,
    lastItem: 20,
    firstItem: 1,
    currentPage: 1,
    hasMorePages: false,
  };

  const [pagination, setPagination] = useState(paginationInitState);

  useEffect(() => {
    return () => {
      setPagination(paginationInitState);
    };
  }, []);

  const fetchVariables = {
    rows: pagination?.perPage,
    page: pagination?.currentPage,
    field: inboundRequestsList.sorting.key,
    directManaged: inboundRequestsList.directManaged,
    order: inboundRequestsList.sorting.dir.toUpperCase(),
    status: !!inboundRequestsList.statusFilter
      ? inboundRequestsList.statusFilter
      : null,
    from: inboundRequestsList?.from,
    payable_month: !inboundRequestsList.payable_month
      ? null
      : inboundRequestsList.payable_month,
    to: inboundRequestsList?.to,
    office_id: inboundRequestsList?.office_id,
    emp_id: inboundRequestsList?.employees?.length
      ? inboundRequestsList?.employees
      : null,
    request_type_id: !!inboundRequestsList?.type
      ? inboundRequestsList?.type
      : null,
    route: "requests_list",
  };

  const refetchQueries = [
    {
      query: inboundRequestsQuery,
      variables: fetchVariables,
    },
  ];

  const {
    data: inboundRequestsQueryData,
    loading: inboundRequestsLoading,
    refetch
  } = useQuery(inboundRequestsQuery, {
    variables: fetchVariables,
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    onCompleted: (data) => {
      setPagination(data?.users_requests?.paginatorInfo);
    },
  });

  const handleRequestsFilter = () => {
    refetch({
      office_id: props.data[inboundRequestsRef].office_id,
      status: !!inboundRequestsList.statusFilter
        ? inboundRequestsList.statusFilter
        : null,
      from: inboundRequestsList?.from,
      directManaged: inboundRequestsList.directManaged,
      payable_month: !inboundRequestsList.payable_month
        ? null
        : inboundRequestsList.payable_month,
      to: inboundRequestsList?.to,
      request_type_id: !!inboundRequestsList?.type
        ? inboundRequestsList?.type
        : null,
      emp_id: inboundRequestsList?.employees?.length
        ? inboundRequestsList?.employees
        : null,
    });
  };

  const didRequestsUpdtate = useDidUpdateEffect(handleRequestsFilter, [
    props.data[inboundRequestsRef].directManaged,
    props.data[inboundRequestsRef].statusFilter,
    props.data[inboundRequestsRef].from,
    props.data[inboundRequestsRef].to,
    props.data[inboundRequestsRef].employees,
    props.data[inboundRequestsRef].type,
    props.data[inboundRequestsRef].payable_month,
    props.data[inboundRequestsRef].office_id,
  ]);

  const handleRequestsSorting = (field, dir) => {
    refetch({
      page: pagination?.currentPage,
      field: field?.selector,
      order: dir?.toUpperCase(),
    });
    props.setDataTableSortingAction(inboundRequestsRef, field.selector, dir);
  };

  const handleRequestsPaginate = (page = pagination.currentPage) => {
    setPagination({ ...pagination, currentPage: page });
    refetch({ page });
  };

  return (
    <div className="content requests_view_wrapper_style ml-0">
      <InboundRequests
        dataTableRef={inboundRequestsRef}
        title=""
        onSorting={handleRequestsSorting}
        onFilter={handleRequestsFilter}
        onPaginate={handleRequestsPaginate}
        pageFlag="requests_list"
        inboundRequestsData={inboundRequestsQueryData?.users_requests?.data?inboundRequestsQueryData?.users_requests?.data :[]}
        officesFilterOptions={inboundRequestsQueryData?.offices?.data}
        employeesFilterOptions={inboundRequestsQueryData?.employees?.data}
        inboundRequestsLoading={inboundRequestsLoading}
        refetchQueries={refetchQueries}
        pagination={pagination}
        refetchRequests={refetch}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state?.super,
  };
};

export default connect(mapStateToProps, {
  setDataTableSortingAction,
})(ViewRequests);
