import React from "react";
import { makeStyles } from "tss-react/mui";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useTranslation } from "react-i18next";
import HelperFns from "../../Helpers/HelperFns";
import Privilages from "../../Constants/Privilages";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import { Spinner } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagic, faMailBulk } from "@fortawesome/free-solid-svg-icons";
import { connect, useSelector } from "react-redux";
import { generateDemoAction } from "../../Store/Actions";
import Constants, { crmTabs } from "../../Constants";
import {
  faBuilding,
  faUser,
  faFile,
  faPlaneDeparture,
} from "@fortawesome/free-solid-svg-icons";
import { faFileLines } from "@fortawesome/free-regular-svg-icons";
import { useHistory } from "react-router-dom";

const verticalNavbarStyles = makeStyles()(() => Constants.verticalNavbarStyles);
const horizontalNavbarStyles = makeStyles()(
  () => Constants.horizontalNavbarStyles
);

const AdminPanelTabs = (props) => {
  const history = useHistory();
  const userType = useSelector((state) => state.auth?.userProfile?.user_type);
  const { classes } = props?.isRouteSidebar
    ? verticalNavbarStyles()
    : horizontalNavbarStyles();
  const { t } = useTranslation();

  const handleGenerateDemo = () => {
    props.generateDemoAction();
  };

  const handleChange = (event, newValue) => {
    history.push(`/admin-panel?tab=${newValue}`);
  };

  return (
    <>
      <Tabs
        variant="scrollable"
        value={props.activeValue}
        onChange={handleChange}
        scrollButtons="auto"
        orientation={props?.isRouteSidebar ? "vertical" : "horizontal"}
        classes={classes}
      >
        {HelperFns.checkPrivileges({
          allowBP: false,
          privileges: [Privilages.VIEW_COMPANIES, Privilages.SUPER_PRIVILEGE],
        }) && (
          <Tab
            classes={{
              root: classes.tabRoot,
              wrapper: "top-bar-label",
              selected: classes.selected,
            }}
            value="companies"
            label={
              <div className="mx-2 routeBar-text">
                <FontAwesomeIcon
                  icon={faBuilding}
                  className="mx-2 routeBar-icon"
                />
                {t("Companies")}
              </div>
            }
          />
        )}

        {HelperFns.checkPrivileges({
          allowBP: false,
          privileges: [Privilages.VIEW_ADMIN_USERS, Privilages.SUPER_PRIVILEGE],
        }) && (
          <Tab
            classes={{
              root: classes.tabRoot,
              wrapper: "top-bar-label",
              selected: classes.selected,
            }}
            value="admin-users"
            label={
              <div className="mx-2 routeBar-text">
                <FontAwesomeIcon icon={faUser} className="mx-2 routeBar-icon" />
                {t("admin users")}
              </div>
            }
          />
        )}

        {HelperFns.checkPrivileges({
          allowBP: false,
          privileges: [Privilages.SUPER_PRIVILEGE, Privilages.VIEW_PLANS],
        }) && (
          <Tab
            classes={{
              root: classes.tabRoot,
              wrapper: "top-bar-label",
              selected: classes.selected,
            }}
            value="plans"
            label={
              <div className="mx-2 routeBar-text">
                <FontAwesomeIcon icon={faFile} className="mx-2 routeBar-icon" />
                {t("packages")}
              </div>
            }
          />
        )}

        {HelperFns.checkPrivileges({
          allowBP: false,
          privileges: [
            Privilages.SUPER_PRIVILEGE,
            Privilages.VIEW_ADMIN_HOLIDAYS,
          ],
        }) && (
          <Tab
            classes={{
              root: classes.tabRoot,
              wrapper: "top-bar-label",
              selected: classes.selected,
            }}
            value="holidays"
            label={
              <div className="mx-2 routeBar-text">
                <FontAwesomeIcon
                  icon={faPlaneDeparture}
                  className="mx-2 routeBar-icon"
                />
                {t("holidays")}
              </div>
            }
          />
        )}

        <Tab
          classes={{
            root: classes.tabRoot,
            wrapper: "top-bar-label",
            selected: classes.selected,
          }}
          value="taxes"
          label={
            <div className="mx-2 routeBar-text">
              <FontAwesomeIcon
                icon={faFileLines}
                className="mx-2 routeBar-icon"
              />
              {t("taxes")}
            </div>
          }
        />

        {HelperFns.checkPrivileges({
          allowBP: false,
          privileges: [
            Privilages.SUPER_PRIVILEGE,
            Privilages.VIEW_ADMIN_ANNOUNCEMENT,
          ],
        }) && (
          <Tab
            classes={{
              root: classes.tabRoot,
              wrapper: "top-bar-label",
              selected: classes.selected,
            }}
            value="announcements"
            label={
              <div className="mx-2 routeBar-text">
                <FontAwesomeIcon
                  icon={faFileLines}
                  className="mx-2 routeBar-icon"
                />
                {t("announcements")}
              </div>
            }
          />
        )}

        {HelperFns.checkPrivileges({
          allowBP: false,
          privileges: [
            Privilages.ADMIN_MANUAL_VERIFY_EMPLOYEE,
            Privilages.SUPER_PRIVILEGE,
          ],
        }) && (
          <Tab
            classes={{
              root: classes.tabRoot,
              wrapper: "top-bar-label",
              selected: classes.selected,
            }}
            value="manual-verification"
            label={
              <div className="mx-2 routeBar-text">
                <FontAwesomeIcon icon={faUser} className="mx-2 routeBar-icon" />
                {t("manual verification")}
              </div>
            }
          />
        )}

        {/* CRM Tabs */}
        {userType === "Super"
          ? crmTabs.map((tab) => (
              <Tab
                key={tab?.value}
                value={tab?.value}
                classes={{
                  root: HelperFns.checkPrivileges({
                    allowBP: false,
                    privileges: [
                      Privilages.SUPER_PRIVILEGE,
                      ...(tab?.privileges || []),
                    ],
                  })
                    ? classes.tabRoot
                    : "d-none",
                  wrapper: "top-bar-label",
                  selected: classes.selected,
                }}
                label={
                  <div className="mx-2 routeBar-text">
                    {tab?.icon || (
                      <FontAwesomeIcon
                        icon={faMailBulk}
                        className="mx-2 routeBar-icon"
                      />
                    )}
                    {t(tab?.value || tab?.value)}
                  </div>
                }
              />
            ))
          : null}
      </Tabs>
      {props?.isRouteSidebar ? null : (
        <HasPrivileges reqireMain={[Privilages.GENERATE_DEMO]}>
          <button
            className="align-items-center bg-white border-0 sub-title-style d-flex p-0 m-0 h-100 ml-auto text-capitalize my-3"
            onClick={handleGenerateDemo}
            disabled={props.isGenerateDemoLoading}
          >
            <>
              {t("generate demo")}
              {props.isGenerateDemoLoading ? (
                <Spinner color="primary" size="sm" className="ml-2" />
              ) : (
                <FontAwesomeIcon
                  icon={faMagic}
                  className="ml-2"
                  color="#23aaeb"
                />
              )}
            </>
          </button>
        </HasPrivileges>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.userProfile,
    isGenerateDemoLoading: state.admin.isGenerateDemoLoading,
  };
};

export default connect(mapStateToProps, {
  generateDemoAction,
})(AdminPanelTabs);
