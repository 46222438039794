import React, { useEffect, useState } from "react";
import MainModal from "../MainModal";
import {
  DateTimePickerForm,
  BSelect,
  CheckboxBooleanForm,
  InputForm,
} from "form-builder";
import { connect, useDispatch } from "react-redux";
import {
  updateSuspensionAttempt,
  initSuspensionAction,
  dismissSuspensionModalActionAction,
  addActivationOnBoardingBalanceAction,
} from "../../Store/Actions";

import Loader from "../Loader";
import { faPen, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { Table } from "reactstrap";
import RadioboxForm from "../../Builder/Form/RadioboxForm";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";
import moment from "moment";
import HelperFns from "../../Helpers/HelperFns";

const SuspensionModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch()

  const handleSubmitBtn = () => {
    setIsSubmitting(true);

    if (props.modalValidation.length) {
      return;
    }

    HelperFns.checkPassword(
      "defaut_warning_messsage",
      "",
      "Confirm, suspend the employee",
      "",
      () => dispatch(updateSuspensionAttempt(props.suspensionModal))
    );
  };

  const handleChangeSuspensionStart = () => {
    if (!!props?.suspensionModal?.suspend_from) {
      props.initSuspensionAction(props.suspensionModal);
    }
  };

  const useDidUpdateSuspensionDate = useDidUpdateEffect(
    handleChangeSuspensionStart,
    [props.suspensionModal.suspend_from]
  );

  // to approximate the numbers of remaning balance
  function remaningBalanceCalcualtion(num) {
    let roundNumToSeven = (+num)?.toFixed(7);
    let roundNumToTwo = (+roundNumToSeven)?.toFixed(2);
    return Math.abs(roundNumToTwo) == 0 ? 0 : roundNumToTwo;
  }

  const formName = "suspensionModal";
  const formNameValidation = "suspensionModalValidation";
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isModalVissible = props.modalData.isVissible;
  const modalMessage = props.modalData.modalMessage;
  const isLoading = props.modalData.isLoading;

  const toggleModal = () => {
    props.dismissSuspensionModalActionAction({
      ref: "suspensionModalActions",
      formName,
    });
  };

  // Start of handle the show and hide of edit remaining leave balance
  const [isShowEdit, setIsShowEdit] = useState(false);

  const handleShowEditRemaningLeaveBalanceInput = () => {
    setIsShowEdit(true);
  };

  const handleHideEditRemaningLeaveBalanceInput = () => {
    setIsShowEdit(false);
  };
  // End of handle the show and hide of edit remaining leave balance

  // Start of handle the adding the activation/onboarding balance to net remaning balance
  const handleAddingActivationOnBoardingBalance = () => {
    if (isShowEdit) {
      return;
    }
    if (!!props?.suspensionModal?.include_activation_onboarding_balance) {
      props.addActivationOnBoardingBalanceAction(
        remaningBalanceCalcualtion(
          +props.initSuspension.remaining?.annual +
          +props?.initSuspension?.onbaording_activation_balance
        )
      );
    } else {
      props.addActivationOnBoardingBalanceAction(
        remaningBalanceCalcualtion(+props.initSuspension.remaining?.annual)
      );
    }
  };

  const useDidUpdateNetRemaningBalance = useDidUpdateEffect(
    handleAddingActivationOnBoardingBalance,
    [
      props?.suspensionModal?.include_activation_onboarding_balance,
      isShowEdit,
      props.initSuspension.remaining?.annual,
    ]
  );
  // End of handle the adding the activation/onboarding balance to net remaning balance

  useEffect(() => {
    setIsSubmitting(false);
    setIsShowEdit(false);
  }, [isModalVissible]);

  const [isInFuture, setIsInFuture]= useState(false);
  const handleDateIntercept = (value) => {
    setIsInFuture(moment.utc().isBefore(value));
  };

  return (
    <MainModal
      isOpen={isModalVissible}
      toggle={toggleModal}
      modalTitle="Suspension"
      btnOnClick={handleSubmitBtn}
      btnSubmitLoading={isLoading}
    >
      <div className="px-4">
        {props?.data?.replace_direct_manager_in_suspension_modal ? (
          <>
            <div
              className={
                props?.data?.managedEmployeesInSuspensionModal
                  ?.map((emp) => emp?.id)
                  ?.includes(props?.suspensionModal?.replace_by) ||
                  !!props?.suspensionModal?.new_manager_for_replaced_by
                  ? "row mb-1"
                  : "row mb-3"
              }
            >
              <div className="col-12">
                <BSelect
                  label="replace by"
                  labelStyle="mb-2"
                  name="replace_by"
                  formName={formName}
                  formNameValidation={formNameValidation}
                  formSubmitting={isSubmitting}
                  validationName={`input.replace_by`}
                  options={props?.data?.replaceByManagersOptions}
                  keepDefaultStyle
                  optionLabel="name"
                  optionValue="id"
                  placeholder={t("select manager")}
                  isClearable
                  optDependType={"notEqual"}
                  optDependValue={[props?.suspensionModal?.suspended_employee]}
                  optDependKey="id"
                />
              </div>
            </div>

            {props?.data?.managedEmployeesInSuspensionModal
              ?.map((emp) => emp?.id)
              ?.includes(props?.suspensionModal?.replace_by) ||
              !!props?.suspensionModal?.new_manager_for_replaced_by ? (
              <div className="row mb-3">
                <div className="col-12">
                  <BSelect
                    name="new_manager_for_replaced_by"
                    formName={formName}
                    formNameValidation={formNameValidation}
                    formSubmitting={isSubmitting}
                    validationName={`input.new_manager_for_replaced_by`}
                    options={props?.data?.replaceByManagersOptions}
                    keepDefaultStyle
                    optionLabel="name"
                    optionValue="id"
                    placeholder={t("select manager")}
                    isClearable
                    optDependType={"notEqual"}
                    optDependValue={[
                      props?.suspensionModal?.suspended_employee,
                      props?.suspensionModal?.replace_by,
                    ]}
                    optDependKey="id"
                  />

                  <span className="danger_note_style mt-2 d-inline-block">
                    <strong>{t("Note")} : </strong>
                    {t("new_manager_note_message", {
                      manager_name: props?.data?.replaceByManagersOptions
                        .filter(
                          (replaceName) =>
                            replaceName.id ==
                            props?.suspensionModal?.suspended_employee
                        )[0]
                        ?.name.toLowerCase(),
                      replace_by_name: props?.data?.replaceByManagersOptions
                        .filter(
                          (replaceName) =>
                            replaceName.id == props?.suspensionModal?.replace_by
                        )[0]
                        ?.name.toLowerCase(),
                    })}
                  </span>
                </div>
              </div>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}

        <DateTimePickerForm
          formName={formName}
          formNameValidation={formNameValidation}
          formSubmitting={isSubmitting}
          containerStyle="custom_datepicker_wrapper_style mb-3"
          name="suspend_from"
          placeholder={t("Suspension Start Date")}
          // label="start date"
          labelStyle="mb-2"
          validateBy="textRequired"
          hasIcon
          onIntercept={handleDateIntercept}
        />
        {/* (Start) Error Message */}
        {modalMessage && <div className="warnig-msg-style">{modalMessage}</div>}
        {/* (End) Error Message */}
        <div>
          {props.initSuspensionLoading ||
            props?.isReplaceByManagersOptionsLoading ? (
            <div className="loader_wrapper_style">
              <Loader />
            </div>
          ) : null}

          {!!props?.suspensionModal?.suspend_from ? (
            <>
              {!isInFuture ? <><h5 className="suspense-label">
                {t("Employee Remaining Balance At Suspension Date")}
              </h5>
              <Table
                className="suspension_leave_list_style"
                responsive
                size="md"
              >
                <thead>
                  <tr className="table-light-gray">
                    <th>{t("leaves")}</th>
                    <th>{t("annual")}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">{t("deserved")}</th>
                    <td className="suspension-values">
                      {isNaN(
                        remaningBalanceCalcualtion(
                          props.initSuspension?.deserved?.annual
                        )
                      )
                        ? "----"
                        : remaningBalanceCalcualtion(
                          props.initSuspension?.deserved?.annual
                        )}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">{t("used")}</th>
                    <td className="suspension-values">
                      {isNaN(
                        remaningBalanceCalcualtion(
                          props.initSuspension.used?.annual
                        )
                      )
                        ? "----"
                        : remaningBalanceCalcualtion(
                          props.initSuspension.used?.annual
                        )}
                    </td>
                  </tr>
                  <tr className="table-light-gray">
                    <th scope="row">{t("total")}</th>
                    <td className="suspension-values">
                      {isNaN(
                        remaningBalanceCalcualtion(
                          props.initSuspension.remaining?.annual
                        )
                      )
                        ? "----"
                        : remaningBalanceCalcualtion(
                          props.initSuspension.remaining?.annual
                        )}
                    </td>
                  </tr>
                </tbody>
              </Table></>:null}

              {+props.initSuspension.remaining?.annual +
                +props?.initSuspension?.onbaording_activation_balance !=
                0 ? (
                <>
                  {props?.initSuspension?.onbaording_activation_balance !=
                    null ? (
                    <div className="row align-items-baseline mb-1">
                      <div className="col-10">
                        <CheckboxBooleanForm
                          formName={formName}
                          formNameValidation={formNameValidation}
                          formSubmitting={isSubmitting}
                          options={[t("Include Activation/Onboarding Balance")]}
                          name="include_activation_onboarding_balance"
                          type="checkbox"
                          disabled={isShowEdit}
                        />
                      </div>

                      <div className="col-2 px-0">
                        <h5 className="on_boarding_balance_style">
                          {isNaN(
                            remaningBalanceCalcualtion(
                              props?.initSuspension
                                ?.onbaording_activation_balance
                            )
                          )
                            ? "----"
                            : remaningBalanceCalcualtion(
                              props?.initSuspension
                                ?.onbaording_activation_balance
                            )}
                        </h5>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {!isInFuture ? <div className="row align-items-baseline">
                    <div className="col-10">
                      <h5 className="suspense-sub-title">
                        {t("Net Remaining Leave Balance")}
                      </h5>
                    </div>

                    <div className="col-2 px-0">
                      {!isShowEdit ? (
                        <div className="d-flex align-items-baseline">
                          <div className="">
                            <h5 className="suspense-sub-title suspension-values">
                              {isNaN(props?.suspensionModal.affected_balance)
                                ? "----"
                                : props?.suspensionModal.affected_balance}
                            </h5>
                          </div>

                          {props?.initSuspensionLoading ? (
                            ""
                          ) : (
                            <div className="px-3">
                              <FontAwesomeIcon
                                className="cursor-pointer suspension_show_input_icon_style"
                                icon={faPen}
                                onClick={
                                  handleShowEditRemaningLeaveBalanceInput
                                }
                              />
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="d-flex align-items-baseline">
                          <InputForm
                            formName={formName}
                            formNameValidation={formNameValidation}
                            formSubmitting={isSubmitting}
                            name="affected_balance"
                            inputContainerStyle="custom_suspension_input_style"
                            containerStyle=" "
                            type="number"
                          />

                          <div className="px-2">
                            <FontAwesomeIcon
                              className="cursor-pointer suspension_hide_input_icon_style"
                              icon={faTimes}
                              onClick={handleHideEditRemaningLeaveBalanceInput}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div> : null}

                  <div>
                    <RadioboxForm
                      formName={formName}
                      formNameValidation={formNameValidation}
                      formSubmitting={isSubmitting}
                      labelStyle="suspense-label"
                      containerStyle=""
                      optionsContainerStyle="d-flex flex-column"
                      optionItemStyle="d-flex flex-row my-1"
                      name="handleLeaveBalanceAfterSuspensionOption"
                      label="Handle Leave Balance After Suspension"
                      optionInputStyle
                      options={
                        +props?.suspensionModal?.affected_balance > 0
                          ? [
                            {
                              label: t(
                                "Zero Remiaining Balance and Convert to Bonus Days"
                              ),
                              value: "2",
                            },
                            {
                              label: t("Zero Balance"),
                              value: "3",
                            },
                          ]
                          : [
                            {
                              label: t(
                                "Zero Remiaining Balance and Convert to Deduction Days"
                              ),
                              value: "5",
                            },
                            {
                              label: t("Zero Balance"),
                              value: "3",
                            },
                          ]
                      }
                    />
                  </div>
                  <div>
                         <CheckboxBooleanForm
                          formName={formName}
                          formNameValidation={formNameValidation}
                          formSubmitting={isSubmitting}
                          options={[t("blacklisted")]}
                          name="is_blacklisted"
                          type="checkbox"
                          disabled={isShowEdit}
                          setWithValue
                        />
                  </div>

                  {!isInFuture ? <div className="notes-style my-1">
                    <p className="my-2 h6">{t("deserved_leave_balance")}</p>
                    <p className="my-2 h6">{t("used_leave_balance")}</p>
                  </div> : null}
                </>
              ) : null}
            </>
          ) : null}
        </div>
      </div>
    </MainModal>
  );
};

const mapStateToProps = (state) => ({
  data: state.super,
  modalData: state.super.suspensionModalActions,
  modalValidation: state.super.suspensionModalValidation,
  suspensionModal: state.super.suspensionModal,
  initSuspension: state.super.init_suspension,
  initSuspensionLoading: state.super.initSuspenseLoading,
  isReplaceByManagersOptionsLoading:
    state?.super?.isReplaceByManagersOptionsLoading,
});

export default connect(mapStateToProps, {
  updateSuspensionAttempt,
  initSuspensionAction,
  dismissSuspensionModalActionAction,
  addActivationOnBoardingBalanceAction,
})(SuspensionModal);
