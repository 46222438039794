import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import MainModal from "../MainModal";
import { Config } from "../../Config";
import moment from "moment";
import { components } from "react-select/dist/react-select.cjs.prod";
import Avatar from "@mui/material/Avatar";

import { RadioboxForm, CheckboxBooleanForm, DateTimePickerForm, BSelect } from "form-builder";
import {
	hideEditHistoryModal,
	viewWorkTimeAction,
	setFormServerValidationAction,
	updateValueAction,
	showToast,
	showErrorToast,
} from "../../Store/Actions";
import { useTranslation } from "react-i18next";
import Loader from "../../Components/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import IconButtonWithTooltip from "../IconButtonWithTooltip";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { GET_WORKING_TIMMING_OPTIONS_QUERY, fetchEditAttendanceEmpProfile } from "../../Graphql/query";
import HelperFns from "../../Helpers/HelperFns";
import BreakSection from "./BreakSection";

const formName = "editAttRecord";
const formNameValidation = "editAttHistoryModalValidation";
const formServerValidation = "editAttHistoryServerValidation";

const formatWorkTiming = (time, type) => {
	switch (type) {
		case "time_range":
			return moment(time, "h:mm:ss").format("h:mm a");
		case "hours":
			return moment(time, "h:mm:ss").format("hh:mm");
		default:
			return;
	}
};

// constants

const editAttendanceInitState = {
	signInOutId: null,
	status: "attended",
	dayType: "normal",
	daySubType: null,
	workTimingId: null,
	signInTime: null,
	signInDate: null,
	signOutTime: null,
	signOutDate: null,
	fromHome: false,
	customLeavePaymentType: "paid",
	date: null,
	employee_id: null,
	leaveType: null,
};

const EDIT_ATTENDANCE_RECORD_QUERY = gql`
	query fetchEditAttendanceRecord($id: ID!, $date: String, $signInOutId: ID, $work_timming_date: Date) {
		availableRequests: getAllowedRequests(employeeID: $id, date: $date) {
			id
			name
		}
		work_timings_menu(type: "normal", new_versions_only: true, archive: $work_timming_date) {
			id
			name
			sign_in_req
			sign_in_start_time
			sign_in_end_time
			work_hours
			archived
		}
		half_work_timings_menu: work_timings_menu(type: "half", new_versions_only: true, archive: $work_timming_date) {
			id
			name
			sign_in_req
			sign_in_start_time
			sign_in_end_time
			work_hours
			archived
		}
		employee(id: $id) {
			on_probation
		}
		getEmployeeProfile(employeeID: $id, date: $date) {
			id
			can_request_half_day
			permission_leaves_break_setting(date: $date) {
				annualLeaveSetting {
					allow_annual_leaves
					allowed_annual_leaves
					allowed_emergency_leaves
				}
				unpaidLeaveSetting {
					unpaid_leaves
				}
				sickLeaveSetting {
					sick_leaves
				}
				probationPeriodConfiguration {
					request_leaves
				}
				customLeaveSetting {
					allow_custom_leave
					bereavement_leave
					maternal_paternal_leave
					hajj_leave
					sabbatical_leave
					study_leave
					marriage_leave
					in_lieu_of_work_day_leave
					military_call_leave
				}
			}
		}
		signInOut(id: $signInOutId) {
			id
			date
			emp_id
			sign_in_time
			sign_out_time
			work_timing {
				id
				name
				sign_in_req
				sign_in_start_time
				sign_in_end_time
				work_hours
				type: work_timing_profile_type
				archived
			}
			auto_signed_out
			status
			from_home
			day_type
			day_sub_type
			employee {
				id
				name
			}
			sign_in_location {
				name
			}
			sign_out_location {
				name
			}
			sign_out_lat
			sign_out_lng
			sign_in_lat
			sign_in_lng
			sign_in_face {
				path
			}
			sign_out_face {
				path
			}
			custom_leave_payment_type
			getSignInConfigType {
				... on Exception {
					__typename
					id
					assignment {
						first_half_work_timing_id
						second_half_work_timing_id
						treat_as_normal
					}
				}
				... on ScheduleDetailEmployee {
					__typename
				}
				... on AttendanceProfileWorkTiming {
					__typename
				}
			}
			breakInOuts {
				id
				break_in_time
				break_out_time
			}
			break_setting {
				allow_break
			}
		}
	}
`;
const UPDATE_EDIT_ATTENDANCE = gql`
	mutation editAttendance($input: EditAttendanceInput) {
		editAttendance(input: $input) {
			id
		}
	}
`;

const initStatusOptions = [
	{ label: "attended", value: "attended" },
	{ label: "leave", value: "Leave" },
	{ label: "absent", value: "Absent" },
	{ label: "holiday", value: "Holiday" },
	{ label: "Day Off", value: "Day Off" },
];

const getHalfDayPaymentTypes = (reqFlags) => {
	const { isNormalHalf, isEmgHalf, isUnpaidHalf } = reqFlags;

	return [
		...(isNormalHalf ? [{ label: "annual", value: "Normal" }] : []),
		...(isEmgHalf ? [{ label: "emergency", value: "Emergency" }] : []),
		...(isUnpaidHalf ? [{ label: "unpaid", value: "Unpaid" }] : []),
	];
};

const CustomeLeavesPaymentFlag = [
	{ label: "paid", value: "paid" },
	{ label: "unpaid", value: "unpaid" },
];

const availableExceptoinStatusOptions = [
	{ label: "attended", value: "attended" },
	{ label: "absent", value: "Absent" },
];

const signInOutTypes = {
	ATTENDANCE_PROFILE_WORK_TIMING: "AttendanceProfileWorkTiming",
	EXCEPTION: "Exception",
	SHIFT: "ScheduleDetailEmployee",
};

const getStatusOptions = (getSignInConfigType) => {
	switch (getSignInConfigType?.__typename) {
		case signInOutTypes.ATTENDANCE_PROFILE_WORK_TIMING:
		case signInOutTypes.SHIFT:
			return initStatusOptions;

		case signInOutTypes.EXCEPTION:
			if (getSignInConfigType?.assignment?.treat_as_normal) {
				return initStatusOptions;
			}
			return availableExceptoinStatusOptions;

		default:
			return [
				{ label: "attended", value: "attended" },
				{ label: "leave", value: "Leave" },
				{ label: "absent", value: "Absent" },
				{ label: "Day Off", value: "Day Off" },
				{ label: "holiday", value: "Holiday" },
			];
	}
};

const dayTypeOptions = [
	{ label: "normal", value: "normal" },
	{ label: "First Half", value: "1st_half" },
	{ label: "Second Half", value: "2nd_half" },
];

const CUSTOM_LEAVES = [
	"Bereavement",
	"Marriage",
	"Sabbatical",
	"Study",
	"Hajj",
	"Maternity_Paternity",
	"Bereavement",
	"In_Lieu_Of_Work_Day",
	"Military_Call",
];

const serializeEditAttendanceVariables = (form, signInReq) => {
	let input;
	switch (form?.status) {
		case "attended":
			input = {
				status: "attended",
				signInOutId: form?.signInOutId,
				dayType: form?.dayType,
				daySubType: form?.dayType?.includes("half") ? form?.daySubType : null,
				workTimingId: form?.workTimingId,
				signInTime:
					moment(`${form?.signInDate} ${form?.signInTime}`, "YYYY-MM-DD HH:mm", true).isValid() && signInReq
						? moment(`${form?.signInDate} ${form?.signInTime}`, "YYYY-MM-DD HH:mm").format("YYYY-MM-DD HH:mm")
						: null,
				signOutTime:
					moment(`${form?.signOutDate} ${form?.signOutTime}`, "YYYY-MM-DD HH:mm", true).isValid() && signInReq
						? moment(`${form?.signOutDate} ${form?.signOutTime}`, "YYYY-MM-DD HH:mm").format("YYYY-MM-DD HH:mm")
						: null,
				fromHome: Boolean(form?.fromHome),
				customLeavePaymentType: null,
				breaks: form?.breakInOuts.map((b) => ({
					id: b?.id,
					break_in_time: b?.break_in_time,
					break_out_time: b?.break_out_time,
				})),
			};
			return input;
		case "Absent":
			input = {
				status: "Absent",
				workTimingId: form?.workTimingId,
				signInOutId: form?.signInOutId,
				dayType: form?.dayType,
				daySubType: form?.dayType?.includes("half") ? form?.daySubType : null,
				customLeavePaymentType: null,
				signInTime: null,
				signOutTime: null,
				fromHome: null,
			};
			return input;
		case "Leave":
			input = {
				status: "Leave",
				dayType: "normal",
				signInOutId: form?.signInOutId,
				daySubType: form?.leaveType == "Normal" && form?.daySubType == "Emergency" ? "Emergency" : form?.leaveType,
				customLeavePaymentType: CUSTOM_LEAVES?.includes(form?.leaveType) ? form?.customLeavePaymentType : null,
				workTimingId: null,
				signInTime: null,
				signOutTime: null,
				fromHome: null,
			};
			return input;
		case "Holiday":
		case "Day Off":
			input = {
				signInOutId: form?.signInOutId,
				status: form?.status,
				dayType: "normal",
				daySubType: null,
				customLeavePaymentType: null,
				workTimingId: null,
				signInTime: null,
				signOutTime: null,
				fromHome: null,
			};
			return input;

		default:
			break;
	}
};

const getMainWorkTimingsOptionsFromWorkTimingMenu = (workTimingsMenu = [], actualWorkTime = {}, type = "normal") => {
	let options = [];
	if (workTimingsMenu && workTimingsMenu?.length) {
		if (actualWorkTime?.archived && type == actualWorkTime?.type) {
			options = [actualWorkTime, ...workTimingsMenu];
		} else {
			options = workTimingsMenu;
		}
		return options?.flat()?.map((workTiming) => ({
			...workTiming,
			id: workTiming?.id,
			archived: workTiming?.archived,
		}));
	}
	return [];
};

const isSignInReq = (editAttendance, data) => {
	let worktimeOptions = [];
	if (editAttendance?.dayType?.includes("half")) {
		worktimeOptions =
			getMainWorkTimingsOptionsFromWorkTimingMenu(data?.half_work_timings_menu, data?.signInOut?.work_timing, "half") ||
			[];
	} else {
		worktimeOptions =
			getMainWorkTimingsOptionsFromWorkTimingMenu(data?.work_timings_menu, data?.signInOut?.work_timing) || [];
	}
	let selectedWorktime = worktimeOptions?.find((wt) => wt.id == editAttendance?.workTimingId);

	if (selectedWorktime && !selectedWorktime?.sign_in_req) {
		return false;
	} else {
		return true;
	}
};

const EditAttHistoryModal = (props) => {
	const [statusOptions, setStatusOptions] = useState(initStatusOptions);

	const editAttendance = useSelector((state) => state?.super?.editAttRecord);
	const [formSubmitting, setFormSubmitting] = useState(false);
	const formProps = {
		formName,
		formNameValidation,
		formServerValidation,
		formSubmitting,
	};

	const [errorState, setErrorState] = useState({
		validation: {},
		errorMessage: "",
	});

	const [reqFlags, setReqFlags] = useState({});
	const [selectTypesOptions, setSelectTypesOptions] = useState([]);

	const [normalWorkTimingList, setNormalWorkTimingList] = useState([]);
	const [halfWorkTimingList, setHalfWorkTimingList] = useState([]);

	const { loading, error, data, refetch } = useQuery(EDIT_ATTENDANCE_RECORD_QUERY, {
		notifyOnNetworkStatusChange: true,
		skip: !!!editAttendance?.employee?.id || !!!editAttendance?.id || !moment(editAttendance?.date).isValid(),
		variables: {
			id: editAttendance?.employee?.id,
			date: moment(editAttendance?.date).format("YYYY-MM-DD"),
			signInOutId: editAttendance?.id,
		},

		onCompleted: (data) => {
			setNormalWorkTimingList(data?.work_timings_menu);
			setHalfWorkTimingList(data?.half_work_timings_menu);
			const status =
				data?.signInOut?.status?.toLowerCase()?.includes("leave") || CUSTOM_LEAVES?.includes(data?.signInOut?.status)
					? "Leave"
					: data?.signInOut?.status?.toLowerCase() == "absent" && !!!data?.signInOut?.sign_in_time
						? "Absent"
						: data?.signInOut?.sign_in_time || data?.signInOut?.status == "Ontime"
							? "attended"
							: data?.signInOut?.status;
			let leaveType = status == "Leave" ? data?.signInOut?.status?.split(" ")?.[0] : null;
			let daySubType = leaveType?.toLowerCase()?.includes("emergency") ? "Emergency" : data?.signInOut?.day_sub_type;
			leaveType = leaveType?.toLowerCase()?.includes("emergency") ? "Normal" : leaveType;
			dispatch({
				type: "EDIT_ATTENDANCE_RECORD",
				payload: {
					...editAttendance,
					status,
					daySubType,
					leaveType,
					customLeavePaymentType: data?.signInOut?.custom_leave_payment_type,
					employee: data?.signInOut?.employee,
					signInOutId: data?.signInOut?.id,
					signInTime:
						data?.signInOut?.sign_in_time && moment(data?.signInOut?.sign_in_time).isValid()
							? moment(data?.signInOut?.sign_in_time).format("HH:mm")
							: null,
					signInDate: data?.signInOut?.sign_in_time
						? moment(data?.signInOut?.sign_in_time).format("YYYY-MM-DD")
						: data?.signInOut?.date,
					signOutTime:
						data?.signInOut?.sign_out_time && moment(data?.signInOut?.sign_out_time).isValid()
							? moment(data?.signInOut?.sign_out_time).format("HH:mm")
							: null,
					signOutDate: data?.signInOut?.sign_out_time
						? moment(data?.signInOut?.sign_out_time).format("YYYY-MM-DD")
						: data?.signInOut?.date,
					workTimingId: data?.signInOut?.work_timing?.id,
					signInConfig: data?.signInOut?.getSignInConfigType,
					dayType: data?.signInOut?.day_type,
					fromHome: data?.signInOut?.from_home,
					date: data?.signInOut?.date,
					signInOutDates: [
						{
							label: moment(data?.signInOut?.date).subtract(1, "day").format("ddd DD/MM/YYYY"),
							value: moment(data?.signInOut?.date).subtract(1, "day").format("YYYY-MM-DD"),
						},
						{
							label: moment(data?.signInOut?.date).format("ddd DD/MM/YYYY"),
							value: moment(data?.signInOut?.date).format("YYYY-MM-DD"),
						},
						{
							label: moment(data?.signInOut?.date).add(1, "day").format("ddd DD/MM/YYYY"),
							value: moment(data?.signInOut?.date).add(1, "day").format("YYYY-MM-DD"),
						},
					],
					breakInOuts: data?.signInOut?.breakInOuts || [],
					break_setting: data?.signInOut?.break_setting || {},
				},
			});

			if (data?.signInOut?.getSignInConfigType) {
				setStatusOptions(getStatusOptions(data?.signInOut?.getSignInConfigType));
			} else {
				setStatusOptions(getStatusOptions());
			}

			// req options
			const { availableRequestsOptions, reqFlags } = HelperFns.getReqData(data?.availableRequests || [], "edit_att");

			setSelectTypesOptions(availableRequestsOptions);
			setReqFlags(reqFlags);
		},
		onError: () => {
			setSelectTypesOptions([]);
			setReqFlags({});
		},
	});

	const prevWT = data?.signInOut?.work_timing;

	const { loading: workTimmingLoading } = useQuery(GET_WORKING_TIMMING_OPTIONS_QUERY, {
		variables: {
			work_timming_date: moment(editAttendance?.signInDate).format("YYYY-MM-DD"),
		},
		onCompleted: (res) => {
			setNormalWorkTimingList(res?.work_timings_menu);
			setHalfWorkTimingList(res?.half_work_timings_menu);

			if (
				!res?.work_timings_menu?.find((wt) => wt.id == editAttendance?.workTimingId) &&
				editAttendance?.workTimingId != prevWT.id
			) {
				dispatch(updateValueAction(formName, "workTimingId", null));
			}
		},
		skip: !editAttendance?.signInDate,
		onError: (err) => {
			showToast("error", err?.graphQLErrors[0]?.extensions?.reason || err?.graphQLErrors[0]?.message || err?.message);
		},
	});

	const getDayTypesOptions = useCallback(() => {
		const can_request_half_day = reqFlags?.isUnpaidHalf || reqFlags?.isNormalHalf || reqFlags?.isEmgHalf;

		if (can_request_half_day) {
			return dayTypeOptions;
		} else {
			return dayTypeOptions?.filter((dayType) => !dayType.value.toLowerCase()?.includes("half"))
		}
	}, [reqFlags]);

	const [submitEditAttendance, { loading: submitLoading, data: submitData, error: submitError, reset }] = useMutation(
		UPDATE_EDIT_ATTENDANCE,
		{
			onCompleted(res) {
				// do someting
				console.log({ res });
				if (res?.editAttendance?.id) {
					// goBack();
					// refetch()
					props?.refetch();
					dismiss();

					// setEditAttendance(editAttendanceInitState)
				}
			},
			onError(error) {
				if (error.graphQLErrors?.[0]?.extensions?.reason) {
					dispatch(
						showErrorToast(
							error?.graphQLErrors[0]?.extensions?.reason || error?.graphQLErrors[0]?.message || error?.message
						)
					);
					return;
				}
				// Alert(t("error"), error?.message || t("something went wrong"))
				return;

				// do something
			},
		}
	);

	useEffect(() => {
		if (submitError) {
			if (submitError?.graphQLErrors?.[0]?.extensions?.category == "validation") {
				setErrorState({
					validation: submitError?.graphQLErrors?.[0]?.extensions?.validation,
					errorMessage: null,
				});
				dispatch(
					setFormServerValidationAction({
						serverValidationName: formServerValidation,
						validations: submitError?.graphQLErrors?.[0]?.extensions?.validation,
					})
				);
			} else {
				setErrorState({
					validation: {},
					errorMessage: submitError?.graphQLErrors?.[0]?.extensions?.reason ?? submitError?.message,
				});
			}
			return;
		}
		return () => {};
	}, [submitError]);

	const resetMutation = () => {
		reset();
	};

	const dismiss = () => {
		resetMutation();
		dispatch(hideEditHistoryModal());
		setSelectTypesOptions([]);
		setReqFlags({});
	};

	const handleSaveRequest = () => {
		let signInReq = isSignInReq(editAttendance, data);
		let input = serializeEditAttendanceVariables(editAttendance, signInReq);
		submitEditAttendance({
			variables: {
				input,
			},
		});
	};

	const handleInputChange = (name, value) => {
		if (name == "dayType" && !(value?.includes("half") && editAttendance?.dayType?.includes("half"))) {
			dispatch({
				type: "EDIT_ATTENDANCE_RECORD",
				payload: {
					...editAttendance,
					[name]: value,
					workTimingId: null,
				},
			});
			return false;
		}

		if (name == "status") {
			dispatch({
				type: "EDIT_ATTENDANCE_RECORD",
				payload: {
					...editAttendance,
					customLeavePaymentType: editAttendanceInitState.customLeavePaymentType,
					daySubType: editAttendanceInitState.daySubType,
					[name]: value,
				},
			});
			return false;
		}

		dispatch({
			type: "EDIT_ATTENDANCE_RECORD",
			payload: {
				...editAttendance,
				[name]: value,
			},
		});
	};

	const renderEditSignInDetails = () => {
		switch (editAttendance?.status) {
			case "attended":
			case "Absent":
				return (
					<>
						{/* DayType */}

						<div>
							<RadioboxForm
								{...formProps}
								name="dayType"
								validationName="input.dayType"
								options={getDayTypesOptions() ?? []}
								interceptChange={(e, value) => handleInputChange("dayType", value)}
								optionsContainerStyle="row edit_attendance_form_container"
								containerStyle={"mt-2"}
								optionItemStyle="col-md col-xl-2 text-nowrap mt-1 day_type_item"
								optionInputStyle={`d-flex edit_attendance_checkbox`}
								optionLabelStyle
								label="dayType"
								labelStyle="mb-2"
								validateBy="textRequired"
							/>
						</div>
						<>
							{/* Work Timing */}
							{editAttendance?.dayType?.includes("half") ? (
								<div className="align-items-end d-flex flex-row justify-content-between mb-2">
									<>
										<div className={`flex-1`}>
											<BSelect
												{...formProps}
												containerStyle="mt-3"
												name="workTimingId"
												label="applicable work timing"
												validationName="input.workTimingId"
												keepDefaultStyle
												placeholder={t("select option")}
												isDisabled={workTimmingLoading || loading}
												options={getMainWorkTimingsOptionsFromWorkTimingMenu(
													halfWorkTimingList,
													data?.signInOut?.work_timing,
													"half"
												)}
												isLoading={workTimmingLoading || loading}
												// value={worktimingProfile}
												optionLabel="name"
												optionValue="id"
												labelStyle="mb-2"
												inputContainerStyle="b-select-style attendance_work_timing_select"
												components={{ Option }}
												skipLocalization
												isClearable
												getOptionLabel={(opt) => (
													<span dir="auto">
														{opt.name}{" "}
														{opt?.archived
															? `(${moment(opt.created_at).format("DD-MM-YYYY")} ~ ${moment(opt.updated_at).format(
																	"DD-MM-YYYY"
																)})`
															: t("current version")}{" "}
													</span>
												)}
												icon="calendar"
												filterOption={filterOptions}
											/>
										</div>
										{editAttendance?.workTimingId ? (
											<HasPrivileges allowBP reqireMain={[Privilages.PREVIEW_WORK_TIMINGS]}>
												<IconButtonWithTooltip
													label={t("View Work Timing")}
													onClick={() => handleViewWorkTime(editAttendance?.workTimingId)}
													icon={<FontAwesomeIcon size="md" color="#5c6974" icon={faEye} className="ml-2" />}
												/>
											</HasPrivileges>
										) : null}
									</>
								</div>
							) : (
								<div className="align-items-end d-flex flex-row justify-content-between mb-2">
									<>
										<div className={`flex-1`}>
											{data?.signInOut?.getSignInConfigType?.__typename == signInOutTypes.SHIFT ? (
												<BSelect
													{...formProps}
													validationName="input.workTimingId"
													isDisabled={workTimmingLoading || loading}
													containerStyle="mt-3"
													name="workTimingId"
													label="applicable work timing"
													keepDefaultStyle
													placeholder={t("select option")}
													isLoading={workTimmingLoading || loading}
													options={
														getMainWorkTimingsOptionsFromWorkTimingMenu(
															[...(normalWorkTimingList || []), ...(halfWorkTimingList || [])],
															data?.signInOut?.work_timing
														) || []
													}
													optionLabel="name"
													optionValue="id"
													labelStyle="mb-2"
													inputContainerStyle="b-select-style attendance_work_timing_select"
													components={{ Option }}
													skipLocalization
													isClearable
													getOptionLabel={(opt) => (
														<span dir="auto">
															{opt.name}{" "}
															{opt?.archived
																? `(${moment(opt.created_at).format("DD-MM-YYYY")} ~ ${moment(opt.updated_at).format(
																		"DD-MM-YYYY"
																	)})`
																: t("current version")}{" "}
														</span>
													)}
													icon="calendar"
													filterOption={filterOptions}
												/>
											) : (
												<BSelect
													{...formProps}
													validationName="input.workTimingId"
													containerStyle="mt-3"
													name="workTimingId"
													label="applicable work timing"
													keepDefaultStyle
													placeholder={t("select option")}
													isDisabled={workTimmingLoading || loading}
													options={
														getMainWorkTimingsOptionsFromWorkTimingMenu(
															normalWorkTimingList,
															data?.signInOut?.work_timing
														) || []
													}
													isLoading={workTimmingLoading || loading}
													optionLabel="name"
													optionValue="id"
													labelStyle="mb-2"
													inputContainerStyle="b-select-style attendance_work_timing_select"
													components={{ Option }}
													skipLocalization
													isClearable
													getOptionLabel={(opt) => (
														<span dir="auto">
															{opt.name}{" "}
															{opt?.archived
																? `(${moment(opt.created_at).format("DD-MM-YYYY")} ~ ${moment(opt.updated_at).format(
																		"DD-MM-YYYY"
																	)})`
																: t("current version")}{" "}
														</span>
													)}
													icon="calendar"
													filterOption={filterOptions}
												/>
											)}
										</div>
										{editAttendance?.workTimingId ? (
											<HasPrivileges allowBP reqireMain={[Privilages.PREVIEW_WORK_TIMINGS]}>
												<IconButtonWithTooltip
													label={t("View Work Timing")}
													onClick={() => handleViewWorkTime(editAttendance?.workTimingId)}
													icon={<FontAwesomeIcon size="md" color="#5c6974" icon={faEye} className="ml-2" />}
												/>
											</HasPrivileges>
										) : null}
									</>
								</div>
							)}
						</>
						{editAttendance?.dayType?.includes("half") ? (
							// Half Day Payment type "daySubType"
							<>
								<RadioboxForm
									{...formProps}
									validationName="input.daySubType"
									name="daySubType"
									options={getHalfDayPaymentTypes(reqFlags)}
									optionsContainerStyle="row edit_attendance_form_container"
									containerStyle="mt-2"
									optionItemStyle="col-md text-nowrap mt-1 day_type_item"
									optionInputStyle={`d-flex edit_attendance_checkbox`}
									optionLabelStyle
									label="half day type"
									labelStyle="mb-2"
									validateBy="textRequired"
								/>
							</>
						) : null}
						{/*sign in out date times  */}
						{editAttendance?.status === "attended" && isSignInReq(editAttendance, data) ? (
							<>
								<div className="row align-items-start mt-3">
									<div className="col-sm-6 attendance_sign_container">
										<RadioboxForm
											{...formProps}
											name="signInDate"
											label="sign in time"
											labelStyle="mb-2"
											options={editAttendance?.signInOutDates}
											optionsContainerStyle="row sign_in_days"
											optionLabelStyle
											optionItemStyle="col-md-6 col-xl-4 attendance_sign_in_item"
											optionInputStyle="edit_attendance_checkbox"
										/>
										<DateTimePickerForm
											{...formProps}
											validationName="input.signInTime"
											inputStyle="date-picker-input-default validity-input-style sign_in_out_input attendance_sign_picker"
											name="signInTime"
											containerStyle=" "
											labelStyle="mb-2"
											validateBy="textRequired"
											hasIcon
											timeOnly
											requestFormat="HH:mm"
										/>
									</div>

									<div className="col-sm-6 attendance_sign_container">
										<RadioboxForm
											{...formProps}
											name="signOutDate"
											label={"sign out time"}
											labelStyle="mb-2"
											options={editAttendance?.signInOutDates}
											optionsContainerStyle="row sign_in_days"
											active
											optionItemStyle="col-md-6 col-xl-4 attendance_sign_in_item"
											optionInputStyle="edit_attendance_checkbox"
											optionLabelStyle
										/>
										<DateTimePickerForm
											{...formProps}
											validationName="input.signOutTime"
											name="signOutTime"
											containerStyle=" "
											inputStyle="date-picker-input-default validity-input-style sign_in_out_input attendance_sign_picker"
											labelStyle="mb-2"
											hasIcon
											timeOnly
											requestFormat="HH:mm"
										/>
									</div>
								</div>

								{reqFlags?.isHome ? (
									<CheckboxBooleanForm
										{...formProps}
										validationName="input.fromHome"
										options={[t("Employee Is Working Remotely")]}
										name="fromHome"
										type="checkbox"
										containerStyle="mt-3"
										optionLabelStyle="content-header-sub-label"
										// disabled={
										//   exception?.id &&
										//   !exception?.assignment?.treat_as_normal &&
										//   !exception?.assignment?.allow_home
										// }
									/>
								) : null}
							</>
						) : null}

						<div className="row mt-1">
							{editAttendance?.signInTime && editAttendance?.status === "attended" && (
								<div className={data?.signInOut?.sign_in_lat ? "col" : "col-12"}>
									<div className="my-3 font-weight-bold">{t("sign in location")}</div>
									{data?.signInOut?.sign_in_lat ? (
										<div style={{ height: "250px" }}>
											<div className="w-100 h-100 position-relative">
												<iframe
													width="100%"
													height="auto"
													style={{ border: 0, width: "100%", height: "100%" }}
													loading="eager"
													allowfullscreen
													referrerpolicy="no-referrer-when-downgrade"
													src={`https://www.google.com/maps/embed/v1/place?key=${Config.googleMapKey}&q=${data?.signInOut?.sign_in_lat},${data?.signInOut?.sign_in_lng}`}
												></iframe>
												{data?.signInOut?.sign_in_face ? (
													<Avatar
														src={data?.signInOut?.sign_in_face?.path}
														className="avatar blackish-avatar position-absolute"
														sx={{
															width: 100,
															height: 100,
															right: "/* @noflip */ 0",
															top: 0,
														}}
													/>
												) : null}
											</div>
										</div>
									) : (
										<div>
											<p>{data?.signInOut?.sign_in_location?.name || t("unspecified location")}</p>
											{data?.signInOut?.sign_in_face ? (
												<Avatar
													src={data?.signInOut?.sign_in_face?.path}
													className="avatar blackish-avatar position-absolute"
													sx={{
														width: 100,
														height: 100,
														right: "/* @noflip */ 0",
														top: 0,
													}}
												/>
											) : null}
										</div>
									)}
								</div>
							)}

							{editAttendance?.signOutTime && editAttendance?.status === "attended" && (
								<div className={data?.signInOut.sign_out_lat ? "col" : "col-12"}>
									<div className="my-3 font-weight-bold">{t("sign out location")}</div>
									{data?.signInOut?.sign_out_lat ? (
										<div style={{ height: "250px" }}>
											<div className="w-100 h-100 position-relative">
												<iframe
													width="100%"
													height="auto"
													style={{ border: 0, width: "100%", height: "100%" }}
													loading="eager"
													allowfullscreen
													referrerpolicy="no-referrer-when-downgrade"
													src={`https://www.google.com/maps/embed/v1/place?key=${Config.googleMapKey}&q=${data?.signInOut?.sign_out_lat},${data?.signInOut?.sign_out_lng}`}
												></iframe>
												{data?.signInOut?.sign_out_face ? (
													<Avatar
														src={data?.signInOut?.sign_out_face?.path}
														className="avatar blackish-avatar position-absolute"
														style={{
															width: 100,
															height: 100,
															right: "/* @noflip */ 0",
															top: 0,
														}}
													/>
												) : null}
											</div>
										</div>
									) : (
										<div>
											<p>{data?.signInOut?.sign_out_location?.name || t("unspecified location")}</p>
											{data?.signInOut?.sign_out_face ? (
												<Avatar
													src={data?.signInOut?.sign_out_face?.path}
													className="avatar blackish-avatar position-absolute"
													style={{
														width: 100,
														height: 100,
														right: "/* @noflip */ 0",
														top: 0,
													}}
												/>
											) : null}
										</div>
									)}
								</div>
							)}
						</div>
					</>
				);
				break;
			case "Leave":
				return (
					<>
						{true ? (
							<div className="align-items-end d-flex flex-row justify-content-between mb-2">
								<>
									<div className={`flex-1`}>
										<BSelect
											{...formProps}
											validationName={"input.work_timing_id"}
											containerStyle="mt-3"
											name="workTimingId"
											label="applicable work timing"
											keepDefaultStyle
											placeholder={t("select option")}
											options={
												getMainWorkTimingsOptionsFromWorkTimingMenu(
													normalWorkTimingList,
													data?.signInOut?.work_timing
												) || []
											}
											isDisabled={workTimmingLoading || loading}
											optionLabel="name"
											isLoading={workTimmingLoading || loading}
											optionValue="id"
											labelStyle="mb-2"
											inputContainerStyle="b-select-style attendance_work_timing_select"
											skipLocalization
											isClearable
											getOptionLabel={(opt) => (
												<span dir="auto">
													{opt.name}{" "}
													{opt?.archived
														? `(${moment(opt.created_at).format("DD-MM-YYYY")} ~ ${moment(opt.updated_at).format(
																"DD-MM-YYYY"
															)})`
														: t("current version")}{" "}
												</span>
											)}
											icon="calendar"
											filterOption={filterOptions}
										/>
									</div>
									{editAttendance?.workTimingId ? (
										<HasPrivileges allowBP reqireMain={[Privilages.PREVIEW_WORK_TIMINGS]}>
											<IconButtonWithTooltip
												label={t("View Work Timing")}
												onClick={() => handleViewWorkTime(editAttendance?.workTimingId)}
												icon={<FontAwesomeIcon size="md" color="#5c6974" icon={faEye} className="ml-2" />}
											/>
										</HasPrivileges>
									) : null}
								</>
							</div>
						) : null}

						<>
							<BSelect
								{...formProps}
								isLoading={loading}
								isDisabled={loading}
								validationName="input.daySubType"
								name="leaveType"
								label={t("leave type")}
								labelStyle="mb-2"
								keepDefaultStyle
								placeholder={t("select option")}
								options={selectTypesOptions}
								validateBy="textRequired"
								icon="type"
							/>
						</>

						{CUSTOM_LEAVES?.includes(editAttendance?.leaveType) ? (
							<>
								<RadioboxForm
									{...formProps}
									validationName="input.customLeavePaymentType"
									name="customLeavePaymentType"
									options={CustomeLeavesPaymentFlag}
									optionsContainerStyle="row edit_attendance_form_container"
									containerStyle="mt-2 d-flex align-items-end justify-content-start"
									// optionItemStyle="col-md-4 col-xl-2 text-nowrap mt-1 day_type_item"
									optionInputStyle={`d-flex edit_attendance_checkbox`}
									optionLabelStyle
									label="payment type"
									labelStyle="mr-2"
									validateBy="textRequired"
								/>
							</>
						) : null}

						{editAttendance?.leaveType == "Normal" && reqFlags?.isEmgNormal ? (
							<CheckboxBooleanForm
								{...formProps}
								validationName="input.daySubType"
								options={["Discount From Emergency Credit"]}
								name="daySubType"
								type="checkbox"
								containerStyle="mt-3"
								optionLabelStyle="content-header-sub-label"
								labelStyle="mb-2"
								setWithValue
								checkedValue="Emergency"
								unCheckedValue="Normal"
							/>
						) : null}
					</>
				);
				break;

			default:
				return <></>;
				break;
		}
	};

	const dispatch = useDispatch();
	const { t } = useTranslation();

	const {
		isLoading,
		modalMessage,
		isVissible: isModalVissible,
	} = useSelector((state) => state.super.editAttHistoryModalActions);

	/* ↓ Helpers ↓ */

	const Option = ({ children, ...props }) => {
		let { sign_in_start_time, sign_in_end_time, work_hours, sign_in_req, created_at, updated_at, name, id } =
			props.data;

		return (
			<components.Option {...props}>
				<div key={id}>
					<div>{children}</div>
					<span className={`work-timing-time-range-style ${props.isSelected ? "text-white" : ""}`}>
						{!sign_in_req
							? t("Don't Need to Sign In At The Start Of Each Work Day")
							: `Sign in range from ${formatWorkTiming(sign_in_start_time, "time_range")} to ${formatWorkTiming(
									sign_in_end_time,
									"time_range"
								)} - work hours ${formatWorkTiming(work_hours, "hours")}`}
					</span>
				</div>
			</components.Option>
		);
	};

	const handleViewWorkTime = (id) => {
		if (id) {
			dispatch(viewWorkTimeAction(id));
		}
	};

	const filterOptions = ({ data, label }, string) => {
		if (
			`${data?.name} ${
				data?.archived
					? `(${moment(data.created_at).format("DD-MM-YYYY")} ~ ${moment(data.updated_at).format("DD-MM-YYYY")})`
					: t("current version")
			}}`
				?.toLowerCase()
				?.includes(string)
		)
			return true;
		return false;
	};

	const onStatusChange = ({ target }) => {
		const { name, value } = target;
		if (value == "attended" && !editAttendance?.dayType) {
			dispatch({
				type: "EDIT_ATTENDANCE_RECORD",
				payload: {
					...editAttendance,
					[name]: value,
					dayType: "normal",
				},
			});
			return false;
		}
	};

	return (
		<MainModal
			isOpen={isModalVissible}
			toggle={dismiss}
			modalTitle={t("Edit Attendance")}
			btnOnClick={handleSaveRequest}
			btnSubmitLoading={submitLoading}
			disableSubmitButton={Boolean(submitLoading || loading)}
			className="att-history-modal"
			size="lg"
		>
			{loading ? (
				<div className="loader_wrapper_style">
					<Loader />
				</div>
			) : null}

			<div className="mb-2 mx-4">
				<div>
					<span className="sec-color text-16 font-weight-bold">{editAttendance?.employee?.name}</span>
					<span className="text-12 ml-2">{moment(editAttendance?.date).format("dddd DD-MM-YYYY")}</span>
				</div>

				{error ? (
					<span style={{ color: "red", textAlign: "center" }}>
						{error?.message ?? t("network error ,try to reload")}
					</span>
				) : null}

				{data?.signInOut ? (
					<>
						{/* Status Section */}
						<>
							<RadioboxForm
								{...formProps}
								validationName={"input.status"}
								label={t("status")}
								labelStyle="mb-2"
								name="status"
								options={statusOptions}
								optionsContainerStyle="row edit_attendance_form_container"
								containerStyle="my-2"
								optionItemStyle="col-md col-xl-2 text-nowrap edit_attendance_item"
								optionLabelStyle
								optionInputStyle="edit_attendance_checkbox"
								interceptChange={onStatusChange}
							/>
						</>

						{renderEditSignInDetails()}
						{editAttendance?.break_setting?.allow_break && editAttendance?.status?.toLowerCase() === "attended" ? (
							<BreakSection breaks={editAttendance?.breakInOuts} />
						) : null}

						{/* (Start) Error Message */}
						{modalMessage ||
						errorState?.validation?.["input.breaks"] ||
						errorState?.validation?.["input.date"] ||
						errorState?.errorMessage ? (
							<div className="warnig-msg-style">
								{modalMessage ||
									errorState?.validation?.["input.breaks"] ||
									errorState?.validation?.["input.date"] ||
									errorState?.errorMessage}
							</div>
						) : null}
						{/* (End) Error Message */}
					</>
				) : null}
			</div>
		</MainModal>
	);
};

export default EditAttHistoryModal;
