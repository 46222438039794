import React from "react";
import { CheckboxBooleanForm } from "form-builder";
import { Button } from "@mui/material";
import * as REQUESTS from "../../Constants/Requests";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import { useSelector, useDispatch } from "react-redux";
import swal from "sweetalert";
import { Spinner } from "reactstrap";
import {
  hideNotificationDrawer,
  showViewAttRequestModal,
  acceptInboundRequestAction,
  rejectInboundRequestAction,
  showErrorToast,
  showSuccessToast,
} from "../../Store/Actions";
import { acceptDayOffMutation } from "../../Graphql/mutation";
import { useMutation } from "@apollo/client";

function NotificationHistoryEmployeeCard({
  notification,
  handleRefresh,
  showChangeShiftModal,
}) {
  const requestId = notification?.notifiable?.id;
  const { requstsLoader } = useSelector((state) => state.super);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [acceptDayOff, { loading: dayOffLoading }] = useMutation(acceptDayOffMutation, {
    onCompleted: (res) => {
      if (
        res?.accept_day_off_request?.__typename === "GeneralException" ||
        res?.accept_day_off_request?.__typename === "PaidPayrollException"
      ) {
        dispatch(showErrorToast(res?.accept_day_off_request?.message ?? ""));
        return;
      }
      if (res?.accept_day_off_request?.id) {
        // refetch requests
        dispatch(showSuccessToast("Done"));
        handleRefresh();
      } else {
        dispatch(showErrorToast());
      }
    },
    onError: (error) => {
      if (error?.message === "swal") {
        swal({
          title: t("are you sure"),
          text: t("You have other requests on this day"),
          icon: "warning",
          className: "swal-warning-style",
          dangerMode: true,
          buttons: [t("Cancel"), t("confirm")],
        }).then((confirm) => {
          if (confirm) {
            //  send mutation
            acceptDayOff({
              variables: {
                input: {
                  id: requestId,
                  swalValue: true,
                },
              },
            });
            return;
          }
        });
        return;
      }
      dispatch(showErrorToast(error?.graphQLErrors?.[0]?.extensions?.reason));
    },
  });

  const checkForActions = () => {
    return (
      notification?.notifiable?.__typename === "Request" &&
      notification?.notifiable?.status.toLowerCase() === "pending"
    );
  };

  const requestDetails = () => {
    if (notification?.notifiable?.__typename?.toLowerCase() === "sign_in_out") {
      let {
        sign_in_time,
        sign_out_time,
        signed_in_outside_specified_locations,
        signed_out_outside_specified_locations,
      } = notification?.notifiable;
      if (
        notification?.message_title?.toLowerCase() === "sign in" ||
        notification?.message_title?.toLowerCase() === "sign out"
      ) {
        return (
          <>
            {" "}
            <i className="font-weight-bold">{t("at")}</i>{" "}
            {moment(
              notification?.message_title?.toLowerCase() === "sign in"
                ? sign_in_time
                : sign_out_time
            ).format("DD/MM/YYYY hh:mm a")}{" "}
            {notification?.message_title?.toLowerCase() === "sign in" &&
            signed_in_outside_specified_locations
              ? t("outside specified locations")
              : null}
            {notification?.message_title?.toLowerCase() === "sign out" &&
            signed_out_outside_specified_locations
              ? t("outside specified locations")
              : null}
          </>
        );
      }
    }
    if (notification?.notifiable?.__typename?.toLowerCase() === "checkin") {
      let { check_in_time, check_out_time } = notification?.notifiable;
      return (
        <>
          {" "}
          <i className="font-weight-bold">{t("at")}</i>{" "}
          {moment(
            notification?.message_title?.toLowerCase() === "check in"
              ? check_in_time
              : check_out_time
          ).format("DD/MM/YYYY hh:mm a")}{" "}
        </>
      );
    }
    if (notification?.notifiable?.__typename === "Request") {
      switch (notification?.notifiable?.requestType?.id) {
        case REQUESTS.NORMAL_LEAVES:
        case REQUESTS.EMERGENCY_LEAVES:
        case REQUESTS.UNPAID_LEAVES:
        case REQUESTS.SICK_LEAVES:
        case REQUESTS.BEREAVEMENT:
        case REQUESTS.MATERNITY_PATERNITY_LEAVE:
        case REQUESTS.HAJJ_LEAVE:
        case REQUESTS.STUDY_LEAVE:
        case REQUESTS.SABBATICAL_LEAVE:
        case REQUESTS.MARRIAGE_LEAVE:
        case REQUESTS.In_Lieu_Of_Work_Day_Leave:
        case REQUESTS.MILITARY_CALL_LEAVE:
          return (
            <>
              {" "}
              <i className="font-weight-bold">{t("from")}</i>{" "}
              {moment(notification?.notifiable?.time_from).format("DD/MM/yyyy")}{" "}
              <i className="font-weight-bold">{t("to")}</i>{" "}
              {moment(notification?.notifiable?.time_to).format("DD/MM/yyyy")}
            </>
          );

        case REQUESTS.OVERTIME:
          return (
            <>
              {moment(notification?.notifiable?.req_day).format("DD/MM/YYYY")}{" "}
              {notification?.notifiable?.status?.toLowerCase() === "accepted"
                ? moment(
                    notification?.notifiable?.payable_interval_in_time,
                    "hh:mm:ss"
                  ).format("hh:mm")
                : moment(
                    notification?.notifiable?.interval_in_time,
                    "hh:mm:ss"
                  ).format("hh:mm")}
            </>
          );

        case REQUESTS.HOME:
          return (
            <>
              <i className="font-weight-bold">{t("at")}</i>{" "}
              {moment(notification?.notifiable?.req_day).format("DD/MM/YYYY")}
            </>
          );

        case REQUESTS.NORMAL_HALF_DAY:
        case REQUESTS.EMERGENCY_HALF_DAY:
          return (
            <>
              {moment(notification?.notifiable?.req_day).format("DD/MM/yyyy")}
              {t(
                `${notification?.notifiable?.add_info?.toLocaleLowerCase()}_half`
              )}{" "}
            </>
          );

        case REQUESTS.EDIT_ATTENDANCE:
          return (
            <>
              <i className="font-weight-bold">{t("for")}</i>{" "}
              {moment(notification?.notifiable?.req_day).format("DD/MM/yyyy")}
            </>
          );

        default:
          return (
            <>
              {" "}
              <i className="font-weight-bold">{t("from")}</i>{" "}
              {moment(notification?.notifiable?.time_from).format(
                "DD-MM-YYYY hh:mm a"
              )}{" "}
              <i className="font-weight-bold">{t("to")}</i>{" "}
              {moment(notification?.notifiable?.time_to).format(
                "DD-MM-YYYY hh:mm a"
              )}
            </>
          );
      }
    }
  };

  const handleAcceptRequest = () => {
    if (notification?.notifiable?.requestType?.id === REQUESTS.CHANGE_SHIFT || notification?.notifiable?.requestType?.id === REQUESTS.ADDITIONAL_SHIFT) {
      showChangeShiftModal(notification?.notifiable);
      dispatch(hideNotificationDrawer());
      return;
    }
    if (notification?.notifiable?.requestType?.id === REQUESTS.DAY_OFF) {
      acceptDayOff({
        variables: {
          input: {
            id: requestId,
            swalValue: false,
          },
        },
      });
      return;
    }

    // check if it's an edit request to open edit request modal
    if (
      notification?.notifiable?.requestType?.id === REQUESTS.EDIT_ATTENDANCE
    ) {
      // second argument is true so we open the edit request modal that is related to notifications only
      dispatch(showViewAttRequestModal(notification?.notifiable, true));
      dispatch(hideNotificationDrawer());
      return;
    }

    swal({
      title: t("default_warning_accept_message"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: [t("Cancel"), t("OK")],
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(acceptInboundRequestAction(requestId, null, "notifications"));
      }
    });
  };

  const handleDeclineRequest = () => {
    swal({
      title: t("default_warning_reject_message"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: true,
      buttons: [t("Cancel"), t("OK")],
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(rejectInboundRequestAction(requestId, null, "notifications"));
      }
    });
  };

  return (
    <div
      style={{
        backgroundColor: notification?.seen ? "#f8fafc" : "ffffff",
      }}
    >
      <div className="notification-history-card-wrapper">
        <div>
          {/* message title and body seciton start */}
          <div className="notification-history-card-header">
            <div className="notification-history-card-title">
              {notification?.message_title}{" "}
              <span className="notification-message-body">
                {notification?.message_body}{" "}
                <span className="text-lowercase">{requestDetails()}</span>
              </span>
            </div>
          </div>
          {/* message title and body seciton end */}

          <div className="notification-history-card-details d-flex justify-content-between">
            <div className="w-100 d-flex align-items-center">
              <span className="notification-date">
                {moment
                  .utc(notification?.utc_date_time)
                  .tz(moment.tz.guess())
                  .fromNow()}
              </span>
              {notification?.notifiable?.__typename === "Request" &&
              notification?.notifiable?.status?.toLowerCase() !== "pending" ? (
                <span>{notification?.notifiable?.status}</span>
              ) : null}
            </div>
            {checkForActions() ? (
              <HasPrivileges
                reqireMain={[Privilages.MANAGE_EMPLOYEE_REQUESTS]}
                allowBP
                avalibleOnExpire={false}
                altExpireChildren={
                  <div className="notification-history-button-wrapper col-4">
                    <Button
                      variant="outlined"
                      size="small"
                      className="notification-history-card-accept-btn"
                    >
                      {t("accept")}
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      className="notification-history-card-decline-btn"
                    >
                      {t("decline")}
                    </Button>
                  </div>
                }
              >
                <div className="notification-history-button-wrapper col-4">
                  <Button
                    variant="outlined"
                    size="small"
                    className="notification-history-card-accept-btn"
                    onClick={handleAcceptRequest}
                    disabled={dayOffLoading || requstsLoader?.includes(requestId)}
                  >
                    {dayOffLoading || requstsLoader?.includes(requestId) ? (
                      <Spinner
                        style={{
                          width: "1rem",
                          height: "1rem",
                          color: "#27b40c",
                        }}
                      />
                    ) : (
                      t("accept")
                    )}
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    className="notification-history-card-decline-btn"
                    onClick={handleDeclineRequest}
                    disabled={dayOffLoading || requstsLoader?.includes(requestId)}
                  >
                    {dayOffLoading || requstsLoader?.includes(requestId) ? (
                      <Spinner
                        style={{
                          width: "1rem",
                          height: "1rem",
                          color: "#ff6a6a",
                        }}
                      />
                    ) : (
                      t("decline")
                    )}
                  </Button>
                </div>
              </HasPrivileges>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotificationHistoryEmployeeCard;
