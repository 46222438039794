/* eslint-disable eqeqeq */
import React, { useState, useEffect, useRef } from "react";
import { BSelect, DateTimePickerForm, InputForm } from "form-builder";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import Divider from "@mui/material/Divider";
import {
  fetchScheduleResourcesQuery,
  fetchScheduleWorkGroupsQuery,
  fetchWorkScheduleQuery,
  fetchScheduleShiftsQuery,
  fetchShiftQuery,
  fetchShiftEmployeesQuery,
} from "../../Graphql/query";
import {
  upsertShiftByLocationMutation,
  upsertShiftByEmployeeMutation,
  updateShiftMutation,
  cloneShiftMutation,
  deleteShiftMutation,
  upsertScheduleMutation,
  publishScheduleMutation,
} from "../../Graphql/mutation";
import { setWorkScheduleServerValidationAction } from "../../Store/Actions/shiftsActions/actions.js";

// Icons
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import Groups3OutlinedIcon from "@mui/icons-material/Group";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOn";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import {
  Eventcalendar,
  snackbar,
  setOptions,
  formatDate,
  toast,
  Select,
  Popup,
  localeAr,
  localeEn,
} from "@mobiscroll/react";
import moment from "moment";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import _ from "lodash";
import Switch from "@mui/material/Switch";
import { useHistory } from "react-router-dom";

import MainModal from "../../Components/MainModal";
import Loader from "../../Components/Loader";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import { handleActionMutation } from "../../Helpers/HelperFns";
import { useTranslation } from "react-i18next";
import Validations from "../../Helpers/Validations";
import { Spinner } from "reactstrap";
import {
  setShouldExitCalendarPage,
  showSuccessToast,
} from "../../Store/Actions";
import { Link } from "react-router-dom";

const useCustomFormValidation = ({
  inputValue,
  validateBy = "textRequired",
}) => {
  const [isValid, setIsValid] = useState(false);
  const [validationMessage, SetValidationMessage] = useState("");

  useEffect(() => {
    Validations.validationSchema
      .validateAt(validateBy, { [validateBy]: inputValue })
      .then((valid) => {
        setIsValid(true);
        SetValidationMessage("");
      })
      .catch((err) => {
        setIsValid(false);
        SetValidationMessage(err?.message);
      });
  }, [inputValue]);

  return { isValid, validationMessage };
};



setOptions({
  theme: "ios",
  themeVariant: "light",
});
const contextMenu = [
  {
    text: "Copy",
    value: "copy",
  },
  {
    text: "Delete",
    value: "delete",
  },
];

const pasteContextMenu = [
  {
    text: "Paste",
    value: "paste",
  },
];

//   form props
const formName = "workScheduleForm";
const formNameValidation = "workScheduleFormValidation";
const formServerValidation = "workScheduleFormServerValidation";
const reducerName = "shifts";

const CreateWorkSchedulePage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const cycleLength = [...Array(100)].map((_, i) => {
    return {
      id: i + 1,
      name: i + 1,
    };
  });
  
  const authId = useSelector((state) =>state?.auth?.userProfile?.id);

  const [formSubmitting, setFormSubmitting] = useState(false);

  const formProps = {
    formName,
    formNameValidation,
    formServerValidation,
    formSubmitting: formSubmitting,
    reducer: reducerName,
  };

  // Apollo Hooks
  // Queries
  const [fetchResources] = useLazyQuery(fetchScheduleResourcesQuery, {
    onCompleted: (data) => {
      setResources(data?.resources);
      if (
        filteredResourcesValues != null &&
        filteredResourcesValues.length != 0
      ) {
        if (Array.isArray(filteredResourcesValues)) {
          setFilteredResources(filteredResourcesValues);
        } else {
          setFilteredResources([]);
        }
      } else {
        if (Array.isArray(data?.resources)) {
          setFilteredResources(data?.resources);
        } else {
          setFilteredResources([]);
        }
      }

      // setFilteredResourcesValues([]);
    },
    onError: (err) => {
      console.log("fetchResources", { err });
      return toast({
        message: "There was an error",
        color: "danger",
      });
    },
  });

  const [fetchWorkGroups, { loading: fetchWorkGroupsLoading }] = useLazyQuery(
    fetchScheduleWorkGroupsQuery,
    {
      onCompleted: (data) => {
        setWorkGroups(data?.work_groups_menu);
        loadSchedule();
      },
      onError: (err) => {
        console.log("fetchWorkGroups", { err });
        return toast({
          message: "There was an error",
          color: "danger",
        });
      },
    }
  );

  const [fetchScheduleShifts] = useLazyQuery(fetchScheduleShiftsQuery, {
    onCompleted: (data) => {
      setShifts(data?.schedule_shifts);
    },
    onError: (err) => {
      console.log("fetchScheduleShifts", { err });
      return toast({
        message: err?.graphQLErrors?.[0]?.extensions?.reason ?? err?.message,
        color: "danger",
      });
    },
  });

  const [fetchWorkSchedule, { loading: fetchWorkScheduleLoading }] =
    useLazyQuery(fetchWorkScheduleQuery, {
      variables: {
        authId
      },
      onCompleted: (data) => {
        setSchedule((state) => ({
          ...state,
          ...data?.schedule,
          schedule_days: data?.schedule?.schedule_days.toString(),
          break_into: data?.schedule?.break_into.toString(),
          work_group_id: data?.schedule?.workGroup?.id,
          workTeam: data?.schedule?.employees?.concat(data?.schedule?.teams),
        }));
        setWorkGroup(data?.schedule?.workGroup);

        loadResources(data?.schedule);
        setShifts(data?.schedule_shifts);
        initInterval(data?.schedule);
        initInvalidDates(data?.schedule);
        setIsCalendarLoading(false);
        setIsLoading(false);
        setShowCalendar(true);

        if (refDate == null) {
          setRefDate(data?.schedule?.draft_date);
        }
      },
      onError: (error) => {
        toast({
          message:
            error?.graphQLErrors?.[0]?.extensions?.reason ?? error.message,
          color: "danger",
        });
      },
    });

  const [fetchShift] = useLazyQuery(fetchShiftQuery, {
    onCompleted: (data) => {
      setTempShift((state) => ({
        ...state,
        workTeam: data?.shift?.scheduleDetails[0].shift_work_teams,
        work_timing: data?.shift?.scheduleDetails[0].WorkTiming,
        work_timing_id: data?.shift?.scheduleDetails[0].WorkTiming?.id,
        location: data?.shift?.scheduleDetails[0].location,
        day_off: data?.shift?.scheduleDetails[0].day_off,
        workplace_setting: workPlaceSettingOptions.filter((setting) => {
          return (
            setting.id === data?.shift?.scheduleDetails[0].workplace_setting
          );
        })[0],
        workplace_setting_signout: workPlaceSettingOptions.filter((setting) => {
          return (
            setting.id ===
            data?.shift?.scheduleDetails[0].workplace_setting_signout
          );
        })[0],
        workplace_setting_type:
          data?.shift?.scheduleDetails[0].workplace_setting,
        workplace_setting_identical:
          data?.shift?.scheduleDetails[0].workplace_setting_identical,
        container_id: data?.shift?.id,
        additional_work_places: data?.shift?.scheduleDetails[0].workPlaces,
        additional_work_places_signout:
          data?.shift?.scheduleDetails[0].workPlacesOut,
      }));
      setTempShifts([]);
      let workTimings = [];
      
      data?.shift?.scheduleDetails.map((shift) => {
        let temp = {
          id: shift.id,
          schedule_detail_id: shift.id,
          work_timing: shift.WorkTiming,
          work_timing_id: shift?.WorkTiming?.id,
          work_timing_first_half: shift?.FirstHalfWorkTiming,
          first_half_work_timing_id: shift?.FirstHalfWorkTiming?.id,
          work_timing_second_half: shift?.SecondHalfWorkTiming,
          second_half_work_timing_id: shift?.SecondHalfWorkTiming?.id,
          allow_half_day: shift?.allow_half_day,
          weight: {
            name: String(shift.weight) + " Day",
            value: String(shift.weight),
          },
        };
        workTimings[shift.id] = shift?.WorkTiming?.id;
        setTempShifts((state) => [...state, temp]);
      });

      const moreTempShifts =
        data?.shift?.scheduleDetails?.[0]?.alternativeTiming?.map(
          (alterWorkTiming) => {
            return {
              id: data?.ScheduleDetail?.[0]?.id,
              schedule_detail_id: data?.ScheduleDetail?.[0]?.id,
              work_timing: alterWorkTiming?.work_timing,
              work_timing_id: alterWorkTiming?.work_timing_id,
              work_timing_first_half: alterWorkTiming?.work_timing_first_half,
              first_half_work_timing_id:
                alterWorkTiming?.first_half_work_timing_id,
              work_timing_second_half: alterWorkTiming?.work_timing_second_half,
              second_half_work_timing_id:
                alterWorkTiming?.second_half_work_timing_id,
              allow_half_day:
                !!alterWorkTiming?.first_half_work_timing_id ||
                !!alterWorkTiming?.second_half_work_timing_id,
              weight: {
                name: String(alterWorkTiming?.weight) + " Day",
                value: String(alterWorkTiming?.weight),
              },
            };
          }
        );
      setTempShifts((prev) => [...prev, ...moreTempShifts]);

      setSelectedWorkTimings(workTimings);
    },
    onError: (err) => {
      console.log("fetchShift", { err });
      return toast({
        message: "There was an error",
        color: "danger",
      });
    },
  });

  const [fetchShiftEmployees] = useLazyQuery(fetchShiftEmployeesQuery, {
    onCompleted: (data) => {
      setTempShift((state) => ({
        ...state,
        workTeam: [data?.ScheduleDetail],
        work_timing: data?.ScheduleDetail[0].WorkTiming,
        location: data?.ScheduleDetail[0].location,
        day_off: data?.ScheduleDetail[0].day_off,
        additional_work_places: data?.ScheduleDetail[0].workPlacesIn,
        additional_work_places_signout: data?.ScheduleDetail[0].workPlacesOut,
        workplace_setting_type: data?.ScheduleDetail[0].workplace_setting,
        workplace_setting_identical:
          data?.ScheduleDetail[0].workplace_setting_identical,
        workplace_setting_signout: workPlaceSettingOptions.filter((setting) => {
          return (
            setting.id === data?.ScheduleDetail[0].workplace_setting_signout
          );
        })[0],
        workplace_setting: workPlaceSettingOptions.filter((setting) => {
          return setting.id === data?.ScheduleDetail[0].workplace_setting;
        })[0],
      }));
      setTempShifts([]);
      let workTimings = [];

      data?.ScheduleDetail.map((shift) => {
        let temp = {
          id: shift.id,
          schedule_detail_id: shift.id,
          work_timing: shift.WorkTiming,
          work_timing_id: shift?.WorkTiming?.id,
          work_timing_first_half: shift?.FirstHalfWorkTiming,
          first_half_work_timing_id: shift?.FirstHalfWorkTiming?.id,
          work_timing_second_half: shift?.SecondHalfWorkTiming,
          second_half_work_timing_id: shift?.SecondHalfWorkTiming?.id,
          allow_half_day: shift?.allow_half_day,
          weight: {
            name: String(shift.weight) + " Day",
            value: String(shift.weight),
          },
        };
        workTimings[shift.id] = shift?.WorkTiming?.id;
        setTempShifts((state) => [...state, temp]);
      });
      const moreTempShifts = data?.ScheduleDetail?.[0]?.alternativeTiming?.map(
        (alterWorkTiming) => {
          return {
            id: data?.ScheduleDetail?.[0]?.id,
            schedule_detail_id: data?.ScheduleDetail?.[0]?.id,
            work_timing: alterWorkTiming?.work_timing,
            work_timing_id: alterWorkTiming?.work_timing_id,
            work_timing_first_half: alterWorkTiming?.work_timing_first_half,
            first_half_work_timing_id:
              alterWorkTiming?.first_half_work_timing_id,
            work_timing_second_half: alterWorkTiming?.work_timing_second_half,
            second_half_work_timing_id:
              alterWorkTiming?.second_half_work_timing_id,
            allow_half_day:
              !!alterWorkTiming?.first_half_work_timing_id ||
              !!alterWorkTiming?.second_half_work_timing_id,
            weight: {
              name: String(alterWorkTiming?.weight) + " Day",
              value: String(alterWorkTiming?.weight),
            },
          };
        }
      );
      setTempShifts((prev) => [...prev, ...moreTempShifts]);
      setSelectedWorkTimings(workTimings);
    },
    onError: (err) => {
      console.log("fetchShiftEmployees", { err });
      return toast({
        message: "There was an error",
        color: "danger",
      });
    },
  });

  // Mutations
  const [upsertShiftByLocation] = useMutation(upsertShiftByLocationMutation);

  const [upsertShiftByEmployee] = useMutation(upsertShiftByEmployeeMutation);

  const [updateShift] = useMutation(updateShiftMutation);

  const [cloneShift] = useMutation(cloneShiftMutation);

  const [deleteShift] = useMutation(deleteShiftMutation);

  const [upsertSchedule] = useMutation(upsertScheduleMutation);

  const [publishSchedule] = useMutation(publishScheduleMutation);


  const [shifts, setShifts] = React.useState(null);
  const [viewSettings, setViewSettings] = React.useState({
    timeline: {
      type: "day",
      size: 7,
      resolution: "day",
      eventList: true,
    },
  });
  const workPlaceSettingOptions = [
    {
      id: "DEFAULT",
      name: "As set",
    },
    {
      id: "ANY_PLACE",
      name: "Anywhere",
    },
    {
      id: "ALL_COMPANY_WORKPLACES",
      name: "Any Wokrplace in the Company",
    },
    {
      id: "ALL_WORKGROUP_WORKPLACES",
      name: "Any workplace belonging to this workgroup",
    },
  ];
  const tempShiftsInitialState = [
    {
      id: 1,
      schedule_detail_id: null,
      work_timing: {},
      work_timing_id: null,
      work_timing_first_half: {},
      first_half_work_timing_id: null,
      work_timing_second_half: {},
      second_half_work_timing_id: null,
      workplace_setting: workPlaceSettingOptions[0],
      workplace_setting_type: workPlaceSettingOptions[0].id,
      workplace_setting_signout: workPlaceSettingOptions[0],
      workplace_setting_type_signout: workPlaceSettingOptions[0].id,
      workplace_setting_identical: 1,
      allow_half_day: 0,
      weight: { name: "1 Day", value: "1" },
    },
  ];
  const weightsInitialState = [
    { name: "1 day", value: "1" },
    { name: "0.5 Day", value: "0.5" },
    { name: "0.25 day", value: "0.25" },
    { name: "1.5 day", value: "1.5" },
    { name: "2 day", value: "2" },
  ];
  const [selectedEventsToCopy, setSelectedEventsToCopy] = useState([]);
  const [insufficientScheduledEmployees, setInsufficientScheduledEmployees] =
    useState(false);

  const [isCalendarLoading, setIsCalendarLoading] = useState(false);
  const [oldValue, setOldValue] = useState(null);
  const [oldField, setOldField] = useState(null);
  const [scheduleCreated, setScheduleCreated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmDeleteEmployees, setConfirmDeleteEmployees] = useState(false);
  const [confirmScheduleChange, setConfirmScheduleChange] = useState(false);

  const [schedule, setSchedule] = useState({
    repeat: 1,
    schedule_days: 7,
    break_into: 7,
    draft_employees: 1,
  });
  const [resources, setResources] = React.useState([]);
  const [formHidden, setFormHidden] = React.useState(0);
  const [filteredResources, setFilteredResources] = React.useState([]);
  const [filteredResourcesValues, setFilteredResourcesValues] = React.useState(
    []
  );
  const [refDate, setRefDate] = React.useState(null);

  const [mySelectedEvents, setSelectedEvents] = React.useState([]);
  const [selectedWorkTimings, setSelectedWorkTimings] = React.useState([]);
  const [selectedValue, setSelected] = React.useState(false);
  const [invalidDates, setInvalidDates] = React.useState([]);
  const [tempShift, setTempShift] = React.useState({
    allow_half_day: 0,
    workplace_setting: workPlaceSettingOptions[0],
    workplace_setting_type: "DEFAULT",
    workplace_setting_signout: workPlaceSettingOptions[0],
    workplace_setting_type_signout: "DEFAULT",
    workplace_setting_identical: 1,
  });
  const [tempShifts, setTempShifts] = React.useState(tempShiftsInitialState);

  const [workGroups, setWorkGroups] = React.useState([]);
  const [workGroup, setWorkGroup] = React.useState({});
  const [isBlockedDate, setIsBlockedDate] = React.useState(false);
  const [removedEmployees, setRemovedEmployees] = React.useState([]);
  const [removedTeams, setRemovedTeams] = React.useState([]);
  const [pasteDate, setPasteDate] = React.useState(null);
  const [pasteResource, setPasteResource] = React.useState(null);
  const [isOpen, setOpen] = React.useState(false);
  const [isEdit, setEdit] = React.useState(false);
  const [headerText, setHeader] = React.useState("");
  const [shiftDate, setDate] = React.useState([]);
  const [showCalendar, setShowCalendar] = React.useState(false);
  const [menuAnchor, setMenuAnchor] = React.useState();
  const [pasteAnchor, setPasteAnchor] = React.useState();
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [pasteMenuOpen, setPasteMenuOpen] = React.useState(false);
  const [activeValue, setActiveValue] = React.useState(1);
  const [intervalArray, setIntervalArray] = React.useState([]);
  const [dropDownWorkTimings, setDropDownWorkTimings] = React.useState([]);
  const [upsertShiftLoading, setUpsertShiftLoading] = useState(false);
  const [publishScheduleLoading, setPublishScheduleLoading] = useState(false);
  const [confirmPublishSchedule, setConfirmPublishSchedule] = useState(false);
  const [teamAnchor, setTeamAnchor] = useState();
  const [isTeamOpen, setIsTeamOpen] = useState(false);
  const [tooltipEvent, setTooltipEvent] = useState({});
  const [isEventOpen, setEventOpen] = useState(false);
  const [eventAnchor, setEventAnchor] = useState(null);
  const [popupTeam, setPopupTeam] = useState({});
  const timerRef = React.useRef(null);

  const handleWorkplaceIdenticalChange = () => {
    setTempShift((state) => ({
      ...state,
      workplace_setting_identical:
        tempShift.workplace_setting_identical == 1 ? 0 : 1,
    }));
  };

  const handleHideFormChange = () => {
    setFormHidden(formHidden == 1 ? 0 : 1);
  };
  const handleSwitchChange = () => {
    setSelectedEvents([]);
    setSelectedEventsToCopy([]);
    setIsCalendarLoading(true);
    if (schedule.draft_employees == 1) {
      setSchedule((state) => ({
        ...state,
        draft_employees: 0,
      }));
      schedule.draft_employees = 0;
    } else {
      setSchedule((state) => ({
        ...state,
        draft_employees: 1,
      }));
      schedule.draft_employees = 1;
    }
    setTimeout(() => {
      loadSchedule();
      loadResources(schedule);
    }, 1000);

    const newRefDate = schedule.draft_date;
    setRefDate(newRefDate);

    updateScheduleFields({ reset: 0, skipLoading: true });
  };
  const deleteAllShifts = () => {
    setIsLoading(true);
    updateScheduleFields({ reset: 1, skipLoading: true, mirror: false });
  };

  const handleAllowHalfDayChange = (args, shift_id, isAlternative, index) => {
    if (isAlternative) {
      setTempShifts((shifts) =>
        shifts?.map((shift, i) => {
          if (shift?.id == shift_id && i == index) {
            return {
              ...shift,
              allow_half_day: tempShifts?.[index]?.allow_half_day == 1 ? 0 : 1,
              ...(tempShifts?.[index]?.allow_half_day == 1
                ? {
                    first_half_work_timing_id: null,
                    second_half_work_timing_id: null,
                    work_timing_first_half: {},
                    work_timing_second_half: {},
                  }
                : {}),
            };
          } else {
            return shift;
          }
        })
      );
      return;
    }
    setTempShift((state) => ({
      ...state,
      allow_half_day: tempShift.allow_half_day == 1 ? 0 : 1,
    }));

    const newState = tempShifts.map((shift, i) => {
      if (shift.id === shift_id && i == index) {
        return {
          ...shift,
          allow_half_day: shift.allow_half_day == 1 ? 0 : 1,
        };
      }

      return shift;
    });
    setTempShifts(newState);
  };

  const handlePopupDayOffSwitchChange = () => {
    if (tempShift.day_off == 1) {
      setTempShift((state) => ({
        ...state,
        day_off: 0,
      }));
    } else {
      setTempShift((state) => ({
        ...state,
        day_off: 1,
        location: {},
        work_timing: {},
      }));
    }
  };



  const loadResources = function (schedule) {
    if (schedule.draft_employees == 1) {
      fetchResources({
        variables: {
          schedule_id: schedule.id,
        },
      });
    } else {
      if (schedule?.workGroup?.locations != null) {
        setResources(schedule.workGroup.locations);
        setFilteredResources(schedule.workGroup.locations);
      } else {
        setResources([]);
        setFilteredResources([]);
      }
      // setFilteredResourcesValues([]);
    }
  };

  const history = useHistory();

  useEffect(() => {
    fetchWorkGroups({
      variables: {
        start_date: moment().format("YYYY-MM-DD"),
      },
    });
    return () => {
      setFormSubmitting(false);
    };
  }, []);

  useEffect(() => {
    adjustWorkTimingDropDown();
  }, [tempShifts, selectedWorkTimings]);

  const reloadShifts = () => {
    const get_schedule_id = new URLSearchParams(window.location.search).get(
      "schedule_id"
    );

    if (get_schedule_id) {
      fetchScheduleShifts({
        variables: {
          id: get_schedule_id,
        },
      });
    }
  };

  const loadSchedule = () => {
    const get_schedule_id = new URLSearchParams(window.location.search).get(
      "schedule_id"
    );

    if (get_schedule_id) {
      setScheduleCreated(true);

      fetchWorkSchedule({
        variables: {
          id: get_schedule_id,
        },
      });
    }
  };

  const saveEvent = React.useCallback(() => {
    setFormSubmitting(true);
    if (schedule.draft_employees == 0) {
      if (
        ((!tempShift?.work_timing_id || !tempShift?.workTeam?.length) &&
          !tempShift?.day_off) ||
        (tempShift.day_off && !tempShift?.workTeam?.length)
      ) {
        return;
      }
      setUpsertShiftLoading(true);
      handleActionMutation(upsertShiftByLocation, {
        variables: {
          schedule_id: schedule.id,
          shift: {
            id: isNaN(tempShift.id) ? null : tempShift.id,
            container_id: isNaN(tempShift.id) ? null : tempShift.id,
            start: moment(tempShift.start).format("YYYY-MM-DD"),
            end: moment(tempShift.end).format("YYYY-MM-DD"),
            day_off: tempShift?.day_off ? tempShift?.day_off : 0,
            work_timing_id: tempShifts[0]?.work_timing_id ?? null,
            work_timings: [tempShifts?.[0]],
            workplace_setting: tempShift.workplace_setting,
            additional_work_places: tempShift.additional_work_places,
            workplace_setting_signout: tempShift.workplace_setting_signout,
            additional_work_places_signout:
              tempShift.additional_work_places_signout,
            workplace_setting_identical: tempShift.workplace_setting_identical,
            resource: tempShift.resource,
            workTeam: tempShift.workTeam,
            alternativeWorkTimings:
              tempShifts?.length > 1
                ? tempShifts?.slice(1, tempShifts?.length)?.map((shift) => ({
                    work_timing_id: shift?.work_timing_id,
                    ...(!!shift?.allow_half_day
                      ? {
                          first_half_work_timing_id:
                            shift?.first_half_work_timing_id,
                          second_half_work_timing_id:
                            shift?.second_half_work_timing_id,
                        }
                      : {}),
                    weight: parseFloat(shift?.weight?.value),
                  }))
                : [],
          },
        },
      })
        .then(({ response, error, serverError }) => {
          setUpsertShiftLoading(false);
          if (error || serverError) {
            return toast({
              message: "There was an error",
              color: "danger",
            });
          }
          setFormSubmitting(false);
          loadSchedule();
          setOpen(false);
          setTempShift(tempShiftsInitialState[0]);
          setTempShifts(tempShiftsInitialState);
          setSelectedWorkTimings([]);
          setDropDownWorkTimings([]);
        })
        .catch((error) => {
          setUpsertShiftLoading(false);
          console.log("upsertShiftByLocation", { error });
          if (error) {
            return toast({
              message: "There was an error",
              color: "danger",
            });
          }
        });
    } else {
      setUpsertShiftLoading(true);

      handleActionMutation(upsertShiftByEmployee, {
        variables: {
          schedule_id: schedule.id,
          shift_employee: {
            id: isNaN(tempShift.id) ? null : tempShift.id,
            container_id: !tempShift.id.includes("---")
              ? null
              : tempShift.id.split("---")[0],
            start: moment(tempShift.start).format("YYYY-MM-DD"),
            day_off: tempShift?.day_off ? tempShift?.day_off : 0,
            work_timing_id: tempShifts[0]?.work_timing_id ?? null,
            work_timings: [tempShifts?.[0]],
            workplace_setting: tempShift.workplace_setting,
            additional_work_places: tempShift.additional_work_places,
            workplace_setting_signout: tempShift.workplace_setting_signout,
            additional_work_places_signout:
              tempShift.additional_work_places_signout,
            workplace_setting_identical: tempShift.workplace_setting_identical,
            location_id: tempShift.location?.id ?? null,
            resource: tempShift.resource,
            end: moment(tempShift.end).format("YYYY-MM-DD"),
            alternativeWorkTimings:
              tempShifts?.length > 1
                ? tempShifts?.slice(1, tempShifts?.length)?.map((shift) => ({
                    work_timing_id: shift?.work_timing_id,
                    ...(!!shift?.allow_half_day
                      ? {
                          first_half_work_timing_id:
                            shift?.first_half_work_timing_id,
                          second_half_work_timing_id:
                            shift?.second_half_work_timing_id,
                        }
                      : {}),
                    weight: parseFloat(shift?.weight?.value),
                  }))
                : [],
          },
        },
      })
        .then(({ response, error, serverError }) => {
          setUpsertShiftLoading(false);
          if (error || serverError) {
            return toast({
              message: "There was an error",
              color: "danger",
            });
          }
          setFormSubmitting(false);

          setTempShifts(tempShiftsInitialState);
          setSelectedWorkTimings([]);
          setDropDownWorkTimings([]);

          toast({
            message: "Shift updated",
          });

          loadSchedule();
          setOpen(false);
        })
        .catch((error) => {
          console.log("upsertShiftByEmployee", { error });
          setUpsertShiftLoading(false);

          if (error) {
            return toast({
              message: "There was an error",
              color: "danger",
            });
          }
        });
    }

    // close the popup
  }, [isEdit, shifts, tempShift, shiftDate, tempShifts]);

  const deleteEvent = React.useCallback(
    (event) => {
      setShifts(shifts.filter((item) => item.id !== event.id));
      setTimeout(() => {
        snackbar({
          button: {
            action: () => {
              setShifts((prevEvents) => [...prevEvents, event]);
            },
            text: "Undo",
          },
          message: "Deleted",
        });
      });
    },
    [shifts]
  );

  const getSelectedEventTitles = React.useCallback((events) => {
    let titles = [];

    for (const event of events) {
      titles = [...titles, event.title];
    }
    return titles;
  }, []);

  const loadPopupForm = React.useCallback((event) => {
    setDate([event.start, event.end]);
    //setTempSchedule({});
  }, []);
  let teamPopupRef = useRef();
  useEffect(() => {
    let teamMenuHandler = (e) => {
      if (
        teamPopupRef.current !== null &&
        teamPopupRef.current !== undefined &&
        !teamPopupRef.current.contains(e.target)
      ) {
        setIsTeamOpen(false);
      }
    };

    document.addEventListener("mousedown", teamMenuHandler);

    return () => {
      document.removeEventListener("mousedown", teamMenuHandler);
    };
  },[]);
  // scheduler options
  const onEventClick = (args) => {
    const event = args.event;

    if (event?.id && event?.id?.includes("mbsc")) {
      return;
    }

    if (schedule.draft_employees == 0) {
      fetchShift({
        variables: {
          id: event.id,
        },
      });
    } else {
      fetchShiftEmployees({
        variables: {
          id: event.id,
        },
      });
    }

    setHeader(moment(event?.start).format("dddd , DD MMMM YYYY"));
    //   formatDate("DDDD", new Date(event.start), { moment: moment }) +
    //     ", " +
    //     formatDate("DD MMMM YYYY", new Date(event.start), { moment: moment })
    // );

    setEdit(true);
    setTempShift(event); // fetching the shift above does the same thing so this should be deleted
    // fill popup form with event data
    loadPopupForm(event);
    setOpen(true);
  };

  const cancelEventAdd = () => {
    setFormSubmitting(false);
    setTempShift(tempShiftsInitialState[0]);
    setTempShifts(tempShiftsInitialState);
    setSelectedWorkTimings([]);
    setDropDownWorkTimings([]);
    setShifts([...shifts]);
    setOpen(false);
  };
  const openTeamPopup = (team, args) => {
    if (team.type == "team" && team?.team_members) {
      setTeamAnchor(args.target);
      setPopupTeam(team);
      setTimeout(() => {
        setIsTeamOpen(true);
      });
    }
  };
  const onEventCreated = React.useCallback(
    (args) => {
      const event = args.event;
      event.workplace_setting = tempShiftsInitialState[0].workplace_setting;
      event.workplace_setting_type =
        tempShiftsInitialState[0].workplace_setting_type;
      event.workplace_setting_signout =
        tempShiftsInitialState[0].workplace_setting;
      event.workplace_setting_type_signout =
        tempShiftsInitialState[0].workplace_setting_type;
      event.workplace_setting_identical = 1;
      //const slot = slots.find((s) => { return s.id === event.slot });
      setHeader(moment(event?.start).format("dddd , DD MMMM YYYY"));

      setEdit(false);
      console.log(tempShift);
      // setMinTime(event.slot === 1 ? '07:00' : '12:00');
      // setMaxTime(event.slot === 1 ? '13:00' : '18:00');
      setTempShift(event);

      // fill popup form with event data
      loadPopupForm(event);
      // open the popup
      setOpen(true);
      adjustWorkTimingDropDown();
    },
    [loadPopupForm, schedule]
  );

  const onEventDeleted = React.useCallback(
    (args) => {
      deleteEvent(args.event);
    },
    [deleteEvent]
  );

  const extendDefaultEvent = React.useCallback((args) => {
    const d = args.start;
    const start = new Date(d.getFullYear(), d.getMonth(), d.getDate(), 7);
    const end = new Date(d.getFullYear(), d.getMonth(), d.getDate(), 13);

    return {
      title: formatDate("HH:mm", start) + " - " + formatDate("HH:mm", end),
      start: start,
      end: end,
      resource: args.resource,
    };
  }, []);

  const refreshSelectedEvents = React.useCallback(
    (events) => {
      setSelectedEvents(events);
    },
    [getSelectedEventTitles]
  );

  const renderMyResource = (resource) => {
    return (
      <div className="employee-shifts-cont">
        {schedule.draft_employees == 1 ? (
          resource.type == "team" ? (
            <div
              className="employee-shifts-name"
              onClick={(event) => openTeamPopup(resource, event)}
            >
              <span>
                <Groups3OutlinedIcon />
                {resource.name}
              </span>
            </div>
          ) : (
            <Link
              className="custom-link-style"
              to={`/employees/employee-profile/${resource?.id?.split("-")[1]}`}
            >
              <PersonOutlineOutlinedIcon />
              {resource.name}
            </Link>
          )
        ) : (
          <div className="employee-shifts-name">
            <span>
              {" "}
              <LocationOnOutlinedIcon /> {resource.name}
            </span>
          </div>
        )}
      </div>
    );
  };

  const myCustomResourceHeaderTemplate = () => {
    return (
      <>
        <div>{schedule.draft_employees ? t("employees") : t("locations")}</div>
      </>
    );
  };

  const scheduleStartDateChange = (args) => {
    setOldField("draft_date");
    setOldValue(schedule?.draft_date);
    setSchedule((state) => ({
      ...state,
      draft_date: moment(args).format("YYYY-MM-DD"),
    }));

    if (scheduleCreated) {
      setConfirmScheduleChange(true);
    }
  };

  const handleChangeApplyFrom = (args) => {
    const get_schedule_id = new URLSearchParams(window.location.search).get(
      "schedule_id"
    );

    setSchedule((state) => ({
      ...state,
      start: moment(args).format("YYYY-MM-DD"),
      draft_date: moment(args).format("YYYY-MM-DD"),
    }));

    fetchWorkGroups({
      variables: {
        start_date: moment(args).format("YYYY-MM-DD"),
      },
    });

    if (moment(args).isValid()) {
      setWorkGroup({});
      setSchedule((state) => ({
        ...state,
        work_group_id: null,
        workGroup: {},
        workTeam: null,
      }));
    }

    if (get_schedule_id) {
      schedule.start = moment(args).format("YYYY-MM-DD");
      handleTableDraw();
    }
  };

  const handleChangeTab = (_event, newValue) => {
    let newRefDate;
    if (Number(newValue) !== 1) {
      newRefDate = moment(schedule.draft_date)
        .add((Number(newValue) - 1) * Number(schedule.break_into), "days")
        .format("YYYY-MM-DD");
    } else {
      newRefDate = schedule.draft_date;
    }
    setRefDate(newRefDate);
    setActiveValue(newValue);
  };

  const handleWorkGroupChange = (selectedOptions) => {
    schedule.workTeam = null;
    setWorkGroup(selectedOptions);
    setSchedule((state) => ({
      ...state,
      work_group_id: selectedOptions.id,
      workGroup: selectedOptions,
      workTeam: null,
    }));
    if (scheduleCreated) {
      handleTableDraw();
    }
  };

  const handlePopupWorkTimingChange = (
    selectedOptions,
    shift_id,
    isAlternative = false,
    index
  ) => {
    if (isAlternative) {
      setTempShifts((shifts) =>
        shifts?.map((shift, i) => {
          if (shift?.id == shift_id && i == index) {
            return {
              ...shift,
              work_timing: selectedOptions,
              work_timing_id: selectedOptions?.id,
            };
          } else {
            return shift;
          }
        })
      );
      return;
    }
    let workTimings = selectedWorkTimings;
    workTimings[shift_id] = selectedOptions.id;
    setSelectedWorkTimings(workTimings);
    setTempShift((state) => ({
      ...state,
      work_timing_id: selectedOptions.id,
      work_timing: selectedOptions,
    }));
    const newState = tempShifts.map((shift, i) => {
      if (shift.id === shift_id && i == index) {
        return {
          ...shift,
          work_timing_id: selectedOptions.id,
          work_timing: selectedOptions,
        };
      }

      return shift;
    });
    setTempShifts(newState);
  };
  const handlePopupWorkTimingFirstHalfChange = (
    selectedOptions,
    shift_id,
    isAlternative,
    index
  ) => {
    if (isAlternative) {
      setTempShifts((shifts) =>
        shifts?.map((shift, i) => {
          if (shift?.id == shift_id && i == index) {
            return {
              ...shift,
              first_half_work_timing_id: selectedOptions.id,
              work_timing_first_half: selectedOptions,
            };
          } else {
            return shift;
          }
        })
      );
      return;
    }
    // let workTimings = selectedWorkTimings;
    // workTimings[shift_id] = selectedOptions.id;
    // setSelectedWorkTimings(workTimings);
    // setTempShift((state) => ({
    //   ...state,
    //   work_timing_id: selectedOptions.id,
    //   work_timing: selectedOptions,
    // }));
    const newState = tempShifts.map((shift, i) => {
      if (shift.id === shift_id && i == index) {
        return {
          ...shift,
          first_half_work_timing_id: selectedOptions.id,
          work_timing_first_half: selectedOptions,
        };
      }

      return shift;
    });
    setTempShifts(newState);
  };
  const handlePopupWorkTimingSecondHalfChange = (
    selectedOptions,
    shift_id,
    isAlternative,
    index
  ) => {
    if (isAlternative) {
      setTempShifts((shifts) =>
        shifts?.map((shift, i) => {
          if (shift?.id == shift_id && i == index) {
            return {
              ...shift,
              second_half_work_timing_id: selectedOptions.id,
              work_timing_second_half: selectedOptions,
            };
          } else {
            return shift;
          }
        })
      );
      return;
    }
    // let workTimings = selectedWorkTimings;
    // workTimings[shift_id] = selectedOptions.id;
    // setSelectedWorkTimings(workTimings);
    // setTempShift((state) => ({
    //   ...state,
    //   work_timing_id: selectedOptions.id,
    //   work_timing: selectedOptions,
    // }));
    const newState = tempShifts.map((shift, i) => {
      if ((shift.id === shift_id, i == index)) {
        return {
          ...shift,
          second_half_work_timing_id: selectedOptions.id,
          work_timing_second_half: selectedOptions,
        };
      }

      return shift;
    });
    setTempShifts(newState);
  };
  const handlePopupWeightChange = (
    selectedOptions,
    shift_id,
    isAlternative,
    index
  ) => {
    if (isAlternative) {
      setTempShifts((shifts) =>
        shifts?.map((shift, i) => {
          if (shift?.id == shift_id && i == index) {
            return {
              ...shift,
              weight: selectedOptions,
            };
          } else {
            return shift;
          }
        })
      );
      return;
    }
    const newState = tempShifts.map((shift, i) => {
      if (shift.id === shift_id && i == index) {
        return { ...shift, weight: selectedOptions };
      }

      return shift;
    });
    setTempShifts(newState);
  };
  const removeWorkTiming = (args, shift_id, work_timing_id) => {
    let shifts = tempShifts.filter(
      (shifts, kkk) => shifts?.work_timing_id !== work_timing_id
    );
    setTempShifts(shifts);
    selectedWorkTimings[shift_id] = null;
    setSelectedWorkTimings(selectedWorkTimings);
  };
  const addWorkTiming = (args) => {
    setTempShifts((state) => [
      ...state,
      {
        id:
          !!tempShifts > 0 && tempShifts?.length > 0
            ? Number(tempShifts?.[Number(tempShifts?.length) - 1]?.id) + 1
            : 1,
        schedule_detail_id: null,
        work_timing: {},
        work_timing_id: null,
        work_timing_first_half: {},
        first_half_work_timing_id: null,
        work_timing_second_half: {},
        second_half_work_timing_id: null,
        weight: { name: "1 Day", value: "1" },
        allow_half_day: 0,
      },
    ]);
  };
  const adjustWorkTimingDropDown = () => {
    let dropDownWorkTimings = [];
    setDropDownWorkTimings([]);
    tempShifts?.forEach((shift) => {
      schedule?.workGroup?.workTimings?.forEach((workTiming) => {
        let disabled = false;
        selectedWorkTimings?.forEach((selectedWorkTiming, index) => {
          if (selectedWorkTiming == workTiming.id && index != shift.id) {
            disabled = true;
          }
        });
        if (!disabled) {
          if (dropDownWorkTimings?.[shift.id] == undefined) {
            dropDownWorkTimings[shift.id] = [];
          }
          dropDownWorkTimings[shift.id].push(workTiming);
        }
      });
    });
    setDropDownWorkTimings(dropDownWorkTimings);
  };

  const handleFilterResourceChange = (selectedOptions, event) => {
    if (selectedOptions != null && selectedOptions.length != 0) {
      if (Array.isArray(selectedOptions)) {
        setFilteredResources(selectedOptions);
      } else {
        setFilteredResources([]);
      }
    } else {
      if (Array.isArray(resources)) {
        setFilteredResources(resources);
      } else {
        setFilteredResources([]);
      }
    }
    if (Array.isArray(selectedOptions)) {
      setFilteredResourcesValues(selectedOptions);
    } else {
      setFilteredResourcesValues([]);
    }
  };

  const handleWorkTeamChange = (selectedOptions, event) => {
    if (event.action === "remove-value" || event.action === "pop-value") {
      let toBeRemoved = {
        id: event?.removedValue?.id,
        name: event?.removedValue?.name,
      };

      if (
        event.removedValue !== undefined &&
        event.removedValue.user_type === "Employee"
      ) {
        setRemovedEmployees((state) => [...state, toBeRemoved]);
      } else {
        setRemovedTeams((state) => [...state, toBeRemoved]);
      }

      if (scheduleCreated) {
        setOldField("workTeam");
        setOldValue(schedule.workTeam);
        setConfirmDeleteEmployees(true);
      }
    }

    if (event.action === "select-option") {
      if (scheduleCreated) {
        schedule.workTeam = selectedOptions;
        handleTableDraw();
      }
    }

    setSchedule((state) => ({
      ...state,
      workTeam: selectedOptions,
    }));
  };

  const handlePopupLocationChange = (selectedOptions) => {
    setTempShift((state) => ({
      ...state,
      location: selectedOptions,
    }));
  };

  const handleAdditionalWorkplacesChange = (type, selectedOptions) => {
    if (type == "signin") {
      setTempShift((state) => ({
        ...state,
        additional_work_places: selectedOptions,
      }));
    }
    if (type == "signout") {
      setTempShift((state) => ({
        ...state,
        additional_work_places_signout: selectedOptions,
      }));
    }
  };

  const handleWorkPlaceSettingChange = (type, selectedOptions) => {
    if (type == "signin") {
      setTempShift((state) => ({
        ...state,
        workplace_setting: selectedOptions,
        workplace_setting_type: selectedOptions.id,
      }));
    }
    if (type == "signout") {
      setTempShift((state) => ({
        ...state,
        workplace_setting_signout: selectedOptions,
        workplace_setting_type_signout: selectedOptions.id,
      }));
    }
  };

  const handlePopupWorkTeamChange = (selectedOptions) => {
    setTempShift((state) => ({
      ...state,
      workTeam: selectedOptions,
    }));
  };
  const handlePopupWorkTeamChangeSelectAll = () => {
    let selectAllData = [schedule?.employees || [], schedule?.teams || []].flat(
      Infinity
    );
    setTempShift((state) => ({
      ...state,
      workTeam: selectAllData,
    }));
  };

  const cycleDaysChange = (args) => {
    setOldValue(schedule.schedule_days);
    setOldField("schedule_days");
    setSchedule((state) => ({
      ...state,
      // schedule_days: args.id,
      schedule_days: args.name,
    }));

    if (scheduleCreated) {
      setConfirmScheduleChange(true);
    }
  };

  const breakIntoChange = (args) => {
    const get_schedule_id = new URLSearchParams(window.location.search).get(
      "schedule_id"
    );

    setSchedule((state) => ({
      ...state,
      // break_into: Number(args.id),
      break_into: args.target.value,
    }));

    if (get_schedule_id) {
      schedule.break_into = args.id;
      // handleTableDraw();
    }
  };

  const onSelectedEventsChange = React.useCallback(
    (args) => {
      refreshSelectedEvents(args.events);
    },
    [refreshSelectedEvents]
  );

  const onEventRightClick = (args) => {
    let event_ids = [];
    mySelectedEvents.forEach((eve) => {
      event_ids.push(eve.id);
    });
    if (!event_ids.includes(args?.event?.id)) {
      setSelectedEvents((state) => [...state, args?.event]);
    }
    args?.domEvent?.preventDefault();
    setMenuAnchor(args?.domEvent?.target);
    setTempShift(args?.event);
    setTimeout(() => {
      setMenuOpen(true);
    });
  };

  const onMouseEnterEvent = React.useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  }, []);

  const onMouseLeaveEvent = React.useCallback(() => {
    timerRef.current = setTimeout(() => {
      setOpen(false);
    }, 200);
  }, []);

  const onEventUpdate = async (args) => {
    const { error } = await handleActionMutation(updateShift, {
      variables: {
        schedule_id: schedule.id,
        paste_date: moment(args.event.start).format("YYYY-MM-DD"),
        paste_resource: args.event.resource,
        container_id: args.event.id,
      },
      disableSuccessToast: true,
    });

    if (error) {
      return reloadShifts();
    }

    setOpen(false);
    loadSchedule();
  };

  const onCellRightClick = React.useCallback((args) => {
    args.domEvent.preventDefault();
    if (schedule.draft_employees == 0) {
      if (
        args.inst._invalidsMap[moment(args.date).format("YYYY-MM-DD")] ===
        undefined
      ) {
        setIsBlockedDate(false);
      } else {
        setIsBlockedDate(true);
      }
    } else {
      if (
        args.inst._invalidsMap[moment(args.date).format("YYYY-MM-DD")] ===
        undefined
      ) {
        setIsBlockedDate(false);
      } else {
        setIsBlockedDate(false);
        if (
          args.inst._invalidsMap[moment(args.date).format("YYYY-MM-DD")][0]
            .resource == args.resource
        ) {
          setIsBlockedDate(true);
        }
      }
    }
    setPasteDate(args.date);
    setPasteResource(args.resource);
    setPasteAnchor(args.domEvent.target);
    setTimeout(() => {
      setPasteMenuOpen(true);
    });
  }, []);

  const paste = async (args) => {
    setSelected(args.value);
    if (schedule.draft_employees == 1) {
      let work_timing_ids = [];
      for (let i = 0; i < selectedEventsToCopy.length; i++) {
        if (!work_timing_ids.includes(selectedEventsToCopy[i].work_timing_id)) {
          work_timing_ids.push(selectedEventsToCopy[i].work_timing_id);
        } else {
          toast({
            message: "Cannot paste the same work timing more than one time",
            color: "danger",
          });
          return false;
        }
        if (
          selectedEventsToCopy[i].day_off == 1 &&
          selectedEventsToCopy.length > 1
        ) {
          toast({
            message:
              "Cannot paste a work timing and a day-off at the same time",
            color: "danger",
          });
          return false;
        }
      }
    }

    const { error } = await handleActionMutation(cloneShift, {
      variables: {
        paste_date: moment(pasteDate).format("YYYY-MM-DD"),
        paste_resource: pasteResource,
        schedule_id: schedule.id,
        shifts: selectedEventsToCopy.map((shift) => {
          return {
            id: shift.id,
            schedule_detail_id: shift.schedule_detail_id,
          };
        }),
      },
      disableSuccessToast: true,
    });

    if (!error) {
      loadSchedule();
    }
  };

  const selectChange = async (args) => {
    setSelected(args.value);

    if (args.value === "copy") {
      if (mySelectedEvents.length > 1) {
        toast({
          message: "Cannot copy more than one shift",
        });
        setSelectedEventsToCopy([]);
      } else {
        setSelectedEventsToCopy(mySelectedEvents);
        toast({
          message: "Copied",
        });
      }
    }

    if (args.value === "delete") {
      const { response, error } = await handleActionMutation(deleteShift, {
        variables: {
          schedule_id: schedule.id,
          container_ids: mySelectedEvents?.map((event) => event.id),
        },
      });

      if (error) {
        return toast({
          message: "There was an error",
        });
      }

      setSelectedEvents([]);
      setSelectedEventsToCopy([]);
      toast({
        message: "Deleted",
      });
      loadSchedule();
    }
  };

  const menuClose = React.useCallback(() => {
    setSelected("");
    setMenuOpen(false);
  }, []);

  const pasteMenuClose = React.useCallback(() => {
    setSelected("");
    setPasteMenuOpen(false);
  }, []);

  const initInvalidDates = (schedule) => {
    if (schedule.draft_employees == 1) {
      schedule.blockedDates?.push({
        // multi day range with date object
        allDay: true,
        start: moment(schedule.draft_date)
          .add(Number(schedule.schedule_days), "days")
          .format("YYYY-MM-DD"),
        end: moment(schedule.draft_date)
          .add(
            Number(schedule.schedule_days) + Number(schedule.break_into),
            "days"
          )
          .format("YYYY-MM-DD"),
        cssClass: "blocked-date",
      });

      setInvalidDates(schedule?.blockedDates);
    } else {
      setInvalidDates([
        {
          // multi day range with date object
          allDay: true,
          start: moment(schedule?.draft_date)
            .add(Number(schedule?.schedule_days), "days")
            .format("YYYY-MM-DD"),
          end: moment(schedule?.draft_date)
            .add(
              Number(schedule?.schedule_days) + Number(schedule?.break_into),
              "days"
            )
            .format("YYYY-MM-DD"),
          cssClass: "blocked-date",
        },
      ]);
    }
  };

  const [eventTouchStartTime, setEventTouchStartTime] = useState(null);

  const handleOnTouchStart = () => {
    setEventTouchStartTime(moment());
  };

  const handleOnTouchEnd = (event, elementEvent) => {
    if (!!eventTouchStartTime) {
      const eventTouchEndTime = moment();
      const differenceInSeconds =
        eventTouchEndTime.diff(eventTouchStartTime) / 1000;

      if (differenceInSeconds > 1.5) {
        onEventRightClick({
          event,
          domEvent: {
            preventDefault: elementEvent.preventDefault,
            target: elementEvent.target,
          },
        });
      }
    }
    setEventTouchStartTime(null);
  };

  const renderEvent = (event) => {
    let isEventSelected = mySelectedEvents?.find(
      (ev) => ev?.id == event?.original?.id
    );
    let final_title = [];
    if (event?.original?.title?.includes(",")) {
      event.original.title.split(",")?.forEach((work_timing) => {
        final_title.push(
          <div dir="auto" className="single-worktiming">
            {work_timing}
          </div>
        );
      });
    } else {
      final_title = (
        <div dir="auto" className="single-worktiming">
          {event.original.title}
        </div>
      );
    }
    return (
      <div
        onTouchStart={handleOnTouchStart}
        onTouchEnd={(elementEvent) => handleOnTouchEnd(event, elementEvent)}
        className={isEventSelected ? "event-day selected" : "event-day"}
      >
        {schedule.draft_employees == 0 ? (
          <div className="user_count">
            <PersonOutlineOutlinedIcon /> {event.original.user_count}{" "}
          </div>
        ) : (
          ""
        )}
        {event.original.day_off == 1 || event.original.day_off == null ? (
          <div className="single-worktiming"> {t("Day Off")}</div>
        ) : (
          final_title
        )}{" "}
        {/* {schedule.draft_employees == 0 ? (
          ""
        ) : event?.original?.day_off == 0 ? (
          <p className="mb-0">
            {schedule?.workGroup?.locations?.find(
              (location) => location?.id == event.original.location_id
            )?.name ?? "location namessssssssss"}
          </p>
        ) : null} */}
        {/* <br/><img src="/user-calendar.png" height={30} /> <span className="employee-count">{event.original.emp_count}</span> */}
        {schedule.draft_employees == 1 ? (
          <div className="single-worktiming">
            <LocationOnOutlinedIcon />{" "}
            {event.original.location_name
              ? event.original.location_name
              : "None"}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };

  const renderPasteMenuItem = (item) => {
    let attributes = false;
    if (selectedEventsToCopy.length == 0 || isBlockedDate) {
      attributes = true;
    }
    if (selectedEventsToCopy.length > 1 && schedule.draft_employees == 0) {
      attributes = false;
    }
    return (
      <div className="my-custom-class">
        <button className="paste-button" disabled={attributes}>
          {item.display}
        </button>
      </div>
    );
  };

  const updateScheduleFields = async ({
    reset = 0,
    skipLoading = false,
    mirror = true,
  } = {}) => {
    if (!skipLoading) {
      setIsLoading(true);
    }

    const get_schedule_id = new URLSearchParams(window.location.search).get(
      "schedule_id"
    );

    const { response, error, serverError } = await handleActionMutation(
      upsertSchedule,
      {
        variables: {
          input: {
            id: schedule.id ?? null,
            ...(get_schedule_id
              ? { work_group_id: schedule?.workGroup?.id }
              : { work_group_id: schedule?.work_group_id }),
            work_team_id: schedule?.workTeam?.map((teamOrEmployee) => {
              return {
                id: teamOrEmployee?.id,
                name: teamOrEmployee?.name,
                ...(teamOrEmployee?.user_type
                  ? {
                      user_type: teamOrEmployee?.user_type,
                    }
                  : { work_group_id: teamOrEmployee?.work_group_id }),
              };
            }),
            schedule_start_date: schedule?.draft_date,
            apply_from: schedule?.start,
            cycle_days: +schedule?.schedule_days,
            break_into: +schedule?.break_into,
            draft_employees: schedule?.draft_employees ?? 0,
            repeat: schedule?.repeat,
            reset: reset,
            mirror: mirror,
          },
        },
        disableSuccessToast: skipLoading,
      }
    );

    setIsLoading(false);

    if (serverError || error) {
      setShowCalendar(false);

      if (serverError) {
        dispatch(setWorkScheduleServerValidationAction(serverError));
      } else {
        toast({
          message: "There was an error",
          color: "danger",
          duration: 6000,
        });
      }
      return;
    }
    if (response?.data?.upsert_schedule) {
      setSchedule((state) => ({
        ...state,
        ...response?.data?.upsert_schedule,
      }));
      window.history.pushState(
        {},
        null,
        `/work-schedules/form?schedule_id=${response?.data?.upsert_schedule?.id}`
      );
      loadSchedule();
      setRemovedEmployees([]);
      setRemovedTeams([]);
      setShowCalendar(true);
      setFormSubmitting(false);
    }
  };

  const confirmPublish = async () => {
    let scheduledEmployees = schedule.scheduledEmployeesAndTeams.length;
    let scheduleWorkTeam =
      Number(schedule.employees.length) + Number(schedule.teams.length);
    if (scheduledEmployees < scheduleWorkTeam) {
      setInsufficientScheduledEmployees(true);
      return false;
    }
    setConfirmPublishSchedule(true);
  };

  const handlePublish = async () => {
    setPublishScheduleLoading(true);
    const { error } = await handleActionMutation(publishSchedule, {
      variables: {
        schedule_id: schedule.id,
      },
    });

    if (!error) {
      toast({
        message: "Published Successfully",
        color: "success",
        duration: 6000,
      });
      history.push("/attendance?tab=work-calendar");
      dispatch(setShouldExitCalendarPage(true));
    }
    setPublishScheduleLoading(false);
  };

  const scheduleChangeCanceled = () => {
    setSchedule((state) => ({
      ...state,
      [oldField]: oldValue,
    }));
    setConfirmScheduleChange(false);
  };
  const schedulePublishCanceled = () => {
    setSchedule((state) => ({
      ...state,
      [oldField]: oldValue,
    }));
    setConfirmPublishSchedule(false);
  };

  const scheduleChangeConfirmed = () => {
    setConfirmScheduleChange(false);
    handleTableDraw(1);
  };

  const deleteCanceled = () => {
    setConfirmDeleteEmployees(false);
    setSchedule((state) => ({
      ...state,
      [oldField]: oldValue,
    }));
    setRemovedEmployees([]);
    setRemovedTeams([]);
  };

  const deleteConfirmed = () => {
    setConfirmDeleteEmployees(false);
    handleTableDraw();
  };

  const onEventHoverIn = React.useCallback((args) => {
    const event = args.event;
    if (args.event.day_off == 1) {
      return;
    }

    setTooltipEvent(event);

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    setEventAnchor(args.domEvent.target);
    setEventOpen(true);
  }, []);

  const onEventHoverOut = React.useCallback(() => {
    timerRef.current = setTimeout(() => {
      setEventOpen(false);
    }, 200);
  }, []);

  const submitHandler = () => {
    if (
      scheduleCreated &&
      (removedEmployees.length > 0 || removedTeams.length > 0)
    ) {
      setConfirmDeleteEmployees(true);
      return false;
    } else {
      handleTableDraw();
    }
  };

  const handleTableDraw = async (reset = 0) => {
    setFormSubmitting(true);
    if (
      +schedule?.break_into > +schedule?.schedule_days ||
      !workGroupValidation.isValid ||
      !startFromValidation.isValid ||
      !applyFromValidation.isValid ||
      !workTeamsValidation.isValid ||
      !cycleDaysValidation.isValid ||
      !breakIntoValidation.isValid
    ) {
      return;
    }
    dispatch(setWorkScheduleServerValidationAction({}));
    try {
      await updateScheduleFields({ reset });
      initInvalidDates(schedule);
      setRefDate(schedule?.draft_date);
      initInterval(schedule);
    } catch (error) {
      console.log("updateScheduleFields", { error });
      toast({
        message: "There was an error",
        color: "danger",
      });
    }
  };

  const initInterval = function (schedule) {
    setViewSettings({
      timeline: {
        type: "day",
        size: Number(schedule?.break_into),
        resolution: "day",
        eventList: true,
      },
    });
    setIntervalArray(
      _.range(
        Math.ceil(Number(schedule.schedule_days) / Number(schedule.break_into))
      )
    );
    // deleted for not going back to interval 1 after adding a shift in interval 2
    // setActiveValue(1);
  };

  const employeeTeamsOptions = workGroup?.WorkTeams?.concat(
    workGroup?.employeesNotInTeams
      ?.map((employee) => {
        return {
          id: employee?.user?.id,
          name: employee?.user?.name,
          user_type: employee?.user?.user_type,
        };
      })
      ?.filter((el) => el !== undefined)
  );

  // validation start
  const workGroupValidation = useCustomFormValidation({
    inputValue: schedule?.work_group_id,
  });

  const startFromValidation = useCustomFormValidation({
    inputValue: schedule?.draft_date,
  });

  const applyFromValidation = useCustomFormValidation({
    inputValue: schedule?.start,
  });

  const workTeamsValidation = useCustomFormValidation({
    validateBy: "arrayRequired",
    inputValue: schedule?.workTeam ?? [],
  });

  const cycleDaysValidation = useCustomFormValidation({
    inputValue: schedule?.schedule_days,
  });

  const breakIntoValidation = useCustomFormValidation({
    inputValue: schedule?.break_into,
  });
  // validation end

  const ShiftModalHeader = () => {
    return (
      <div className="custom-shift-modal-header">
        <p className="mb-0 mr-5">{headerText}</p>
        <input
          id="shifts-day-off"
          className="shifts-custom-checkbox d-none"
          type="checkbox"
          checked={tempShift?.day_off}
          onChange={handlePopupDayOffSwitchChange}
        />
        <label htmlFor="shifts-day-off" className="user-select-none d-none">
          {t("make this a day off")}
        </label>
      </div>
    );
  };

  const handleSaveAsDraft = () => {
    setTimeout(() => {
      history.push("/attendance?tab=work-calendar");
    }, 2000);
    dispatch(showSuccessToast("saved as draft"));
  };

  const handleEmployeesWorkTeamsSelectAll = () => {
    schedule.workTeam = employeeTeamsOptions;

    setSchedule((state) => ({
      ...state,
      workTeam: employeeTeamsOptions,
    }));

    if (scheduleCreated) {
      handleTableDraw();
    }
  };

  const getUniqueShiftWorktimings = (
    worktimings = [],
    index,
    tempShifts = []
  ) => {
    const selectedWorkTimingsInOtherShifts = tempShifts
      ?.filter((_, i) => i != index)
      ?.map((temp) => temp?.work_timing_id);

    return worktimings?.filter(
      (workTiming) =>
        !selectedWorkTimingsInOtherShifts?.includes(workTiming?.id)
    );
  };

  return (
    <div className="content create_work_schedule_container px-5">
      {/* {isLoading ? ( */}
      {fetchWorkGroupsLoading || fetchWorkScheduleLoading ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : (
        ""
      )}
      <div className={formHidden == 1 ? "d-none" : ""}>
        <h1 className="page-title">{t("New work schedule")}</h1>

        <div className="row align-items-start">
          <div className="col-3">
            <DateTimePickerForm
              {...formProps}
              datePickerContainer="w-100"
              label="Apply this starting from"
              labelStyle="my-2"
              name="applyFrom"
              id="applyFrom"
              isDisabled={scheduleCreated}
              disabled={scheduleCreated}
              value={!!schedule?.start ? moment(schedule?.start) : null}
              // validateBy="textRequired"
              validationName="input.apply_from"
              onChange={handleChangeApplyFrom}
              onSelect={handleChangeApplyFrom}
              containerStyle={`flex-column align-items-start ${
                formSubmitting && !applyFromValidation.isValid
                  ? "invalid-schedule-input-style"
                  : ""
              }`}
            />
            {formSubmitting && !applyFromValidation.isValid ? (
              <p className="shift-validation-message">
                {t(applyFromValidation.validationMessage)}
              </p>
            ) : null}
          </div>
          <div className="col-6">
            <BSelect
              {...formProps}
              name="work_group_id"
              id="work_group_id"
              placeholder={t("Select work group")}
              validationName="input.work_group_id"
              label={"work group"}
              keepDefaultStyle
              options={workGroups}
              isDisabled={scheduleCreated}
              onChange={handleWorkGroupChange}
              value={schedule?.workGroup ?? null}
              optionLabel="name"
              defaultValue={workGroup}
              optionValue="id"
              labelStyle="my-2"
              containerStyle="flex-column align-items-start"
              inputContainerStyle={`flex-grow-1 mr-0 w-100 ${
                formSubmitting && !workGroupValidation.isValid
                  ? "invalid-schedule-input-style"
                  : ""
              }`}
              skipLocalization
              icon="people"
              // validateBy="textRequired"
              // validateMessage="invalid"
            />
            {formSubmitting && !workGroupValidation.isValid ? (
              <p className="shift-validation-message">
                {t(workGroupValidation.validationMessage)}
              </p>
            ) : null}
          </div>
          <div className="col-3">
            <DateTimePickerForm
              {...formProps}
              datePickerContainer="w-100"
              label="Starting From"
              labelStyle="my-2"
              name="start"
              id="scheduleStartDate"
              value={!!schedule.draft_date ? moment(schedule.draft_date) : null}
              onChange={scheduleStartDateChange}
              onSelect={scheduleStartDateChange}
              containerStyle={`flex-column align-items-start ${
                formSubmitting && !startFromValidation.isValid
                  ? "invalid-schedule-input-style"
                  : ""
              }`}
              // validationName={`input.${props.validationInputName}.${index}.start`}
              // onSelect={handleChildDateInputChange}
            />
            {formSubmitting && !startFromValidation.isValid ? (
              <p className="shift-validation-message">
                {t(startFromValidation.validationMessage)}
              </p>
            ) : null}
          </div>
        </div>

        <div className="selectall_dropdown mt-4">
          <BSelect
            {...formProps}
            name="work_team_id"
            id="work_team_id"
            value={schedule.workTeam}
            label={t("employees") + "/" + t("work teams")}
            keepDefaultStyle
            isMulti={true}
            options={employeeTeamsOptions}
            onChange={handleWorkTeamChange}
            optionLabel="name"
            optionValue="id"
            labelStyle="my-2"
            rootStyle="w-100"
            containerStyle="containerStyle flex-column align-items-start"
            inputContainerStyle={`flex-grow-1 mr-0 w-100 ${
              formSubmitting && !workTeamsValidation.isValid
                ? "invalid-schedule-input-style"
                : ""
            }`}
            skipLocalization
            icon="people"
            isClearable={false}
            validationName="input.work_team_id"

            // validateBy="textRequired"
            // isDisabled={showCalendar}
          />
          <span onClick={handleEmployeesWorkTeamsSelectAll}>
            {t("select all")}
          </span>
        </div>
        {formSubmitting && !workTeamsValidation.isValid ? (
          <p className="shift-validation-message">
            {t(workTeamsValidation?.validationMessage)}
          </p>
        ) : null}

        <h3 className="subtitle">{t("Schedule")}</h3>
        <div className="row align-items-start">
          <div className="col-3 align-self-start">
            <BSelect
              {...formProps}
              name="cycle_days"
              validationName="input.cycle_days"
              label={"No. of days in a cycle"}
              keepDefaultStyle
              isMulti={false}
              onChange={cycleDaysChange}
              options={cycleLength}
              bValue={schedule.schedule_days}
              optionLabel="name"
              optionValue="id"
              labelStyle="my-2 col-6"
              containerStyle="containerStyle flex-row align-items-center"
              inputContainerStyle={`flex-grow-1 mr-0 w-100 ${
                formSubmitting && !cycleDaysValidation.isValid
                  ? "invalid-schedule-input-style"
                  : ""
              }`}
              skipLocalization
              icon="type"
              // validateBy="textRequired"
            />
            {/* <InputForm
            {...formProps}
            label={"No. of days in a cycle"}
            labelStyle="my-2 mr-5"
            name="cycle_days"
            validationName="input.cycle_days"
            type="text"
            value={schedule.schedule_days}
            hasSuffix
            suffixTitle="Days"
            containerStyle="containerStyle d-flex align-items-center justify-content-start"
            inputContainerStyle={`d-flex align-items-center ${
              formSubmitting && !cycleDaysValidation.isValid
                ? "invalid-schedule-days-input-style"
                : ""
            }`}
            onChange={cycleDaysChange}
          />
          {formSubmitting && !cycleDaysValidation.isValid ? (
            <p className="shift-validation-message">
              {t(cycleDaysValidation.validationMessage)}
            </p>
          ) : null} */}
          </div>

          <div className="col-3">
            {/* <BSelect
            {...formProps}
            name="break_into"
            validationName="input.break_into"
            label={"Break into"}
            keepDefaultStyle
            isMulti={false}
            onChange={breakIntoChange}
            options={cycleLength}
            bValue={schedule.break_into}
            optionLabel="name"
            optionValue="id"
            labelStyle="my-2"
            containerStyle="containerStyle flex-column align-items-start"
            inputContainerStyle={`flex-grow-1 mr-0 w-100 ${
              formSubmitting &&
              (!breakIntoValidation.isValid ||
                schedule?.break_into > schedule?.schedule_days)
                ? "invalid-schedule-input-style"
                : ""
            }`}
            skipLocalization
            icon="type"
            // validateBy="textRequired"
          />
           */}
            <InputForm
              {...formProps}
              labelStyle="my-2 mr-5"
              name="break_into"
              validationName="input.break_into"
              label={"Break into"}
              type="text"
              value={schedule.break_into}
              hasSuffix
              suffixTitle="Days"
              containerStyle="containerStyle d-flex align-items-center flex-row justify-content-start"
              inputContainerStyle={`d-flex align-items-center ${
                formSubmitting &&
                (!breakIntoValidation.isValid ||
                  +schedule?.break_into > +schedule?.schedule_days)
                  ? "invalid-schedule-days-input-style"
                  : ""
              }`}
              onChange={breakIntoChange}
            />
            {formSubmitting && !breakIntoValidation.isValid ? (
              <p className="shift-validation-message">
                {t(breakIntoValidation.validationMessage)}
              </p>
            ) : null}
            {formSubmitting &&
            +schedule?.break_into > +schedule?.schedule_days ? (
              <div className="invalid-container-style">
                <p className="validity-msg-style">{`this value can't be more than ${schedule?.schedule_days}`}</p>
              </div>
            ) : null}
          </div>

  
          <div className="col-2 mx-1">
            {scheduleCreated ? (
              <button
                className="draw_btn"
                onClick={deleteAllShifts}
                disabled={isLoading}
              >
                {isLoading ? (
                  <Spinner
                    style={{ width: "1rem", height: "1rem", color: "#fff" }}
                  />
                ) : !scheduleCreated ? (
                  t("Reset Shifts")
                ) : (
                  t("Reset Shifts")
                )}
              </button>
            ) : (
              ""
            )}
          </div>
          <div className="col-2 mx-1">
            <button
              className="draw_btn"
              onClick={submitHandler}
              disabled={isLoading}
            >
              {isLoading ? (
                <Spinner
                  style={{ width: "1rem", height: "1rem", color: "#fff" }}
                />
              ) : !scheduleCreated ? (
                t("draw")
              ) : (
                t("Update")
              )}
            </button>
          </div>
        </div>
      </div>
      {showCalendar ? (
        <div className="table_container scheduler_container">
          <div className="row">
            <div className="col-4">
              <div className="calendar_switch">
                <span>{t("locations")}</span>
                <Switch
                  checked={schedule.draft_employees == 1}
                  onChange={handleSwitchChange}
                  color="primary"
                  name="checkedB"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
                <span>{t("employees")}</span>
              </div>
            </div>
            <div className="col-4">
              <div className="calendar_switch">
                <span>{t("Hide Form")}</span>
                <Switch
                  checked={formHidden == 1}
                  onChange={handleHideFormChange}
                  color="primary"
                  name="checkedB"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </div>
            </div>
            <div className="col-4">
              <BSelect
                {...formProps}
                name="resource_filter"
                id="resource_filter"
                value={
                  Array.isArray(filteredResourcesValues)
                    ? filteredResourcesValues
                    : []
                }
                keepDefaultStyle
                isMulti={true}
                options={Array.isArray(resources) ? resources : []}
                onChange={handleFilterResourceChange}
                labelStyle="my-2"
                rootStyle="w-100"
                containerStyle="containerStyle flex-column align-items-start"
                inputContainerStyle="flex-grow-1 mr-0 w-100"
                skipLocalization
                icon="people"
                isClearable={false}
                validationName="input.work_team_id"

                // validateBy="textRequired"
                // isDisabled={showCalendar}
              />
            </div>
          </div>

          <div className="mb-3">
            <Tabs
              value={activeValue}
              onChange={handleChangeTab}
              orientation={"horizontal"}
              scrollButtons="auto"
              variant="fullWidth"
              // classes={{
              //   root: classes.root,
              //   indicator: classes.indicator,
              //   flexContainer: classes.flexContainer,
              // }}
            >
              {intervalArray.map((iteration) => (
                <Tab
                  // classes={{
                  //   root: classes.tabRoot,
                  //   wrapper: "top-bar-label",
                  //   selected: isRtl ? classes.selectedRtl : classes.selected,
                  // }}
                  value={Number(iteration) + 1}
                  label={
                    <div className="routeBar-text">
                      {t("interval")} {Number(iteration) + 1}
                    </div>
                  }
                />
              ))}
            </Tabs>
          </div>
          {!isCalendarLoading ? (
            <Eventcalendar
              view={viewSettings}
              locale={
                document.documentElement.lang?.includes("ar")
                  ? localeAr
                  : localeEn
              }
              data={shifts}
              resources={
                Array.isArray(filteredResources) ? filteredResources : []
              }
              invalid={invalidDates}
              dragToCreate={true}
              dragToResize={false}
              dragToMove={true}
              clickToCreate={true}
              extendDefaultEvent={extendDefaultEvent}
              onEventDoubleClick={onEventClick}
              onEventCreated={onEventCreated}
              onEventDeleted={onEventDeleted}
              renderResource={renderMyResource}
              cssClass="md-employee-shifts"
              selectMultipleEvents={true}
              selectedEvents={mySelectedEvents}
              onSelectedEventsChange={onSelectedEventsChange}
              // renderScheduleEventContent={myScheduleEvent}
              renderScheduleEvent={renderEvent}
              renderResourceHeader={myCustomResourceHeaderTemplate}
              refDate={refDate}
              // min={refDate}
              // max={refEndDate}
              selectedDate={refDate}
              onEventRightClick={onEventRightClick}
              onCellRightClick={onCellRightClick}
              onEventUpdated={onEventUpdate}
              // height={"100%"}
              // onEventHoverIn={onEventHoverIn}
              // onEventHoverOut={onEventHoverOut}
              dir
            />
          ) : (
            ""
          )}
          <Popup
            display="anchored"
            isOpen={isEventOpen}
            anchor={eventAnchor}
            touchUi={false}
            showOverlay={false}
            contentPadding={false}
            closeOnOverlayClick={false}
            width={350}
            cssClass="md-tooltip"
          >
            <div
              onMouseEnter={onMouseEnterEvent}
              onMouseLeave={onMouseLeaveEvent}
            >
              <div
                className="md-tooltip-header"
                style={{ backgroundColor: "#2665ac" }}
              >
                <span className="md-tooltip-name-age">
                  {tooltipEvent.title}
                </span>
                <span className="md-tooltip-time"></span>
              </div>
              <div className="md-tooltip-info">
                <LocationOnOutlinedIcon />{" "}
                {tooltipEvent?.location_name
                  ? tooltipEvent?.location_name
                  : "None"}
                {tooltipEvent?.shift_teams?.split(",").map((teamMember) => (
                  <div>
                    <Divider />
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end" aria-label="delete"></IconButton>
                      }
                    >
                      <ListItemAvatar>
                        <Avatar>
                          <Groups3OutlinedIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={teamMember} secondary={null} />
                    </ListItem>
                  </div>
                ))}
                {tooltipEvent?.shift_users?.split(",").map((teamMember) => (
                  <div>
                    <Divider />
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end" aria-label="delete"></IconButton>
                      }
                    >
                      <ListItemAvatar>
                        <Avatar>
                          <PersonOutlineOutlinedIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={teamMember} secondary={null} />
                    </ListItem>
                  </div>
                ))}
              </div>
            </div>
          </Popup>
          <Popup
            display="anchored"
            isOpen={isTeamOpen}
            anchor={teamAnchor}
            touchUi={false}
            showOverlay={false}
            contentPadding={false}
            closeOnOverlayClick={false}
            width={350}
            cssClass="md-tooltip"
          >
            {popupTeam.name ? (
              <div ref={teamPopupRef}>
                <div className="md-tooltip-info">
                  {popupTeam.team_members?.split(",")?.map((teamMember) => (
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end" aria-label="delete"></IconButton>
                      }
                    >
                      <ListItemAvatar>
                        <Avatar>
                          <PersonOutlineOutlinedIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={teamMember} secondary={null} />
                    </ListItem>
                  ))}
                </div>
              </div>
            ) : (
              ""
            )}
          </Popup>
          {!isCalendarLoading ? (
            <div className="calendar-buttons-container">
              <button
                className="calendar-button save-as-draft"
                onClick={handleSaveAsDraft}
              >
                {t("save as draft")}
              </button>
              <button
                className="calendar-button save-and-publish"
                onClick={confirmPublish}
              >
                {t("save and publish")}
              </button>
            </div>
          ) : (
            ""
          )}
          <Select
            inputProps={{ type: "hidden" }}
            display="anchored"
            touchUi={false}
            anchor={menuAnchor}
            data={contextMenu}
            value={selectedValue}
            isOpen={menuOpen}
            onChange={selectChange}
            onClose={menuClose}
          />
          <Select
            inputProps={{ type: "hidden" }}
            display="anchored"
            touchUi={false}
            anchor={pasteAnchor}
            data={pasteContextMenu}
            value={selectedValue}
            isOpen={pasteMenuOpen}
            onChange={paste}
            onClose={pasteMenuClose}
            disabled={true}
            renderItem={renderPasteMenuItem}
          />
        </div>
      ) : null}

      <div className="add-shift-modal-container">
        <MainModal
          isOpen={confirmScheduleChange}
          modalTitle={"Warning!"}
          toggle={scheduleChangeCanceled}
          btnOnClick={scheduleChangeConfirmed}
          btnOnCancelClick={scheduleChangeCanceled}
          btnLabel={t("Confirm")}
          btnLabelCancel={t("Cancel")}
          // className="employee-shifts-popup"
          // modalFooterComponent={() => (
          //   <div className="add-shift-footer">
          //     <button
          //       type="button"
          //       className="modal-cancel"
          //       onClick={scheduleChangeCanceled}
          //     >
          //       Cancel
          //     </button>
          //     <button
          //       type="button"
          //       className="modal-Save"
          //       onClick={scheduleChangeConfirmed}
          //     >
          //       Confirm and Continue
          //     </button>
          //   </div>
          // )}
        >
          <h4>
            {t(
              "By applying this change the schedule will be reset and all the changes done to the shifts will be discarded"
            )}
          </h4>
        </MainModal>

        <MainModal
          isOpen={insufficientScheduledEmployees}
          modalTitle={"Cannot publish schedule!"}
          // toggle={scheduleChangeCanceled}
          btnOnClick={() => setInsufficientScheduledEmployees(false)}
          // btnOnCancelClick={scheduleChangeCanceled}
          btnLabel={t("Ok")}
          // btnLabelCancel={t("Cancel")}
          // className="employee-shifts-popup"
          // modalFooterComponent={() => (
          //   <div className="add-shift-footer">
          //     <button
          //       type="button"
          //       className="modal-cancel"
          //       onClick={scheduleChangeCanceled}
          //     >
          //       Cancel
          //     </button>
          //     <button
          //       type="button"
          //       className="modal-Save"
          //       onClick={scheduleChangeConfirmed}
          //     >
          //       Confirm and Continue
          //     </button>
          //   </div>
          // )}
        >
          <h4>{t("There are employees / teams with no shifts")}</h4>
        </MainModal>

        <MainModal
          isOpen={confirmPublishSchedule}
          modalTitle={"Warning!"}
          // toggle={scheduleChangeCanceled}
          btnOnClick={handlePublish}
          btnOnCancelClick={schedulePublishCanceled}
          btnLabel={t("Confirm")}
          btnLabelCancel={t("Cancel")}
          btnSubmitLoading={publishScheduleLoading}
          // className="employee-shifts-popup"
          // modalFooterComponent={() => (
          //   <div className="add-shift-footer">
          //     <button
          //       type="button"
          //       className="modal-cancel"
          //       onClick={scheduleChangeCanceled}
          //     >
          //       Cancel
          //     </button>
          //     <button
          //       type="button"
          //       className="modal-Save"
          //       onClick={scheduleChangeConfirmed}
          //     >
          //       Confirm and Continue
          //     </button>
          //   </div>
          // )}
        >
          <h4>
            {t(
              "By publishing this schedule all attendance records, assignments, and requests will be deleted for all the selected employees starting from (" +
                moment(schedule.start ?? undefined).format("DD/MM/YYYY") +
                ")"
            )}
          </h4>
        </MainModal>

        <MainModal
          modalTitle={"Warning!"}
          isOpen={confirmDeleteEmployees}
          toggle={deleteCanceled}
          btnOnClick={deleteConfirmed}
          btnOnCancelClick={deleteCanceled}
          btnLabel={t("Confirm")}
          // className="employee-shifts-popup"
        >
          <p>
            All the shifts for the following employees / teams are going to be
            reset
          </p>
          <div className="row ">
            {removedEmployees.length > 0 ? (
              <div className="col-6">
                <h4>Employees</h4>

                {removedEmployees.map((iteration) => (
                  <ListItem
                    secondaryAction={
                      <IconButton edge="end" aria-label="delete"></IconButton>
                    }
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <PersonOutlineOutlinedIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={iteration.name} secondary={null} />
                  </ListItem>
                ))}
              </div>
            ) : (
              ""
            )}
            <div className="col-6">
              {" "}
              {removedTeams.length > 0 ? <h4>Teams</h4> : ""}
              {removedTeams.map((iteration) => (
                <ListItem
                  secondaryAction={
                    <IconButton edge="end" aria-label="delete"></IconButton>
                  }
                >
                  <ListItemAvatar>
                    <Avatar>
                      <Groups3OutlinedIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={iteration.name} secondary={null} />
                </ListItem>
              ))}
            </div>
          </div>
        </MainModal>

        <MainModal
          isOpen={isOpen}
          modalHeader={<ShiftModalHeader />}
          toggle={cancelEventAdd}
          btnOnClick={saveEvent}
          btnSubmitLoading={upsertShiftLoading}
          btnOnCancelClick={cancelEventAdd}
          // className="employee-shifts-popup"
        >
          {/* <span>Make this day off </span>
          <Switch
            checked={tempShift.day_off === 1}
            onChange={handlePopupDayOffSwitchChange}
            color="primary"
            name="checkedB"
            inputProps={{ "aria-label": "primary checkbox" }}
          /> */}

          <h3 className="shift-form-location-title">
            {!schedule?.draft_employees
              ? `${t("location")}: ${
                  schedule?.workGroup?.locations?.find(
                    (location) => location?.id === tempShift?.resource
                  )?.name
                }`
              : tempShift?.resource?.indexOf("team") > -1
                ? `${t("team")}: ${
                    schedule?.teams?.find(
                      (team) => team?.id === tempShift?.resource?.split("-")[1]
                    )?.name
                  }`
                : `${t("employee")}: ${
                    schedule?.employees?.find(
                      (employee) =>
                        employee?.id === tempShift?.resource?.split("-")[1]
                    )?.name
                  }`}
          </h3>

          <h5>
            {t("work timings")}
            <IconButton
              color="success"
              aria-label="Add work timing"
              onClick={addWorkTiming}
              disabled={tempShift?.day_off}
            >
              <AddCircleIcon />
            </IconButton>
          </h5>
          {tempShifts.map((shift, index) => {
            return (
              <>
                {index === 1 ? (
                  <div>
                    <p>{t("alternative work timings")}</p>
                    <hr />
                  </div>
                ) : null}
                <div>
                  <div className="row">
                    <div className="col-6">
                      <BSelect
                        {...formProps}
                        name="popup_work_timing_id"
                        validateBy="textRequired"
                        keepDefaultStyle
                        onChange={(event) =>
                          handlePopupWorkTimingChange(
                            event,
                            shift.id,
                            index !== 0,
                            index
                          )
                        }
                        placeholder={t("select work timing")}
                        value={shift.work_timing?.id ? shift.work_timing : null}
                        options={
                          getUniqueShiftWorktimings(
                            dropDownWorkTimings[shift?.id] ?? [],
                            index,
                            tempShifts
                          ) ?? []
                        }
                        optionLabel="name"
                        optionValue="id"
                        labelStyle="my-2"
                        containerStyle="containerStyle flex-column align-items-start"
                        inputContainerStyle="flex-grow-1 mr-0 w-100"
                        skipLocalization
                        isDisabled={tempShift.day_off == 1}
                        icon="people"
                      />
                    </div>

                    <div className="col-5">
                      <BSelect
                        {...formProps}
                        name="weight"
                        validateBy="textRequired"
                        validationName="input.weight"
                        // label={"Break into"}
                        keepDefaultStyle
                        isMulti={false}
                        onChange={(event) =>
                          handlePopupWeightChange(
                            event,
                            shift.id,
                            index !== 0,
                            index
                          )
                        }
                        options={weightsInitialState}
                        value={shift.weight}
                        optionLabel="name"
                        optionValue="value"
                        labelStyle="my-2"
                        containerStyle="containerStyle flex-column align-items-start"
                        inputContainerStyle="flex-grow-1 mr-0 w-100"
                        skipLocalization
                        isDisabled={tempShift.day_off == 1}
                        icon="type"
                      />
                    </div>
                    <div className="col-1 p-0">
                      {index != 0 ? (
                        <IconButton
                          color="secondary"
                          aria-label="delete"
                          onClick={(event) =>
                            removeWorkTiming(
                              event,
                              shift.id,
                              shift.work_timing_id
                            )
                          }
                        >
                          <DeleteIcon color="warning" />
                        </IconButton>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-2">
                      <span>{t("Allow half day")}</span>
                      <Switch
                        checked={shift?.allow_half_day == 1}
                        onChange={(event) =>
                          handleAllowHalfDayChange(
                            event,
                            shift.id,
                            index !== 0,
                            index
                          )
                        }
                        color="primary"
                        name="checkedB"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </div>
                    <div className="col-5">
                      <BSelect
                        {...formProps}
                        name="popup_work_timing_id_first_half"
                        keepDefaultStyle
                        onChange={(event) =>
                          handlePopupWorkTimingFirstHalfChange(
                            event,
                            shift.id,
                            index !== 0,
                            index
                          )
                        }
                        placeholder={t("select first half work timing")}
                        value={
                          shift.work_timing_first_half?.id
                            ? shift.work_timing_first_half
                            : null
                        }
                        options={schedule?.workGroup?.workTimings}
                        optionLabel="name"
                        optionValue="id"
                        labelStyle="my-2"
                        containerStyle="containerStyle flex-column align-items-start"
                        inputContainerStyle="flex-grow-1 mr-0 w-100"
                        skipLocalization
                        isDisabled={
                          shift?.allow_half_day == 0 || shift?.day_off == 1
                        }
                        icon="people"
                      />
                    </div>
                    <div className="col-5">
                      <BSelect
                        {...formProps}
                        name="popup_work_timing_id_first_half"
                        // validateBy="textRequired"
                        keepDefaultStyle
                        onChange={(event) =>
                          handlePopupWorkTimingSecondHalfChange(
                            event,
                            shift.id,
                            index !== 0,
                            index
                          )
                        }
                        placeholder={t("select second half work timing")}
                        value={
                          shift?.work_timing_second_half?.id
                            ? shift.work_timing_second_half
                            : null
                        }
                        options={schedule?.workGroup?.workTimings}
                        optionLabel="name"
                        optionValue="id"
                        labelStyle="my-2"
                        containerStyle="containerStyle flex-column align-items-start"
                        inputContainerStyle="flex-grow-1 mr-0 w-100"
                        skipLocalization
                        isDisabled={
                          shift?.allow_half_day == 0 || shift?.day_off == 1
                        }
                        icon="people"
                      />
                    </div>
                  </div>
                </div>
              </>
            );
          })}

          {schedule.draft_employees == 0 ? (
            <div className="selectall_dropdown mt-4">
              <span role="button" onClick={handlePopupWorkTeamChangeSelectAll}>
                {t("select all")}
              </span>
              <BSelect
                {...formProps}
                name="work_team_id"
                validateBy="textRequired"
                label={t("employees") + "/" + t("work teams")}
                keepDefaultStyle
                isMulti={true}
                onChange={handlePopupWorkTeamChange}
                options={
                  schedule.employees !== undefined
                    ? schedule.employees.concat(schedule.teams)
                    : []
                }
                value={tempShift.workTeam}
                optionLabel="name"
                optionValue="id"
                labelStyle="my-2"
                containerStyle="containerStyle flex-column align-items-start"
                inputContainerStyle="flex-grow-1 mr-0 w-100"
                skipLocalization
                icon="people"
              />
            </div>
          ) : (
            <BSelect
              {...formProps}
              name="location_id"
              validateBy="textRequired"
              label={"Location"}
              keepDefaultStyle
              isMulti={false}
              onChange={handlePopupLocationChange}
              isDisabled={tempShift.day_off == 1}
              options={workGroup.locations}
              value={tempShift.location}
              optionLabel="name"
              optionValue="id"
              labelStyle="my-2"
              containerStyle="containerStyle flex-column align-items-start"
              inputContainerStyle="flex-grow-1 mr-0 w-100"
              skipLocalization
              icon="people"
            />
          )}
          <BSelect
            {...formProps}
            name="workplace_setting"
            // validateBy="textRequired"
            label={"Employee can additionally sign in From "}
            keepDefaultStyle
            isMulti={false}
            onChange={(event) => handleWorkPlaceSettingChange("signin", event)}
            isDisabled={tempShift.day_off == 1}
            options={workPlaceSettingOptions}
            value={tempShift.workplace_setting}
            optionLabel="name"
            optionValue="id"
            labelStyle="my-2"
            containerStyle="containerStyle flex-column align-items-start"
            inputContainerStyle="flex-grow-1 mr-0 w-100"
            // skipLocalization
            icon="people"
          />
          {tempShift.workplace_setting_type == "DEFAULT" ? (
            <BSelect
              {...formProps}
              name="additional_workplaces"
              // validateBy="textRequired"
              label={"Additional Workplaces"}
              keepDefaultStyle
              isMulti={true}
              onChange={(event) =>
                handleAdditionalWorkplacesChange("signin", event)
              }
              isDisabled={tempShift.day_off == 1}
              options={workGroup.locations}
              value={tempShift.additional_work_places}
              optionLabel="name"
              optionValue="id"
              labelStyle="my-2"
              containerStyle="containerStyle flex-column align-items-start"
              inputContainerStyle="flex-grow-1 mr-0 w-100"
              skipLocalization
              icon="people"
            />
          ) : (
            ""
          )}
          <div className="col-12">
            <span>{t("Apply the same settings when signing out")}</span>
            <Switch
              checked={tempShift.workplace_setting_identical == 1}
              onChange={handleWorkplaceIdenticalChange}
              color="primary"
              name="checkedB"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </div>
          {tempShift.workplace_setting_identical == 0 ? (
            <div>
              <h4>{t("Sign-out location settings")}</h4>
              <BSelect
                {...formProps}
                name="workplace_setting_signout"
                // validateBy="textRequired"
                label={"Employee can additionally sign out From "}
                keepDefaultStyle
                isMulti={false}
                onChange={(event) =>
                  handleWorkPlaceSettingChange("signout", event)
                }
                isDisabled={tempShift.day_off == 1}
                options={workPlaceSettingOptions}
                value={tempShift.workplace_setting_signout}
                optionLabel="name"
                optionValue="id"
                labelStyle="my-2"
                containerStyle="containerStyle flex-column align-items-start"
                inputContainerStyle="flex-grow-1 mr-0 w-100"
                // skipLocalization
                icon="people"
              />
              {tempShift.workplace_setting_signout.id == "DEFAULT" ? (
                <BSelect
                  {...formProps}
                  name="additional_workplaces_signout"
                  // validateBy="textRequired"
                  label={"Additional Workplaces"}
                  keepDefaultStyle
                  isMulti={true}
                  onChange={(event) =>
                    handleAdditionalWorkplacesChange("signout", event)
                  }
                  isDisabled={tempShift.day_off == 1}
                  options={workGroup.locations}
                  value={tempShift.additional_work_places_signout}
                  optionLabel="name"
                  optionValue="id"
                  labelStyle="my-2"
                  containerStyle="containerStyle flex-column align-items-start"
                  inputContainerStyle="flex-grow-1 mr-0 w-100"
                  skipLocalization
                  icon="people"
                />
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}

          {/* {isEdit && <div className="mbsc-button-group">
                <Button className="mbsc-button-block" color="danger" variant="outline" onClick={onDeleteClick}>Delete shift</Button>
            </div>} */}
        </MainModal>
      </div>
    </div>
  );
};

export default CreateWorkSchedulePage;
