import gql from "graphql-tag";

import {
  PlanFragment,
  PaymentFragment,
  paginatorFragment,
  SubscriptionFragment,
} from "../fragments";

/*
  Plans
*/

export const plansListQuery = gql`
  query plansList(
    $first: Int!
    $page: Int
    $name: String
    $duration: Int
    $period: String
    $status: String
    $package_id: Int
    $currency_id: Int
  ) {
    plans: payment_tracking_plans(
      first: $first
      page: $page
      name: $name
      status: $status
      period: $period
      duration: $duration
      package_id: $package_id
      currency_id: $currency_id
    ) {
      data {
        ...plan
      }
      pagination: paginatorInfo {
        ...paginator
      }
    }
  }
  ${PlanFragment}
  ${paginatorFragment}
`;
export const plansListOptionsQuery = gql`
  query plansListOptions {
    currencies {
      id
      name
    }
    packages: plans(first: 9999, input: { type: "active" }) {
      data {
        id
        name
      }
    }
  }
`;

/*
  Subscriptions
*/

export const subscriptionsListOptionsQuery = gql`
  query subscriptionsListOptions {
    companies: saasCompaniesMenu {
      id
      name
    }
    packages: plans(first: 9999, input: { type: "active" }) {
      data {
        id
        name
      }
    }
    countries {
      id
      name
    }
  }
`;
export const subscriptionsListQuery = gql`
  query subscriptionsList(
    $page: Int
    $first: Int!
    $status: String
    $period: String
    $company_id: Int
    $package_id: Int
  ) {
    subscriptions: payment_tracking_subscriptions(
      page: $page
      first: $first
      status: $status
      period: $period
      package_id: $package_id
      company_id: $company_id
    ) {
      data {
        ...subscription
        payments {
          ...payment
        }
      }
      pagination: paginatorInfo {
        ...paginator
      }
    }
  }
  ${PaymentFragment}
  ${paginatorFragment}
  ${SubscriptionFragment}
`;

export const subscriptionFormQuery = gql`
  query subscriptionForm($id: ID, $isEdit: Boolean! = false) {
    subscription: payment_tracking_subscription(id: $id) @include(if: $isEdit) {
      ...subscription
    }

    # Options
    countries {
      id
      name
    }
    plans: payment_tracking_plans(first: 999) {
      data {
        id
        name
        configuration
        billingPeriod: period
        contractDuration: duration
        currency {
          name
        }
      }
    }
  }
  ${SubscriptionFragment}
`;

/*
  Subscription Profile
*/

export const subscriptionProfileListQuery = gql`
  query subscriptionProfileList($id: ID) {
    data: payment_tracking_subscription(id: $id) {
      ...subscription
      isEnded
      history
      payments {
        ...payment
      }
    }
  }
  ${PaymentFragment}
  ${SubscriptionFragment}
`;

export const getCurrentPlanIDQuery = gql`
  query getCurrentPlanID($from: String, $company_id: Int, $date: String) {
    planID: payment_tracking_plan_at(
      from: $from
      date: $date
      company_id: $company_id
    )
  }
`;

export const changePlanModalQuery = gql`
  query changePlanModal {
    # Options
    plans: payment_tracking_plans(first: 999) {
      data {
        id
        name
        configuration
        billingPeriod: period
        contractDuration: duration
        currency {
          name
        }
      }
    }
  }
`;

export const changeTiersModalQuery = gql`
  query changeTiersModal(
    $from: String
    $date: String
    $type: String
    $company_id: Int
  ) {
    # Options
    tiers: payment_tracking_tiers(
      from: $from
      date: $date
      type: $type
      company_id: $company_id
    )
  }
`;

export const endSubscriptionQuery = gql`
  query endSubscriptionModal($to: String, $from: String, $companyId: Int) {
    data: payment_tracking_end_subscription(
      to: $to
      from: $from
      companyId: $companyId
    ) {
      dueAmount
      paidAmount
      remainingAmount
      paidItems {
        name
        amount
      }
      dueItems {
        name
        amount
      }
    }
  }
`;

/*
  Payments
*/

export const paymentsListOptionsQuery = gql`
  query paymentsListOptions {
    plans: payment_tracking_plans(first: 999) {
      data {
        id
        name
      }
    }
    companies: saasCompaniesMenu {
      id
      name
    }
  }
`;
export const paymentsListQuery = gql`
  query paymentsList(
    $page: Int
    $first: Int!
    $plan_id: Int
    $status: String
    $due_to: String
    $company_id: Int
    $due_from: String
  ) {
    payments: payment_tracking_payments(
      page: $page
      first: $first
      status: $status
      due_to: $due_to
      plan_id: $plan_id
      due_from: $due_from
      company_id: $company_id
    ) {
      data {
        ...payment
        company_id
      }
      pagination: paginatorInfo {
        ...paginator
      }
    }
  }
  ${PaymentFragment}
  ${paginatorFragment}
`;

export const editPaymentFormQuery = gql`
  query editPaymentForm($id: ID) {
    payment: payment_tracking_payment(id: $id) {
      method
      due_date
      firstItem
      tax {
        type
        amount
        isApplied
      }
    }

    # Options
    countries {
      id
      name
    }
  }
`;

export const payInvoiceFormQuery = gql`
  query payInvoiceForm($id: ID) {
    payment: payment_tracking_payment(id: $id) {
      method
      amount
      receivedAmount
      received_at
      file {
        id
        path
      }
      collector {
        id
        name
      }
    }

    # Options
    users: payment_tracking_saas {
      id
      name
    }
  }
`;

export const paymentsInvoiceDetailsQuery = gql`
  query paymentsInvoiceDetails($id: ID) {
    payment: payment_tracking_payment(id: $id) {
      invoiceInfo
      invoice
      status
    }
  }
`;
