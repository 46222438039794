import Types from "../Actions/types";

export const initState = {
  companyDocsForm: {
    required_documents: [],
    optional_documents: [],
  },
  rowDocsForm: {
    officeDocsForm: [
      // {
      //   documentable: "",
      //   required_documents: [],
      //   optional_documents: [],
      //   showEdit : false,
      //  isLoading: false
      // },
    ],
    departmentDocsForm: [],
    employeesDocsForm: [],
    positionsDocsForm: [],
  },
  upsertDocModal: {
    id: null,
    name: "",
    country_id: null,
    exp_date: 0,
    issuing_date: 0,
    attachment: 0,
    id_number: 0,
    // fields: [],
  },
  upsertDocModalActions: {
    isVissible: false,
    isLoading: false,
    modalMessage: "",
  },
  required_documents: {
    isVissible: false,
    isLoading: false,
  },

  optionalActions: {
    isVissible: false,
    isLoading: false,
  },
  countries: [],
  company_offices: [],
  company_departments: [],
  company_positions: [],
  employees_for_document: [],
  upsertDocModalValidation: [],
  companyRequiredDocuments: [],
  companyOptionalDocuments: [],
  documentsListOptions: [],
  documentsListActions: {
    isLoading: false,
  },

  // Required Documents
  required_documents: {
    isLoading: false,
    filterByEmployee: "",
    filterByDocument: "",
    missingDocuments: [],
    expiredDocuments: [],
    employeesListOptions: [],
    documentPresetsOptions: [],
  },

  upsertDocsHistory: [],
  historyDocumentsModal: {
    employee_id: "",
    document_id: "",
  },
  historyDocumentsModalActions: {
    isVissible: false,
    isLoading: false,
    modalMessage: "",
  },
};

export const documentsReducer = (state = initState, action) => {
  switch (action.type) {
    case Types.SHOW_COMPANY_DOCUMENT_MODAL:
    case Types.SHOW_MAIN_MODAL_WITH_DATA:
      return {
        ...state,
        [action.payload.ref]: {
          isVissible: true,
          isLoading: false,
          modalMessage: "",
        },
        [action.payload.formName]: {
          ...state[action.payload.formName],
          ...action.payload.data,
        },
      };

    case Types.UPSERT_DOCUMENT_ATTEMPT:
    case Types.FETCH_DOCUMENTS_LIST_ATTEMPT:
    case Types.STORE_DOCUMENTABLE_ATTEMPT:
    case Types.FETCH_HISTORY_DOC_MODAL_ATTEMPT:
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: true,
        },
      };

    case Types.HIDE_COMPANY_DOCUMENT_MODAL:
    case Types.HIDE_MAIN_MODAL_WITH_DATA:
      return {
        ...state,
        [action.payload.ref]: {
          isVissible: false,
          isLoading: false,
          modalMessage: "",
        },
        [action.payload.formName]: initState[action.payload.formName],
      };

    case Types.UPSERT_DOCUMENT_FAILED:
    case Types.FETCH_DOCUMENTS_LIST_FAILED:
    case Types.FETCH_HISTORY_DOC_MODAL_FAILED:
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: false,
          modalMessage: action.payload.message,
        },
      };

    case Types.STORE_DOCUMENTABLE_FAILED:
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: false,
        },
      };

    case Types.FETCH_DOCUMENTS_LIST_FAILED:
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: false,
          modalMessage: action.payload.message,
        },
      };

    case Types.UPSERT_DOCUMENT_SUCCESS:
    case Types.STORE_DOCUMENTABLE_SUCCESS:
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: false,
          isVissible: false,
        },
        [action.payload.formName]: action?.payload?.data,
      };

    case Types.FETCH_HISTORY_DOC_MODAL_SUCCESS:
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: false,
        },
        [action.payload.formName]: action?.payload?.data,
      };

    case Types.SHOW_DOCUMENTS_EDIT:
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isVissible: true,
        },
      };

    case Types.HIDE_DOCUMENTS_EDIT:
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isVissible: false,
        },
      };

    case Types.FETCH_DOCUMENTS_LIST_SUCCESS:
      const {
        requiredDocuments = [],
        optionalDocuments = [],
        documentPresets = [],
      } = action?.payload?.data?.profile?.company;

      // Extract Data
      const handleFetchedCompany = (ref) => action?.payload?.data?.[ref]?.data;

      // (Start) Office
      const fetchedCompanyOffices = handleFetchedCompany("company_offices");
      const companyOfficesHaveDocs = fetchedCompanyOffices?.filter(
        (office) =>
          office.requiredDocuments?.length || office.optionalDocuments?.length
      );
      // (End) Office

      // (Start) Department
      const fetchedCompanyDepartments = handleFetchedCompany(
        "company_departments"
      );
      const companyDepartmentsHaveDocs = fetchedCompanyDepartments?.filter(
        (department) =>
          department.requiredDocuments?.length ||
          department.optionalDocuments?.length
      );
      // (End) Department

      // (Start) Positions
      const fetchedCompanyPositions = handleFetchedCompany("company_positions");
      const companyPositionsHaveDocs = fetchedCompanyPositions?.filter(
        (positions) =>
          positions.requiredDocuments?.length ||
          positions.optionalDocuments?.length
      );
      // (End) Positions

      // (Start) Employees
      var fetchedCompanyEmployees = handleFetchedCompany(
        "employees_for_document"
      );
      var companyEmployeesHaveDocs = fetchedCompanyEmployees?.filter(
        (employee) =>
          employee.requiredDocuments?.length ||
          employee.optionalDocuments?.length
      );
      // (End) Employees

      return {
        ...state,
        documentsListActions: {
          ...state.documentsListActions,
          isLoading: false,
        },

        companyRequiredDocuments: requiredDocuments,
        companyOptionalDocuments: optionalDocuments,

        documentsListOptions: documentPresets,
        companyDocsForm: {
          required_documents: requiredDocuments?.map((doc) => doc.id),
          optional_documents: optionalDocuments?.map((doc) => doc.id),
        },
        countries: action?.payload?.data?.countries,
        company_offices: fetchedCompanyOffices,
        company_departments: fetchedCompanyDepartments,
        company_positions: fetchedCompanyPositions,
        employees_for_document: fetchedCompanyEmployees?.map((employee) => ({
          id: employee.user.id,
          name: employee.user.name,
          requiredDocuments: employee.requiredDocuments,
          optionalDocuments: employee.optionalDocuments,
        })),
        rowDocsForm: {
          ...state.rowDocsForm,
          officeDocsForm: companyOfficesHaveDocs?.map((item) => ({
            documentable: item.id,
            required_documents: item.requiredDocuments?.map((doc) => doc.id),
            optional_documents: item.optionalDocuments?.map((doc) => doc.id),
            showEdit: false,
            isLoading: false,
          })),
          departmentDocsForm: companyDepartmentsHaveDocs?.map((item) => ({
            documentable: item.id,
            required_documents: item.requiredDocuments?.map((doc) => doc.id),
            optional_documents: item.optionalDocuments?.map((doc) => doc.id),
            showEdit: false,
            isLoading: false,
          })),
          employeesDocsForm: companyEmployeesHaveDocs?.map((item) => ({
            documentable: item.user.id,
            required_documents: item.requiredDocuments?.map((doc) => doc.id),
            optional_documents: item.optionalDocuments?.map((doc) => doc.id),
            showEdit: false,
            isLoading: false,
          })),
          positionsDocsForm: companyPositionsHaveDocs?.map((item) => ({
            documentable: item.id,
            required_documents: item.requiredDocuments?.map((doc) => doc.id),
            optional_documents: item.optionalDocuments?.map((doc) => doc.id),
            showEdit: false,
            isLoading: false,
          })),
        },
      };

    // handle children input
    case Types.ON_CHILD_INPUT_CHANGE_DOCUMENTS:
    case Types.STORE_DOCUMENTABLE_ROW_ATTEMPT:
    case Types.ON_CHILD_INPUT_CHANGE_DOCUMENTS_MULTIPLE:
      let newValues = [
        ...state[action.payload.formName][action.payload.childName],
      ];
      if (Array.isArray(action.payload.name)) {
        for (let name of action.payload.name) {
          newValues[action.payload.index][name] = action.payload.value[name];
        }
      } else {
        newValues[action.payload.index][action.payload.name] =
          action.payload.value;
      }
      return {
        ...state,
        [action.payload.formName]: {
          ...state[action.payload.formName],
          [action.payload.childName]: newValues,
        },
      };

    // handle children row reset
    case Types.ON_RESET_DOCUMENTS_ROW:
      let newRow = [
        ...state[action.payload.formName][action.payload.childName],
      ];
      newRow[action.payload.index] = action.payload.value;
      return {
        ...state,
        [action.payload.formName]: {
          ...state[action.payload.formName],
          [action.payload.childName]: newRow,
        },
      };    

    case Types.HANDLE_ROW_DOCUMENT:
      const intialRow = {
        documentable: "",
        required_documents: [],
        optional_documents: [],
        showEdit: true,
        isLoading: false,
      };

      let newData;

      if (action.payload?.action === "add") {
        newData = [
          ...state[action.payload.formName][action.payload.childName],
          intialRow,
        ];
      } else if (action.payload?.action === "delete") {
        newData = state[action.payload.formName][
          action.payload.childName
        ]?.filter((item, index) => index !== action.payload?.index);
      }
      return {
        ...state,
        [action.payload.formName]: {
          ...state[action.payload.formName],
          [action.payload.childName]: newData,
        },
      };

    case Types.UPDATE_COMPANY_ROW_DOCS:
      let newRowData = [...state[action.payload.fetchedCompanyRef]];
      const rowIndex = newRowData?.findIndex(
        (row) => row.documentable === action.payload.documentable
      );

      newRowData?.splice(rowIndex, 1, {
        // Clone Old Data
        ...state[action.payload.fetchedCompanyRef][rowIndex],
        // Replace Required Documents
        requiredDocuments: action.payload.requiredDocuments,
        // Replace Optional Documents
        optionalDocuments: action.payload.optionalDocuments,
      });
      return {
        ...state,
        [action.payload.fetchedCompanyRef]: newRowData,
      };

    case Types.FETCH_REQUIRED_DOCUMENTS_ATTEMPT:
      return {
        ...state,
        required_documents: {
          ...state.required_documents,
          isLoading: true,
        },
      };

    case Types.FETCH_REQUIRED_DOCUMENTS_FAILED:
      return {
        ...state,
        required_documents: {
          ...state.required_documents,
          isLoading: false,
        },
      };

    case Types.FETCH_REQUIRED_DOCUMENTS_SUCCESS:
      // (Start) Employees
      var fetchedCompanyEmployees =
        action?.payload?.data?.employees_for_document?.data?.map(
          (employee) => employee.user
        );
      // (Note) To Enable filter add requiredDocuments and optionalDocuments to fetch query and uncomment next lines
      // var companyEmployeesHaveDocs = fetchedCompanyEmployees?.filter(
      //   (employee) =>
      //     employee.requiredDocuments?.length ||
      //     employee.optionalDocuments?.length
      // );
      // (End) Employees

      // (Start) Document Presets
      const documentPresetsOptions =
        action?.payload?.data?.profile?.company?.documentPresets;
      // (End) Document Presets

      return {
        ...state,
        required_documents: {
          ...state.required_documents,
          isLoading: false,
          missingDocuments: action?.payload?.missingDocuments,
          expiredDocuments: action?.payload?.expiredDocuments,
          employeesListOptions: fetchedCompanyEmployees,
          documentPresetsOptions,
        },
      };

    default:
      return state;
  }
};
