import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { InputForm, CheckboxBooleanForm } from "form-builder";

// not in reducer
const PermissionSection = ({ formProps }) => {
  const { t } = useTranslation();

  // redux hooks
  const leavesBreaksForm = useSelector((state) => state.super.leavesBreaksForm);

  return (
    <>
      <CheckboxBooleanForm
        {...formProps}
        options={["Allow Employees To Requets Permissions"]}
        name="allow_permissions"
        validationName="input.permission_setting.allow_permissions"
        type="checkbox"
        containerStyle="leaves_checkbox_container"
      />
      <div className="sub-container-style">
        <CheckboxBooleanForm
          {...formProps}
          options={["Employee must submit permission request before the start of work day"]}
          name="allow_permission_cutoff"
          validationName="input.permission_setting.allow_permission_cutoff"
          type="checkbox"
          containerStyle="leaves_checkbox_container mb-2"
          dependOn="allow_permissions"
          dependancyType="equal"
          dependancyValue={[1]}
          setWithValue
        />

        <InputForm
          {...formProps}
          validateBy="textRequired"
          label="Employee must submit permission request before the start of work day by"
          name="permission_cutoff"
          validationName="input.permission_setting.permission_cutoff"
          type="text"
          hasSuffix
          suffixTitle="Hours"
          containerStyle="leaves_field_container my-2"
          inputContainerStyle="d-flex align-items-center"
          dependOn="allow_permission_cutoff"
          dependancyType="equal"
          dependancyValue={[true]}
        />

        <InputForm
          {...formProps}
          dependOn="allow_permissions"
          dependancyType="equal"
          dependancyValue={[1]}
          validateBy="textRequired"
          label="Minimum Permission Duration"
          name="min_permission_unit"
          validationName="input.permission_setting.min_permission_unit"
          type="text"
          hasSuffix
          suffixTitle="Hours"
          containerStyle="leaves_field_container mt-2"
          inputContainerStyle="d-flex align-items-center"
        />

        <InputForm
          {...formProps}
          dependOn="allow_permissions"
          dependancyType="equal"
          dependancyValue={[1]}
          validateBy="textRequired"
          label="Maximum No. Of Permission Request Per Day"
          name="max_units_per_day"
          validationName="input.permission_setting.max_units_per_day"
          type="text"
          hasSuffix
          suffixTitle={`${(
            Number(leavesBreaksForm?.max_units_per_day) *
            Number(leavesBreaksForm?.min_permission_unit)
          ).toFixed(2)}  ${t("hours/day")}`}
          suffixStyle="seperate-suffix text-center"
          containerStyle="leaves_field_container mt-3"
          inputContainerStyle="d-flex align-items-center"
          inputStyle="suffix-input separated-input"
        />

        <InputForm
          {...formProps}
          dependOn="allow_permissions"
          dependancyType="equal"
          dependancyValue={[1]}
          validateBy="textRequired"
          label="Maximum No. Of Permissions Per Month"
          name="max_units_per_month"
          validationName="input.permission_setting.max_units_per_month"
          type="text"
          hasSuffix
          suffixTitle={`${
            isNaN(
              Number(leavesBreaksForm?.max_units_per_month) *
                Number(leavesBreaksForm?.min_permission_unit)
            )
              ? " "
              : (
                  Number(leavesBreaksForm?.max_units_per_month) *
                  Number(leavesBreaksForm?.min_permission_unit)
                ).toFixed(2)
          } ${t("hours/month")}`}
          suffixStyle="seperate-suffix text-center"
          containerStyle="leaves_field_container mt-3"
          inputContainerStyle="d-flex align-items-center"
          inputStyle="suffix-input separated-input"
        />
      </div>
    </>
  );
};

export default PermissionSection;
