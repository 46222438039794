import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  resetAttProfileFormAction,
  fetchAttendanceProfileAction,
  upsertAttendanceProfileAction,
} from "../../Store/Actions";
import HelperFns from "../../Helpers/HelperFns";

import { Spinner } from "reactstrap";
import CheckInsSection from "./CheckInsSection";
import EditAttSignInSection from "./EditAttSignInSection";
import WorkTimingForm from "../../Routes/WorkTimingsListPage/WorkTimingForm";
import LeavesBreaksSection from "../../Components/AttProfileSections/LeavesBreaksSection";
import EditWorkTimePreview from "../../Components/AttProfileSections/EditWorkTimePreview";
import AttWorkingDaysSection from "../../Components/AttProfileSections/AttWorkingDaysSection";
import AttOfficeHolidaySection from "../../Components/AttProfileSections/AttOfficeHolidaySection";
import Swal from "sweetalert2";

const formName = "attendaceProfile";
const formNameValidation = "attProfileWorkdaysValidation";
const formServerValidation = "attProfileNormalServerValidation";
const topFunction = () => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
};

const AttendanceProfileForm = (props) => {
  const params = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Local State
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Reducer State
  const isLoading = useSelector((state) => state.super.upsertAttProfileLoading);
  const attProfileNormalServerValidation = useSelector(
    (state) => state.super[formServerValidation]
  );
  const attendanceProfile = useSelector((state) => state.super[formName]);
  const attProfileWorkdaysValidation = useSelector(
    (state) => state.super[formNameValidation]
  );
  const attProfileSignInValidation = useSelector(
    (state) => state.super.attProfileSignInValidation
  );

  // Constants
  const attId = props?.isSetupWizard ? props?.wizardProfileId : params.attId;
  const commonProps = {
    formName,
    formNameValidation,
    formServerValidation,
    formSubmitting: isSubmitting,
  };

  /* ↓ State Effects ↓ */

  React.useEffect(() => {
    dispatch(fetchAttendanceProfileAction(attId));

    return () => {
      dispatch(resetAttProfileFormAction());
    };
  }, []);

  /* ↓ Helpers ↓ */

  const handleSubmit = () => {
    setIsSubmitting(true);
    if (
      !attProfileWorkdaysValidation.length &&
      !attProfileSignInValidation.length
    ) {
      const isSameConfig =
        JSON.stringify(
          attendanceProfile?.attendance_profile_work_timing_input
        ) ===
        JSON.stringify(
          attendanceProfile?.old_attendance_profile_work_timing_input
        );

      if (attendanceProfile?.id && !isSameConfig && !props?.isSetupWizard) {
        Swal.fire({
          icon: "warning",
          title: t("are you sure"),
          text: t(
            "As a result of updating the attendance type configuration, all requests related to upcoming dates will be deleted as well as all ongoing leaves will end at the end of the previous configuration And any leaves accepted leaves past this date will need to be resubmitted"
          ),
          showConfirmButton: true,
          confirmButtonText: t("Yes"),
          confirmButtonColor: "#7cd1f9",
          showDenyButton: true,
          denyButtonText: t("No"),
          showCancelButton: false,
          customClass: {
            popup: "swal-warning-style",
            icon: "swal-icon",
            denyButton: "order-2",
            confirmButton: "order-3",
            htmlContainer: "attendance-type-alert-text",
          },
        }).then((values) => {
          if (values.isConfirmed) {
            dispatch(
              upsertAttendanceProfileAction({
                normal:
                  HelperFns.serializedSubmitedAttProfile(attendanceProfile),
                isSetupWizard: props?.isSetupWizard,
              })
            );
          }
        });
      } else {
        dispatch(
          upsertAttendanceProfileAction({
            normal: HelperFns.serializedSubmitedAttProfile(attendanceProfile),
            isSetupWizard: props?.isSetupWizard,
          })
        );
      }
    }
    !props?.isSetupWizard && topFunction();
  };

  return (
    <div className="edit_attendance_profile">
      {attProfileNormalServerValidation?.["input.id"] ? (
        <p className="error-color mt-2 mb-0">
          {attProfileNormalServerValidation?.["input.id"]}
        </p>
      ) : null}

      <AttWorkingDaysSection formProps={commonProps} />
      <LeavesBreaksSection formProps={commonProps} />
      <EditAttSignInSection formProps={commonProps} submitting={isSubmitting} />
      <CheckInsSection formProps={commonProps} />
      <AttOfficeHolidaySection formProps={commonProps} />

      {attProfileNormalServerValidation?.["input.id"] ? (
        <p className="border-1 border-bottom border-danger error-color fit-width mb-0 mt-2">
          {attProfileNormalServerValidation?.["input.id"]}
        </p>
      ) : null}

      <div className="mb-1 text-right">
        <button
          type="submit"
          onClick={handleSubmit}
          className="save-button"
          disabled={isLoading}
        >
          {isLoading ? (
            <Spinner style={{ width: "1rem", height: "1rem", color: "#fff" }} />
          ) : (
            t("save")
          )}
        </button>
      </div>

      <EditWorkTimePreview />
      <WorkTimingForm inAttProfile={true} />
    </div>
  );
};

export default AttendanceProfileForm;
