import React from "react";
import { useRouteMatch } from "react-router-dom";

import Privileges from "../../Constants/Privilages";
import { PaymentTrackingSidebarPrivileges } from "../../Constants/Nav";

import PaymentTrackingPage from "./PaymentTrackingPage";
import SubscriptionProfile from "./SubscriptionProfile";
import ProtectedRouter from "../../Helpers/HOC/ProtectedRouter";

const PaymentTrackingRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <ProtectedRouter
        exact
        allowBP
        path={path}
        component={PaymentTrackingPage}
        privileges={PaymentTrackingSidebarPrivileges}
      />
      <ProtectedRouter
        exact
        allowBP
        path={`${path}/subscription/:id`}
        component={SubscriptionProfile}
        privileges={[Privileges.VIEW_PLANS]} // TODO: Fix page privileges
      />
    </>
  );
};

export default PaymentTrackingRoutes;
