import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Drawer,
  FormControlLabel,
  styled,
  Tooltip,
} from "@mui/material";
import { DateTimePickerForm, BSelect, CheckboxBooleanForm } from "form-builder";
import "./FiltersDrawerStyle.scss";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Const from "../../Constants";
import { pink } from "@mui/material/colors";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import NewMultiSelect from "./NewMultiSelect";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { updateValueAction } from "../../Store/Actions";
import { ToggleActive } from "../../Containers/AttendanceLogsList";

const FiltersDrawer = ({
  dataTableRef,
  isFilterDrawerOpen,
  toggleDrawer,
  onFilterReset,
  onFilterSubmit,
  handelAddToAplliedFiltersList,
  ...props
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const allOffices = useSelector((state) => state.super.allOffices);
  const allWorkTimings = useSelector((state) => state.super.allWorkTimings);
  const attendanceLogsList = useSelector((state) => state.super[dataTableRef]);
  const refetchFromSagaToHook = useSelector(
    (state) => state.super.refetchFromSagaToHook
  );
  const adjustSignOutState = useSelector(
    (state) => state.super.adjustSignOutState
  );
  const logsEmployeesFilter = useSelector(
    (state) => state.super.logsEmployeesFilter
  );
  const allDepartmentsByCompany = useSelector(
    (state) => state.super.allDepartmentsByCompany
  );

  const allPositions = useSelector((state) => state.super.allPositions);

  const UncheckedIcon = () => (
    <div
      style={{
        width: 16,
        height: 16,
        padding: 1.5,
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "#E9EFF7",
          borderRadius: 3,
          margin: 0,
          border: 0.5,
          borderColor: "#D7DEDD",
        }}
      />
    </div>
  );

  const FilterCheckBox = ({ label, formName, name, value }) => {
    const handelChange = (e) => {
      dispatch(updateValueAction(formName, name, e?.target?.checked));
    };
    return (
      <>
        <FormControlLabel
          control={
            <Checkbox
              sx={{
                margin: 0,
                padding: 0,
                marginRight: 1,
              }}
              icon={<UncheckedIcon />}
              checkedIcon={
                <CheckBoxIcon sx={{ color: "#009EFB", fontSize: 16 }} />
              }
              onChange={handelChange}
              name={name}
              checked={value}
            />
          }
          label={label}
          sx={{
            "& .MuiFormControlLabel-label": {
              fontSize: 13,
              color: value ? "#313030" : "#57585A",
              fontWeight: "400",
            },
          }}
        />
      </>
    );
  };

  const filteredEmployeesOptions = logsEmployeesFilter?.filter((emp) => {
    if (!props.isActive) return true; // return all the employees
    return props.isActive == Boolean(emp?.active);
  });

  return (
    <Drawer
      anchor={"right"}
      open={isFilterDrawerOpen}
      onClose={onFilterSubmit}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: "rgba(87, 88, 90, 0.2)",
          },
        },
      }}
    >
      <div className="log-list-filters-drawer pb-5 d-flex flex-column gap-1 h-100 position-relative">
        <div className="d-flex justify-content-between align-items-center py-4 px-3">
          <label className="filters-header">{t("Filter Properties")}</label>
          <Button onClick={toggleDrawer(false)} className="px-0">
            <CloseIcon fontSize="small" sx={{ color: "#70bdea" }} />
          </Button>
        </div>
        <div className="px-4 d-flex flex-column gap-1">
          <div className="d-flex gap-1">
            <DateTimePickerForm
              name="from"
              formName={dataTableRef}
              label={t("from")}
              hasIcon
              reducer="super"
              rootStyle="flex-fill"
              datePickerContainer="w-100"
              labelStyle="date_picker_label"
              placeholder={t("select date")}
              isLoading={attendanceLogsList.isLoading}
              allowClear
              onIntercept={handelAddToAplliedFiltersList}
            />

            <DateTimePickerForm
              name="to"
              formName={dataTableRef}
              label={t("to")}
              hasIcon
              reducer="super"
              rootStyle="flex-fill"
              datePickerContainer="w-100"
              labelStyle="date_picker_label"
              placeholder={t("select date")}
              isLoading={attendanceLogsList.isLoading}
              allowClear
            />
          </div>

          <div className="position-relative">
            <div className="position-absolute active-toogler-in-logs-filters">
              <ToggleActive
                isActive={props.isActive}
                isLoading={props.isLoading}
                onEmpDataToggle={props.onEmpDataToggle}
              />
            </div>
            <NewMultiSelect
              isLoading={attendanceLogsList.isEmpLoading}
              options={
                attendanceLogsList.isEmpLoading ? [] : filteredEmployeesOptions
              }
              allOptions={logsEmployeesFilter}
              value={attendanceLogsList?.employees}
              dataTableRef={dataTableRef}
              name="employees"
              label={t("employees")}
              icon="people"
              placeHolder={t("employees")}
            />
          </div>

          <NewMultiSelect
            isLoading={attendanceLogsList.isLoading}
            options={allPositions || []}
            value={attendanceLogsList?.positions}
            dataTableRef={dataTableRef}
            name="positions"
            label={t("positions")}
            icon="person"
            placeHolder={t("positions")}
          />

          <NewMultiSelect
            options={Const.signInStatusOptions}
            value={attendanceLogsList?.status}
            dataTableRef={dataTableRef}
            name="status"
            label={t("status")}
            icon="list"
            placeHolder={t("status")}
            optionLabel="value"
            optionValue="value"
          />

          <NewMultiSelect
            options={allWorkTimings}
            isLoading={attendanceLogsList.isLoading}
            value={attendanceLogsList?.work_timings}
            dataTableRef={dataTableRef}
            name="work_timings"
            label={t("work timings")}
            icon="calendar"
            placeHolder={t("work timings")}
          />

          <NewMultiSelect
            options={allDepartmentsByCompany}
            isLoading={attendanceLogsList.isLoading}
            value={attendanceLogsList?.departments}
            dataTableRef={dataTableRef}
            name="departments"
            label={t("departments")}
            icon="building"
            placeHolder={t("departments")}
          />

          <NewMultiSelect
            options={allOffices}
            isLoading={attendanceLogsList.isLoading}
            value={attendanceLogsList?.offices}
            dataTableRef={dataTableRef}
            name="offices"
            label={t("offices")}
            icon="office"
            placeHolder={t("offices")}
          />
        </div>
        <div className="check-box-container-in-filters">
          <FilterCheckBox
            label={t("Only show employees I manage directly")}
            formName={dataTableRef}
            name="direct_managed"
            value={attendanceLogsList?.direct_managed}
          />

          <FilterCheckBox
            label={t("Only show records with no sign-out time")}
            formName={dataTableRef}
            name="missing_sign_out"
            value={attendanceLogsList?.missing_sign_out}
          />

          <FilterCheckBox
            label={t("Attention Required!")}
            formName={dataTableRef}
            name="attendance_configuration_conflict"
            value={attendanceLogsList?.attendance_configuration_conflict}
          />
        </div>

        <div className="d-flex align-items-center position-fixed btns-container-logs-filter">
          <div className="apply-btn" onClick={onFilterSubmit}>
            {t("apply")}
          </div>
          <div className="reset-btn" onClick={onFilterReset}>
            {t("reset")}
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default FiltersDrawer;
