import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";

import {
  updateValueAction,
  onFormResetAction,
  onInputResetAction,
  toggleModalDisplayAction,
  onInputResetWithValueAction,
  fetchAssignmentsAttempt,
  upsertAssignmentFailed,
  upsertAssignmentSuccess,
  showErrorToast,
  resetAssignmentModalMessage,
} from "../../Store/Actions";
import HelperFns, { showToast } from "../../Helpers/HelperFns";
import Constants, { assignmentTypesConstants } from "../../Constants";
import Privilages from "../../Constants/Privilages";
import { components } from "react-select/dist/react-select.cjs.prod";

import {
  BSelect,
  InputForm,
  RadioboxForm,
  DateTimePickerForm,
  CheckboxBooleanForm,
} from "form-builder";
import {
  AssignmentPreviewNamePart,
  AssignmentPreviewCanEditPart,
} from "./shared";
import Loader from "../../Components/Loader";
import MainModal from "../../Components/MainModal";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import { WorkPlaces } from "../../Components/SharedFormSections/DayOffException";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { upsertAssignmentMutation } from "../../Graphql/mutation";
import moment from "moment";
import {
  GET_WORKING_TIMMING_OPTIONS_QUERY,
  applicableWorktimingsQuery,
} from "../../Graphql/query";
import { Spinner } from "reactstrap";
import _ from "lodash";

const assignmentTypes = [
  {
    label: "normal work day",
    value: assignmentTypesConstants.NORMAL_WORK_DAY,
  },
  {
    label: "exceptional shift",
    value: assignmentTypesConstants.EXCEPTIONAL_SHIFT,
  },
  {
    label: "day off",
    value: assignmentTypesConstants.DAY_OFF,
  },
  {
    label: "holiday",
    value: assignmentTypesConstants.HOLIDAY,
  },
];

const UpsertAssignmentModal = ({
  isAssignemntsList = false,
  fetchCalendarData = () => undefined,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Local State
  const [formSubmitting, setFormSubmitting] = useState(false);

  // Reducer State
  const formInputs = useSelector(
    (state) => state.assignments.upsertAssignmentForm
  );
  const fetchAssignmentLoading = useSelector(
    (state) => state.assignments?.fetchAssignmentLoading
  );

  const { isVissible, isLoading, modalMessage } = useSelector(
    (state) => state.assignments.upsertAssignmentModal
  );
  const formValidations = useSelector(
    (state) => state.assignments.upsertAssignmentFormValidation
  );

  // Constants
  const formProps = {
    formName: "upsertAssignmentForm",
    formNameValidation: "upsertAssignmentFormValidation",
    formServerValidation: "upsertAssignmentFormServerValidation",
    formSubmitting,
    reducer: "assignments",
  };

  /* ↓ State Effects ↓ */

  useEffect(() => {
    dispatch(onFormResetAction(formProps.formNameValidation));
    dispatch(onFormResetAction(formProps.formServerValidation));
    if (!isVissible) {
      dispatch(onFormResetAction("upsertAssignmentModal"));
      dispatch(onFormResetAction(formProps.formNameValidation));
      dispatch(onFormResetAction(formProps.formServerValidation));
      dispatch(onFormResetAction(formProps.formName));
    }
  }, [isVissible]);

  const handleResetInLieuOfWorkday = () => {
    dispatch(
      onInputResetAction(formProps.formName, "inLieuOfWorkdayRequestable")
    );
    dispatch(
      onInputResetAction(formProps.formName, "inLieuOfWorkdayRequestableObject")
    );
    dispatch(onInputResetAction(formProps.formName, "compensation"));
  };

  useEffect(() => {
    if (!formInputs?.to) {
      dispatch(
        onInputResetWithValueAction(formProps.formName, "to", formInputs?.from)
      );
    }

    if (!formInputs?.payable_at) {
      dispatch(
        onInputResetWithValueAction(
          formProps.formName,
          "payable_at",
          formInputs?.from
        )
      );
    }
  }, [formInputs?.from]);

  useEffect(() => {
    if (!formInputs?.payable_at) {
      dispatch(
        onInputResetWithValueAction(
          formProps.formName,
          "payable_at",
          formInputs?.to
        )
      );
    }
  }, [formInputs?.to]);

  useEffect(() => {
    setFormSubmitting(false);
    return () => {
      setFormSubmitting(false);
      dispatch(onFormResetAction(formProps.formName));
    };
  }, []);

  useEffect(() => {
    if (formInputs?.assignmentType == assignmentTypesConstants.DAY_OFF) {
      dispatch(onInputResetAction(formProps.formName, "no_compensation"));
    }
  }, [formInputs?.assignmentType]);

  /* ↓ Helpers ↓ */

  const handleClosingAssignmentModal = () => {
    dispatch(toggleModalDisplayAction(false));
  };

  const assignmentsListsFilters = useSelector(
    (state) => state.assignments?.assignmentFilters
  );

  const [attemptSubmitAssignment, { loading: upsertAssignmentLoadingg }] =
    useMutation(upsertAssignmentMutation, {
      variables: {
        input: HelperFns.serializeUpsertingAssignment(formInputs),
      },
      onCompleted: (data) => {
        if (
          data?.updateOrCreateAssignment.__typename.toLowerCase() ==
          "generalexception"
        ) {
          dispatch(
            upsertAssignmentFailed(
              "generalexception",
              data?.updateOrCreateAssignment?.message ?? "something went wrong"
            )
          );
          return;
        }

        dispatch(upsertAssignmentSuccess());
        if (isAssignemntsList) {
          dispatch(
            fetchAssignmentsAttempt({
              from: moment(assignmentsListsFilters?.from, "DD-MM-YYYY").format(
                "YYYY-MM-DD"
              ),
              to: moment(assignmentsListsFilters?.from, "DD-MM-YYYY")
                .add(6, "days")
                .format("YYYY-MM-DD"),
              employeeIds: assignmentsListsFilters?.employees.map(
                (employee) => +employee
              ),
              locationIds: assignmentsListsFilters?.locations.map(
                (location) => +location
              ),
              workTimingsIds: assignmentsListsFilters?.work_timings.map(
                (workTiming) => +workTiming
              ),
            })
          );
        } else {
          fetchCalendarData();
        }
      },
      onError: (error) => {
        dispatch(
          upsertAssignmentFailed("errors", {
            serverRef: formProps.formServerValidation,
            errors: HelperFns.parseValidatorErrors(
              error.graphQLErrors?.[0]?.extensions?.validation ?? {}
            ),
          })
        );
        dispatch(
          showErrorToast(
            error?.graphQLErrors?.[0]?.extensions?.reason || error?.message
          )
        );
      },
    });

  const handleAssignmentSubmit = () => {
    setFormSubmitting(true);
    if (formValidations.length > 0) return;
    if (
      formInputs?.compensation == "request" &&
      (!!!formInputs?.inLieuOfWorkdayRequestable ||
        !!!formInputs?.inLieuOfWorkdayRequestableObject)
    ) {
      return;
    }

    dispatch(resetAssignmentModalMessage());

    attemptSubmitAssignment();
  };

  const renderFormwithEditCapability = () => {
    if (formInputs?.canEdit === true || formInputs?.canEdit === null) {
      return <AssignmentFormCanEditPart {...formProps} />;
    } else {
      return <AssignmentPreviewCanEditPart />;
    }
  };

  if (fetchAssignmentLoading) return <Loader fixed />;

  return (
    <MainModal
      isOpen={isVissible}
      size="lg"
      modalTitle={formInputs.id ? t("edit assignment") : t("new assignment")}
      btnOnClick={handleAssignmentSubmit}
      toggle={handleClosingAssignmentModal}
      btnSubmitLoading={upsertAssignmentLoadingg}
      className="assignments-modal"
      disableSubmitButton={
        formInputs?.compensation == "request" &&
        (!!!formInputs?.inLieuOfWorkdayRequestable ||
          !!!formInputs?.inLieuOfWorkdayRequestableObject)
      }
    >
      {/* Modal Form */}
      <div className="assignment-form">
        {formInputs?.canEdit === true ||
        formInputs?.canEditTo === true ||
        formInputs?.canEdit === null ? (
          <InputForm
            {...formProps}
            name="name"
            placeholder={t("name")}
            validateBy="textRequired"
            validationName="input.name"
            label="name"
            labelStyle="mb-2"
            // containerStyle=" "
            inputContainerStyle="w-100"
            rootStyle="mb-2"
            icon="person"
          />
        ) : (
          <div className="d-flex flex-column align-items-start justiyf-content-center mt-3">
            <AssignmentPreviewNamePart name={formInputs?.name} />
          </div>
        )}

        {/* start of from to section */}
        <div className="d-flex align-items-start justify-contnet-around">
          <DateTimePickerForm
            {...formProps}
            name="from"
            validateBy="textRequired"
            validationName="input.from"
            label="from"
            // labelStyle="mr-3"
            placeholder={t("choose day")}
            datePickerContainer="w-100"
            hasIcon
            isClearable
            inputStyle="assignment-form-date-picker date-picker-input-default"
            iconBackground={false}
            rootStyle="w-50 mr-custom-5"
            disabled={formInputs?.canEditFrom === false}
            onIntercept={() => {
              handleResetInLieuOfWorkday();
              return false;
            }}
          />

          <DateTimePickerForm
            {...formProps}
            name="to"
            validateBy="textRequired"
            validationName="input.to"
            label="to"
            labelStyle="mr-3"
            placeholder={t("choose day")}
            datePickerContainer="w-100"
            hasIcon
            isClearable
            inputStyle="assignment-form-date-picker date-picker-input-default"
            iconBackground={false}
            rootStyle="w-50"
            disabled={formInputs?.canEditTo === false}
            onIntercept={() => {
              handleResetInLieuOfWorkday();
              return false;
            }}
          />
        </div>

        {renderFormwithEditCapability()}
      </div>

      {/* Modal Message */}
      {modalMessage && formSubmitting && (
        <div className="warnig-msg-style mt-3">{modalMessage}</div>
      )}
    </MainModal>
  );
};

export default UpsertAssignmentModal;

const AssignmentFormCanEditPart = ({ ...formProps }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [inLieuOfWorkdayGeneralMessage, setInLieuOfWorkdayGeneralMessage] =
    useState("");

  // Reducer State
  const {
    office_applicable_on_ids,
    department_applicable_on_ids,
    apply_work_timing_location,
    from,
    normal_work_timing_id,
    normalWorkTiming,
    firstHalfWorkTiming,
    secondHalfWorkTiming,
    ...formInputs
  } = useSelector((state) => state.assignments.upsertAssignmentForm);

  const canEditRetain =
    !formInputs?.id || (formInputs?.id && formInputs?.canEditRetain);

  const { users_by_role, departments, offices, locations } = useSelector(
    (state) => state.assignments.assignmentFormInputsData
  );

  const [normalWorkTimingList, setNormalWorkTimingList] = useState([]);
  const [halfWorkTimingList, setHalfWorkTimingList] = useState([]);

  const { loading: workTimmingLoading } = useQuery(
    GET_WORKING_TIMMING_OPTIONS_QUERY,
    {
      variables: {
        work_timming_date: moment(from).format("YYYY-MM-DD"),
      },
      onCompleted: (res) => {
        setNormalWorkTimingList(res?.work_timings_menu);
        setHalfWorkTimingList(res?.half_work_timings_menu);

        if (
          !res?.work_timings_menu?.find((wt) => wt.id == normal_work_timing_id)
        ) {
          dispatch(
            updateValueAction(
              "upsertAssignmentForm",
              "normal_work_timing_id",
              null
            )
          );
        }
        if (
          !res?.half_work_timings_menu?.find(
            (wt) => wt.id == formInputs.first_half_work_timing_id
          )
        ) {
          dispatch(
            updateValueAction(
              "upsertAssignmentForm",
              "first_half_work_timing_id",
              null
            )
          );
        }
        if (
          !res?.half_work_timings_menu?.find(
            (wt) => wt.id == formInputs.second_work_timing_id
          )
        ) {
          dispatch(
            updateValueAction(
              "upsertAssignmentForm",
              "second_work_timing_id",
              null
            )
          );
        }
      },
      skip: !from,
      onError: (err) => {
        showToast(
          "error",
          err?.graphQLErrors[0]?.extensions?.reason ||
            err?.graphQLErrors[0]?.message ||
            err?.message
        );
      },
    }
  );

  /* ↓ State Effects ↓ */

  useDidUpdateEffect(() => {
    if (formInputs.assignmentType == assignmentTypesConstants.DAY_OFF) {
      dispatch(onInputResetAction("upsertAssignmentForm", "weight"));
      dispatch(onInputResetAction("upsertAssignmentForm", "allow_home"));
      dispatch(onInputResetAction("upsertAssignmentForm", "allow_overtime"));
      dispatch(onInputResetAction("upsertAssignmentForm", "allow_permission"));
      dispatch(
        onInputResetAction("upsertAssignmentForm", "normal_work_timing_id")
      );
    }
  }, [formInputs?.assignmentType]);

  /* ↓ Helpers ↓ */

  const handleUpdateWeight = (_, val) => {
    dispatch(updateValueAction(formProps.formName, "weight", val?.weight));
  };

  const Option = ({ children, ...props }) => {
    let { sign_in_start_time, sign_in_end_time, work_hours, sign_in_req } =
      props.data;

    return (
      <components.Option {...props}>
        <div>
          <div>{children}</div>
          <span
            className={`work-timing-time-range-style ${
              props.isSelected ? "text-white" : ""
            }`}
          >
            {!sign_in_req
              ? t("Don't Need to Sign In At The Start Of Each Work Day")
              : `Sign in range from ${HelperFns.formatWorkTiming(
                  sign_in_start_time,
                  "time_range"
                )} to ${HelperFns.formatWorkTiming(
                  sign_in_end_time,
                  "time_range"
                )} - work hours ${HelperFns.formatWorkTiming(
                  work_hours,
                  "hours"
                )}`}
          </span>
        </div>
      </components.Option>
    );
  };

  const [
    fetchApplicableWorkTimings,
    { data: applicableWorkTimingsData, loading: applicableWorkTimingsLoading },
  ] = useLazyQuery(applicableWorktimingsQuery, {
    onError: (error) => {
      dispatch(
        showErrorToast(
          error?.graphQLErrors?.[0]?.extensions?.reason || error?.message
        )
      );
    },
  });

  const normalizedApplicableWorkTimingsOptions = (data) => {
    let options = [
      ...(data?.employee?.applicableWorkTimings?.default ?? []),
      ...(data?.employee?.applicableWorkTimings?.exceptions ?? []),
    ];
    let normalizedOptions = [];

    options?.forEach((applicable) => {
      switch (applicable?.__typename) {
        case "AttendanceProfileWorkTiming":
          normalizedOptions.push({
            id: applicable?.id,
            name: applicable?.work_timing?.name,
            typename: applicable?.__typename,
          });
          break;
        case "ScheduleDetailEmployee":
          normalizedOptions.push({
            id: applicable?.id,
            name: applicable?.ScheduleDetail?.WorkTiming?.name,
            typename: applicable?.__typename,
          });
          break;
        case "Exception":
          normalizedOptions.push({
            id: applicable?.id,
            name: applicable?.normalWorkTiming?.name,
            typename: applicable?.__typename,
          });
          break;
        default:
          return;
      }
    });
    return normalizedOptions;
  };

  const handleResetInLieuOfWorkday = () => {
    dispatch(
      onInputResetAction(formProps.formName, "inLieuOfWorkdayRequestable")
    );
    dispatch(
      onInputResetAction(formProps.formName, "inLieuOfWorkdayRequestableObject")
    );
    dispatch(onInputResetAction(formProps.formName, "compensation"));
  };

  useEffect(() => {
    dispatch(
      onInputResetAction(formProps.formName, "inLieuOfWorkdayRequestable")
    );
    dispatch(
      onInputResetAction(formProps.formName, "inLieuOfWorkdayRequestableObject")
    );

    if (
      !!formInputs?.inLieuOfWorkdayDate &&
      formInputs?.employee_applicable_on_ids?.length == 1
    ) {
      setInLieuOfWorkdayGeneralMessage("");
      fetchApplicableWorkTimings({
        variables: {
          employee_id: formInputs?.employee_applicable_on_ids?.[0],
          date: moment(formInputs?.inLieuOfWorkdayDate).format("YYYY-MM-DD"),
          for: "Leave",
        },
        onCompleted: (data) => {
          const options = normalizedApplicableWorkTimingsOptions(data);
          if (options?.length == 0) {
            setInLieuOfWorkdayGeneralMessage(
              "There are no shifts on the date selected"
            );
            return;
          }
          if (options?.length == 1) {
            dispatch(
              onInputResetWithValueAction(
                formProps.formName,
                "inLieuOfWorkdayRequestable",
                options?.[0]?.id
              )
            );
          }
        },
      });
    }

    return () => {
      setInLieuOfWorkdayGeneralMessage("");
    };
  }, [formInputs?.inLieuOfWorkdayDate, formInputs?.employee_applicable_on_ids]);

  const showInLieuOfWorkdayOption =
    !!from &&
    !!formInputs?.to &&
    from == formInputs?.to &&
    formInputs?.apply_on == "employee" &&
    formInputs?.employee_applicable_on_ids?.length == 1;

  useEffect(() => {
    if (!!formInputs?.inLieuOfWorkdayRequestable) {
      dispatch(
        onInputResetWithValueAction(
          formProps.formName,
          "inLieuOfWorkdayRequestableObject",
          normalizedApplicableWorkTimingsOptions(
            applicableWorkTimingsData
          )?.find((el) => el?.id == formInputs?.inLieuOfWorkdayRequestable)
        )
      );
    }
  }, [formInputs?.inLieuOfWorkdayRequestable]);

  const getWorkTimings = (workTimingList, actualWorkTiming = false) => {
    let returnOptions = [...workTimingList];
    if (actualWorkTiming) {
      returnOptions = [...workTimingList, actualWorkTiming];
    }

    return _.uniqBy(returnOptions, "id");
  };

  return (
    <div className="assignment-form">
      <h4 className="assignment-form-header">{t("apply on")}</h4>
      {/* Employees */}
      <HasPrivileges
        allowBP
        reqireMain={[Privilages.ADD_EDIT_DELETE_EMPLOYEE_ASSIGNMENTS]}
      >
        <RadioboxForm
          {...formProps}
          name="apply_on"
          options={[
            {
              label: "employee",
              value: "employee",
            },
          ]}
          type="radio"
          containerStyle="my-2"
          labelStyle="label-style"
          optionsContainerStyle="optionsContainerStyle"
          optionItemStyle="optionItemStyle"
          optionInputStyle=" "
          optionLabelStyle="optionLabelStyle"
        />
        <BSelect
          {...formProps}
          name="employee_applicable_on_ids"
          validateBy="arrayRequired"
          validationName="input.applicable_on_ids"
          placeholder={t("select employees")}
          keepDefaultStyle
          options={users_by_role}
          getOptionLabel={(opt) => `${opt.name} - ${opt.office.name}`}
          optionValue="id"
          containerStyle="d-flex align-items-start flex-column"
          inputContainerStyle="w-100"
          dependOn="apply_on"
          dependancyType="equal"
          dependancyValue={["employee"]}
          isMulti
          icon="person"
        />
      </HasPrivileges>

      {/* Departments */}
      <HasPrivileges
        allowBP
        reqireMain={[
          Privilages.ADD_EDIT_DELETE_ASSIGNMENTS_IN_MY_DEPARTMENT,
          Privilages.ADD_EDIT_DELETE_ASSIGNMENTS_IN_OTHER_DEPARTMENTS,
        ]}
      >
        <RadioboxForm
          {...formProps}
          name="apply_on"
          options={[{ label: "departments", value: "department" }]}
          type="radio"
          containerStyle="my-2"
          labelStyle="label-style"
          optionsContainerStyle="optionsContainerStyle"
          optionItemStyle="optionItemStyle"
          optionInputStyle=" "
          optionLabelStyle="optionLabelStyle"
        />
        <BSelect
          {...formProps}
          name="department_applicable_on_ids"
          validateBy="arrayRequired"
          validationName="input.applicable_on_ids"
          placeholder={t("select departments")}
          keepDefaultStyle
          options={departments}
          optionLabel="name"
          optionValue="id"
          containerStyle="d-flex align-items-start flex-column"
          inputContainerStyle="w-100"
          dependOn="apply_on"
          dependancyType="equal"
          dependancyValue={["department"]}
          isMulti
          icon="department"
        />

        {/* exclude employees section */}
        <CheckboxBooleanForm
          {...formProps}
          name="exclude_department_employees"
          options={["exclude employees from this commission"]}
          containerStyle="my-1"
          dependOn="apply_on"
          dependancyType="equal"
          dependancyValue={["department"]}
        />
        <BSelect
          {...formProps}
          name="department_employee_excludes"
          placeholder={t("select employees")}
          validateBy="arrayRequired"
          validationName="input.assignment_employee_excludes"
          options={HelperFns.filterUsersBy(
            "department",
            users_by_role,
            department_applicable_on_ids
          )}
          optionLabel="name"
          optionValue="id"
          containerStyle="d-flex align-items-start flex-column"
          inputContainerStyle="w-100"
          dependOn="exclude_department_employees"
          dependancyType="equal"
          dependancyValue={[1]}
          isMulti
          icon="person"
        />
      </HasPrivileges>

      {/* Offices */}
      <HasPrivileges
        allowBP
        reqireMain={[
          Privilages.ADD_EDIT_DELETE_ASSIGNMENTS_IN_MY_OFFICE,
          Privilages.ADD_EDIT_DELETE_ASSIGNMENTS_IN_OTHER_OFFICES,
        ]}
      >
        <RadioboxForm
          {...formProps}
          name="apply_on"
          options={[{ label: "offices", value: "office" }]}
          type="radio"
          containerStyle="my-2"
          labelStyle="label-style"
          optionsContainerStyle="optionsContainerStyle"
          optionItemStyle="optionItemStyle"
          optionInputStyle=" "
          optionLabelStyle="optionLabelStyle"
        />
        <BSelect
          {...formProps}
          placeholder={t("select offices")}
          name="office_applicable_on_ids"
          validateBy="arrayRequired"
          validationName="input.applicable_on_ids"
          keepDefaultStyle
          options={offices}
          optionLabel="name"
          optionValue="id"
          containerStyle="d-flex align-items-start flex-column"
          inputContainerStyle="w-100"
          dependOn="apply_on"
          dependancyType="equal"
          dependancyValue={["office"]}
          isMulti
          icon="office"
          skipLocalization
        />
        {/* exclude employees section */}
        <CheckboxBooleanForm
          {...formProps}
          name="exclude_office_employees"
          options={["exclude employees from this commission"]}
          containerStyle="my-1"
          dependOn="apply_on"
          dependancyValue={["office"]}
          dependancyType="equal"
        />
        <BSelect
          {...formProps}
          name="office_employee_excludes"
          placeholder={t("select employees")}
          validateBy="arrayRequired"
          validationName="input.assignment_employee_excludes"
          keepDefaultStyle
          options={HelperFns.filterUsersBy(
            "office",
            users_by_role,
            office_applicable_on_ids
          )}
          optionLabel="name"
          optionValue="id"
          containerStyle="d-flex align-items-start flex-column"
          inputContainerStyle="w-100"
          dependOn="exclude_office_employees"
          dependancyType="equal"
          dependancyValue={[1]}
          isMulti
          icon="person"
        />
      </HasPrivileges>

      <h4 className="assignment-form-header mb-2">{t("work schedule")}</h4>
      <RadioboxForm
        {...formProps}
        name="assignmentType"
        options={assignmentTypes?.map((opt) => ({
          ...opt,
          optProps: {
            disabled: !!formInputs?.id,
          },
        }))}
        containerStyle="my-1"
        labelStyle="label-style"
        optionsContainerStyle="work-schedule-container"
        optionItemStyle="my-1 mr-1"
        optionInputStyle=" "
        optionLabelStyle="optionLabelStyle"
        interceptChange={() => {
          handleResetInLieuOfWorkday();
        }}
      />

      <RadioboxForm
        {...formProps}
        label="retention type"
        name="retain_default_work_timing"
        options={[
          {
            label: "replace",
            value: 0,
            optProps: {
              disabled: !canEditRetain,
            },
          },
          {
            label: "additional",
            value: 1,
            optProps: {
              disabled: !canEditRetain,
            },
          },
        ]}
        labelStyle="mb-3"
        containerStyle="my-1"
        optionsContainerStyle="optionsContainerStyle"
        optionItemStyle="optionItemStyle"
        optionInputStyle=" "
        optionLabelStyle="optionLabelStyle"
        dependOn="assignmentType"
        dependancyType="equal"
        dependancyValue={[assignmentTypesConstants.NORMAL_WORK_DAY]}
        rootStyle="mt-3"
      />

      {/* <CheckboxBooleanForm
        {...formProps}
        name="retain_default_work_timing"
        options={["retain default shifts on this day"]}
        containerStyle="my-3"
        dependOn="assignmentType"
        dependancyType="equal"
        dependancyValue={[assignmentTypesConstants.NORMAL_WORK_DAY]}
      /> */}

      <div className="d-flex align-items-end gap-10">
        {/* here */}
        <BSelect
          {...formProps}
          name="normal_work_timing_id"
          validateBy="textRequired"
          validationName="input.work_timing"
          placeholder={t("select work timing")}
          onInterceptInputOnChange={handleUpdateWeight}
          options={getWorkTimings(normalWorkTimingList, normalWorkTiming)}
          optionLabel="name"
          label="Work timing"
          optionValue="id"
          labelStyle="mt-3 mb-2"
          containerStyle="d-flex align-items-start flex-column"
          inputContainerStyle="w-100"
          dependOn="assignmentType"
          dependancyType="equal"
          dependancyValue={[
            assignmentTypesConstants.NORMAL_WORK_DAY,
            assignmentTypesConstants.EXCEPTIONAL_SHIFT,
          ]}
          icon="calendar"
          rootStyle="flex-1"
          customComponents={{ Option }}
          skipLocalization
          isLoading={workTimmingLoading}
          isDisabled={workTimmingLoading}
        />
        <InputForm
          {...formProps}
          validateBy="textRequired"
          label="weight"
          name="weight"
          type="number"
          placeholder={t("weight")}
          containerStyle="d-flex gap-10 flex-column"
          inputContainerStyle=" "
          dependOn="assignmentType"
          dependancyType="equal"
          dependancyValue={[assignmentTypesConstants.NORMAL_WORK_DAY]}
        />
      </div>
      <CheckboxBooleanForm
        {...formProps}
        name="allow_employees_to_request_half_days"
        options={["allow employees to request half-days"]}
        containerStyle="my-3"
        dependOn="assignmentType"
        dependancyType="equal"
        dependancyValue={[assignmentTypesConstants.NORMAL_WORK_DAY]}
      />

      {/* here */}
      <div className="d-flex align-items-center justify-content-between">
        <BSelect
          {...formProps}
          validation="textRequired"
          label="first half work timing"
          name="first_half_work_timing_id"
          placeholder={t("select work timing")}
          validateBy="textRequired"
          validationName="input.work_timing"
          keepDefaultStyle
          options={getWorkTimings(halfWorkTimingList, firstHalfWorkTiming)}
          optionLabel="name"
          optionValue="id"
          inputContainerStyle="w-100"
          dependOn="allow_employees_to_request_half_days"
          dependancyType="equal"
          dependancyValue={[1]}
          icon="user"
          rootStyle="w-100 mr-5"
          customNewStyles
          customComponents={{ Option }}
          isLoading={workTimmingLoading}
          isDisabled={workTimmingLoading}
        />
        {/* here */}
        <BSelect
          {...formProps}
          label="second half work timing"
          name="second_half_work_timing_id"
          placeholder={t("select work timing")}
          validateBy="textRequired"
          validationName="input.work_timing"
          keepDefaultStyle
          options={getWorkTimings(halfWorkTimingList, secondHalfWorkTiming)}
          optionLabel="name"
          optionValue="id"
          inputContainerStyle="w-100"
          dependOn="allow_employees_to_request_half_days"
          dependancyType="equal"
          dependancyValue={[1]}
          icon="user"
          rootStyle="w-100"
          customNewStyles
          customComponents={{ Option }}
          isLoading={workTimmingLoading}
          isDisabled={workTimmingLoading}
        />
      </div>

      <div>
        {formInputs?.assignmentType != assignmentTypesConstants.DAY_OFF &&
        formInputs?.assignmentType != assignmentTypesConstants.HOLIDAY ? (
          <>
            <h4 className="assignment-form-header">{t("locations")}</h4>
            <CheckboxBooleanForm
              {...formProps}
              name="apply_work_timing_location"
              options={["use work timing location settings"]}
              containerStyle="m-l-170"
              dependOn="assignmentType"
              dependancyType="equal"
              dependancyValue={[
                assignmentTypesConstants.NORMAL_WORK_DAY,
                assignmentTypesConstants.EXCEPTIONAL_SHIFT,
              ]}
            />
            {!apply_work_timing_location ? (
              <WorkPlaces formProps={formProps} locationsOptions={offices} />
            ) : null}
          </>
        ) : null}
      </div>
      {/* Employee compensation section */}
      {formInputs.assignmentType ==
      assignmentTypesConstants.EXCEPTIONAL_SHIFT ? (
        <>
          <h4
            className={`assignment-form-header ${
              formInputs.assignmentType == assignmentTypesConstants.DAY_OFF &&
              "d-none"
            }`}
          >
            {t("employee compensation")}
          </h4>
          <CheckboxBooleanForm
            {...formProps}
            name="compensate_for_extra_time_worked"
            options={["compensate employee for the extra time worked"]}
            containerStyle="my-1"
            dependOn="assignmentType"
            dependancyValue={[
              assignmentTypesConstants.NORMAL_WORK_DAY,
              assignmentTypesConstants.EXCEPTIONAL_SHIFT,
            ]}
            dependancyType="equal"
          />
          <div
            className={`info-line-container ${
              !formInputs.compensate_for_extra_time_worked ? "d-none" : ""
            }`}
          >
            <div className="info-line">
              <div className="testing-top-circle"></div>
              <div className="testing-bottom-circle"></div>
            </div>
            {/* award bonus days section */}
            {HelperFns.checkCompanyPrivileges({
              privileges: [Privilages.ADD_EDIT_DELETE_BONUSES_DEDUCTIONS],
            }) ? (
              <div>
                <RadioboxForm
                  {...formProps}
                  name="compensation"
                  options={[{ label: "award bonus days", value: "bonus" }]}
                  containerStyle="my-1"
                  dependOn="compensate_for_extra_time_worked"
                  dependancyValue={[1]}
                  dependancyType="equal"
                  labelStyle="label-style"
                  optionsContainerStyle="optionsContainerStyle"
                  optionItemStyle="optionItemStyle"
                  optionInputStyle=" "
                  optionLabelStyle="optionLabelStyle"
                />
                <div className="d-flex align-items-center justify-content-between compensation-responsive">
                  <div className="d-flex gap-20 flex-1">
                    <BSelect
                      {...formProps}
                      label="type"
                      name="payment_factor_id"
                      validateBy="textRequired"
                      optionLabel="label"
                      optionValue="value"
                      options={Constants.BounsTypes}
                      validationName="input.assignment_compensation_configurations.payment_factor_id"
                      rootStyle="flex-1"
                      icon="type"
                      dependOn="compensation"
                      dependancyType="equal"
                      dependancyValue={["bonus"]}
                    />
                    <InputForm
                      {...formProps}
                      name="award_bonus_days_quantity"
                      validateBy="textRequired"
                      validationName="input.assignment_compensation_configurations.0.quantity"
                      label="value"
                      labelStyle="mb-2"
                      containerStyle=""
                      dependOn="compensation"
                      dependancyType="equal"
                      dependancyValue={["bonus"]}
                      rootStyle="compensation-input-responsive flex-1"
                      type="number"
                    />
                    <DateTimePickerForm
                      {...formProps}
                      name="payable_at"
                      label="payable at"
                      rootStyle="flex-1"
                      labelStyle="mb-1"
                      validateBy="textRequired"
                      validationName="input.from"
                      placeholder={t("choose day")}
                      datePickerContainer="w-100"
                      hasIcon
                      inputStyle="assignment-form-date-picker date-picker-input-default"
                      iconBackground={false}
                      dependOn="compensation"
                      dependancyType="equal"
                      dependancyValue={["bonus"]}
                    />
                  </div>
                  <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center">
                      {/* <BSelect
                    {...formProps}
                    name="month"
                    options={Constants.MonthsData}
                    optionLabel="value"
                    optionValue="key"
                    containerStyle="d-flex align-items-center justify-content-center"
                    placeholder={t("month")}
                    inputContainerStyle="date-inputs-style"
                    dependOn="compensation"
                    dependancyType="equal"
                    dependancyValue={["bonus"]}
                    customNewStyles
                    icon="calendar"
                  />
                  <BSelect
                    name="year"
                    {...formProps}
                    options={Constants.YearData}
                    optionLabel="value"
                    optionValue="value"
                    containerStyle="d-flex align-items-center justify-content-center ml-3"
                    placeholder={t("year")}
                    inputContainerStyle="date-inputs-style"
                    dependOn="compensation"
                    dependancyType="equal"
                    dependancyValue={["bonus"]}
                    customNewStyles
                    icon="calendar"
                  /> */}
                  </div>
                </div>
              </div>
            ) : null}

            {/* award bonus days section */}

            {/* award additional days section */}
            <div>
              <RadioboxForm
                {...formProps}
                name="compensation"
                options={[{ label: "award additional days", value: "leaves" }]}
                containerStyle="my-1"
                dependOn="compensate_for_extra_time_worked"
                dependancyValue={[1]}
                dependancyType="equal"
                labelStyle="label-style"
                optionsContainerStyle="optionsContainerStyle"
                optionItemStyle="optionItemStyle"
                optionInputStyle=" "
                optionLabelStyle="optionLabelStyle"
              />
              <InputForm
                {...formProps}
                name="award_additional_leaves_quantity"
                validateBy="textRequired"
                validationName="input.assignment_compensation_configurations.1.quantity"
                label="leave days / work day"
                labelStyle="min-w-150"
                containerStyle="d-flex align-items-center justify-content-start"
                inputContainerStyle="ml-3 inputs-days-inputs-style"
                dependOn="compensation"
                dependancyValue={["leaves"]}
                dependancyType="equal"
                type="number"
              />
            </div>
            {/* award additional days section */}
            {/* lieu section start */}
            {showInLieuOfWorkdayOption ? (
              <div>
                <RadioboxForm
                  {...formProps}
                  name="compensation"
                  options={[{ label: "in lieu of work day", value: "request" }]}
                  containerStyle="my-1"
                  dependOn="compensate_for_extra_time_worked"
                  dependancyValue={[1]}
                  dependancyType="equal"
                  labelStyle="label-style"
                  optionsContainerStyle="optionsContainerStyle"
                  optionItemStyle="optionItemStyle"
                  optionInputStyle=" "
                  optionLabelStyle="optionLabelStyle"
                />

                <div className="d-flex align-items-end">
                  <DateTimePickerForm
                    {...formProps}
                    name="inLieuOfWorkdayDate"
                    validateBy="textRequired"
                    label="workday"
                    // labelStyle="mr-3"
                    placeholder={t("choose day")}
                    datePickerContainer="w-100"
                    hasIcon
                    isClearable
                    inputStyle="assignment-form-date-picker date-picker-input-default"
                    iconBackground={false}
                    dependOn="compensation"
                    dependancyType="equal"
                    dependancyValue={["request"]}
                  />

                  {applicableWorkTimingsLoading ? (
                    <Spinner
                      style={{
                        width: "1rem",
                        height: "1rem",
                        color: "#03a9f4",
                        marginBottom: 5,
                        marginLeft: 10,
                      }}
                    />
                  ) : null}
                </div>

                {formInputs?.compensation == "request" &&
                !!inLieuOfWorkdayGeneralMessage ? (
                  <div className="invalid-container-style">
                    <p className="validity-msg-style">
                      {t(inLieuOfWorkdayGeneralMessage)}
                    </p>
                  </div>
                ) : null}

                {normalizedApplicableWorkTimingsOptions(
                  applicableWorkTimingsData
                )?.length > 1 ? (
                  <BSelect
                    {...formProps}
                    name="inLieuOfWorkdayRequestable"
                    validateBy="textRequired"
                    placeholder={t("select option")}
                    keepDefaultStyle
                    options={normalizedApplicableWorkTimingsOptions(
                      applicableWorkTimingsData
                    )}
                    optionValue="id"
                    containerStyle="d-flex align-items-start flex-column"
                    inputContainerStyle="w-100"
                    dependOn="compensation"
                    dependancyType="equal"
                    dependancyValue={["request"]}
                  />
                ) : null}
              </div>
            ) : null}

            {/* lieu section end */}
          </div>
          {/* Employee compensation section */}

          {/* Requests section */}
          {formInputs.assignmentType != assignmentTypesConstants.DAY_OFF ? (
            <>
              <h4 className="assignment-form-header">{t("Requests")}</h4>
              <div className="d-flex justify-content-between">
                <CheckboxBooleanForm
                  {...formProps}
                  setWithValue
                  checkedValue={true}
                  unCheckedValue={false}
                  name="allow_permission"
                  options={["permissions"]}
                />
                <CheckboxBooleanForm
                  {...formProps}
                  setWithValue
                  checkedValue={true}
                  unCheckedValue={false}
                  name="allow_overtime"
                  options={["overtime"]}
                />
                <CheckboxBooleanForm
                  {...formProps}
                  setWithValue
                  checkedValue={true}
                  unCheckedValue={false}
                  name="allow_home"
                  options={["work remotely"]}
                />
              </div>
            </>
          ) : null}

          {/* Absence section */}
          <h4
            className={`assignment-form-header ${
              formInputs.assignmentType == assignmentTypesConstants.DAY_OFF &&
              "d-none"
            }`}
          >
            {t("in case of absence")}
          </h4>
          <CheckboxBooleanForm
            {...formProps}
            name="penalise"
            options={[
              "penalize employees according to the selected work timing",
            ]}
            containerStyle="my-1"
            dependOn="assignmentType"
            dependancyType="equal"
            dependancyValue={[
              assignmentTypesConstants.NORMAL_WORK_DAY,
              assignmentTypesConstants.EXCEPTIONAL_SHIFT,
            ]}
          />
          {formInputs.assignmentType != assignmentTypesConstants.DAY_OFF &&
          formInputs.compensation ? (
            <CheckboxBooleanForm
              {...formProps}
              name="no_compensation"
              options={["don't apply compensation"]}
              containerStyle="my-1"
              dependOn="compensate_for_extra_time_worked"
              dependancyType="equal"
              dependancyValue={[1]}
            />
          ) : (
            ""
          )}
        </>
      ) : null}
    </div>
  );
};
