import React, { useEffect, useState } from "react";
import MainModal from "../../Components/MainModal";
import { connect } from "react-redux";
import {
  dismissManagePrivilagesModalAction,
  upsertEmployeePrivilegesAction,
  fetchRolePresetPrivilegesAction,
} from "../../Store/Actions";
import Const from "../../Constants";
import TreeView from "../../Builder/TreeView";
import Loader from "../Loader";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import { useSelector } from "react-redux";

const formName = "managePrivilagesModal";
const formNameValidation = "managePrivilagesModalValidation";

const ManagePrivilagesModal = (props) => {
  const { t } = useTranslation();
  const { privilegeTemplateModalValidatoin } = useSelector(
    (state) => state.super
  );

  const [presetRole, setpresetRole] = useState(null);
  const [presetAction, setPresetAction] = useState({
    role_id: null,
    action: null,
    name: null,
  });
  useEffect(() => {
    if (presetRole?.value) {
      props.fetchRolePresetPrivilegesAction(presetRole?.value);
      setPresetAction((prevState) => ({
        ...prevState,
        action: null,
        name: presetRole.label,
        role_id: presetRole.value,
      }));
    }
  }, [presetRole]);

  const handleSubmitBtn = () => {
    setIsSubmitting(true);
    if (props.modalValidation.length) {
      return;
    }

    props.upsertEmployeePrivilegesAction({
      user_id: props.managePrivilagesModal.id,
      main: props.managePrivilagesModal.main,
      all: props.managePrivilagesModal.all,
      managed: props.managePrivilagesModal.managed,
      presetAction: !!presetAction.action
        ? {
            ...presetAction,
            role_id: presetAction.action == 1 ? presetAction.role_id : null,
            user_type: "employee",
          }
        : null,
    });
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  const isModalVissible = props.modalData.isVissible;
  const modalMessage = props.modalData.modalMessage;
  const isLoading = props.modalData.isLoading;
  const toggleModal = () => {
    props.dismissManagePrivilagesModalAction({
      ref: "managePrivilagesModalActions",
      formName,
    });
  };

  useEffect(() => {
    setIsSubmitting(false);
    setpresetRole(null);
    setPresetAction({
      role_id: null,
      action: null,
      name: null,
    });
    return () => {
      setpresetRole(null);
      setPresetAction({
        role_id: null,
        action: null,
        name: null,
      });
    };
  }, [isModalVissible]);

  const handleInputChange = (event) => {
    const { name, value, checked } = event.target;
    if (name === "name") {
      setPresetAction((prevState) => ({ ...prevState, [name]: value }));
      return;
    }
    if (checked) {
      setPresetAction((prevState) => ({ ...prevState, [name]: Number(value) }));
      return;
    } else {
      setPresetAction((prevState) => ({ ...prevState, [name]: null }));
    }
  };

  if (props?.isLoading) {
    return (
      <div className="loader_wrapper_style">
        <Loader />
      </div>
    );
  }

  return (
    <MainModal
      isOpen={isModalVissible}
      btnLabelCancel="Cancel"
      btnOnCancelClick={toggleModal}
      modalTitle={`${t("Management Privileges")} - ${
        props.managePrivilagesModal.name
      }`}
      btnOnClick={handleSubmitBtn}
      btnSubmitLoading={isLoading}
      className="manage_privilages_modal_wrapper_style"
    >
      <div>
        <div className="d-flex flex-row align-items-center px-3 py-1">
          <label className="d-flex">{t("role")}</label>
          <Select
            className={
              "select-def-input-containe b-select-style col-md-6 col-sm-12"
            }
            classNamePrefix={"b-select-style"}
            value={presetRole}
            onChange={(val) => setpresetRole(val)}
            isLoading={props.loadingRolePresetPrivileges}
            isClearable={true}
            isRtl={document.dir?.toLowerCase() == "rtl"}
            isSearchable
            id="role_preset"
            placeholder={t("select option")}
            options={props.planRoles}
          />
        </div>

        {props.managePrivilagesModal.id && (
          <>
            {props.loadingRolePresetPrivileges ? (
              <div className="loader_wrapper_style">
                <Loader />
              </div>
            ) : null}
            <TreeView
              parents={JSON.parse(
                props?.managePrivilagesModal?.system_privileges
              )}
              // selectedOptions={props.managePrivilagesModal.main_privileges}
            />
          </>
        )}

        <HasPrivileges
          reqireMain={[Privilages.ADD_EDIT_DELETE_NEW_ACCESS_LEVELS]}
          allowBP
        >
          <div className="d-flex flex-row align-items-center px-3 py-1 gap-10">
            {!!presetRole && presetRole.roleable?.type == "Company" ? (
              <>
                <label className="checkbox">
                  <input
                    type={"checkbox"}
                    name="action"
                    value="1"
                    checked={presetAction.action == 1}
                    onChange={handleInputChange}
                  />
                  <span></span>
                  <div
                    className={`
                  ${"booleanInputLabelStyle"}
                  ${presetAction.action == 1 ? "lightActiveColor" : ""}
                  `}
                  >
                    {t("update role template")}
                  </div>
                </label>
                <label className="checkbox">
                  <input
                    type={"checkbox"}
                    name="action"
                    value="2"
                    checked={presetAction.action == 2}
                    onChange={handleInputChange}
                  />
                  <span></span>
                  <div
                    className={`
              ${"booleanInputLabelStyle"}
              ${presetAction.action == 2 ? "lightActiveColor" : ""}
              `}
                  >
                    {t("save as a new role template")}
                  </div>
                </label>
              </>
            ) : (
              <label className="checkbox">
                <input
                  type={"checkbox"}
                  name="action"
                  value="2"
                  checked={presetAction.action == 2}
                  onChange={handleInputChange}
                />
                <span></span>
                <div
                  className={`
                ${"booleanInputLabelStyle"}
                ${presetAction.action == 2 ? "lightActiveColor" : ""}
                `}
                >
                  {t("save as a new role template")}
                </div>
              </label>
            )}

            {!!presetAction.action ? (
              <div className="w-25">
                <input
                  type={"text"}
                  className={`input-style-default w-100 ${
                    privilegeTemplateModalValidatoin?.hasOwnProperty(
                      "input.presetAction.name"
                    )
                      ? "privilege-template-input-error"
                      : ""
                  }`}
                  placeholder={t("name")}
                  name="name"
                  value={presetAction.name}
                  onChange={handleInputChange}
                />
              </div>
            ) : null}
            {privilegeTemplateModalValidatoin?.hasOwnProperty(
              "input.presetAction.name"
            ) && presetAction.action ? (
              <div className="privilege-template-error">
                {privilegeTemplateModalValidatoin["input.presetAction.name"][0]}
              </div>
            ) : null}
          </div>
        </HasPrivileges>
        {/* (Start) Error Message */}
        {modalMessage && isSubmitting && (
          <div className="warnig-msg-style">{modalMessage}</div>
        )}
        {/* (End) Error Message */}
      </div>
    </MainModal>
  );
};

const mapStateToProps = (state) => ({
  modalData: state.super.managePrivilagesModalActions,
  modalValidation: state.super[formNameValidation],
  managePrivilagesModal: state.super[formName],
  isLoading: state.super.editPrivileges,
  planRoles: state.super.planRoles,
  loadingRolePresetPrivileges: state.super.loadingRolePresetPrivileges,
});

export default connect(mapStateToProps, {
  dismissManagePrivilagesModalAction,
  upsertEmployeePrivilegesAction,
  fetchRolePresetPrivilegesAction,
})(ManagePrivilagesModal);
