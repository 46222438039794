import React from "react";
import { connect, useDispatch } from "react-redux";

import MainModal from "../../Components/MainModal";
import { CheckboxBooleanForm, RadioboxForm, CheckboxForm } from "form-builder";
import Loader from "../../Components/Loader";
import {
  dismissExportPayrollOptions,
  showErrorToast,
  submitExportPayrollAction,
  submitExportPayrollFailed,
  submitExportPayrollSuccess,
} from "../../Store/Actions";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import HelperFns, { showToast } from "../../Helpers/HelperFns";
import { exportPayrollMutation } from "../../Graphql/mutation";
import CountryFields from "../../Components/CountryFields";

const formName = "exportPayrollForm";

const ExportPayrollModal = ({
  type,
  paidAt,
  officeId,
  departmentId,
  currencyId,
  status,
  isExpected,
  isExternal,
  ...props
}) => {
  const dispatch = useDispatch();

  const {
    base_salary,
    payable_amount,
    allowances,
    bonuses_amount,
    deductions_amount,
    overtime_amount,
    claims_amount,
    total_penalties,
    total_deductions,
    total_allowances,
    deductibles,
    otherDeductibles,
    penalties,
    office,
    position,
    department,
    emp_code,
  } = props.payrolldata;

  const { t } = useTranslation();

  const [attemptExportPayroll, { loading: exportPayrollLoading }] = useMutation(
    exportPayrollMutation,
    {
      onCompleted: (data) => {
        showToast(data?.exportPayroll?.status, data?.exportPayroll?.message);
        if (data?.exportPayroll?.status?.toLowerCase() == "success") {
          dispatch(submitExportPayrollSuccess());
          HelperFns.downloadFile(data?.exportPayroll?.file);
        }
      },
      onError: (error) => {
        dispatch(submitExportPayrollFailed());
        dispatch(
          showErrorToast(error?.[0]?.extensions?.reason || error?.[0]?.message)
        );
      },
    }
  );

  // --sharkas--
  const penaltyTypes = [
    { label: "absent", value: "absent penalty" },
    { label: t("Late Sign In"), value: "late penalty" },
    { label: t("Early Sign Out"), value: "early signout penalty" },
    { label: t("missing sign out"), value: "missing_sign_out penalty" },
    // { label: "minor late", value: "minor penalty" },
    // { label: t("major late", { tier: 2 }), value: "major penalty" },
    // { label: t("major late", { tier: 3 }), value: "major1 penalty" },
    // { label: t("major late", { tier: 4 }), value: "major2 penalty" },
    // { label: t("major late", { tier: 5 }), value: "major3 penalty" },
    // { label: t("major late", { tier: 6 }), value: "major4 penalty" },
    // { label: t("major late", { tier: 7 }), value: "major5 penalty" },
    // { label: t("major late", { tier: 8 }), value: "major6 penalty" },
    // { label: t("major late", { tier: 9 }), value: "major7 penalty" },
    // { label: t("major late", { tier: 10 }), value: "major8 penalty" },
    // { label: t("major late", { tier: 11 }), value: "major9 penalty" },
    // { label: t("major late", { tier: 12 }), value: "major10 penalty" },
  ];

  const handleSubmit = () => {
    let formData = {
      ...props?.payrolldata,
      allow_allowances:undefined,
      allow_deductions:undefined,
      allow_deductibles:undefined,
      otherDeductibles:undefined,
      other_deductions:undefined,
      allow_penalty:undefined,
      allow_additions:undefined,
      allow_other_deductibles:undefined,
      base_salary: Boolean(+base_salary),
      payable_amount: Boolean(+payable_amount),
      allowances,
      bonuses_amount: Boolean(+bonuses_amount),
      overtime_amount: Boolean(+overtime_amount),
      claims_amount: Boolean(+claims_amount),
      deductibles: [...deductibles, ...otherDeductibles],
      deductions_amount: Boolean(+deductions_amount),
      total_penalties: Boolean(+total_penalties),
      total_deductions: Boolean(+total_deductions),
      total_allowances: Boolean(+total_allowances),
      office: Boolean(+office),
      position: Boolean(+position),
      department: Boolean(+department),
      emp_code: Boolean(+emp_code),
      penalties,
      filter_data: {
        month: props.payrollFiler.month,
        year: +props.payrollFiler.year,
        office_id: props.payrollFiler.office_id,
        user_id: props.payrollFiler.user_id,
        status: props.payrollFiler.status,
        currency_id: props.payrollFiler.currency_id,
        config_type: isExternal ? "external" : "internal",
        regulation_id: props?.payrollFiler?.regulation_id,
        type: type,
        paid_at: paidAt,
        monthly_view: !!isExpected,
      },
    };

    attemptExportPayroll({
      variables: {
        input: formData,
      },
    });
  };

  const dimissExportPayrollModal = () => {
    props.dismissExportPayrollOptions();
  };

  return (
    <MainModal
      isOpen={props.modalActions?.isVissible}
      modalTitle={t("export payroll")}
      btnOnClick={handleSubmit}
      toggle={dimissExportPayrollModal}
      btnSubmitLoading={exportPayrollLoading}
    >
      <div className="px-2">
        <CheckboxBooleanForm
          formName={formName}
          options={["base_salary"]}
          name="base_salary"
          key="base_salary"
          type="checkbox"
          labelStyle="checkbox"
          containerStyle="my-2"
          optionsContainerStyle="d-flex flex-column"
          optionItemStyle="optionItemStyle mr-0"
          optionInputStyle="optionInputStyle containerStyle checkbox fit-width"
          optionLabelStyle="optionLabelStyle ml-2 w-100"
          optionLabelActiveStyle="optionLabelActiveStyle"
        />
        <div className="my-2">
          <CheckboxBooleanForm
            formName={formName}
            options={["additions"]}
            name="allow_additions"
            key="allow_additions"
            type="checkbox"
            labelStyle="checkbox"
            containerStyle="my-2"
            optionsContainerStyle="d-flex flex-column"
            optionItemStyle="optionItemStyle mr-0"
            optionInputStyle="optionInputStyle containerStyle checkbox fit-width"
            optionLabelStyle="optionLabelStyle ml-2 w-100"
            optionLabelActiveStyle="optionLabelActiveStyle"
          />
          <div className="ml-4">
            <CheckboxBooleanForm
              dependOn="allow_additions"
              dependancyType="equal"
              dependancyValue={[1]}
              formName={formName}
              options={["allowances"]}
              name="allow_allowances"
              key="allow_allowances"
              type="checkbox"
              labelStyle="checkbox"
              containerStyle="my-2"
              optionsContainerStyle="d-flex flex-column"
              optionItemStyle="optionItemStyle mr-0"
              optionInputStyle="optionInputStyle containerStyle checkbox fit-width"
              optionLabelStyle="optionLabelStyle ml-2 w-100"
              optionLabelActiveStyle="optionLabelActiveStyle"
            />
            <CheckboxForm
              options={props?.allowancesOpts}
              dependOn="allow_allowances"
              dependancyType="equal"
              dependancyValue={[1]}
              name="allowances"
              key="allowances"
              formName={formName}
              type="checkbox"
              containerStyle=" "
              labelStyle="label-style"
              optionsContainerStyle="my-1 ml-4"
              optionItemStyle=" "
              optionInputStyle=" "
              optionLabelStyle=" "
              optionLabelActiveStyle="optionLabelActiveStyle"
            />
            <CheckboxBooleanForm
              formName={formName}
              options={["total allowances"]}
              name="total_allowances"
              dependOn="allow_allowances"
              dependancyType="equal"
              dependancyValue={[1]}
              type="checkbox"
              labelStyle="checkbox"
              containerStyle="my-2"
              optionsContainerStyle="d-flex flex-column ml-4"
              optionItemStyle="optionItemStyle mr-0"
              optionInputStyle="optionInputStyle containerStyle checkbox fit-width"
              optionLabelStyle="optionLabelStyle ml-2 w-100"
              optionLabelActiveStyle="optionLabelActiveStyle"
            />

            <CheckboxBooleanForm
              formName={formName}
              options={["bonuses"]}
              name="bonuses_amount"
              key="bounses"
              dependOn="allow_additions"
              dependancyType="equal"
              dependancyValue={[1]}
              type="checkbox"
              labelStyle="checkbox"
              containerStyle="my-2"
              optionsContainerStyle="d-flex flex-column"
              optionItemStyle="optionItemStyle mr-0"
              optionInputStyle="optionInputStyle containerStyle checkbox fit-width"
              optionLabelStyle="optionLabelStyle ml-2 w-100"
              optionLabelActiveStyle="optionLabelActiveStyle"
            />

            <CheckboxBooleanForm
              formName={formName}
              options={["overtime"]}
              name="overtime_amount"
              key="overtime"
              dependOn="allow_additions"
              dependancyType="equal"
              dependancyValue={[1]}
              type="checkbox"
              labelStyle="checkbox"
              containerStyle="my-2"
              optionsContainerStyle="d-flex flex-column"
              optionItemStyle="optionItemStyle mr-0"
              optionInputStyle="optionInputStyle containerStyle checkbox fit-width"
              optionLabelStyle="optionLabelStyle ml-2 w-100"
              optionLabelActiveStyle="optionLabelActiveStyle"
            />
          </div>
        </div>

        <div className="my-2">
          <CheckboxBooleanForm
            formName={formName}
            options={["deductions"]}
            name="allow_deductions"
            key="allow_deductions"
            type="checkbox"
            labelStyle="checkbox"
            containerStyle="my-2"
            optionsContainerStyle="d-flex flex-column"
            optionItemStyle="optionItemStyle mr-0"
            optionInputStyle="optionInputStyle containerStyle checkbox fit-width"
            optionLabelStyle="optionLabelStyle ml-2 w-100"
            optionLabelActiveStyle="optionLabelActiveStyle"
          />
          <div className="ml-4">
            <CheckboxBooleanForm
              formName={formName}
              options={["penalties"]}
              name="allow_penalty"
              key="penalty"
              dependOn="allow_deductions"
              dependancyType="equal"
              dependancyValue={[1]}
              type="checkbox"
              labelStyle="checkbox"
              containerStyle="my-2"
              optionsContainerStyle="d-flex flex-column"
              optionItemStyle="optionItemStyle mr-0"
              optionInputStyle="optionInputStyle containerStyle checkbox fit-width"
              optionLabelStyle="optionLabelStyle ml-2 w-100"
              optionLabelActiveStyle="optionLabelActiveStyle"
            />
            <CheckboxForm
              options={penaltyTypes}
              name="penalties"
              key="deductibles"
              dependOn="allow_penalty"
              dependancyType="equal"
              dependancyValue={[1]}
              formName={formName}
              type="checkbox"
              containerStyle=" "
              labelStyle="label-style"
              optionsContainerStyle="my-1 ml-4"
              optionItemStyle=" "
              optionInputStyle=" "
              optionLabelStyle=" "
              optionLabelActiveStyle="optionLabelActiveStyle"
            />
            <CheckboxBooleanForm
              formName={formName}
              options={["total penalties"]}
              name="total_penalties"
              dependOn="allow_penalty"
              dependancyType="equal"
              dependancyValue={[1]}
              type="checkbox"
              labelStyle="checkbox"
              containerStyle="my-2"
              optionsContainerStyle="d-flex flex-column ml-4"
              optionItemStyle="optionItemStyle mr-0"
              optionInputStyle="optionInputStyle containerStyle checkbox fit-width"
              optionLabelStyle="optionLabelStyle ml-2 w-100"
              optionLabelActiveStyle="optionLabelActiveStyle"
            />

            <CheckboxBooleanForm
              formName={formName}
              options={["deductibles"]}
              name="allow_deductibles"
              key="allow_deductibles"
              dependOn="allow_deductions"
              dependancyType="equal"
              dependancyValue={[1]}
              type="checkbox"
              labelStyle="checkbox"
              containerStyle="my-2"
              optionsContainerStyle="d-flex flex-column"
              optionItemStyle="optionItemStyle mr-0"
              optionInputStyle="optionInputStyle containerStyle checkbox fit-width"
              optionLabelStyle="optionLabelStyle ml-2 w-100"
              optionLabelActiveStyle="optionLabelActiveStyle"
            />

            <CheckboxForm
              options={props?.deductiblesOpts
                ?.filter((deduct) => deduct.company_id == 0)
                .map(({ label, value }) => ({
                  label: t(label.toLowerCase()),
                  value,
                }))}
              name="deductibles"
              key="deductibles"
              dependOn="allow_deductibles"
              dependancyType="equal"
              dependancyValue={[1]}
              formName={formName}
              type="checkbox"
              containerStyle=" "
              labelStyle="label-style"
              optionsContainerStyle="my-1 ml-4"
              optionItemStyle=" "
              optionInputStyle=" "
              optionLabelStyle=" "
              optionLabelActiveStyle="optionLabelActiveStyle"
            />
            {props?.deductiblesOpts?.filter((deduct) => deduct.company_id != 0)
              .length != 0 ? (
              <div className="ml-4">
                <CheckboxBooleanForm
                  formName={formName}
                  options={["other deductibles"]}
                  name="allow_other_deductibles"
                  key="allow_other_deductibles"
                  dependOn="allow_deductibles"
                  dependancyType="equal"
                  dependancyValue={[1]}
                  type="checkbox"
                  labelStyle="checkbox"
                  containerStyle="my-2"
                  optionsContainerStyle="d-flex flex-column"
                  optionItemStyle="optionItemStyle mr-0"
                  optionInputStyle="optionInputStyle containerStyle checkbox fit-width"
                  optionLabelStyle="optionLabelStyle ml-0 w-100"
                  optionLabelActiveStyle="optionLabelActiveStyle"
                />

                <CheckboxForm
                  options={props?.deductiblesOpts?.filter(
                    (deduct) => deduct.company_id != 0
                  )}
                  name="otherDeductibles"
                  key="otherDeductibles"
                  dependOn="allow_other_deductibles"
                  dependancyType="equal"
                  dependancyValue={[1]}
                  formName={formName}
                  type="checkbox"
                  containerStyle=" "
                  labelStyle="label-style"
                  optionsContainerStyle="my-1 ml-4"
                  optionItemStyle=" "
                  optionInputStyle=" "
                  optionLabelStyle=" "
                  optionLabelActiveStyle="optionLabelActiveStyle"
                />
              </div>
            ) : null}
            <CheckboxBooleanForm
              formName={formName}
              options={["total deductions"]}
              name="total_deductions"
              dependOn="allow_deductibles"
              dependancyType="equal"
              dependancyValue={[1]}
              type="checkbox"
              labelStyle="checkbox"
              containerStyle="my-2"
              optionsContainerStyle="d-flex flex-column ml-4"
              optionItemStyle="optionItemStyle mr-0"
              optionInputStyle="optionInputStyle containerStyle checkbox fit-width"
              optionLabelStyle="optionLabelStyle ml-2 w-100"
              optionLabelActiveStyle="optionLabelActiveStyle"
            />

            <CheckboxBooleanForm
              formName={formName}
              options={["onetime deductions"]}
              name="deductions_amount"
              key="deductions_amount"
              dependOn="allow_deductions"
              dependancyType="equal"
              dependancyValue={[1]}
              type="checkbox"
              labelStyle="checkbox"
              containerStyle="my-2"
              optionsContainerStyle="d-flex flex-column"
              optionItemStyle="optionItemStyle mr-0"
              optionInputStyle="optionInputStyle containerStyle checkbox fit-width"
              optionLabelStyle="optionLabelStyle ml-2 w-100"
              optionLabelActiveStyle="optionLabelActiveStyle"
            />
          </div>
        </div>

        <CheckboxBooleanForm
          formName={formName}
          options={["claims"]}
          name="claims_amount"
          key="claims_amount"
          type="checkbox"
          labelStyle="checkbox"
          containerStyle="my-2 mt-3"
          optionsContainerStyle="d-flex flex-column"
          optionItemStyle="optionItemStyle mr-0"
          optionInputStyle="optionInputStyle containerStyle checkbox fit-width"
          optionLabelStyle="optionLabelStyle ml-2 w-100"
          optionLabelActiveStyle="optionLabelActiveStyle"
        />

        <CheckboxBooleanForm
          formName={formName}
          options={["net_salary"]}
          name="payable_amount"
          key="net_salary"
          type="checkbox"
          labelStyle="checkbox"
          containerStyle="my-2"
          optionsContainerStyle="d-flex flex-column"
          optionItemStyle="optionItemStyle mr-0"
          optionInputStyle="optionInputStyle containerStyle checkbox fit-width"
          optionLabelStyle="optionLabelStyle ml-2 w-100"
          optionLabelActiveStyle="optionLabelActiveStyle"
        />

        <CheckboxBooleanForm
          formName={formName}
          options={["office"]}
          name="office"
          key="office"
          type="checkbox"
          labelStyle="checkbox"
          containerStyle="my-2"
          optionsContainerStyle="d-flex flex-column"
          optionItemStyle="optionItemStyle mr-0"
          optionInputStyle="optionInputStyle containerStyle checkbox fit-width"
          optionLabelStyle="optionLabelStyle ml-2 w-100"
          optionLabelActiveStyle="optionLabelActiveStyle"
        />

        <CheckboxBooleanForm
          formName={formName}
          options={["position"]}
          name="position"
          key="position"
          type="checkbox"
          labelStyle="checkbox"
          containerStyle="my-2"
          optionsContainerStyle="d-flex flex-column"
          optionItemStyle="optionItemStyle mr-0"
          optionInputStyle="optionInputStyle containerStyle checkbox fit-width"
          optionLabelStyle="optionLabelStyle ml-2 w-100"
          optionLabelActiveStyle="optionLabelActiveStyle"
        />

        <CheckboxBooleanForm
          formName={formName}
          options={["department"]}
          name="department"
          key="department"
          type="checkbox"
          labelStyle="checkbox"
          containerStyle="my-2"
          optionsContainerStyle="d-flex flex-column"
          optionItemStyle="optionItemStyle mr-0"
          optionInputStyle="optionInputStyle containerStyle checkbox fit-width"
          optionLabelStyle="optionLabelStyle ml-2 w-100"
          optionLabelActiveStyle="optionLabelActiveStyle"
        />

        <CheckboxBooleanForm
          formName={formName}
          options={["employee code"]}
          name="emp_code"
          key="emp_code"
          type="checkbox"
          labelStyle="checkbox"
          containerStyle="my-2"
          optionsContainerStyle="d-flex flex-column"
          optionItemStyle="optionItemStyle mr-0"
          optionInputStyle="optionInputStyle containerStyle checkbox fit-width"
          optionLabelStyle="optionLabelStyle ml-2 w-100"
          optionLabelActiveStyle="optionLabelActiveStyle"
        />

        <CountryFields formName={formName} />
      </div>
    </MainModal>
  );
};

const mapStateToProps = (state) => {
  return {
    modalActions: state.super.exportPayrollModalActions,
    payrolldata: state.super.exportPayrollForm,
    allowancesOpts: state.super.allowancesOptions,
    deductiblesOpts: state.super.deductiblesOptions,
    payrollFiler: state.super.payrollList,
  };
};

export default connect(mapStateToProps, {
  dismissExportPayrollOptions,
  submitExportPayrollAction,
})(ExportPayrollModal);
