import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  logoutAction,
  // createSignInAction,
  // createSignOutAction,
  // hideForceSignOutAlert,
  upsertUserLocaleAction,
  showNotificationDrawer,
  onFormResetAction,
} from "../../Store/Actions";
import moment from "moment-timezone";
import MainModal from "../MainModal";
import Helper from "../../Helpers/HelperFns";
import Privilages from "../../Constants/Privilages";
import { SidebarPrivilages } from "../../Constants/Nav";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";

import Loader from "../Loader";
import { Link } from "react-router-dom";
import SetupWizardComponent from "./SetupWizardComponent";
import { Avatar, Badge, useMediaQuery } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IconButtonWithTooltip from "../../Components/IconButtonWithTooltip";
import {
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import {
  faChevronDown,
  faGlobe,
  faEnvelope,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import GlobalSearch from "../GlobalSearch";
import NotificationDrawer from "../NotificationDrawer";
import {
  faPlusSquare,
  faTimesRectangle,
} from "@fortawesome/free-regular-svg-icons";
import AddNewAccount from "../../Containers/AddNewAccount/AddNewAccount";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useHistory } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

let secTimer;
const Languages = [
  {
    code: "en-US",
    name: "English",
    countryCode: "us",
  },
  {
    code: "ar-EG",
    name: "العربية",
    countryCode: "eg",
  },
  // {
  //   code: "ar-SA",
  //   name: "العربية",
  //   countryCode: "sa",
  // },
];

const AttendanceNavbar = ({ Toggler }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const isMobileMedia = useMediaQuery("(max-width: 425px)");

  // Local State
  const [dt, setDt] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [addAcoount, setAddAcoount] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Reducer State
  const usersList = useSelector((state) => state.LoginUserList?.usersList);
  const auth = useSelector((state) => state?.auth?.userProfile);
  const token = useSelector((state) => state?.auth?.token);
  const userProfileRef = useSelector((state) => state?.auth?.userProfileRef);

  const authAccount = {
    access_token: token,
    user: auth,
  };

  const id = useSelector((state) => state.auth.userProfile.id);
  const lng = useSelector((state) => state.auth.userProfile.lng);
  const name = useSelector((state) => state.auth.userProfile.name);
  const office = useSelector((state) => state.auth.userProfile.office);
  const user_type = useSelector((state) => state.auth.userProfile?.user_type);
  const company = useSelector((state) => state.auth.userProfile?.company?.name);
  // const dismissHome = useSelector((state) => state.user.homeSignInAlert);
  const isUpsertLocale = useSelector((state) => state.user.isUpsertLocale);
  // const forceSignOut = useSelector((state) => state.user.forceSignOutAlert);
  const profile_picture = useSelector(
    (state) => state.auth.userProfile?.profile_picture
  );

  const normalizedUsersList = [
    ...usersList?.filter(
      (account) => account?.user?.id != authAccount?.user?.id
    ),
    authAccount,
  ]?.sort((a, b) => {
    if (a?.user?.id === id) return -1;
    if (b?.user?.id === id) return 1;
    return 0;
  });

  // const isSignedIn = useSelector((state) => state.user.isSignedIn);
  // const signInLoading = useSelector((state) => state.user.signInLoading);
  // const signOutLoading = useSelector((state) => state.user.signOutLoading);
  // const attendance_profile = useSelector((state) => state.auth.userProfile.attendance_profile);

  /* ↓ State Effects ↓ */

  useEffect(() => {
    clearInterval(secTimer);
    setDt(null);
    if (office?.time_zone) {
      secTimer = setInterval(() => {
        setDt(moment.tz(office?.time_zone));
      }, 1000);
    }
    return () => {
      if (office?.time_zone) {
        clearInterval(secTimer);
        setDt(null);
      }
    };
  }, [office?.time_zone]);

  /* ↓ Helpers ↓ */

  const handleUpsertUserLocale = (locale) => {
    dispatch(upsertUserLocaleAction(id, locale));
  };

  const toggle = () => setDropdownOpen(!dropdownOpen);

  // const handleSignIn = () => {
  //   dispatch(getSignInInfoAction());
  // };

  // const handleSignOut = () => {
  //   dispatch(getSignOutInfoAction());
  // };

  const handleLogout = (id) => {
    dispatch(logoutAction());
    dispatch({
      type: "REMOVE_ACCOUNT",
      payload: id,
    });
    // window.localStorage.clear();
  };

  // const handleHomeSignIn = () => {
  //   dispatch(createSignInAction(1));
  // };

  // const handleOfficeSignIn = () => {
  //   dispatch(createSignInAction(0));
  // };

  // const handleForceSignOut = () => {
  //   dispatch(createSignOutAction(1));
  // };

  // const handleDismissForceSignOut = () => {
  //   dispatch(hideForceSignOutAlert());
  // };

  const showNotification = () => {
    dispatch(showNotificationDrawer());
  };

  return (
    <>
      <nav className="header d-flex align-items-center justify-content-between">
        <Toggler />

        {/* Logo */}
        <div className="d-flex " style={{ gap: 10 }}>
          <HasPrivileges
            reqireMain={SidebarPrivilages}
            allowBP
            altChildren={
              <Link to="/" aria-label="Navigate to home page" className="px-3">
                {isMobileMedia ? (
                  <img
                    src="favicon.ico"
                    width="30"
                    height="auto"
                    alt="Mawared Logo"
                  />
                ) : (
                  <img
                    src={require("../../Assets/img/logo.svg").default}
                    width="120"
                    height="auto"
                    alt="Mawared Logo"
                  />
                )}
              </Link>
            }
          >
            <div style={{ width: "250px" }} className="d-none d-md-block">
              <Link
                to="/"
                aria-label="Navigate to home page"
                className="translateX-96 px-3 d-none d-md-block"
              >
                <img
                  src={require("../../Assets/img/logo.svg").default}
                  width="120"
                  height="auto"
                  alt="Mawared Logo"
                />
              </Link>
            </div>
          </HasPrivileges>

          <HasPrivileges
            reqireMain={[Privilages.VIEW_EMPLOYEE_PROFILES]}
            allowBP
          >
            <GlobalSearch />
          </HasPrivileges>
        </div>

        {window.location.pathname !== "/setup-wizard" ? (
          <HasPrivileges allowBP>
            <SetupWizardComponent />
          </HasPrivileges>
        ) : null}

        <ul className="list-unstyled d-flex align-items-center h-100 mb-0">
          <li className="nav-item timing_style d-sm-block d-none">
            {dt && dt.format("dddd, DD MMM hh:mm:ss A")}
          </li>
          <li className="nav-item timing_style d-sm-none d-block">
            {dt && dt.format("hh:mm:ss a")}
          </li>

          {/* change language */}
          <Dropdown
            className="mx-2 language"
            nav
            isOpen={dropdownOpen}
            toggle={toggle}
          >
            <DropdownToggle
              nav
              caret
              tabIndex={-1}
              className="d-flex align-items-center px-0"
            >
              <IconButtonWithTooltip
                label={t("change language")}
                onClick={() => undefined}
                icon={
                  <FontAwesomeIcon
                    icon={faGlobe}
                    className="gray-color"
                    style={{ fontSize: "15px" }}
                  />
                }
              />
            </DropdownToggle>

            <DropdownMenu className="language">
              {Languages.map(({ code, name, countryCode }) => (
                <DropdownItem
                  key={countryCode}
                  onClick={() => handleUpsertUserLocale(code)}
                  className="d-flex flex-row justify-content-between align-items-center"
                  disabled={code.includes(lng)}
                >
                  <span
                    style={{
                      opacity: code.includes(lng) ? 0.5 : 1,
                    }}
                    className={`flag-icon flag-icon-${countryCode} mx-1`}
                  />
                  <span className="mx-1">{name}</span>
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>

          {/* Notifications */}
          <HasPrivileges
            reqireMain={[
              Privilages.CUSTOMIZE_NOTIFICATIONS,
              Privilages.VIEW_NOTIFICATIONS_HISTORY,
            ]}
            allowBP
          >
            <li className="nav-item dropdown">
              <IconButtonWithTooltip
                label={t("show notifications")}
                onClick={() => showNotification()}
                icon={
                  <Badge variant="dot" color="error">
                    <FontAwesomeIcon
                      size="sm"
                      icon={faEnvelope}
                      className="gray-color"
                    />
                  </Badge>
                }
              />
            </li>

            <NotificationDrawer />
          </HasPrivileges>

          {/* Logout */}

          {userProfileRef?.id ? (
            <LogoutOnBehalf
              auth={{ token, userProfileRef, userProfile: auth }}
            />
          ) : (
            <>
              <button
                className="dropdown-toggle nav-link d-flex gap-10 align-items-center cursor-pointer btn"
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <div className="dropdown-toggle nav-link d-flex gap-10 align-items-center cursor-pointer btn">
                  <span className="user-img">
                    <Avatar
                      src={profile_picture?.path}
                      className="rounded_circle_user_img blackish-avatar"
                    >
                      {Helper.getIntials(name)}
                    </Avatar>
                  </span>

                  <div className="text-left">
                    <b className="align-middle gray-color d-none d-block">
                      {name}
                    </b>
                    {user_type === "Owner" ? (
                      <span
                        className="font-11 d-block"
                        style={{ mb: -5, color: "#212529" }}
                      >
                        {company}
                      </span>
                    ) : null}
                  </div>

                  <FontAwesomeIcon
                    icon={faChevronDown}
                    className="gray-color"
                  />
                </div>
              </button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                // nav
              >
                {normalizedUsersList?.map((account, i) => (
                  <MenuItem
                    key={account?.user?.id}
                    onClick={() => {
                      dispatch({
                        type: "SWITCH_ACCOUNT",
                        payload: { account },
                      });
                      history.push("/redirecting");
                    }}
                    className="d-flex flex-row justify-content-between align-items-center"
                    disabled={id === account?.user?.id}
                    style={{
                      opacity: id === account?.user?.id ? 0.5 : 1,
                      paddingInline: 10,
                    }}
                  >
                    <div
                      className={`d-flex flex-row align-items-center justify-content-between mr-1 ${
                        account?.user?.id == id
                          ? `border-bottom pb-2 w-100 ${
                              usersList?.length == 1 ? "mb-1" : "mb-3"
                            }`
                          : ""
                      } ${normalizedUsersList?.length === 0 ? "mb-3 " : ""}`}
                    >
                      <div className="d-flex flex-row align-items-center gap-5">
                        <span className="user-img accounts-list-avatar-style">
                          <Avatar
                            src={account?.user?.profile_picture?.path}
                            className="rounded_circle_user_img blackish-avatar"
                          >
                            {Helper.getIntials(account?.user?.name)}
                          </Avatar>
                        </span>
                        <div className="accounts-list-user-name-container">
                          <span className="align-middle gray-color text-ellipsis">
                            {account?.user?.name}
                          </span>
                          {account?.user?.user_type === "Owner" ? (
                            <span
                              className="font-11 d-block"
                              style={{ mb: -5, color: "#212529" }}
                            >
                              {account?.user?.company?.name}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    {id !== account?.user?.id ? (
                      <FontAwesomeIcon
                        size="md"
                        icon={faSignOutAlt}
                        className="red-color login-users-list-logout-btn-style"
                        onClick={(e) => {
                          e.stopPropagation();
                          dispatch({
                            type: "REMOVE_ACCOUNT",
                            payload: account?.user?.id,
                          });
                        }}
                      />
                    ) : null}
                  </MenuItem>
                ))}
                <MenuItem
                  className="d-flex flex-row justify-content-between align-items-center mt-2"
                  onClick={() => {
                    handleClose();
                    setAddAcoount(true);
                  }}
                >
                  <FontAwesomeIcon
                    size="lg"
                    icon={faPlusSquare}
                    className="blue-color"
                  />
                  <span className="dropdown-item add-new-account-button blue-color">
                    {t("add another account")}
                  </span>
                </MenuItem>
                <MenuItem
                  onClick={() => handleLogout(id)}
                  className="d-flex flex-row justify-content-between align-items-center"
                >
                  <FontAwesomeIcon
                    size="lg"
                    icon={faSignOutAlt}
                    className="red-color"
                  />
                  <span className="dropdown-item switch-account-logout-button red-color">
                    {t("logout")}
                  </span>
                </MenuItem>
              </Menu>
            </>
          )}
        </ul>
      </nav>

      {addAcoount ? (
        <MainModal
          hasModalFooter={false}
          isOpen={addAcoount}
          toggle={() => setAddAcoount(false)}
          modalTitle={t("add new account")}
        >
          <AddNewAccount dismiss={() => setAddAcoount(false)} />
        </MainModal>
      ) : null}

      {isUpsertLocale ? <Loader fixed /> : null}
    </>
  );
};

export default AttendanceNavbar;

const LogoutOnBehalf = ({ auth }) => {
  const dispatch = useDispatch();

  // Local State
  const tokenRef = useSelector((state) => state?.auth?.tokenRef);

  // Constants
  const originalUser = auth?.userProfile;
  const onBehalfUser = auth?.userProfileRef;

  /* ↓ Helpers ↓ */

  const handleLogout = () => {
    dispatch({
      type: "LOG_ON_OUT_BEHALF",
      payload: {
        tokenRef: null,
        token: tokenRef,
        userProfileRef: null,
        userProfile: auth?.userProfileRef,
      },
      isOut: true,
    });
  };

  return (
    <div className="d-flex gap-10 mx-4 align-items-center">
      {/* On Behalf Account */}
      <div className="d-flex gap-5">
        <span className="user-img">
          <Avatar
            src={originalUser?.profile_picture?.path}
            className="rounded_circle_user_img blackish-avatar"
            sx={{ bgcolor: "#2764ac !important" }}
          >
            {Helper.getIntials(originalUser?.name)}
          </Avatar>
        </span>

        <div className="text-left">
          <b className="align-middle d-none d-block blue-color">
            {originalUser?.name}
          </b>
          {originalUser?.user_type === "Owner" ? (
            <span className="font-11 d-block blue-color" style={{ mb: -5 }}>
              {originalUser?.company?.name}
            </span>
          ) : null}
        </div>
      </div>

      <ArrowForwardIosIcon
        sx={{
          mt: 0.5,
          "[dir='rtl'] &": { transform: "rotate(0deg)" },
          "[dir='ltr'] &": { transform: "rotate(180deg)" },
        }}
      />

      {/* Original Account */}
      <div
        onClick={handleLogout}
        className="cursor-pointer align-items-center d-flex gap-5"
        style={{ height: 40 }}
      >
        <span className="user-img">
          <Avatar
            src={onBehalfUser?.profile_picture?.path}
            className="rounded_circle_user_img blackish-avatar"
          >
            {Helper.getIntials(onBehalfUser?.name)}
          </Avatar>
        </span>

        <div className="text-left">
          <b className="align-middle d-none d-block">{onBehalfUser?.name}</b>
          {onBehalfUser?.user_type === "Owner" ? (
            <span className="font-11 d-block" style={{ mb: -5 }}>
              {onBehalfUser?.company?.name}
            </span>
          ) : null}
        </div>
      </div>
    </div>
  );
};
