import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import SalaryConfiguration from "./SalaryConfiguration";
import ViewSalaryConfigModal from "./ViewSalaryConfigModal";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import Loader from "../../Components/Loader";
import { initSalaryConfig } from "../../Store/Actions";
import { useParams } from "react-router-dom";
import UpsertSalaryConfigModal from "./UpsertSalaryConfigModal";
import { useTranslation } from "react-i18next";
import { useLazyQuery } from "@apollo/client";
import { GET_USER_SALARY_CONFIGS } from "../../Graphql/query";

const SalaryConfigTab = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { userId } = useParams();

  const [
    attemptGetUserSalaryConfigs,
    { data, loading: userSalaryConfigsLoading },
  ] = useLazyQuery(GET_USER_SALARY_CONFIGS);

  const userCountryId = useSelector(
    (state) => state?.super?.selectedEmployeeProfile?.office?.country?.id
  );

  const isDeleteSalaryConfigLoading = useSelector(
    (state) => state?.super?.deleteSalaryConfigLoading
  );

  const handleAddNewSalaryConfig = () => {
    dispatch(
      initSalaryConfig(data?.user?.office?.id, true, null, userCountryId)
    );
  };

  const handleFetchUserSalaryConfigs = () => {
    attemptGetUserSalaryConfigs({
      variables: {
        id: userId,
      },
    });
  };

  useEffect(() => {
    handleFetchUserSalaryConfigs();
    return () => {};
  }, [userId]);

  // for show salary config modal and use modal component one time
  const [ShowSalaryConfigModal, setShowSalaryConfigModal] = useState(false);

  const [salaryDetails, setSalaryDetails] = useState(null);

  const toggleShowSalaryConfigModal = (salaryDetails) => {
    setShowSalaryConfigModal(true);
    setSalaryDetails(salaryDetails);
  };

  const toggleHideSalaryConfigModal = () => {
    setShowSalaryConfigModal(false);
    setSalaryDetails(null);
  };

  if (userSalaryConfigsLoading) {
    return <Loader />;
  }

  return (
    <div className="employee-form">
      {isDeleteSalaryConfigLoading ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : null}

      <div className="d-flex flex-column flex-md-row justify-content-between align-items-center">
        <div></div>

        <HasPrivileges
          reqireMain={[Privilages.ADD_EMPLOYEE_SALARY_CONFIGURATION]}
          allowBP
          avalibleOnExpire={false}
          altExpireChildren={
            <button
              className="btn add_new_btn_style mt-3 mt-md-0 mx-4 mx-lg-0"
              type="button"
              disabled
            >
              {t("Add New Configuration")}
            </button>
          }
        >
          <button
            className="btn add_new_btn_style mt-3 mt-md-0 mx-4 mx-lg-0"
            type="button"
            onClick={handleAddNewSalaryConfig}
            disabled={data?.user?.active == "0"}
            title={data?.user?.active == "0" ? "employee is suspended" : null}
          >
            {t("Add New Configuration")}
          </button>
        </HasPrivileges>
      </div>

      <>
        {!data?.user?.salaryConfigurations?.length ? (
          <div className="mt-5 w-100 text-center">No Data Available ...</div>
        ) : (
          <div className="mt-4 mx-4 mx-lg-0">
            {data?.user?.salaryConfigurations?.map((salary) => (
              <SalaryConfiguration
                key={salary?.id}
                salaryConfig={salary}
                office_id={data?.user?.office?.id}
                handleShowSalaryConfigModal={toggleShowSalaryConfigModal}
                userId={userId}
                refetch={handleFetchUserSalaryConfigs}
              />
            ))}
          </div>
        )}
      </>

      {ShowSalaryConfigModal ? (
        <ViewSalaryConfigModal
          salaryConfig={salaryDetails}
          isOpen={ShowSalaryConfigModal}
          handleToggleModal={toggleHideSalaryConfigModal}
        />
      ) : null}
      <UpsertSalaryConfigModal refetch={handleFetchUserSalaryConfigs} />
    </div>
  );
};

export default SalaryConfigTab;
