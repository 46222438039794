import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  openModalContainer,
  showLoansModalAction,
  fetchAssignmentFormAction,
  showWorkTimingModalAction,
  showAddNewBonusModalAction,
  toggleAttendanceActionModal,
  showAddNewDeductionsModalAction,
} from "../../Store/Actions";
import HelperFns from "../../Helpers/HelperFns";
import Privileges from "../../Constants/Privilages";
import { addEditDeleteAssignmentPrivileges } from "../../Constants";

import {
  Add,
  Help,
  Drafts,
  Remove,
  LocalAtm,
  PersonAdd,
  ContentPaste,
  ManageHistory,
  AccessTimeFilled,
} from "@mui/icons-material";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";

const QuickActionsFB = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Reducer state
  const canAddNewEmployee = useSelector(
    (state) => state?.auth?.userProfile?.company?.canAddNewEmployee
  );

  // Constants
  const actions = React.useMemo(
    () =>
      [
        {
          icon: <PersonAdd />,
          label: "new employee",
          onClick: () => history.push(`/employees/new`),
          isRendered:
            !canAddNewEmployee &&
            HelperFns.checkPrivileges({
              allowBP: true,
              privileges: [Privileges.ADD_EDIT_DELETE_EMPLOYEES],
            }),
        },
        {
          icon: <AccessTimeFilled />,
          label: "new work timing",
          onClick: () => {
            handleOpenModal("newWorkTiming", showWorkTimingModalAction());
          },
          isRendered: HelperFns.checkPrivileges({
            allowBP: true,
            privileges: [Privileges.ADD_EDIT_CLONE_WORK_TIMINGS],
          }),
        },
        {
          icon: <ContentPaste />,
          label: "new assignment",
          onClick: () => {
            handleOpenModal(
              "newAssignment",
              fetchAssignmentFormAction({ includeInputs: true })
            );
          },
          isRendered: HelperFns.checkPrivileges({
            allowBP: true,
            privileges: addEditDeleteAssignmentPrivileges,
          }),
        },
        {
          icon: <ManageHistory />,
          label: "change shifts",
          onClick: () => {
            handleOpenModal(
              "changeShifts",
              fetchAssignmentFormAction({ includeInputs: true })
            );
          },
          isRendered: HelperFns.checkPrivileges({
            allowBP: true,
            privileges: [Privileges.CHANGE_SHIFT],
          }),
        },
        {
          icon: <Drafts />,
          label: "Request On Behalf",
          onClick: () => {
            handleOpenModal(
              "newRequestOnBehalf",
              toggleAttendanceActionModal()
            );
          },
          isRendered: HelperFns.checkPrivileges({
            allowBP: true,
            privileges: [Privileges.SUBMIT_REQUEST_ON_BEHALF_OF_EMPLOYEES],
          }),
        },
        {
          icon: <Help />,
          label: "new request",
          onClick: () => {
            handleOpenModal("newRequest", toggleAttendanceActionModal());
          },
          isRendered: HelperFns.checkPrivileges({
            privileges: [
              Privileges.APPLY_FOR_REQUESTS,
              Privileges.APPLY_FOR_LEAVE_REQUESTS,
              Privileges.APPLY_FOR_CLAIM_REQUESTS,
            ],
          }),
        },
        {
          icon: <LocalAtm />,
          label: "new loan",
          onClick: () => {
            handleOpenModal(
              "newLoan",
              showLoansModalAction({
                data: { id: null },
                ref: "loansModalActions",
                formName: "loansModal",
              })
            );
          },
          isRendered: HelperFns.checkPrivileges({
            allowBP: true,
            privileges: [Privileges.ADD_EDIT_DELETE_LOAN],
          }),
        },
        {
          icon: <Remove />,
          label: "Add New Deduction",
          onClick: () => {
            handleOpenModal(
              "newDeduction",
              showAddNewDeductionsModalAction({
                ref: "AddNewDeductionsModalActions",
                formName: "AddNewDeductionsModal",
              })
            );
          },
          isRendered: HelperFns.checkPrivileges({
            allowBP: true,
            privileges: [Privileges.ADD_EDIT_DELETE_BONUSES_DEDUCTIONS],
          }),
        },
        {
          icon: <Add />,
          label: "add new bonus",
          onClick: () => {
            handleOpenModal(
              "newBonus",
              showAddNewBonusModalAction({
                ref: "AddNewBonusModalActions",
                formName: "AddNewBonusModal",
              })
            );
          },
          isRendered: HelperFns.checkPrivileges({
            allowBP: true,
            privileges: [Privileges.ADD_EDIT_DELETE_BONUSES_DEDUCTIONS],
          }),
        },
      ].reverse(),
    [canAddNewEmployee]
  );

  /* ↓ Helpers ↓ */

  const handleOpenModal = (name, modalAction, cb) => {
    modalAction && dispatch(modalAction);
    dispatch(openModalContainer(name));
    cb && cb();
  };

  return (
    <SpeedDial
      icon={<SpeedDialIcon />}
      ariaLabel="Toggle quick actions menu"
      sx={{ position: "fixed", bottom: 20, right: 20 }}
      hidden={actions.every((action) => !action.isRendered)}
    >
      {actions
        .filter((action) => action.isRendered)
        .map((action) => (
          <SpeedDialAction
            key={action.label}
            icon={action.icon}
            onClick={action.onClick}
            tooltipTitle={t(action.label)}
          />
        ))}
    </SpeedDial>
  );
};

export default QuickActionsFB;
