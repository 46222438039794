import React from "react";
import { useFormValidation } from "../../Helpers/Hooks/useFormValidation";

import { connect } from "react-redux";
import IconButton from "@mui/material/IconButton";
import RemoveIcon from "@mui/icons-material/Remove";
import { ErrorOutline } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import Tooltip from "@mui/material/Tooltip";
import "./commonStyles.scss";
import "./InputFormStyle.scss";

import {
  onInputChangeAction,
  onInputResetAction,
  setInputInValidateAction,
  setInputValidateAction,
  onInputIncrementAction,
  onInputDecrementAction,
} from "../../Store/Actions";
import { withDependency } from "../../Helpers/HOC/withDependency";
import { useTranslation } from "react-i18next";
import { renderIcon } from "./formIcons";
import { numbersRegex } from "../../Constants";

const InputTooltip = ({ title = "", tooltipStyle }) => {
  return (
    <span className={`${tooltipStyle ? tooltipStyle : "tooltip-icon-style"}`}>
      <Tooltip placement="top" title={title}>
        <ErrorOutline fontSize="inherit" color="inherit" />
      </Tooltip>
    </span>
  );
};

const InputForm = ({
  name,
  dependOn,
  label,
  formName,
  _onChange,
  validateBy,
  isMultiLines,
  formSubmitting,
  handleInValidForm,
  FormValid = [],
  formNameValidation,
  containerStyle,
  inputContainerStyle,
  inputStyle,
  labelStyle,
  stepInputStyle,
  stepInput,
  hasSuffix,
  suffixStyle,
  suffixTitle,
  icon,
  iconStyle,
  hasTooltip,
  tooltipTitle,
  tooltipStyle,
  validateContainerStyle,
  validateMessage,
  reducer,
  formServerValidation,
  rootStyle = "",
  validationName = undefined,
  dependVal,
  skipLocalization = false,
  hasMinMax,
  minValue = 0,
  maxValue = 0,
  isNumberInput = false,
  ...props
}) => {
  const inputval = props.value ?? props.super[formName]?.[name];
  const isDisabled = props.disabled;
  const validate = useFormValidation(
    inputval,
    validationName ?? name,
    formNameValidation,
    formSubmitting,
    validateBy,
    isDisabled,
    dependVal
  );

  const { t } = useTranslation();

  const isServerValid = !Boolean(props.super[formServerValidation])
    ? false
    : props.super[formServerValidation][validationName ?? name]
    ? true
    : false;

  const handleInputChange = (event) => {
    if (
      isNumberInput &&
      event.target.value != "" &&
      !numbersRegex.test(event.target.value)
    ) {
      console.log("return");
      return;
    }

    event.persist();
    props.onInputChangeAction(formName, event);
  };

  const handleInputBlur = (event) => {
    event.persist();
    let d;
    if (hasMinMax) {
      d = event.target.value;
      if (Number(event.target.value) < Number(minValue)) {
        d = minValue;
      }
      if (Number(event.target.value) > Number(maxValue)) {
        d = maxValue;
      }
      event.target.value = d;
    }
    props.onInputChangeAction(formName, event);
  };

  const handleInputWheel = (event) => {
    if (event.target.type === "number") {
      event.target.blur();
    }
  };

  const handleIncrementChange = (event) => {
    props?.childInput
      ? props?.childInputIncrement()
      : props?.onInputIncrementAction(formName, name);
  };

  const handleDecrementChange = (event) => {
    props?.childInput
      ? props?.childInputDecrement()
      : props?.onInputDecrementAction(formName, name);
  };

  const vaildContainer = () => {
    if (!validate.validity || isServerValid) {
      return validateContainerStyle ?? "invalid-container-style";
    }
    return " ";
  };

  return (
    <div className={`${vaildContainer()} ${rootStyle}`}>
      <div className={`${containerStyle ?? "container-style-default"}`}>
        {/* Input label */}
        {label && (
          <label
            className={`label-style-default ${
              labelStyle ?? ""
            } validity-label-style`}
          >
            {skipLocalization ? label : t(label)}
            {hasTooltip && (
              <InputTooltip title={tooltipTitle} tooltipStyle={tooltipStyle} />
            )}
          </label>
        )}

        {stepInput ? (
          // Step input
          <div
            className={`input-container-style-default step-input-container ${
              inputContainerStyle ?? ""
            } validity-input-style `}
          >
            <IconButton
              color="primary"
              aria-label="add to shopping cart"
              className="buttonRoot"
              onClick={handleDecrementChange}
              disabled={isDisabled}
            >
              <RemoveIcon classes={{ root: "iconRoot" }}></RemoveIcon>
            </IconButton>

            <input
              dir="auto"
              className={`stepInputRoot ${stepInputStyle ?? ""}`}
              name={name}
              value={props.super[formName][name]}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              onWheel={handleInputWheel}
              type={props.type}
              {...props}
              disabled={isDisabled}
            />

            <IconButton
              color="primary"
              aria-label="add to shopping cart"
              className="buttonRoot"
              onClick={handleIncrementChange}
              disabled={isDisabled}
            >
              <AddIcon classes={{ root: "iconRoot" }}></AddIcon>{" "}
            </IconButton>
          </div>
        ) : hasSuffix ? (
          // Suffix input
          <div
            className={`input-container-style-default ${
              inputContainerStyle ?? ""
            } validity-input-style`}
          >
            <input
              dir="auto"
              className={`suffix-input ${
                inputStyle ?? ""
              } validity-input-style`}
              name={name}
              value={props?.super?.[formName]?.[name]}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              onWheel={handleInputWheel}
              type={props.type}
              {...props}
            />
            <span
              className={`suffix-style-default ${
                suffixStyle ?? ""
              } validity-input-style`}
            >
              {t(suffixTitle)}
            </span>
          </div>
        ) : (
          // Normal Input
          <div
            className={`input-container-style-default ${
              inputContainerStyle ?? ""
            }`}
          >
            {isMultiLines ? (
              <textarea
                dir="auto"
                className={`${inputStyle} validity-input-style`}
                name={name}
                value={inputval}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                onWheel={handleInputWheel}
                type={props.type}
                style={{ resize: "none" }}
                {...props}
              />
            ) : (
              <>
                {icon ? (
                  <div className={`input_icon ${iconStyle ?? ""}`}>
                    {renderIcon(icon)}
                  </div>
                ) : null}
                <input
                  dir="auto"
                  className={`input-style-default ${icon ? "px-4" : ""} ${
                    inputStyle ?? ""
                  } validity-input-style`}
                  name={name}
                  value={inputval}
                  onChange={handleInputChange}
                  onBlur={handleInputBlur}
                  onWheel={handleInputWheel}
                  type={props.type}
                  {...props}
                />
              </>
            )}
          </div>
        )}
      </div>

      {/* Client Validation Message */}
      <div className="validity-msg-style">
        {validateMessage ?? validate?.validityMessage}
        {validate.validityMessage && <br />}
        {Boolean(props.super[formServerValidation])
          ? props.super[formServerValidation][
              validationName ?? name
            ]?.toString() ?? ""
          : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state, { reducer = "super" }) => {
  return {
    super: state[reducer],
  };
};

export default connect(mapStateToProps, {
  onInputChangeAction,
  onInputResetAction,
  setInputInValidateAction,
  setInputValidateAction,
  onInputDecrementAction,
  onInputIncrementAction,
})(withDependency(InputForm));
