import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  editPenaltyMutation,
  revertPenaltyMutation,
  applyPenaltiesMutation,
} from "../../Graphql/mutation";
import swal from "sweetalert";
import Constants from "../../Constants";
import { showToast } from "../../Helpers/HelperFns";
import Privilages from "../../Constants/Privilages";
import { updateValueAction } from "../../Store/Actions";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";

import Loader from "../Loader";
import IconButtonWithTooltip, {
  EditIconButton,
  TimesIconButton,
  AcceptIconButton,
} from "../IconButtonWithTooltip";
import AntSwitch from "../AntSwitch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Select, MenuItem, TextField, FormControlLabel } from "@mui/material";

const formName = "editPenaltyModal";

const PenaltyUnit = ({ penalty, setIsDirty }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modalData = useSelector((state) => state.super[formName]);

  // Local State
  const [isEditMode, setIsEditMode] = useState(false);
  const [quantity, setQuantity] = useState(penalty?.quantity);
  const [quantityUnit, setQuantityUnit] = useState(penalty?.quantity_unit);

  // Mutations
  const [applyPenalties, { loading: isApplyLoading, error: applyError }] =
    useMutation(applyPenaltiesMutation, {
      variables: { id: penalty.id },
      onError: (err) => showToast("error", err?.graphQLErrors?.[0]?.extensions?.reason ?? err?.message),  
      onCompleted: ({ switch_apply_flag }, { variables }) => {
        if (applyError) {
          showToast("error", applyError?.graphQLErrors?.[0]?.extensions?.reason ??applyError?.message);
        } else {
          setIsDirty(true);
          handleUpdate(switch_apply_flag, variables.id);
        }
      },
    });
  const [revertPenalties, { loading: isRevertLoading, error: revertError }] =
    useMutation(revertPenaltyMutation, {
      variables: { id: penalty.id },
      onError: (err) => showToast("error", err?.message),
      onCompleted: ({ revert_penalty }) => {
        if (revertError) {
          showToast("error", revertError?.message);
        } else {
          setIsDirty(true);
          dispatch(updateValueAction(formName, "penalties", [revert_penalty]));
        }
      },
    });
  const [editPenalty, { loading: isEditLoading, error: editError }] =
    useMutation(editPenaltyMutation, {
      variables: {
        input: {
          id: penalty.id,
          quantity,
          quantity_unit: quantityUnit,
        },
      },
      onError: (err) => showToast("error", err?.message),
      onCompleted: ({ edit_penalty }, { variables }) => {
        if (editError) {
          showToast("error", editError?.message);
        } else {
          setIsDirty(true);
          setIsEditMode(false);
          handleUpdate(edit_penalty, variables.input.id);
        }
      },
    });

  /* ↓ Helpers ↓ */

  const handleUpdate = (obj, id) => {
    const state = penalty?.hasComplementaryPenalty
      ? [obj]
      : [...modalData.penalties.filter((p) => p.id !== id), obj];
    dispatch(updateValueAction(formName, "penalties", state));
  };

  const handleEdit = () => {
    if (penalty?.hasComplementaryPenalty) {
      swal({
        title: t("are you sure"),
        text: t(
          "If you want to edit the part of the penalty that is deducted from salary, the part of the penalty deducted from annual leaves will be removed"
        ),
        icon: "warning",
        className: "swal-warning-style",
        buttons: true,
        dangerMode: true,
      }).then((isConfirmed) => {
        isConfirmed && editPenalty();
      });
    } else editPenalty();
  };

  const handleApply = () => {
    if (penalty?.hasComplementaryPenalty) {
      swal({
        title: t("are you sure"),
        text: t(
          "If you want to edit the part of the penalty that is deducted from salary, the part of the penalty deducted from annual leaves will be removed"
        ),
        icon: "warning",
        className: "swal-warning-style",
        confirmButtonText: t("OK"),
        cancelButtonText: t("Cancel"),
        dangerMode: true,
      }).then((isConfirmed) => {
        isConfirmed && applyPenalties();
      });
    } else applyPenalties();
  };

  return (
    <>
      {isApplyLoading || isRevertLoading || isEditLoading ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : null}

      <div className="d-flex flex-wrap flex-lg-nowrap align-items-center gap-10">
        <div
          style={{ width: 200 }}
          className="d-flex align-items-center gap-10"
        >
          {t("deducted from")}{" "}
          <strong className="blue-color">{t(penalty.deducted_from)}</strong>
        </div>

        <div className="flex-1 d-flex align-items-center gap-10">
          <div className="d-flex gap-10 align-items-center">
            {isEditMode ? (
              <>
                <Select
                  size="small"
                  value={quantityUnit}
                  aria-label={t("quantity unit")}
                  placeholder={t("quantity unit")}
                  onChange={(e) => setQuantityUnit(e.target.value)}
                >
                  {[
                    ...Constants.violdationQuantityUnitsOptions,
                    {
                      value: "minutes",
                      label: "Minutes",
                    },
                  ].map((opt) => (
                    <MenuItem key={opt.value} value={opt.value}>
                      {t(opt.label)}
                    </MenuItem>
                  ))}
                </Select>
                <TextField
                  size="small"
                  type="number"
                  value={quantity}
                  sx={{ width: 70 }}
                  aria-label={t("quantity")}
                  placeholder={t("quantity")}
                  onChange={(e) => setQuantity(+e.target.value)}
                />
              </>
            ) : (
              <>
                <span>{penalty?.quantity}</span>
                <span className="text-nowrap">{t(penalty?.quantity_unit)}</span>
              </>
            )}
          </div>

          {penalty.canEdit ? (
            <div className="d-flex">
              {penalty?.apply_flag && isEditMode ? (
                <>
                  <AcceptIconButton onClick={handleEdit} />
                  <TimesIconButton
                    label="Cancel"
                    onClick={() => {
                      setQuantity(penalty?.quantity);
                      setQuantityUnit(penalty?.quantity_unit);
                      setIsEditMode(false);
                    }}
                  />
                </>
              ) : (
                <>
                  {penalty?.apply_flag && (
                    <HasPrivileges
                      allowBP
                      reqireMain={[Privilages.APPLY_REVERT_PENALTIES]}
                    >
                      <EditIconButton onClick={() => setIsEditMode(true)} />
                    </HasPrivileges>
                  )}
                  {penalty?.apply_flag && penalty?.edited ? (
                    <IconButtonWithTooltip
                      label="Revert"
                      onClick={() => revertPenalties()}
                      icon={
                        <FontAwesomeIcon
                          icon="fa-solid fa-redo"
                          className="sec-color"
                        />
                      }
                    />
                  ) : null}
                </>
              )}
            </div>
          ) : null}
        </div>

        {penalty?.canApply ? (
          <HasPrivileges
            reqireMain={[Privilages.APPLY_REVERT_PENALTIES]}
            allowBP
            altChildren={
              <FormControlLabel
                disabled
                label={t("Applied")}
                labelPlacement="start"
                control={
                  <AntSwitch checked={penalty?.apply_flag} className="mx-2" />
                }
              />
            }
            avalibleOnExpire={false}
            altExpireChildren={
              <FormControlLabel
                disabled
                label={t("Applied")}
                labelPlacement="start"
                control={
                  <AntSwitch checked={penalty?.apply_flag} className="mx-2" />
                }
              />
            }
          >
            <FormControlLabel
              label={t("Applied")}
              labelPlacement="start"
              onChange={handleApply}
              control={
                <AntSwitch checked={penalty?.apply_flag} className="mx-2" />
              }
            />
          </HasPrivileges>
        ) : null}
      </div>
    </>
  );
};

export default PenaltyUnit;
