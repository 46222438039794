import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLazyQuery, useMutation } from "@apollo/client";

import {
  updateValueAction,
  onFormResetAction,
  onInputResetAction,
} from "../../Store/Actions";
import { showToast } from "../../Helpers/HelperFns";
import { changeTiersModalQuery } from "../../Graphql/query/PaymentTracking";
import { changeSubscriptionTiersMutation } from "../../Graphql/mutation/PaymentTracking";

import {
  BSelect,
  InputForm,
  RadioboxForm,
  DateTimePickerForm,
} from "form-builder";
import Loader from "../Loader";
import Box from "@mui/material/Box";
import MainModal from "../MainModal";

const reducer = "paymentTracking";
const formName = "changeTiersModal";
const formNameValidation = "paymentTrackingClientValidation";
const formServerValidation = "paymentTrackingServerValidation";
const formatChangeSubscriptionTiersData = (data) => {
  const json = JSON.stringify({
    type: data?.type,
    tier: data?.tier,
    from: data?.startFrom,
  });

  return {
    json,
    from: data?.from,
    company_id: +data?.company_id,
  };
};

const ChangeTiersModal = ({ onClose, refetchList, ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Local State
  const [errMsg, setErrMsg] = React.useState("");
  const [options, setOptions] = React.useState({ tiers: [] });
  const [formSubmitting, setFormSubmitting] = React.useState(false);
  const [data, setData] = React.useState({ ...(props?.data || {}) });
  const [isSelectMonthYear, setIsSelectMonthYear] = React.useState(true);

  // Reducer State
  const formData = useSelector((state) => state?.[reducer]?.[formName]);
  const formClientValidation = useSelector(
    (state) => state?.[reducer]?.[formNameValidation]
  );

  // Server State
  const [changeSubscriptionTiers, { loading: changeLoading }] = useMutation(
    changeSubscriptionTiersMutation
  );
  const [getData, { loading }] = useLazyQuery(changeTiersModalQuery);

  // Constants
  const FormProps = {
    reducer,
    formName,
    formNameValidation,
    formServerValidation,
    formSubmitting,
  };

  /* ↓ State Effects ↓ */

  React.useEffect(() => {
    if (formData.type && formData.startFrom) {
      dispatch(
        onFormResetAction(formName, {
          ...formData,
          tier: null,
          ratePerPeriod: "",
        })
      );
      getData({
        variables: {
          from: data?.from,
          type: formData?.type,
          date: formData.startFrom,
          company_id: +data?.company_id,
        },
        onCompleted: ({ tiers }) => {
          const data = tiers
            ? JSON.parse(JSON.parse(tiers || "{}") || "{}")
            : {};

          setData((prev) => ({
            ...prev,
            ratePerPeriod: data?.rpp,
            currentTier: data?.currentTier,
          }));
          setOptions((prev) => ({
            ...prev,
            tiers: data?.tiers || [],
          }));
        },
        onError: () => {
          setData((prev) => ({
            ...prev,
            tier: null,
            ratePerPeriod: "",
          }));
          setOptions((prev) => ({
            ...prev,
            tiers: [],
          }));
        },
      });
    }
  }, [formData.type, formData.startFrom]);

  /* ↓ Helpers ↓ */

  const onSelectTier = (_, tier) => {
    dispatch(updateValueAction(formName, "ratePerPeriod", tier.rppName));
  };

  const handleToggleIsSelectMonthYear = () => {
    setIsSelectMonthYear((prev) => !prev);
    dispatch(onInputResetAction(formName, "startFrom"));
  };

  const handleCloseModal = () => {
    onClose();
    dispatch(onFormResetAction(formName));
    dispatch(onFormResetAction(formNameValidation));
    dispatch(onFormResetAction(formServerValidation));
  };

  const handleSubmit = () => {
    setFormSubmitting(true);
    if (formClientValidation?.length) return;
    changeSubscriptionTiers({
      variables: formatChangeSubscriptionTiersData({
        ...formData,
        id: data?.id,
        from: data?.from,
        company_id: data?.company_id,
      }),
      onCompleted: () => {
        refetchList();
        handleCloseModal();
        showToast("success");
      },
      onError: (err) => {
        const validation = err?.graphQLErrors?.[0]?.extensions?.validation;
        dispatch(onFormResetAction(formServerValidation, validation || {}));

        const msg =
          validation?.json?.[0] ||
          err?.graphQLErrors?.[0]?.extensions?.reason ||
          err?.graphQLErrors?.[0]?.message ||
          err?.message;
        setErrMsg(msg || "");
      },
    });
  };

  return (
    <MainModal
      isOpen
      modalTitle="Change Tiers"
      toggle={handleCloseModal}
      btnOnClick={handleSubmit}
      btnSubmitLoading={changeLoading}
    >
      {loading ? <Loader fixed /> : null}

      <RadioboxForm
        {...FormProps}
        name="type"
        options={[
          { value: "Increase", label: "Increase" },
          { value: "Decrease", label: "Decrease" },
        ]}
        optionItemStyle=" "
        optionInputStyle=" "
        containerStyle="mb-3"
        optionsContainerStyle="d-flex gap-20"
      />

      <div className="d-flex justify-content-between mb-2">
        <div>
          <b className="dark-color d-block mb-2">{t("Current Tier")}</b>
          {data?.currentTier}
        </div>
        <div>
          <b className="dark-color d-block mb-2">{t("Rate Per Period")}</b>
          {data?.ratePerPeriod}
        </div>
      </div>

      <div className="position-relative mt-2">
        {/* TODO: add privilege */}
        {formData.type === "Decrease" ? (
          <Box sx={{ position: "absolute", top: 0, right: 0 }}>
            <button
              className="link-btn"
              onClick={handleToggleIsSelectMonthYear}
            >
              {t(isSelectMonthYear ? "Select Custom date" : "Select date")}
            </button>
          </Box>
        ) : null}

        {isSelectMonthYear && formData.type === "Decrease" ? (
          <DateTimePickerForm
            {...FormProps}
            name="startFrom"
            label="Start From"
            format="yyyy-MM-01"
            requestFormat="yyyy-MM-01"
            validateBy="textRequired"
            picker="month"
            labelStyle="mb-2 pt-1"
            containerStyle="w-100 mb-2"
            hasIcon
            validationName="from"
          />
        ) : (
          <DateTimePickerForm
            {...FormProps}
            name="startFrom"
            label="Start From"
            format="yyyy-MM-DD"
            requestFormat="yyyy-MM-DD"
            validateBy="textRequired"
            labelStyle="mb-2 pt-1"
            containerStyle="w-100 mb-2"
            hasIcon
            validationName="from"
          />
        )}
      </div>

      <BSelect
        {...FormProps}
        label="New Tier"
        name="tier"
        icon="employees"
        validateBy="textRequired"
        rootStyle="w-50 d-inline-block pr-2"
        options={options?.tiers || []}
        onInterceptInputOnChange={onSelectTier}
        disabled={!formData?.startFrom}
        optionValue="quantity"
        validationName="tier"
      />
      <InputForm
        {...FormProps}
        name="ratePerPeriod"
        label="Rate Per Period"
        labelStyle="mb-2 pt-1"
        containerStyle="w-100"
        rootStyle="w-50 d-inline-block"
        validateBy="textRequired"
        icon="calculator"
        disabled
      />

      {errMsg ? (
        <p role="alert" className="red-color mt-2 mb-0">
          {t(errMsg)}
        </p>
      ) : null}
    </MainModal>
  );
};

export default ChangeTiersModal;
