import { faMoneyBill } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BSelect, InputForm } from "form-builder";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import CheckboxBooleanForm from "../../Builder/Form/CheckboxBooleanForm";
import { updateValueAction } from "../../Store/Actions";
import SinglePaymentForm from "./SinglePaymentForm";

const GET_OPTIONS = gql`
  query getCountryOptions($countryId: ID!) {
    country(id: $countryId) {
      name
      short_name

      banks {
        id
        name
      }
      phoneWallets {
        id
        name
      }
      prepaidCards {
        id
        name
      }
    }
  }
`;

const paymentInitState = {
  user_payment_id: null,
  user_id: "null",
  payment_info: 0,
  payment_method: "",
  bank_id: "",
  account_name: "",
  account_number: "",
  iban: "",
  prepaid_card_id: "",
  card_number: "",
  phone_wallet_id: null,
  code: null,
  number: null,
  country_short_name: null,
  deletePayment: false,
};

const UpdateOrCreateUserPayment = ({
  isEmployeeForm = true,
  formName,
  formSubmitting,
  formServerValidation,
  countryId,
  userId,
  office,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formProps = {
    formName,
    formServerValidation,
    formSubmitting,
    formNameValidation: "paymentMethodFormValidation",
  };

  const paymentMethodForm = useSelector(
    (state) => state?.super?.paymentMethodForm
  );

  const { data: optionsData, loading: optionsLoading } = useQuery(GET_OPTIONS, {
    variables: {
      countryId: countryId,
    },
    skip: !Boolean(countryId),
  });

  return (
    <div
      className={`${isEmployeeForm ? "boxContainer" : ""} mb-3`}
    >
      {isEmployeeForm ? (
        <>
            <div className="name_container">
              <FontAwesomeIcon
                className=""
                icon={faMoneyBill}
                color="#23aaeb"
                size="2x"
              />
              <h4
                className={
                  isEmployeeForm
                    ? "sub-title-style mb-0"
                    : "secondary_title my-3"
                }
              >
                {t("Payment Info")}
              </h4>
            </div>
          <hr />
        </>
      ) : null}
      {paymentMethodForm?.payments?.map((payment, index) => (
        <SinglePaymentForm
          payment={payment}
          optionsData={optionsData}
          optionsLoading={optionsLoading}
          index={index}
          formSubmitting={formSubmitting}
        />
      ))}
    </div>
  );
};

export default UpdateOrCreateUserPayment;
