import React from "react";
import CreateSelect from "../../Builder/Form/CreateSelect";
import { CheckboxBooleanForm, InputForm, BSelect } from "form-builder";

import { useDispatch, useSelector } from "react-redux";

import {
  createNewAllowanceAction,
  removeAllowanceInputAction,
  childInputChange,
  childCheckboxChange,
} from "../../Store/Actions";
import PaymentFactors from "../../Constants/PaymentFactors";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const AllowanceSection = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    formName,
    formNameValidation,
    formSubmitting,
    formServerValidation,
    allowance,
    index,
  } = props;
  const FormProps = {
    formName,
    formNameValidation,
    formSubmitting,
    formServerValidation,
  };

  const paymentFactors = useSelector((state) => state.super.paymentFactors);
  const salaryConfigForm = useSelector((state) => state.super.salaryConfigForm);
  const allowancesOptions = useSelector(
    (state) => state.super.allowancesOptions
  );

  const handleCreateAllowance = (newValue, index) => {
    dispatch(createNewAllowanceAction(newValue, index));
  };

  const handleRemoveAllowanceInput = (index) => {
    dispatch(removeAllowanceInputAction(index));
  };

  const handleChildInputChange = (event, childName, index) => {
    dispatch(
      childInputChange(
        formName,
        childName,
        event.target.name,
        index,
        event.target.value
      )
    );
  };

  const handleSelectInputChange = (val, event, childName, index) => {
    dispatch(
      childInputChange(
        formName,
        childName,
        event.name,
        index,
        val ? val?.value : ""
      )
    );
  };

  const handleChildCheckboxChange = (event, childName, index) => {
    dispatch(childCheckboxChange(formName, childName, event, index));
  };

  return (
    <>
      <div className="d-flex flex-column flex-md-row align-items-center justify-content-between">
        <div className="my-1 w-100 mr-0 mr-md-3">
          <CreateSelect
            {...FormProps}
            icon="employee"
            optionLabel="label"
            optionValue="value"
            keepDefaultStyle
            containerStyle=""
            placeholder={t("Select Allowance Name")}
            inputContainerStyle="w-100"
            validateBy="textRequired"
            isClearable
            onCreateOption={(newValue) =>
              handleCreateAllowance(newValue, index)
            }
            name="allowance_id"
            isLoading={props?.allowanceLoadingOpts?.includes(index)}
            options={allowancesOptions}
            value={
              allowancesOptions?.filter(
                (opt) => opt.value == allowance.allowance_id
              )[0]
            }
            onChange={(e, val) =>
              handleSelectInputChange(e, val, "salary_allowances", index)
            }
            validationName={`input.user_salary_config_input.salary_allowances.${index}.allowance_id`}
          />
        </div>

        <div className="w-100 mr-0 mr-md-3">
          <BSelect
            {...FormProps}
            name="payment_factor_id"
            optionLabel="label"
            optionValue="value"
            options={paymentFactors}
            keepDefaultStyle
            containerStyle=""
            placeholder={t("Select Payment Factor")}
            inputContainerStyle="w-100"
            validateBy="textRequired"
            optDependType="equal"
            optDependKey="allowance"
            optDependValue={["1", 1]}
            value={
              paymentFactors?.filter(
                (opt) => opt?.value == allowance?.payment_factor_id
              )[0]
            }
            onChange={(e, val) =>
              handleSelectInputChange(e, val, "salary_allowances", index)
            }
            validationName={`input.user_salary_config_input.salary_allowances.${index}.payment_factor_id`}
            icon="money"
            isDisabled={
              salaryConfigForm?.calculate_salary_by !== "payment_interval"
            }
          />
        </div>

        <div className="d-flex align-items-center justify-content-between w-100">
          <div className="w-100 mr-3">
            <InputForm
              name="value"
              type="number"
              onWheelCapture={(e) => {
                e.target.blur();
              }}
              {...FormProps}
              placeholder={
                allowance?.payment_factor_id == PaymentFactors.FIXED_AMOUNT
                  ? t("amount")
                  : "%"
              }
              labelStyle="mb-0"
              containerStyle=""
              inputContainerStyle="w-100"
              validateBy="textRequired"
              value={allowance.value}
              onChange={(e) =>
                handleChildInputChange(e, "salary_allowances", index)
              }
              validationName={`input.user_salary_config_input.salary_allowances.${index}.value`}
              icon="money"
            />
          </div>

          <div className="text-initial">
            <button
              className="btn delete_btn_style"
              type="button"
              onClick={() => handleRemoveAllowanceInput(index)}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
        </div>
      </div>

      <div className="mx-2 my-2 d-flex flex-column flex-lg-row align-items-center">
        <label className="mb-2 mb-lg-0 text-nowrap">
          {t("This allowance should not be payable on")}
        </label>

        <div className="mx-lg-4">
          <CheckboxBooleanForm
            {...FormProps}
            options={[t("unpaid leaves")]}
            name="not_payable_on_un_paid_days"
            type="checkbox"
            containerStyle="mb-2 mb-lg-0"
            onChange={(e) =>
              handleChildCheckboxChange(e, "salary_allowances", index)
            }
            value={allowance?.not_payable_on_un_paid_days}
            checked={allowance?.not_payable_on_un_paid_days}
            optionLabelStyle={
              allowance?.not_payable_on_un_paid_days
                ? "lightActiveColor font-weight-bold"
                : "booleanInputLabelStyle font-weight-bold"
            }
            validationName={`input.user_salary_config_input.salary_allowances.${index}.not_payable_on_un_paid_days`}
          />
        </div>

        <div className="mx-lg-4">
          <CheckboxBooleanForm
            {...FormProps}
            options={[t("paid leaves")]}
            name="not_payable_on_paid_days"
            type="checkbox"
            containerStyle="mb-2mb-lg-0"
            onChange={(e) =>
              handleChildCheckboxChange(e, "salary_allowances", index)
            }
            value={allowance?.not_payable_on_paid_days}
            checked={allowance?.not_payable_on_paid_days}
            optionLabelStyle={
              allowance?.not_payable_on_paid_days
                ? "lightActiveColor font-weight-bold"
                : "booleanInputLabelStyle font-weight-bold"
            }
            validationName={`input.user_salary_config_input.salary_allowances.${index}.not_payable_on_paid_days`}
          />
        </div>

        <div className="mx-lg-4">
          <CheckboxBooleanForm
            {...FormProps}
            options={[t("absent days")]}
            name="not_payable_on_absent_days"
            type="checkbox"
            containerStyle="mb-2mb-lg-0"
            onChange={(e) =>
              handleChildCheckboxChange(e, "salary_allowances", index)
            }
            value={allowance?.not_payable_on_absent_days}
            checked={allowance?.not_payable_on_absent_days}
            optionLabelStyle={
              allowance?.not_payable_on_absent_days
                ? "lightActiveColor font-weight-bold"
                : "booleanInputLabelStyle font-weight-bold"
            }
            validationName={`input.user_salary_config_input.salary_allowances.${index}.not_payable_on_absent_days`}
          />
        </div>
      </div>
    </>
  );
};

export default AllowanceSection;
