import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";

import {
  showToast,
  formatOptions,
  openSwalConfirm,
  formatSendingInputs,
} from "../../Helpers/HelperFns";
import moment from "moment";
import Privileges from "../../Constants/Privilages";
import { dateTimeListFormat } from "../../Constants";
import { removeTrialMutation } from "../../Graphql/mutation";
import { trialsListOptionsQuery, trialsListQuery } from "../../Graphql/query";

import {
  EditIconButton,
  RemoveIconButton,
} from "../../Components/IconButtonWithTooltip";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import Loader from "../../Components/Loader";
import DataTable from "react-data-table-component";
import Pagination from "../../Components/Pagination";
import { AddButton } from "../../Components/Buttons";
import { TrialModal } from "../../Components/CRMFormModals";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import { InputForm, BSelect, DateTimePickerForm } from "form-builder";

const modalInitState = { isOpen: false, data: null };
const paginationInitState = {
  total: 20,
  perPage: 20,
  lastPage: 1,
  lastItem: 20,
  firstItem: 1,
  currentPage: 1,
  hasMorePages: false,
};
const sendFilters = (filters) => {
  return {
    page: 1,
    ...formatSendingInputs(filters),
    company: filters?.company,
  };
};

const TrialsList = ({ isLeadProfile, leadProfileData, adminID }) => {
  const { t } = useTranslation();
  const isAdminProfile = Boolean(adminID);

  // Local State
  const [trials, setTrials] = useState([]);
  const [options, setOptions] = useState({ users: [] });
  const [modalState, setModalState] = useState(modalInitState);
  const [pagination, setPagination] = useState(paginationInitState);
  const [filters, setFilters] = useState({
    to: "",
    from: "",
    company: "",
    user_id: "",
    status: null,
    assigned_to: isAdminProfile ? adminID : null,
    lead_ids: isLeadProfile ? [leadProfileData?.id] : null,
  });

  // Server State
  const [removeTrial, { loading: removeLoading }] =
    useMutation(removeTrialMutation);
  const { loading: isOptionsLoading } = useQuery(trialsListOptionsQuery, {
    onCompleted: ({ users }) => {
      setOptions({ users: users?.data });
    },
    onError: (err) => {
      showToast(
        "error",
        err?.graphQLErrors?.[0]?.extensions?.reason ||
          err?.graphQLErrors?.[0]?.message ||
          err?.message
      );
    },
  });
  const { refetch: refetchList, loading: isListLoading } = useQuery(
    trialsListQuery,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        ...sendFilters(filters),
        first: pagination.perPage,
      },
      onCompleted: ({ trials }) => {
        setTrials(trials?.data);
        setPagination(trials?.paginatorInfo);
      },
      onError: (err) => {
        showToast(
          "error",
          err?.graphQLErrors?.[0]?.extensions?.reason ||
            err?.graphQLErrors?.[0]?.message ||
            err?.message
        );
      },
    }
  );

  // Constants
  const columns = [
    {
      name: t("company"),
      cell: ({ subscription = {}, lead }) => (
        <HasPrivileges
          reqireMain={[
            Privileges.SUPER_PRIVILEGE,
            Privileges.VIEW_LEAD_PROFILE,
          ]}
          altChildren={subscription?.company?.name}
        >
          <Link to={`/crm/lead/${lead?.id}`}>
            {subscription?.company?.name}
          </Link>
        </HasPrivileges>
      ),
    },
    {
      name: t("business partner"),
      cell: ({ subscription = {} }) =>
        subscription?.company?.businessPartners
          ?.map((b) => b?.user?.name)
          .join(", "),
    },
    {
      name: t("packages"),
      cell: ({ subscription = {} }) => subscription?.plan?.name,
    },
    {
      name: t("from"),
      cell: ({ subscription = {} }) =>
        moment(subscription?.start_date).format(dateTimeListFormat),
    },
    {
      name: t("to"),
      cell: ({ subscription = {} }) =>
        moment(subscription?.end_date).format(dateTimeListFormat),
    },
    { name: t("created by"), cell: ({ user = {} }) => user?.name },
    {
      name: t("primary account manager"),
      cell: ({ subscription = {} }) =>
        subscription?.company?.primarySAAS?.user?.name,
    },
    {
      name: t("secondary account managers"),
      cell: ({ subscription = {} }) =>
        subscription?.company?.secondarySAAS
          ?.map(({ user = {} }) => user?.name)
          .join(", "),
    },
    { name: t("status"), selector: "status" },
    {
      name: t("No. Of Employees"),
      selector: "subscription.limit",
    },
    {
      cell: (data) => (
        <>
          <HasPrivileges
            reqireMain={[
              Privileges.ADD_EDIT_TRIALS,
              Privileges.SUPER_PRIVILEGE,
            ]}
          >
            <EditIconButton
              onClick={() =>
                setModalState({ isOpen: true, data: { id: data.id } })
              }
            />
          </HasPrivileges>
          <HasPrivileges
            reqireMain={[Privileges.DELETE_TRIALS, Privileges.SUPER_PRIVILEGE]}
          >
            <RemoveIconButton onClick={() => handleRemoveTrial(data)} />
          </HasPrivileges>
        </>
      ),
    },
  ];

  /* ↓ Helpers ↓ */

  const handlePaginate = (page = pagination.currentPage) => {
    refetchList({ page });
  };

  const handleTextFilters = (e) => {
    setFilters((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSelectFilters = (val, { name }) => {
    setFilters((prev) => ({ ...prev, [name]: val }));
  };

  const handleDateFiltersChange = (name, val) => {
    setFilters((prev) => ({ ...prev, [name]: val }));
  };

  const handleRemoveTrial = (data) => {
    openSwalConfirm((willDelete) => {
      if (willDelete) {
        removeTrial({
          variables: { id: data.id },
          onCompleted: () => {
            refetchList();
            showToast("success");
          },
          onError: (err) => {
            showToast(
              "error",
              err?.graphQLErrors?.[0]?.extensions?.reason ||
                err?.graphQLErrors?.[0]?.message ||
                err?.message
            );
          },
        });
      }
    });
  };

  return (
    <>
      {removeLoading ? <Loader fixed /> : null}

      {/* Filters */}
      <div className="my-4">
        <div className="d-flex align-items-end gap-20 mb-3">
          <InputForm
            name="company"
            rootStyle="flex-fill"
            value={filters.company}
            onChange={handleTextFilters}
            inputContainerStyle="w-100"
            placeholder={t("search")}
          />

          <HasPrivileges
            reqireMain={[
              Privileges.ADD_EDIT_TRIALS,
              Privileges.SUPER_PRIVILEGE,
            ]}
          >
            <AddButton
              label="trial"
              onClick={() =>
                setModalState((prev) => ({ ...prev, isOpen: true }))
              }
            />
          </HasPrivileges>
        </div>

        <Box className="d-sm-flex align-items-end gap-20">
          <BSelect
            isClearable
            name="status"
            label="status"
            icon="employee"
            keepDefaultStyle
            containerStyle=" "
            rootStyle="flex-fill"
            value={filters.status}
            onChange={handleSelectFilters}
            options={formatOptions([
              "Upcoming",
              "Ongoing",
              "Expired",
              "Onboarded",
            ])}
          />
          <DateTimePickerForm
            hasIcon
            isClearable
            label="from"
            value={filters.from}
            rootStyle="flex-fill"
            containerStyle="py-0"
            labelStyle="w-100 mb-2"
            datePickerContainer="w-100"
            onChange={(val) => handleDateFiltersChange("from", val)}
          />
          <DateTimePickerForm
            hasIcon
            label="to"
            isClearable
            value={filters.to}
            rootStyle="flex-fill"
            containerStyle="py-0"
            labelStyle="w-100 mb-2"
            datePickerContainer="w-100"
            onChange={(val) => handleDateFiltersChange("to", val)}
          />
          <BSelect
            isClearable
            icon="employee"
            name="user_id"
            label="created by"
            keepDefaultStyle
            containerStyle=" "
            rootStyle="flex-fill"
            options={options.users}
            value={filters.user_id}
            onChange={handleSelectFilters}
            isLoading={isOptionsLoading}
          />
        </Box>
      </div>

      {/* List */}
      <DataTable
        noHeader
        data={trials}
        columns={columns}
        className="cards_table"
        progressPending={isListLoading}
        progressComponent={<Loader />}
        pagination
        paginationServer
        paginationComponent={() => (
          <Pagination
            styleWraper=""
            onPaginate={handlePaginate}
            customPaginator={pagination}
          />
        )}
      />

      {/* Modals */}
      {modalState.isOpen ? (
        <TrialModal
          data={modalState.data}
          refetchList={refetchList}
          isLeadProfile={isLeadProfile}
          leadProfileData={leadProfileData}
          onClose={() => setModalState(modalInitState)}
        />
      ) : null}
    </>
  );
};

export default TrialsList;
