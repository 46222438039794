import React from "react";
import { useTranslation } from "react-i18next";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";

import moment from "moment";
import Constants from "../../Constants";
import Privilages from "../../Constants/Privilages";
import { onInputResetAction } from "../../Store/Actions";
import { checkCompanyPrivileges } from "../../Helpers/HelperFns";
import {
  GET_WORKING_TIMMING_OPTIONS_QUERY,
  attendanceTypeConfigOptionsQuery,
  getAttendanceProfileQuery,
} from "../../Graphql/query";

import {
  Requests,
  WorkPlaces,
  Compensation,
  WorkOnDaysOff,
} from "./DayOffException";
import {
  BSelect,
  InputForm,
  RadioboxForm,
  DateTimePickerForm,
} from "form-builder";
import Loader from "../Loader";
import MultipleCheckIn from "./MultipleCheckIn";
import CalendarToday from "@mui/icons-material/CalendarToday";
import WorkOnHolidays from "./WorkOnHolidays";
import _ from "lodash";

const shiftBased = Constants.attendanceTypes.SHIFT_BASED;
const officeBased = Constants.attendanceTypes.OFFICE_BASED;
const isCompanyHasShift = checkCompanyPrivileges({
  privileges: [Privilages.VIEW_EMPLOYEE_WORK_GROUPS],
});

/**
 *
 * Start of AttendanceTypeConfig
 *
 */

// Input name => UpdateOrCreateAttendanceTypeConfigurationInput
const AttendanceTypeConfig = ({
  FormProps,
  serverValidationPrefix = "",
  ...props
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const start_date = useSelector(
    (state) => state.super.salaryConfigForm.start_at
  );

  // Local State
  const [options, setOptions] = React.useState({
    offices: [],
    workTeams: [],
    workGroups: [],
    leavesBreaks: [],
  });

  // Reducer State
  const inputData = useSelector(
    (state) => state?.[FormProps?.reducer || "super"]?.[FormProps?.formName]
  );

  // Server State
  const [getOptions, { loading }] = useLazyQuery(
    attendanceTypeConfigOptionsQuery
  );

  const {
    data: getAttendanceProfileOptions,
    loading: getAttendanceProfileLoading,
  } = useQuery(getAttendanceProfileQuery, {
    variables: {
      archive: start_date,
    },
    skip: !Boolean(start_date) || inputData?.attendanceType !== officeBased,
  });

  const { data: workTimingsOptions, loading: workTimingsLoading } = useQuery(
    GET_WORKING_TIMMING_OPTIONS_QUERY,
    {
      variables: {
        work_timming_date: moment(start_date, "YYYY/MM/DD").format(
          "YYYY-MM-DD"
        ),
      },
      skip: !Boolean(start_date) || inputData?.attendanceType === officeBased,
    }
  );

  // Constants (isEmpForm)
  // const selectedAttProfile = React.useMemo(
  //   () =>
  //     inputData?.att_profile_id &&
  //     options?.attendance_profiles?.find(
  //       (attProfile) => attProfile.id == inputData?.att_profile_id
  //     ),
  //   [inputData?.att_profile_id]
  // );
  // const selectedLeaveBreakProfile = React.useMemo(
  //   () =>
  //     inputData?.permissionLeavesBreakSettingId &&
  //     options?.leavesBreaks?.find(
  //       (profile) => profile?.id == inputData?.permissionLeavesBreakSettingId
  //     ),
  //   [inputData?.permissionLeavesBreakSettingId]
  // );
  // const showAnnualLeavesSection =
  //   (inputData?.type === officeBased &&
  //     selectedAttProfile?.permission_leaves_break_setting?.annualLeaveSetting
  //       ?.allowed_annual_leaves) ||
  //   (inputData?.type === shiftBased &&
  //     selectedLeaveBreakProfile?.annualLeaveSetting?.allowed_annual_leaves);

  // const showEmergencyLeavesSection =
  //   (inputData?.type === officeBased &&
  //     selectedAttProfile?.permission_leaves_break_setting?.annualLeaveSetting
  //       ?.allowed_emergency_leaves) ||
  //   (inputData?.type === shiftBased &&
  //     selectedLeaveBreakProfile?.annualLeaveSetting?.allowed_emergency_leaves);

  // const showSickLeavesSection =
  //   (inputData?.type === officeBased &&
  //     selectedAttProfile?.permission_leaves_break_setting?.sickLeaveSetting
  //       ?.sick_leaves) ||
  //   (inputData?.type === shiftBased &&
  //     selectedLeaveBreakProfile?.sickLeaveSetting?.allowed_sick_leaves);

  /* ↓ State Effects ↓ */

  React.useEffect(() => {
    getOptions({
      variables: { isOffice: inputData?.attendanceType === officeBased },
      onCompleted: ({ offices, workTeams, workGroups, leavesBreaks }) => {
        setOptions({
          offices: offices?.data || [],
          workGroups: workGroups || [],
          workTeams: workTeams?.data || [],
          leavesBreaks: leavesBreaks?.data || [],
        });
      },
    });
  }, [inputData?.attendanceType]);

  useDidUpdateEffect(() => {
    if (
      !inputData?.allow_work_on_day_off ||
      inputData?.attendanceType !== shiftBased
    ) {
      dispatch(onInputResetAction(FormProps.formName, "allow_overtime"));
      dispatch(onInputResetAction(FormProps.formName, "allow_permission"));
      dispatch(onInputResetAction(FormProps.formName, "apply_compensation"));
      dispatch(onInputResetAction(FormProps.formName, "allow_work_on_day_off"));
    }
  }, [inputData?.attendanceType, inputData?.allow_work_on_day_off]);

  /* ↓ Helpers ↓ */

  const handleInterceptAttendanceProfileChange = () => {
    dispatch(onInputResetAction(FormProps?.formName, "home_days"));
  };

  const onWorkGroupIdSelect = () => {
    dispatch(onInputResetAction(FormProps?.formName, "workTeamId"));
  };

  // start of handle work time options consist of worktime menu data and old selected work time data
  const serilazedNormalWorkTimingArray = [
    workTimingsOptions?.work_timings_menu ?? [],
    inputData?.normalWorkTiming ?? [],
  ].flat();

  const serilazedHalfWorkTimingArray = [
    workTimingsOptions?.half_work_timings_menu ?? [],
    [
      inputData?.firstHalfWorkTiming ?? {},
      inputData?.secondHalfWorkTiming ?? {},
    ] ?? [],
  ].flat();

  const normalWorkTimeOptions = _.uniqBy(serilazedNormalWorkTimingArray, "id");
  const halfWorkTimeOptions = _.uniqBy(serilazedHalfWorkTimingArray, "id");
  // End of handle work time options consist of worktime menu data and old selected work time data

  return (
    <>
      <RadioboxForm
        {...FormProps}
        options={[
          {
            label: "Office Based Hours",
            value: "office based",
          },
          {
            label: "shift based work schedule",
            value: "shifts",
            optProps: {
              disabled: !isCompanyHasShift,
            },
          },
        ]}
        label="Employee Work Schedule"
        name="attendanceType"
        type="radio"
        containerStyle="my-3"
        labelStyle="label-style"
        optionsContainerStyle="optionsContainerStyle row"
        optionItemStyle="col-6 px-0"
        optionInputStyle=" "
        optionLabelStyle="mb-0 mr-3"
      />
      <BSelect
        {...FormProps}
        name="attendanceProfileId"
        label={t("attendance profile")}
        keepDefaultStyle
        placeholder={t("select option")}
        options={
          getAttendanceProfileOptions?.attendance_profiles?.data
            ? getAttendanceProfileOptions?.attendance_profiles?.data
            : []
        }
        validateBy="textRequired"
        validationName={`input.${serverValidationPrefix}att_profile_id`}
        skipLocalization
        icon="person"
        onInterceptInputOnChange={handleInterceptAttendanceProfileChange}
        isLoading={getAttendanceProfileLoading}
        dependOn={["attendanceType"]}
        dependancyType="equal"
        dependancyValue={[officeBased]}
      />
      <BSelect
        {...FormProps}
        name="permissionLeavesBreakSettingId"
        label={t("leave and break profile")}
        keepDefaultStyle
        placeholder={t("select option")}
        options={options?.leavesBreaks}
        validateBy="textRequired"
        validationName={`input.${serverValidationPrefix}permissionLeavesBreakSettingId`}
        skipLocalization
        icon="person"
        rootStyle="w-100"
        isLoading={loading}
        dependOn={["attendanceType"]}
        dependancyType="equal"
        dependancyValue={[shiftBased]}
      />
      {props?.grantEmployeeSec ? (
        <GrantEmployee FormProps={FormProps} startDate={props?.startDate} />
      ) : null}

      <div className="row row-gap-5">
        <BSelect
          {...FormProps}
          name="workGroupId"
          label={t("work group")}
          keepDefaultStyle
          placeholder={t("select option")}
          options={options?.workGroups}
          validationName={`input.${serverValidationPrefix}workGroupId`}
          skipLocalization
          icon="person"
          isClearable
          rootStyle="col-lg-6"
          isLoading={loading}
          dependOn={["attendanceType"]}
          dependancyType="equal"
          dependancyValue={[shiftBased]}
          onInterceptInputOnChange={onWorkGroupIdSelect}
        />
        <BSelect
          {...FormProps}
          name="workTeamId"
          label={t("work team")}
          keepDefaultStyle
          placeholder={t("select option")}
          isDisabled={!inputData?.workGroupId}
          options={options?.workTeams?.filter(
            (workteam) => workteam?.workGroup?.id === inputData?.workGroupId
          )}
          validationName={`input.${serverValidationPrefix}workTeamId`}
          skipLocalization
          icon="person"
          isClearable
          isLoading={loading}
          rootStyle="col-lg-6"
          dependOn={["attendanceType"]}
          dependancyType="equal"
          dependancyValue={[shiftBased]}
        />
        <DateTimePickerForm
          {...FormProps}
          label={t("join team from")}
          labelStyle="mb-2"
          name="joiningFrom" // workTeamStartDate
          containerStyle="flex-column"
          placeholder={t("select date")}
          validationName={`input.${serverValidationPrefix}joiningFrom`}
          dependOn={["attendanceType"]}
          dependancyType="equal"
          dependancyValue={[shiftBased]}
          rootStyle="col-lg-6"
        />
        <BSelect
          {...FormProps}
          label={t("first day of the week")}
          name="weekStartDay" // NOTE: first_day_of_the_week
          keepDefaultStyle
          placeholder={t("select option")}
          hideSelectedOptions
          optionLabel="label"
          optionValue="value"
          options={Constants.WeekDays}
          containerStyle="row justify-content-between align-items-center"
          labelStyle="col-12 mb-2"
          inputContainerStyle="col-12"
          validationName={`input.${serverValidationPrefix}weekStartDay`}
          validateBy={
            inputData?.attendanceType === shiftBased ? "textRequired" : false
          }
          icon={<CalendarToday />}
          isLoading={loading}
          dependOn={["attendanceType"]}
          dependancyType="equal"
          dependancyValue={[shiftBased]}
          rootStyle="col-lg-6"
        />
      </div>
      {inputData.attendanceType === shiftBased ? (
        <>
          <strong class="text-16 sec-color d-block mt-3 mb-2">
            {t("Check-ins")}
            <hr className="title-line" />
          </strong>
          <MultipleCheckIn
            formProps={FormProps}
            name="allowMultipleCheckIns"
            allow_customize_check_ins={inputData?.allow_customize_check_ins}
            customCheckFormIDs={{
              in: inputData?.check_in_form_id,
              out: inputData?.check_out_form_id,
            }}
          />

          {/* Work On Days Off */}
          <strong class="text-16 sec-color d-block mt-3 mb-2">
            {t("work on days off")}
            <hr className="title-line" />
          </strong>
          <WorkOnDaysOff
            hideName
            formProps={FormProps}
            serverValidationPrefix={serverValidationPrefix}
            halfWorkTimingOptions={halfWorkTimeOptions}
            normalWorkTimingOptions={normalWorkTimeOptions}
          />

          <div className="ml-3">
            {inputData?.allow_work_on_day_off ? (
              <>
                {/* Work Places */}
                <WorkPlaces
                  formProps={FormProps}
                  locationsOptions={options?.offices}
                  serverValidationPrefix={serverValidationPrefix}
                />

                {!inputData?.treat_as_normal ? (
                  <>
                    {/* Compensation */}
                    <Compensation
                      formProps={FormProps}
                      serverValidationPrefix={serverValidationPrefix}
                      compensation_type={inputData?.compensation_type}
                      apply_compensation={inputData?.apply_compensation}
                    />

                    {/* Requests */}
                    <Requests formProps={FormProps} />
                  </>
                ) : null}
              </>
            ) : null}
          </div>

          <WorkOnHolidays
            normalWorkTimingOptions={normalWorkTimeOptions}
            halfWorkTimingOptions={halfWorkTimeOptions}
            formProps={FormProps}
            locationsOptions={options?.offices}
            showWorkPlaceSection={true}
            serverValidationPrefix="input.attendanceTypeConfig"
            isActivationModal={true}
          />
        </>
      ) : null}

      {/* {isEmpForm &&
      (showAnnualLeavesSection ||
        showEmergencyLeavesSection ||
        showSickLeavesSection) ? (
        <div className="row align-items-center mt-3">
          <div className="col-12 mb-2">
            <label className="font-weight-bold sub_title">
              {t("beginning leave balance")}
            </label>
          </div>

          {showAnnualLeavesSection ? (
            <div className="leaves_field col-xl-4">
              <label>{t("annual leaves")}</label>
              <InputForm
                {...FormProps}
                name="annual_leaves"
                validateBy="textRequired"
                validationName="input.user_input.beginning_balance.annual_leaves"
                type="number"
                hasSuffix
                suffixTitle="days"
                rootStyle="mx-xl-4"
                inputContainerStyle="input-container-style-default w-100"
              />
            </div>
          ) : null}

          {showEmergencyLeavesSection ? (
            <div className="leaves_field col-xl-4">
              <label>{t("emergency credit")}</label>
              <InputForm
                {...FormProps}
                name="emergency_leaves"
                validateBy="textRequired"
                validationName="input.user_input.beginning_balance.emergency_leaves"
                type="number"
                hasSuffix
                suffixTitle="days"
                rootStyle="mx-xl-4"
                inputContainerStyle="input-container-style-default w-100"
              />
            </div>
          ) : null}

          {showSickLeavesSection ? (
            <div className="leaves_field col-xl-4">
              <label>{t("sick leaves")}</label>
              <InputForm
                {...FormProps}
                name="sick_leaves"
                validationName="input.user_input.beginning_balance.sick_leaves"
                type="number"
                hasSuffix
                suffixTitle="days"
                rootStyle="mx-xl-4"
                inputContainerStyle="input-container-style-default w-100"
              />
            </div>
          ) : null}
        </div>
      ) : null} */}
    </>
  );
};

export default AttendanceTypeConfig;

/**
 *
 * End of AttendanceTypeConfig
 *
 * Start of GrantEmployee
 *
 */

const GET_BOOL = gql`
  query getBool($id: ID, $isOffice: Boolean!) {
    attendance_profile(id: $id) @include(if: $isOffice) {
      permission_leaves_break_setting {
        sickLeaveSetting {
          sick_leaves
        }
        annualLeaveSetting {
          allow_annual_leaves
          allowed_emergency_leaves
        }
      }
    }

    permission_leaves_break_setting(id: $id) @skip(if: $isOffice) {
      sickLeaveSetting {
        sick_leaves
      }
      annualLeaveSetting {
        allow_annual_leaves
        allowed_emergency_leaves
      }
    }
  }
`;

const GrantEmployee = ({ FormProps, startDate }) => {
  const { t } = useTranslation();

  // Local State
  const [data, setData] = React.useState({
    hasAnnualLeave: false,
    hasSickLeave: false,
    hasEmergencyLeave: false,
  });

  // Reducer State
  const inputData = useSelector(
    (state) => state?.[FormProps?.reducer || "super"]?.[FormProps?.formName]
  );

  // Server State
  const [getBool, { loading }] = useLazyQuery(GET_BOOL);

  // Constants
  const attType = inputData?.attendanceType;
  const hasNotLeaves = Object.values(data).every((d) => !d);
  const id =
    attType === officeBased
      ? inputData?.attendanceProfileId
      : inputData?.permissionLeavesBreakSettingId;

  /* ↓ State Effects ↓ */

  React.useEffect(() => {
    id &&
      getBool({
        variables: {
          id,
          isOffice: attType === officeBased,
        },
        onCompleted: ({
          attendance_profile = {},
          permission_leaves_break_setting = {},
        }) => {
          if (attType === officeBased) {
            setData({
              hasAnnualLeave: Boolean(
                attendance_profile?.permission_leaves_break_setting
                  ?.annualLeaveSetting?.allow_annual_leaves
              ),
              hasEmergencyLeave: Boolean(
                Number(
                  attendance_profile?.permission_leaves_break_setting
                    ?.annualLeaveSetting?.allowed_emergency_leaves
                )
              ),
              hasSickLeave: Boolean(
                attendance_profile?.permission_leaves_break_setting
                  ?.sickLeaveSetting?.sick_leaves
              ),
            });
          } else {
            setData({
              hasAnnualLeave: Boolean(
                permission_leaves_break_setting?.annualLeaveSetting
                  ?.allow_annual_leaves
              ),
              hasEmergencyLeave: Boolean(
                Number(
                  permission_leaves_break_setting?.annualLeaveSetting
                    ?.allowed_emergency_leaves
                )
              ),
              hasSickLeave: Boolean(
                permission_leaves_break_setting?.sickLeaveSetting?.sick_leaves
              ),
            });
          }
        },
      });
  }, [id, attType]);

  return (
    <div className={!id || hasNotLeaves ? "d-none" : "mt-2"}>
      {loading ? <Loader fixed /> : null}
      <strong>{t("grant employee")}</strong>
      <div className="align-items-center d-flex justify-content-between mt-2 mb-3">
        <div className="d-flex justify-content-between align-items-center flex-fill flex-wrap">
          {data?.hasAnnualLeave ? (
            <InputForm
              {...FormProps}
              validateBy="textRequired"
              name="annual_leaves"
              label="normal leaves"
              labelStyle="mb-2"
              containerStyle=" "
              validationName="input.newBalanceData.annual_leaves"
              hasSuffix
              suffixTitle="days"
              rootStyle="mr-4"
            />
          ) : null}

          {data?.hasAnnualLeave && data?.hasEmergencyLeave ? (
            <InputForm
              {...FormProps}
              validateBy="textRequired"
              name="emergency_leaves"
              label="emergency credit"
              labelStyle="mb-2"
              containerStyle=" "
              validationName="input.newBalanceData.emergency_leaves"
              hasSuffix
              suffixTitle="days"
              rootStyle="mr-4"
            />
          ) : null}

          {data?.hasSickLeave ? (
            <InputForm
              {...FormProps}
              name="sick_leaves"
              label="sick leaves"
              labelStyle="mb-2"
              containerStyle=" "
              validationName="input.newBalanceData.sick_leaves"
              hasSuffix
              suffixTitle="days"
              rootStyle="mr-4"
            />
          ) : null}
        </div>

        {moment(startDate).isValid() ? (
          <label className="d-flex flex-grow-1 justify-content-end pt-3">
            {t("for")} {moment(startDate).format("yyyy")}
          </label>
        ) : (
          <label className="d-flex flex-grow-1" />
        )}
      </div>
    </div>
  );
};
