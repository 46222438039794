import React from "react";
import { useTranslation } from "react-i18next";

import { CheckboxBooleanForm } from "../../Builder/Form";

const CustomLeavesSection = ({ formProps }) => {
  const { t } = useTranslation();

  // Constants
  const customLeaves = [
    { name: "bereavement_leave", label: t("Bereavement leave") },
    { name: "maternal_paternal_leave", label: t("Maternal / Paternal Leave") },
    { name: "hajj_leave", label: t("Hajj Leaves") },
    { name: "sabbatical_leave", label: t("Sabbatical leave") },
    { name: "study_leave", label: t("Study leave") },
    { name: "marriage_leave", label: t("Marriage Leave") },
    {
      name: "in_lieu_of_work_day_leave",
      label: t("In Lieu Of Work Day Leave"),
    },
    { name: "military_call_leave", label: t("Military Call Leave") },
  ];

  return (
    <>
      <CheckboxBooleanForm
        {...formProps}
        setWithValue
        name="allow_custom_leave"
        containerStyle="leaves_checkbox_container mb-2"
        options={[
          "Allow Managers to request custom leaves on behalf of employees",
        ]}
      />

      <div className="d-flex flex-wrap gap-10">
        {customLeaves.map((leave) => (
          <CheckboxBooleanForm
            {...formProps}
            name={leave.name}
            options={[leave.label]}
            containerStyle="leaves_checkbox_container"
            dependOn="allow_custom_leave"
            dependancyType="equal"
            dependancyValue={[true]}
            setWithValue
          />
        ))}
      </div>
    </>
  );
};

export default CustomLeavesSection;
