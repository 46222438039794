import companyWizardTypes from "./companyWizardActions/CompanyWizardTypes";
import RequestRulesTypes from "./actionTypes/requestRules";
import AdminActionTypes from "./adminActions/types";
import RecruitmentActionTypes from "./recruitmentActions/types";
import CheckInsActionTypes from "./checkInsActions/types";

const REFETCH_FROM_SAGE_TO_HOOK = "REFETCH_FROM_SAGE_TO_HOOK";
const RESET_REFETCH_FROM_SAGE_TO_HOOK = "RESET_REFETCH_FROM_SAGE_TO_HOOK";

const CHANGE_EMPLOYEE_DATA = "CHANGE_EMPLOYEE_DATA";
// Login Action Types
const LOGING_IN_ATTEMPT = "LOGING_IN_ATTEMPT";
const LOGED_IN_SUCCESS = "LOGED_IN_SUCCESS";
const LOGED_IN_FAILED = "LOGED_IN_FAILED";

// Fetching Profile Action Types
const FETCH_PROFILE_SUCCESS = "FETCH_PROFILE_SUCCESS";
const SET_OFFICE_CURRENCY = "SET_OFFICE_CURRENCY";

// Fetching User Requests Action Types

const CANCEL_REQUEST_ATTEMPT = "CANCEL_REQUEST_ATTEMPT";
const CANCEL_REQUEST_SUCCESS = "CANCEL_REQUEST_SUCCESS";
const CANCEL_REQUEST_FAILED = "CANCEL_REQUEST_FAILED";

// Handle show/hide actions for edit leaves modal
const SHOW_EDIT_LEAVES_MODAL = "SHOW_EDIT_LEAVES_MODAL";
const HIDE_EDIT_LEAVES_MODAL = "HIDE_EDIT_LEAVES_MODAL";

// Fetching User History
const FETCH_ATTENDANCE_RECORDS_ATTEMPT = "FETCH_ATTENDANCE_RECORDS_ATTEMPT";
const FETCH_ATTENDANCE_RECORDS_SUCCESS = "FETCH_ATTENDANCE_RECORDS_SUCCESS";
const FETCH_ATTENDANCE_RECORDS_FAILED = "FETCH_ATTENDANCE_RECORDS_FAILED";

// Init & Edit AttendanceProfile Form
const INIT_ATTENDANCE_PROFILE_ATTEMPT = "INIT_ATTENDANCE_PROFILE_ATTEMPT";
const INIT_ATTENDANCE_PROFILE_SUCCESS = "INIT_ATTENDANCE_PROFILE_SUCCESS";
const INIT_ATTENDANCE_PROFILE_FAILED = "INIT_ATTENDANCE_PROFILE_FAILED";

const FETCH_ATTENDANCE_PROFILE_ATTEMPT = "FETCH_ATTENDANCE_PROFILE_ATTEMPT";
const FETCH_ATTENDANCE_PROFILE_SUCCESS = "FETCH_ATTENDANCE_PROFILE_SUCCESS";
const FETCH_ATTENDANCE_PROFILE_FAILED = "FETCH_ATTENDANCE_PROFILE_FAILED";

const ASSIGN_HLAF_ATTENDANCE_PROFILE = "ASSIGN_HLAF_ATTENDANCE_PROFILE";

// Init & Edit Employee Form
const EDIT_EMPLOYEE_ATTEMPT = "EDIT_EMPLOYEE_ATTEMPT";
const EDIT_EMPLOYEE_SUCCESS = "EDIT_EMPLOYEE_SUCCESS";
const EDIT_EMPLOYEE_FAILED = "EDIT_EMPLOYEE_FAILED";

const INIT_EMPLOYEE_ATTEMPT = "INIT_EMPLOYEE_ATTEMPT";
const INIT_EMPLOYEE_SUCCESS = "INIT_EMPLOYEE_SUCCESS";
const INIT_EMPLOYEE_FAILED = "INIT_EMPLOYEE_FAILED";

const SET_SELECTED_EMPLOYEE_ATT_PROFILE = "SET_SELECTED_EMPLOYEE_ATT_PROFILE";

const UPSERT_EMPLOYEE_ATTEMPT = "UPSERT_EMPLOYEE_ATTEMPT";
const UPDATE_EMPLOYEE_SUCCESS = "UPDATE_EMPLOYEE_SUCCESS";
const UPDATE_EMPLOYEE_FAILED = "UPDATE_EMPLOYEE_FAILED";

const RESET_EMPLOYEE_FORM = "RESET_EMPLOYEE_FORM";

// handle on Form Input change
const ON_INPUT_CHANGE = "ON_INPUT_CHANGE";
const ON_SELECT_INPUT_CHANGE = "ON_SELECT_INPUT_CHANGE";
const ON_INPUT_INCREMENT = "ON_INPUT_INCREMENT";
const ON_INPUT_DECREMENT = "ON_INPUT_DECREMENT";
const ON_RADIO_INPUT_CHANGE = "ON_RADIO_INPUT_CHANGE";
const ON_CHECKBOX_INPUT_CHANGE = "ON_CHECKBOX_INPUT_CHANGE";
const ON_CHECKBOX_BOOLEAN_INPUT_CHANGE = "ON_CHECKBOX_BOOLEAN_INPUT_CHANGE";
const ON_APPEND_INPUT_CHANGE = "ON_APPEND_INPUT_CHANGE";

const UPDATE_VALUE = "UPDATE_VALUE";

// Reset Form Input
const ON_INPUT_RESET = "ON_INPUT_RESET";
const ON_FORM_RESET = "ON_FORM_RESET";

// handle input Form Validation
const SET_INPUT_VALID = "SET_INPUT_VALID";
const SET_INPUT_INVALID = "SET_INPUT_INVALID";

//handle dataTable #sorting #paginate #filter

const SET_DATATABLE_SORTING = "SET_DATATABLE_SORTING";
const SET_DATATABLE_FILTER = "SET_DATATABLE_FILTER";
const SET_DATATABLE_PAGINATE = "SET_DATATABLE_PAGINATE";

// handle Fetch Data for attendance Profile List data table
const FETCH_ATTENDANCE_PROFILES_LIST_ATTEMPT =
  "FETCH_ATTENDANCE_PROFILES_LIST_ATTEMPT";
const FETCH_ATTENDANCE_PROFILES_LIST_SUCCESS =
  "FETCH_ATTENDANCE_PROFILES_LIST_SUCCESS";
const FETCH_ATTENDANCE_PROFILES_LIST_FAILED =
  "FETCH_ATTENDANCE_PROFILES_LIST_FAILED";

// handle create att Profile
const CREATE_ATTENDANCE_PROFILE_ATTEMPT = "CREATE_ATTENDANCE_PROFILE_ATTEMPT";
const CREATE_ATTENDANCE_PROFILE_SUCCESS = "CREATE_ATTENDANCE_PROFILE_SUCCESS";
const CREATE_ATTENDANCE_PROFILE_FAILED = "CREATE_ATTENDANCE_PROFILE_FAILED";

const RESET_ATTENDANCE_PROFILE_FORM = "RESET_ATTENDANCE_PROFILE_FORM";
// Handle All employees
const FETCH_ALL_EMPLOYEES_ATTEMPT = "FETCH_ALL_EMPLOYEES_ATTEMPT";
const FETCH_ALL_EMPLOYEES_SUCCESS = "FETCH_ALL_EMPLOYEES_SUCCESS";
const FETCH_ALL_EMPLOYEES_FAILED = "FETCH_ALL_EMPLOYEES_FAILED";

//handle Edit Employee Modal
const SET_EDIT_EMPLOYEE_OPEN = "SET_EDIT_EMPLOYEE_OPEN";
const SET_EDIT_EMPLOYEE_CLOSED = "SET_EDIT_EMPLOYEE_CLOSED";

// Handle Fetch employee Profile
const FETCH_EMPLOYEE_PROFILE_SUCCESS = "FETCH_EMPLOYEE_PROFILE_SUCCESS";

// Handle Fetch employee attendance logs
const FETCH_EMPLOYEE_ATTENDANCE_LOGS_SUCCESS =
  "FETCH_EMPLOYEE_ATTENDANCE_LOGS_SUCCESS";
const FETCH_EMPLOYEE_ATTENDANCE_LOGS_FAILED =
  "FETCH_EMPLOYEE_ATTENDANCE_LOGS_FAILED";

const RESET_EMPLOYEE_PRFOILE_VIEW = "RESET_EMPLOYEE_PRFOILE_VIEW";

// Handle Fetch All Offices
const FETCH_ALL_OFFICES_ATTEMPT = "FETCH_ALL_OFFICES_ATTEMPT";
const FETCH_ALL_OFFICES_SUCCESS = "FETCH_ALL_OFFICES_SUCCESS";
const FETCH_ALL_OFFICES_FAILED = "FETCH_ALL_OFFICES_FAILED";

const FETCH_ALL_DEPARTMENTS_ATTEMPT = "FETCH_ALL_DEPARTMENTS_ATTEMPT";
const FETCH_ALL_DEPARTMENTS_SUCCESS = "FETCH_ALL_DEPARTMENTS_SUCCESS";
const FETCH_ALL_DEPARTMENTS_FAILED = "FETCH_ALL_DEPARTMENTS_FAILED";

const FETCH_ALL_POSITIONS_ATTEMPT = "FETCH_ALL_POSITIONS_ATTEMPT";
const FETCH_ALL_POSITIONS_SUCCESS = "FETCH_ALL_POSITIONS_SUCCESS";
const FETCH_ALL_POSITIONS_FAILED = "FETCH_ALL_POSITIONS_FAILED";

const FETCH_ALL_HOLIDAYS_ATTEMPT = "FETCH_ALL_HOLIDAYS_ATTEMPT";
const FETCH_ALL_HOLIDAYS_SUCCESS = "FETCH_ALL_HOLIDAYS_SUCCESS";
const FETCH_ALL_HOLIDAYS_FAILED = "FETCH_ALL_HOLIDAYS_FAILED";

const FETCH_ALL_WORK_TIMINGS_OPTIONS = "FETCH_ALL_WORK_TIMINGS_OPTIONS";
const FETCH_ALL_EMPLOYEES_OPTIONS = "FETCH_ALL_EMPLOYEES_OPTIONS";
const FETCH_ALL_LOCATION_OPTIONS = "FETCH_ALL_LOCATION_OPTIONS";
const SET_ALL_POSITION_OPTIONS = "SET_ALL_POSITION_OPTIONS";

// handle DataTable Pagination
const PAGINATE_DATA_TABLE = "PAGINATE_DATA_TABLE";

// HANDLE Logout
const LOG_OUT = "LOG_OUT";

// Handle commission Date Range
const SET_COMMISSION_DATE_RANGE = "SET_COMMISSION_DATE_RANGE";

// update lang & lat location
const UPDATE_LNG_LAT_LOCATION = "UPDATE_LNG_LAT_LOCATION";

// Handle fet fetch hr actions
//fetch inbound requests
const FETCH_INBOUND_REQUESTS_ATTEMPT = "FETCH_INBOUND_REQUESTS_ATTEMPT";
const FETCH_INBOUND_REQUESTS_SUCCESS = "FETCH_INBOUND_REQUESTS_SUCCESS";
const FETCH_INBOUND_REQUESTS_FAILED = "FETCH_INBOUND_REQUESTS_FAILED";

const UPDTAE_REQUESTS_EMPLOYEES_FILTER = "UPDTAE_REQUESTS_EMPLOYEES_FILTER";

const ACCEPT_INBOUND_REQUEST_ATTEMPT = "ACCEPT_INBOUND_REQUEST_ATTEMPT";
const ACCEPT_INBOUND_REQUEST_SUCCESS = "ACCEPT_INBOUND_REQUEST_SUCCESS";
const ACCEPT_INBOUND_REQUEST_FAILED = "ACCEPT_INBOUND_REQUEST_FAILED";

const ACCEPT_OVERTIME_REQUEST_ATTEMPT = "ACCEPT_OVERTIME_REQUEST_ATTEMPT";
const ACCEPT_OVERTIME_REQUEST_SUCCESS = "ACCEPT_OVERTIME_REQUEST_SUCCESS";
const ACCEPT_OVERTIME_REQUEST_FAILED = "ACCEPT_OVERTIME_REQUEST_FAILED";

const REJECT_INBOUND_REQUEST_ATTEMPT = "REJECT_INBOUND_REQUEST_ATTEMPT";
const REJECT_INBOUND_REQUEST_SUCCESS = "REJECT_INBOUND_REQUEST_SUCCESS";
const REJECT_INBOUND_REQUEST_FAILED = "REJECT_INBOUND_REQUEST_FAILED";

// ******* (Start) Profile Action Modal *****

// toggle Attendance Action Modal
const TOGGLE_ATTENDANCE_ACTION_MODAL = "TOGGLE_ATTENDANCE_ACTION_MODAL";

// Modal Success
const ATTENDANCE_ACTION_MODAL_SUCCESS = "ATTENDANCE_ACTION_MODAL_SUCCESS";

// Modal Failed
const ATTENDANCE_ACTION_MODAL_FAILED = "ATTENDANCE_ACTION_MODAL_FAILED";

// Normal Leave Request
const SEND_NORMAL_REQUEST_ATTEMPT = "SEND_NORMAL_REQUEST_ATTEMPT";

// Unpaid Leave Request
const SEND_UNPAID_REQUEST_ATTEMPT = "SEND_UNPAID_REQUEST_ATTEMPT";

// Emergency Leave Request
const SEND_EMERGENCY_REQUEST_ATTEMPT = "SEND_EMERGENCY_REQUEST_ATTEMPT";

// Permission Request
const SEND_PERMISSION_REQUEST_ATTEMPT = "SEND_PERMISSION_REQUEST_ATTEMPT";

// Halfday Request
const SEND_HALFDAY_REQUEST_ATTEMPT = "SEND_HALFDAY_REQUEST_ATTEMPT";

// Home Exchange Request
const SEND_HOME_EXCHANGE_REQUEST_ATTEMPT = "SEND_HOME_EXCHANGE_REQUEST_ATTEMPT";

// Home Flex Request
const SEND_HOME_FLEX_REQUEST_ATTEMPT = "SEND_HOME_FLEX_REQUEST_ATTEMPT";

// ******* (End) Profile Action Modal *****

// handle fetch history list
const FETCH_HISTORY_LIST_ATTEMPT = "FETCH_HISTORY_LIST_ATTEMPT";
const FETCH_HISTORY_LIST_SUCCESS = "FETCH_HISTORY_LIST_SUCCESS";
const FETCH_HISTORY_LIST_FAILED = "FETCH_HISTORY_LIST_FAILED";
// hande Sign In actions

const SIGN_IN_INFO_ATTEMPT = "SIGN_IN_INFO_ATTEMPT";

const SIGN_IN_ATTEMPT = "SIGN_IN_ATTEMPT";
const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
const SIGN_IN_FAILED = "SIGN_IN_FAILED";

const SHOW_DISMISS_HOME_ALERT = "SHOW_DISMISS_HOME_ALERT";
const HIDE_DISMISS_HOME_ALERT = "HIDE_DISMISS_HOME_ALERT";

const DISMISS_HOME_SIGN_IN_ATTEMPT = "DISMISS_HOME_SIGN_IN_ATTEMPT";
const DISMISS_HOME_SIGN_IN_SUCCESS = "DISMISS_HOME_SIGN_IN_SUCCESS";
const DISMISS_HOME_SIGN_IN_FAILED = "DISMISS_HOME_SIGN_IN_FAILED";

const SIGN_OUT_INFO_ATTEMPT = "SIGN_OUT_INFO_ATTEMPT";

const SIGN_OUT_ATTEMPT = "SIGN_OUT_ATTEMPT";
const SIGN_OUT_SUCCESS = "SIGN_OUT_SUCCESS";
const SIGN_OUT_FAILED = "SIGN_OUT_FAILED";

const SHOW_FORCE_SIGN_OUT_ALERT = "SHOW_FORCE_SIGN_OUT_ALERT";
const HIDE_FORCE_SIGN_OUT_ALERT = "HIDE_FORCE_SIGN_OUT_ALERT";

const REFRESH_SIGN_IN_OUT_ATTEMPT = "REFRESH_SIGN_IN_OUT_ATTEMPT";
const REFRESH_SIGN_IN_OUT_SUCCESS = "REFRESH_SIGN_IN_OUT_SUCCESS";

// handle logs actions
const FETCH_ATTENDANCE_LOGS_ATTEMPT = "FETCH_ATTENDANCE_LOGS_ATTEMPT";
const FETCH_ATTENDANCE_LOGS_SUCCESS = "FETCH_ATTENDANCE_LOGS_SUCCESS";
const FETCH_ATTENDANCE_LOGS_FAILED = "FETCH_ATTENDANCE_LOGS_FAILED";

const UPDTAE_EMPLOYEES_FILTER_LOGS = "UPDTAE_EMPLOYEES_FILTER_LOGS";

const FETCH_SUSPENSION_LOGS_ATTEMPT = "FETCH_SUSPENSION_LOGS_ATTEMPT";
const FETCH_SUSPENSION_LOGS_SUCCESS = "FETCH_SUSPENSION_LOGS_SUCCESS";
const FETCH_SUSPENSION_LOGS_FAILED = "FETCH_SUSPENSION_LOGS_FAILED";

const FETCH_BALANCE_LOGS_ATTEMPT = "FETCH_BALANCE_LOGS_ATTEMPT";
const FETCH_BALANCE_LOGS_SUCCESS = "FETCH_BALANCE_LOGS_SUCCESS";
const FETCH_BALANCE_LOGS_FAILED = "FETCH_BALANCE_LOGS_FAILED";

const TOGGLE_SUSPENSION_MODAL = "TOGGLE_SUSPENSION_MODAL";
const HIDE_SUSPENSION_MODAL = "HIDE_SUSPENSION_MODAL";

// Update Suspension
const UPDATE_SUSPENSION_ATTEMPT = "UPDATE_SUSPENSION_ATTEMPT";
const UPDATE_SUSPENSION_SUCCESS = "UPDATE_SUSPENSION_SUCCESS";
const UPDATE_SUSPENSION_FAILED = "UPDATE_SUSPENSION_FAILED";
const DELETE_SUSPENSION_ATTEMPT = "DELETE_SUSPENSION_ATTEMPT";
const DELETE_SUSPENSION_SUCCESS = "DELETE_SUSPENSION_SUCCESS";
const DELETE_SUSPENSION_FAILED = "DELETE_SUSPENSION_FAILED";

const ADD_EMPLOYEE_ID_TO_SUSPENSION = "ADD_EMPLOYEE_ID_TO_SUSPENSION";

// handle adding managed employees to suspension modal
const MANAGED_EMPLOYEES_IN_SUSPENSION_MODAL =
  "MANAGED_EMPLOYEES_IN_SUSPENSION_MODAL";

const FETCH_OVERTIME_LOGS_ATTEMPT = "FETCH_OVERTIME_LOGS_ATTEMPT";
const FETCH_OVERTIME_LOGS_SUCCESS = "FETCH_OVERTIME_LOGS_SUCCESS";
const FETCH_OVERTIME_LOGS_FAILED = "FETCH_OVERTIME_LOGS_FAILED";

// Update Department

const SHOW_DEPARTMENT_MODAL = "SHOW_DEPARTMENT_MODAL";
const HIDE_DEPARTMENT_MODAL = "HIDE_DEPARTMENT_MODAL";

const UPDATE_DEPARTMENT_ATTEMPT = "UPDATE_DEPARTMENT_ATTEMPT";
const UPDATE_DEPARTMENT_SUCCESS = "UPDATE_DEPARTMENT_SUCCESS";
const UPDATE_DEPARTMENT_FAILED = "UPDATE_DEPARTMENT_FAILED";

const CREATE_DEPARTMENT_ATTEMPT = "CREATE_DEPARTMENT_ATTEMPT";
const CREATE_DEPARTMENT_SUCCESS = "CREATE_DEPARTMENT_SUCCESS";
const CREATE_DEPARTMENT_FAILED = "CREATE_DEPARTMENT_FAILED";

const DELETE_DEPARTMENT_ATTEMPT = "DELETE_DEPARTMENT_ATTEMPT";
const DELETE_DEPARTMENT_SUCCESS = " DELETE_DEPARTMENT_SUCCESS";
const DELETE_DEPARTMENT_FAILED = " DELETE_DEPARTMENT_FAILED";

const FETCH_ALL_DEPARTMENTS_BY_COMPANY = "FETCH_ALL_DEPARTMENTS_BY_COMPANY";

// Update POSITION

const SHOW_POSITION_MODAL = "SHOW_POSITION_MODAL";
const HIDE_POSITION_MODAL = "HIDE_POSITION_MODAL";

const UPDATE_POSITION_ATTEMPT = "UPDATE_POSITION_ATTEMPT";
const UPDATE_POSITION_SUCCESS = "UPDATE_POSITION_SUCCESS";
const UPDATE_POSITION_FAILED = "UPDATE_POSITION_FAILED";

const CREATE_POSITION_ATTEMPT = "CREATE_POSITION_ATTEMPT";
const CREATE_POSITION_SUCCESS = "CREATE_POSITION_SUCCESS";
const CREATE_POSITION_FAILED = "CREATE_POSITION_FAILED";

const DELETE_POSITION_ATTEMPT = "DELETE_POSITION_ATTEMPT";
const DELETE_POSITION_SUCCESS = "DELETE_POSITION_SUCCESS";
const DELETE_POSITION_FAILED = "DELETE_POSITION_FAILED";

// Update Holidays

const SHOW_HOLIDAYS_MODAL = "SHOW_HOLIDAYS_MODAL";
const HIDE_HOLIDAYS_MODAL = "HIDE_HOLIDAYS_MODAL";

const CREATE_HOLIDAYS_ATTEMPT = "CREATE_HOLIDAYS_ATTEMPT";
const CREATE_HOLIDAYS_SUCCESS = "CREATE_HOLIDAYS_SUCCESS";
const CREATE_HOLIDAYS_FAILED = "CREATE_HOLIDAYS_FAILED";

const DELETE_HOLIDAYS_ATTEMPT = "DELETE_HOLIDAYS_ATTEMPT";
const DELETE_HOLIDAYS_SUCCESS = "DELETE_HOLIDAYS_SUCCESS";
const DELETE_HOLIDAYS_FAILED = "DELETE_HOLIDAYS_FAILED";

// Employee Actions
const DELETE_USER_ATTEMPT = "DELETE_USER_ATTEMPT";
const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
const DELETE_USER_FAILED = "DELETE_USER_FAILED";
const SHOW_CONFIRM_DELETE_MANAGER_MODAL = "SHOW_CONFIRM_DELETE_MANAGER_MODAL";
const HIDE_CONFIRM_DELETE_MANAGER_MODAL = "HIDE_CONFIRM_DELETE_MANAGER_MODAL";

// handle fetch init delete manager actions
const FETCH_INIT_DELETE_MANAGER_ATTEMPT = "FETCH_INIT_DELETE_MANAGER_ATTEMPT";
const FETCH_INIT_DELETE_MANAGER_SUCCESS = "FETCH_INIT_DELETE_MANAGER_SUCCESS";
const FETCH_INIT_DELETE_MANAGER_FAILED = "FETCH_INIT_DELETE_MANAGER_FAILED";

// handle fetch init suspend manager actions
const FETCH_INIT_SUSPEND_MANAGER_ATTEMPT = "FETCH_INIT_SUSPEND_MANAGER_ATTEMPT";
const FETCH_INIT_SUSPEND_MANAGER_SUCCESS = "FETCH_INIT_SUSPEND_MANAGER_SUCCESS";
const FETCH_INIT_SUSPEND_MANAGER_FAILED = "FETCH_INIT_SUSPEND_MANAGER_FAILED";

// handle fetch replace by managers options actions
const FETCH_REPLACE_BY_MANAGERS_OPTIONS_ATTEMPT =
  "FETCH_REPLACE_BY_MANAGERS_OPTIONS_ATTEMPT";
const FETCH_REPLACE_BY_MANAGERS_OPTIONS_SUCCESS =
  "FETCH_REPLACE_BY_MANAGERS_OPTIONS_SUCCESS";
const FETCH_REPLACE_BY_MANAGERS_OPTIONS_FAILED =
  "FETCH_REPLACE_BY_MANAGERS_OPTIONS_FAILED";

const ACTIVATE_USER_ATTEMPT = "ACTIVATE_USER_ATTEMPT";
const ACTIVATE_USER_SUCCESS = "ACTIVATE_USER_SUCCESS";
const ACTIVATE_USER_FAILED = "ACTIVATE_USER_FAILED";
const TOGGLE_ACTIVATION_MODAL = "TOGGLE_ACTIVATION_MODAL";
const ADD_EMPLOYEE_ID_TO_ACTIVATION = "ADD_EMPLOYEE_ID_TO_ACTIVATION";

// handle adding the activation/onboarding balance to net remaning balance action
const CALCULATE_NEW_NET_REMANING_LEAVE_BALANCE =
  "CALCULATE_NEW_NET_REMANING_LEAVE_BALANCE";

// Edit Employee
const EDIT_ATT_LOG_ATTEMPT = "EDIT_ATT_LOG_ATTEMPT";
const EDIT_ATT_LOG_SUCCESS = "EDIT_ATT_LOG_SUCCESS";
const EDIT_ATT_LOG_FAILED = "EDIT_ATT_LOG_FAILED";
const SHOW_EDIT_HISTORY_MODAL = "SHOW_EDIT_HISTORY_MODAL";
const HIDE_EDIT_HISTORY_MODAL = "HIDE_EDIT_HISTORY_MODAL";

//Balance Modal
const BALANCE_MODAL_ATTEMPT = "BALANCE_MODAL_ATTEMPT";
const BALANCE_MODAL_SUCCESS = "BALANCE_MODAL_SUCCESS";
const BALANCE_MODAL_FAILED = "BALANCE_MODAL_FAILED";
const SHOW_BALANCE_MODAL = "SHOW_BALANCE_MODAL";
const HIDE_BALANCE_MODAL = "HIDE_BALANCE_MODAL";

const SHOW_OFFICE_LOCATION_MODAL = "SHOW_OFFICE_LOCATION_MODAL";
const SHOW_EMPLOYEE_LOCATION_MODAL = "SHOW_EMPLOYEE_LOCATION_MODAL";
const HIDE_OFFICE_LOCATION_MODAL = "HIDE_OFFICE_LOCATION_MODAL";
const RESET_OFFICE_LOCATION = "RESET_OFFICE_LOCATION";
const UPDATE_OFFICE_LOCATION = "UPDATE_OFFICE_LOCATION";
const UPDATE_EMPLOYEE_LOCATION = "UPDATE_EMPLOYEE_LOCATION";
const SET_LOCATIONS_SERVER_VALIDATION = "SET_LOCATIONS_SERVER_VALIDATION";

const SHOW_OFFICE_HOLIDAY_MODAL = "SHOW_OFFICE_HOLIDAY_MODAL";
const HIDE_OFFICE_HOLIDAY_MODAL = "HIDE_OFFICE_HOLIDAY_MODAL";
const RESET_OFFICE_HOLIDAY = "RESET_OFFICE_HOLIDAY";
const UPDATE_OFFICE_HOLIDAY = "UPDATE_OFFICE_HOLIDAY";

const UPDATE_OBJECT_MULTI_SELECT = "UPDATE_OBJECT_MULTI_SELECT";

const UPDATE_OFFICE_ATTEMPT = "UPDATE_OFFICE_ATTEMPT";
const UPDATE_OFFICE_SUCCESS = "UPDATE_OFFICE_SUCCESS";
const UPDATE_OFFICE_FAILED = "UPDATE_OFFICE_FAILED";

const DELETE_OFFICE_ATTEMPT = "DELETE_OFFICE_ATTEMPT";
const DELETE_OFFICE_SUCCESS = "DELETE_OFFICE_SUCCESS";
const DELETE_OFFICE_FAILED = "DELETE_OFFICE_FAILED";

const RESET_OFFICE_FORM = "RESET_OFFICE_FORM";

const FETCH_OFFICE_ATTEMPT = "FETCH_OFFICE_ATTEMPT";
const FETCH_OFFICE_SUCCESS = "FETCH_OFFICE_SUCCESS";
const FETCH_OFFICE_FAILED = "FETCH_OFFICE_FAILED";

// handle of set country currencies action
const SET_SELECTED_CONTURY_CURRENIESES = "SET_SELECTED_CONTURY_CURRENIESES";

const SET_BALANCE_WEEK_RANG = "SET_BALANCE_WEEK_RANG";

const UPDATE_ATTENDANCE_PROFILE_HOLIDAY = "UPDATE_ATTENDANCE_PROFILE_HOLIDAY";

const UPSERT_ATTENDANCE_PROFILE_ATTEMPT = "UPSERT_ATTENDANCE_PROFILE_ATTEMPT";
const UPSERT_ATTENDANCE_PROFILE_SUCCESS = "UPSERT_ATTENDANCE_PROFILE_SUCCESS";
const UPSERT_ATTENDANCE_PROFILE_FAILED = "UPSERT_ATTENDANCE_PROFILE_FAILED";

const UPDATE_ATTENDANCE_PROFILE_HOLIDAYS = "UPDATE_ATTENDANCE_PROFILE_HOLIDAYS";

const FETCH_ALL_OFFICE_HOLIDAYS_ATTEMPT = "FETCH_ALL_OFFICE_HOLIDAYS_ATTEMPT";
const FETCH_ALL_OFFICE_HOLIDAYS_SUCCESS = "FETCH_ALL_OFFICE_HOLIDAYS_SUCCESS";
const FETCH_ALL_OFFICE_HOLIDAYS_FAILED = "FETCH_ALL_OFFICE_HOLIDAYS_FAILED";

const DELETE_ATTENDANCE_PROFILE_ATTEMPT = "DELETE_ATTENDANCE_PROFILE_ATTEMPT";
const DELETE_ATTENDANCE_PROFILE_SUCCESS = "DELETE_ATTENDANCE_PROFILE_SUCCESS";
const DELETE_ATTENDANCE_PROFILE_FAILED = "DELETE_ATTENDANCE_PROFILE_FAILED";

const CLONE_ATTENDANCE_PROFILE_ATTEMPT = "CLONE_ATTENDANCE_PROFILE_ATTEMPT";
const CLONE_ATTENDANCE_PROFILE_SUCCESS = "CLONE_ATTENDANCE_PROFILE_SUCCESS";
const CLONE_ATTENDANCE_PROFILE_FAILED = "CLONE_ATTENDANCE_PROFILE_FAILED";

const FORGOT_PASSWORD_ATTEMPT = "FORGOT_PASSWORD_ATTEMPT";
const FORGOT_PASSWORD_FINISH = "FORGOT_PASSWORD_FINISH";

const RESET_PASSWORD_ATTEMPT = "RESET_PASSWORD_ATTEMPT";
const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";

const FETCH_DASHBOARD_DEPARTMNETS = "FETCH_DASHBOARD_DEPARTMNETS";

// handle fetch employee name on click action
const FETCH_EMPLOYEE_NAME_ON_CLICK_ATTEMPT =
  "FETCH_EMPLOYEE_NAME_ON_CLICK_ATTEMPT";
const FETCH_EMPLOYEE_NAME_ON_CLICK_SUCCESS =
  "FETCH_EMPLOYEE_NAME_ON_CLICK_SUCCESS";
const FETCH_EMPLOYEE_NAME_ON_CLICK_FAILED =
  "FETCH_EMPLOYEE_NAME_ON_CLICK_FAILED";

// handle show/hide Employees Name modal actions
const SHOW_ATT_CHART_EMPLOYEES_NAME_MODAL =
  "SHOW_ATT_CHART_EMPLOYEES_NAME_MODAL";
const HIDE_ATT_CHART_EMPLOYEES_NAME_MODAL =
  "HIDE_ATT_CHART_EMPLOYEES_NAME_MODAL";

const UPDATE_OVERTIME_INPUT = "UPDATE_OVERTIME_INPUT";

// handle fetch total employee requests and claims
const FETCH_TOTAL_EMPLOYEE_REQUESTS = "FETCH_TOTAL_EMPLOYEE_REQUESTS";

// handle fetch total employee requests
const FETCH_TOTAL_PENDING_REQUESTS = "FETCH_TOTAL_PENDING_REQUESTS";

// handle fetch total employee claims
const FETCH_TOTAL_PENDING_CLAIMS = "FETCH_TOTAL_PENDING_CLAIMS";

// Bonus form
const SHOW_BONUS_MODAL = "SHOW_BONUS_MODAL";
const HIDE_BONUS_MODAL = "HIDE_BONUS_MODAL";

// Deductions form
const SHOW_DEDUCTIONS_MODAL = "SHOW_DEDUCTIONS_MODAL";
const HIDE_DEDUCTIONS_MODAL = "HIDE_DEDUCTIONS_MODAL";

// claim request form
const SHOW_CLAIM_REQUEST_MODAL = "SHOW_CLAIM_REQUEST_MODAL";
const HIDE_CLAIM_REQUEST_MODAL = "HIDE_CLAIM_REQUEST_MODAL";

// accept claim request form
const SHOW_ACCEPT_CLAIM_REQUEST_MODAL = "SHOW_ACCEPT_CLAIM_REQUEST_MODAL";
const HIDE_ACCEPT_CLAIM_REQUEST_MODAL = "HIDE_ACCEPT_CLAIM_REQUEST_MODAL";

// fetch users claims
const FETCH_USERS_CLAIMS_ATTEMPT = "FETCH_USERS_CLAIMS_ATTEMPT";
const FETCH_USERS_CLAIMS_SUCCESS = "FETCH_USERS_CLAIMS_SUCCESS";
const FETCH_USERS_CLAIMS_FAILED = "FETCH_USERS_CLAIMS_FAILED";

// fetch employee profile claims

// fetch my profile claims

// fetch users bonus
const FETCH_USERS_BONUS_ATTEMPT = "FETCH_USERS_BONUS_ATTEMPT";
const FETCH_USERS_BONUS_SUCCESS = "FETCH_USERS_BONUS_SUCCESS";
const FETCH_USERS_BONUS_FAILED = "FETCH_USERS_BONUS_FAILED";

// upsert bonus
const UPSERT_BONUS_ATTEMPT = "UPSERT_BONUS_ATTEMPT";
const UPSERT_BONUS_SUCCESS = "UPSERT_BONUS_SUCCESS";
const UPSERT_BONUS_FAILED = "UPSERT_BONUS_FAILED";

// Edit Bonus
const EDIT_BONUS_ATTEMPT = "EDIT_BONUS_ATTEMPT";

// Delete Bonus
const DELETE_BONUS_ATTEMPT = "DELETE_BONUS_ATTEMPT";
const DELETE_BONUS_SUCCESS = "DELETE_BONUS_SUCCESS";
const DELETE_BONUS_FAILED = " DELETE_BONUS_FAILED";

// fetch users deductions
const FETCH_USERS_DEDUCTIONS_ATTEMPT = "FETCH_USERS_DEDUCTIONS_ATTEMPT";
const FETCH_USERS_DEDUCTIONS_SUCCESS = "FETCH_USERS_DEDUCTIONS_SUCCESS";
const FETCH_USERS_DEDUCTIONS_FAILED = "FETCH_USERS_DEDUCTIONS_FAILED";

// upsert deductions
const UPSERT_DEDUCTION_ATTEMPT = "UPSERT_DEDUCTION_ATTEMPT";
const UPSERT_DEDUCTION_SUCCESS = "UPSERT_DEDUCTION_SUCCESS";
const UPSERT_DEDUCTION_FAILED = "UPSERT_DEDUCTION_FAILED";

// Edit Deduction Modal
const EDIT_DEDUCTIONS_ATTEMPT = "EDIT_DEDUCTIONS_ATTEMPT";

// Delete Deduction
const DELETE_DEDUCTION_ATTEMPT = "DELETE_DEDUCTION_ATTEMPT";
const DELETE_DEDUCTION_SUCCESS = "DELETE_DEDUCTION_SUCCESS";
const DELETE_DEDUCTION_FAILED = " DELETE_DEDUCTION_FAILED";

const FETCH_EXTRA_USERS_FILTER = "FETCH_EXTRA_USERS_FILTER";
// Edit amount payroll list modal
const SHOW_EDIT_AMOUNT_MODAL = "SHOW_EDIT_AMOUNT_MODAL";
const HIDE_EDIT_AMOUNT_MODAL = "HIDE_EDIT_AMOUNT_MODAL";
const FETCH_DEDUCTION_AMOUNT_ATTEMPT = "FETCH_DEDUCTION_AMOUNT_ATTEMPT";
const FETCH_DEDUCTION_AMOUNT_SUCCESS = "FETCH_DEDUCTION_AMOUNT_SUCCESS";

// manage privilage modal
const EDIT_EMPLOYEE_PRIVILEGES_ATTEMPT = "EDIT_EMPLOYEE_PRIVILEGES_ATTEMPT";
const EDIT_EMPLOYEE_PRIVILEGES_FAILED = "EDIT_EMPLOYEE_PRIVILEGES_FAILED";

const UPSERT_EMPLOYEE_PRIVILEGES_ATTEMPT = "UPSERT_EMPLOYEE_PRIVILEGES_ATTEMPT";
const UPSERT_EMPLOYEE_PRIVILEGES_SUCCESS = "UPSERT_EMPLOYEE_PRIVILEGES_SUCCESS";
const UPSERT_EMPLOYEE_PRIVILEGES_FAILED = "UPSERT_EMPLOYEE_PRIVILEGES_FAILED";

const SHOW_MANAGE_PRIVILAGES_MODAL = "SHOW_MANAGE_PRIVILAGES_MODAL";
const HIDE_MANAGE_PRIVILAGES_MODAL = "HIDE_MANAGE_PRIVILAGES_MODAL";

const UPDATE_PRIVILEGES_RADIO = "UPDATE_PRIVILEGES_RADIO";

// fetch payroll report list
const FETCH_PAYROLL_REPORT_LIST_ATTEMPT = "FETCH_PAYROLL_REPORT_LIST_ATTEMPT";
const FETCH_PAYROLL_REPORT_LIST_SUCCESS = "FETCH_PAYROLL_REPORT_LIST_SUCCESS";
const FETCH_PAYROLL_REPORT_LIST_FAILED = "FETCH_PAYROLL_REPORT_LIST_FAILED";
const STOP_REPORT_LIST_LOADER = "STOP_REPORT_LIST_LOADER";

// fetch Monthly report list
const FETCH_MONTHLY_REPORT_LIST_ATTEMPT = "FETCH_MONTHLY_REPORT_LIST_ATTEMPT";
const FETCH_MONTHLY_REPORT_LIST_SUCCESS = "FETCH_MONTHLY_REPORT_LIST_SUCCESS";
const FETCH_MONTHLY_REPORT_LIST_FAILED = "FETCH_MONTHLY_REPORT_LIST_FAILED";

// fetch Yearly report list
const FETCH_YEARLY_REPORT_LIST_ATTEMPT = "FETCH_YEARLY_REPORT_LIST_ATTEMPT";
const FETCH_YEARLY_REPORT_LIST_SUCCESS = "FETCH_YEARLY_REPORT_LIST_SUCCESS";
const FETCH_YEARLY_REPORT_LIST_FAILED = "FETCH_YEARLY_REPORT_LIST_FAILED";

// fetch payroll report list employees filter
const FETCH_PAYROLL_REPORT_EMPLOYEES_FILTER =
  "FETCH_PAYROLL_REPORT_EMPLOYEES_FILTER";

// fetch payroll report list regulation filter
const FETCH_PAYROLL_REGULATION_OPTIONS_FILTER =
  "FETCH_PAYROLL_REGULATION_OPTIONS_FILTER";

// fetch payroll report list currencies filter
const FETCH_PAYROLL_REPORT_CURRENCIES_FILTER =
  "FETCH_PAYROLL_REPORT_CURRENCIES_FILTER";

// fetch payroll report list offices filter
const FETCH_PAYROLL_REPORT_OFFICES_FILTER =
  "FETCH_PAYROLL_REPORT_OFFICES_FILTER";

// Handle Fetch employee Salary config

const UPSERT_USER_LOCALE_ATTEMPT = "UPSERT_USER_LOCALE_ATTEMPT";
const UPSERT_USER_LOCALE_SUCCESS = "UPSERT_USER_LOCALE_SUCCESS";
const UPSERT_USER_LOCALE_FAILED = "UPSERT_USER_LOCALE_FAILED";

// upsert claims request
const UPSERT_CLAIM_REQUEST_ATTEMPT = "UPSERT_CLAIM_REQUEST_ATTEMPT";
const UPSERT_CLAIM_REQUEST_SUCCESS = "UPSERT_CLAIM_REQUEST_SUCCESS";
const UPSERT_CLAIM_REQUEST_FAILED = "UPSERT_CLAIM_REQUEST_FAILED";

// upsert Employee claims request
const UPSERT_EMPLOYEE_CLAIM_REQUEST_ATTEMPT =
  "UPSERT_EMPLOYEE_CLAIM_REQUEST_ATTEMPT";
const UPSERT_EMPLOYEE_CLAIM_REQUEST_SUCCESS =
  "UPSERT_EMPLOYEE_CLAIM_REQUEST_SUCCESS";
const UPSERT_EMPLOYEE_CLAIM_REQUEST_FAILED =
  "UPSERT_EMPLOYEE_CLAIM_REQUEST_FAILED";

// edit claim request_EMPLOYEE
const EDIT_EMPLOYEE_CLAIM_REQUEST_ATTEMPT =
  "EDIT_EMPLOYEE_CLAIM_REQUEST_ATTEMPT";

// reject claim request action
const REJECT_CLAIM_REQUEST_ATTEMPT = "REJECT_CLAIM_REQUEST_ATTEMPT";

// accept claim request action
const ACCEPT_CLAIM_REQUEST_ATTEMPT = "ACCEPT_CLAIM_REQUEST_ATTEMPT";

// claim request action
const CLAIM_ACTION_REQUEST_SUCCESS = "CLAIM_ACTION_REQUEST_SUCCESS";
const CLAIM_ACTION_REQUEST_FAILED = "CLAIM_ACTION_REQUEST_FAILED";

// cancel claim request action
const CANCEL_CLAIM_REQUEST_ATTEMPT = "CANCEL_CLAIM_REQUEST_ATTEMPT";
const CANCEL_CLAIM_REQUEST_SUCCESS = "CANCEL_CLAIM_REQUEST_SUCCESS";
const CANCEL_CLAIM_REQUEST_FAILED = "CANCEL_CLAIM_REQUEST_FAILED";
// Fetch Payroll Extra Fields
const FETCH_PAYROLL_EXTRA_FIELDS_SUCCESS = "FETCH_PAYROLL_EXTRA_FIELDS_SUCCESS";
// Fetch Payroll Closed Months
const FETCH_PAYROLL_CLOSED_MONTHS_SUCCESS =
  "FETCH_PAYROLL_CLOSED_MONTHS_SUCCESS";
// Handle Edit Deductions
const EDIT_PAYROLL_DEDUCTIONS_ATTEMPT = "EDIT_PAYROLL_DEDUCTIONS_ATTEMPT";
const EDIT_PAYROLL_DEDUCTIONS_SUCCESS = "EDIT_PAYROLL_DEDUCTIONS_SUCCESS";
const EDIT_PAYROLL_DEDUCTIONS_FAILED = "EDIT_PAYROLL_DEDUCTIONS_FAILED";
// Update Payroll Payment
const SUBMIT_PAYROLL_PAYMENT_ATTEMPT = "SUBMIT_PAYROLL_PAYMENT_ATTEMPT";
const SUBMIT_PAYROLL_PAYMENT_SUCCESS = "SUBMIT_PAYROLL_PAYMENT_SUCCESS";
const SUBMIT_PAYROLL_PAYMENT_FAILED = "SUBMIT_PAYROLL_PAYMENT_FAILED";

const REVERSE_PAYROLL_PAYMENT_ATTEMPT = "REVERSE_PAYROLL_PAYMENT_ATTEMPT";
const REVERSE_PAYROLL_PAYMENT_SUCCESS = "REVERSE_PAYROLL_PAYMENT_SUCCESS";
const REVERSE_PAYROLL_PAYMENT_FAILED = "REVERSE_PAYROLL_PAYMENT_FAILED";

const FETCH_MY_PAYROLL_ATTEMPT = "FETCH_MY_PAYROLL_ATTEMPT";
const FETCH_MY_PAYROLL_SUCCESS = "FETCH_MY_PAYROLL_SUCCESS";
// Salary - config Details

const CREATE_ALLOWANCE_ATTEMPT = "CREATE_ALLOWANCE_ATTEMPT";
const CREATE_ALLOWANCE_SUCCESS = "CREATE_ALLOWANCE_SUCCESS";
const CREATE_ALLOWANCE_FAILED = "CREATE_ALLOWANCE_FAILED";

const CREATE_NEW_EMPLOYEE_SALARY_ATTEMPT = "CREATE_NEW_EMPLOYEE_SALARY_ATTEMPT";
const CREATE_NEW_EMPLOYEE_SALARY_SUCCESS = "CREATE_NEW_EMPLOYEE_SALARY_SUCCESS";
const CREATE_NEW_EMPLOYEE_SALARY_FAILED = "CREATE_NEW_EMPLOYEE_SALARY_FAILED";

const CREATE_DEDUCTIBLE_ATTEMPT = "CREATE_DEDUCTIBLE_ATTEMPT";
const CREATE_DEDUCTIBLE_SUCCESS = "CREATE_DEDUCTIBLE_SUCCESS";
const CREATE_DEDUCTIBLE_FAILED = "CREATE_DEDUCTIBLE_FAILED";

const ADD_ALLOWANCE_INPUT = "ADD_ALLOWANCE_INPUT";
const REMOVE_ALLOWANCE_INPUT = "REMOVE_ALLOWANCE_INPUT";

const ADD_DEDUCTIBLE_INPUT = "ADD_DEDUCTIBLE_INPUT";
const REMOVE_DEDUCTIBLE_INPUT = "REMOVE_DEDUCTIBLE_INPUT";

const ON_CHILD_INPUT_CHANGE = "ON_CHILD_INPUT_CHANGE";
const ON_CHILD_CHECKBOX_CHANGE = "ON_CHILD_CHECKBOX_CHANGE";
const ON_CHILD_INPUT_CHANGE_DOCUMENTS = "ON_CHILD_INPUT_CHANGE_DOCUMENTS";
const ON_MULTI_INPUT_INDEX_CHANGE = "ON_MULTI_INPUT_INDEX_CHANGE";
const ON_RESET_DOCUMENTS_ROW = "ON_RESET_DOCUMENTS_ROW";

const RESET_SALARY_CONFIG_FORM = "RESET_SALARY_CONFIG_FORM";

const INIT_UPSERT_SALARY_CONFIG_FORM = "INIT_UPSERT_SALARY_CONFIG_FORM";
const INIT_UPSERT_SALARY_CONFIG_FORM_FAILED =
  "INIT_UPSERT_SALARY_CONFIG_FORM_FAILED";

const SHOW_NEW_UPSERT_SALARY_CONFIG_FORM_SUCCESS =
  "SHOW_NEW_UPSERT_SALARY_CONFIG_FORM_SUCCESS";

const SHOW_EDIT_UPSERT_SALARY_CONFIG_FORM_SUCCESS =
  "SHOW_EDIT_UPSERT_SALARY_CONFIG_FORM_SUCCESS";

const DISMISS_UPSERT_SALARY_CONFIG_FORM = "DISMISS_UPSERT_SALARY_CONFIG_FORM";

const INIT_ACTIVATE_SALARY_CONFIG_SUCCESS =
  "INIT_ACTIVATE_SALARY_CONFIG_SUCCESS";

const INIT_ACTIVATE_SALARY_CONFIG_FAILED = "INIT_ACTIVATE_SALARY_CONFIG_FAILED";

// handle fetch user payslip actions
const FETCH_USER_PAYSLIP_ATTEMPT = "FETCH_USER_PAYSLIP_ATTEMPT";
const FETCH_USER_PAYSLIP_SUCCESS = "FETCH_USER_PAYSLIP_SUCCESS";
const FETCH_USER_PAYSLIP_FAILED = "FETCH_USER_PAYSLIP_FAILED";

// Add Documents Modal
const SHOW_DOCUMENT_MODAL = "SHOW_DOCUMENT_MODAL";
const HIDE_DOCUMENT_MODAL = "HIDE_DOCUMENT_MODAL";

// Edit Documents Modal
const SHOW_EDIT_DOCUMENT_MODAL = "SHOW_EDIT_DOCUMENT_MODAL";
const HIDE_EDIT_DOCUMENT_MODAL = "HIDE_EDIT_DOCUMENT_MODAL";

// handle fetch holiday offices
const FETCH_HOLIDAY_OFFICES = "FETCH_HOLIDAY_OFFICES";

// handle fetch holiday Attendance Profiles
const FETCH_HOLIDAY_ATT_PROFILE = "FETCH_HOLIDAY_ATT_PROFILE";

const INIT_OFFICE_ATTEMPT = "INIT_OFFICE_ATTEMPT";
const INIT_OFFICE_SUCCESS = "INIT_OFFICE_SUCCESS";
const INIT_OFFICE_FAILED = "INIT_OFFICE_FAILED";

const RESET_OFFICE_PASSWORD = "RESET_OFFICE_PASSWORD";

// handle accept overtime modal actions
const SHOW_OVERTIME_MODAL = "SHOW_OVERTIME_MODAL";
const HIDE_OVERTIME_MODAL = "HIDE_OVERTIME_MODAL";

// handel fetch edit accepted overtime request data action
const FETCH_EDIT_ACCEPTED_OVERTIME_REQUEST_DATA_ATTEMPT =
  "FETCH_EDIT_ACCEPTED_OVERTIME_REQUEST_DATA_ATTEMPT";
const FETCH_EDIT_ACCEPTED_OVERTIME_REQUEST_DATA_SUCCESS =
  "FETCH_EDIT_ACCEPTED_OVERTIME_REQUEST_DATA_SUCCESS";
const FETCH_EDIT_ACCEPTED_OVERTIME_REQUEST_DATA_FAILED =
  "FETCH_EDIT_ACCEPTED_OVERTIME_REQUEST_DATA_FAILED";

// handle fetch export payroll datatable actions
const FETCH_EXPORT_PAYROLL_OPTIONS_ATTEMPT =
  "FETCH_EXPORT_PAYROLL_OPTIONS_ATTEMPT";
const FETCH_EXPORT_PAYROLL_OPTIONS_SUCCESS =
  "FETCH_EXPORT_PAYROLL_OPTIONS_SUCCESS";
const FETCH_EXPORT_PAYROLL_OPTIONS_FAILED =
  "FETCH_EXPORT_PAYROLL_OPTIONS_FAILED";

const DISMISS_EXPORT_PAYROLL_MODAL_ACTION =
  "DISMISS_EXPORT_PAYROLL_MODAL_ACTION";

// handle submit export payroll

const SUBMIT_EXPORT_PAYROLL_ATTEMPT = "SUBMIT_EXPORT_PAYROLL_ATTEMPT";
const SUBMIT_EXPORT_PAYROLL_SUCCESS = "SUBMIT_EXPORT_PAYROLL_SUCCESS";
const SUBMIT_EXPORT_PAYROLL_FAILED = "SUBMIT_EXPORT_PAYROLL_FAILED";
const EXPORT_PAYSLIP_ACTION = "EXPORT_PAYSLIP_ACTION";
const EXPORT_PAYSLIP_SUCCESS = "EXPORT_PAYSLIP_SUCCESS";
const EXPORT_PAYSLIP_FAILED = "EXPORT_PAYSLIP_FAILED";

const VERIFY_USER_PHONE_ATTEMPT = "VERIFY_USER_PHONE_ATTEMPT";
const VERIFY_USER_PHONE_SUCCESS = "VERIFY_USER_PHONE_SUCCESS";
const VERIFY_USER_PHONE_FAILED = "VERIFY_USER_PHONE_FAILED";

const SEND_PHONE_VERIFICATION_ATTEMPT = "SEND_PHONE_VERIFICATION_ATTEMPT";
const SEND_PHONE_VERIFICATION_SUCCESS = "SEND_PHONE_VERIFICATION_SUCCESS";
const SEND_PHONE_VERIFICATION_FAILED = "SEND_PHONE_VERIFICATION_FAILED";

const TOGGLE_MONTHLY_REPORT_CHECKBOX = "TOGGLE_MONTHLY_REPORT_CHECKBOX";
const TOGGLE_YEARLY_REPORT_CHECKBOX = "TOGGLE_YEARLY_REPORT_CHECKBOX";

// handle email verfication actions
const VERIFY_USER_EMAIL_ATTEMPT = "VERIFY_USER_EMAIL_ATTEMPT";
const VERIFY_USER_EMAIL_SUCCESS = "VERIFY_USER_EMAIL_SUCCESS";
const VERIFY_USER_EMAIL_FAILED = "VERIFY_USER_EMAIL_FAILED";

const SEND_EMAIL_VERIFICATION_ATTEMPT = "SEND_EMAIL_VERIFICATION_ATTEMPT";
const SEND_EMAIL_VERIFICATION_SUCCESS = "SEND_EMAIL_VERIFICATION_SUCCESS";
const SEND_EMAIL_VERIFICATION_FAILED = "SEND_EMAIL_VERIFICATION_FAILED";

// handle fetch all companies actions
const FETCH_ALL_COMPANIES_ATTEMPT = "FETCH_ALL_COMPANIES_ATTEMPT";
const FETCH_ALL_COMPANIES_SUCCESS = "FETCH_ALL_COMPANIES_SUCCESS";
const FETCH_ALL_COMPANIES_FAILED = "FETCH_ALL_COMPANIES_FAILED";

// Delete companies
const DELETE_COMPANIES_ATTEMPT = "DELETE_COMPANIES_ATTEMPT";
const DELETE_COMPANIES_SUCCESS = "DELETE_COMPANIES_SUCCESS";
const DELETE_COMPANIES_FAILED = " DELETE_COMPANIES_FAILED";

// acitivate companies action
const ACTIVATE_COMPANIES_ATTEMPT = "ACTIVATE_COMPANIES_ATTEMPT";
const ACTIVATE_COMPANIES_SUCCESS = "ACTIVATE_COMPANIES_SUCCESS";
const ACTIVATE_COMPANIES_FAILED = "ACTIVATE_COMPANIES_FAILED";

// suspend companies action
const SUSPEND_COMPANIES_ATTEMPT = "SUSPEND_COMPANIES_ATTEMPT";
const SUSPEND_COMPANIES_SUCCESS = "SUSPEND_COMPANIES_SUCCESS";
const SUSPEND_COMPANIES_FAILED = "SUSPEND_COMPANIES_FAILED";

// upsert company actions
const SHOW_COMPANY_MODAL = "SHOW_COMPANY_MODAL";
const HIDE_COMPANY_MODAL = "HIDE_COMPANY_MODAL";
const UPSERT_COMPANY_ATTEMPT = "UPSERT_COMPANY_ATTEMPT";
const UPSERT_COMPANY_SUCCESS = "UPSERT_COMPANY_SUCCESS";
const UPSERT_COMPANY_FAILED = "UPSERT_COMPANY_FAILED";
const ADD_BUSSINESS_PARTNER_INPUT = "ADD_BUSSINESS_PARTNER_INPUT";
const REMOVE_BUSSINESS_PARTNER_INPUT = "REMOVE_BUSSINESS_PARTNER_INPUT";

// Update company action
const UPDATE_COMPANY_ATTEMPT = "UPDATE_COMPANY_ATTEMPT";
const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS";
const UPDATE_COMPANY_FAILED = "UPDATE_COMPANY_FAILED";

// handle fetch company account managers
const FETCH_COMPANY_ACCOUNT_MANAGERS = "FETCH_COMPANY_ACCOUNT_MANAGERS";

// handle fetch company subscription plans
const FETCH_SUBSCRIPTION_PLANS = "FETCH_SUBSCRIPTION_PLANS";

// handle fetch company currency
const FETCH_COMPANY_CURRENCY = "FETCH_COMPANY_CURRENCY";

// Handle Fetch company Profile
const FETCH_COMPANY_PROFILE_ATTEMPT = "FETCH_COMPANY_PROFILE_ATTEMPT";
const FETCH_COMPANY_PROFILE_SUCCESS = "FETCH_COMPANY_PROFILE_SUCCESS";
const FETCH_COMPANY_PROFILE_FAILED = "FETCH_COMPANY_PROFILE_FAILED";
const RESET_COMPANY_PRFOILE_VIEW = "RESET_COMPANY_PRFOILE_VIEW";

// update company name action
const SHOW_EDIT_COMPANY_NAME_MODAL = "SHOW_EDIT_COMPANY_NAME_MODAL";
const HIDE_EDIT_COMPANY_NAME_MODAL = "HIDE_EDIT_COMPANY_NAME_MODAL";
const UPDATE_COMPANY_NAME_MODAL_ATTEMPT = "UPDATE_COMPANY_NAME_MODAL_ATTEMPT";
const UPDATE_COMPANY_NAME_MODAL_SUCCESS = "UPDATE_COMPANY_NAME_MODAL_SUCCESS";
const UPDATE_COMPANY_NAME_MODAL_FAILED = "UPDATE_COMPANY_NAME_MODAL_FAILED";

// update company account managers action
const SHOW_EDIT_ACCOUNT_MANAGERS_MODAL = "SHOW_EDIT_ACCOUNT_MANAGERS_MODAL";
const HIDE_EDIT_ACCOUNT_MANAGERS_MODAL = "HIDE_EDIT_ACCOUNT_MANAGERS_MODAL";
const UPDATE_ACCOUNT_MANAGERS_MODAL_ATTEMPT =
  "UPDATE_ACCOUNT_MANAGERS_MODAL_ATTEMPT";
const UPDATE_ACCOUNT_MANAGERS_MODAL_SUCCESS =
  "UPDATE_ACCOUNT_MANAGERS_MODAL_SUCCESS";
const UPDATE_ACCOUNT_MANAGERS_MODAL_FAILED =
  "UPDATE_ACCOUNT_MANAGERS_MODAL_FAILED";

// update company bussiness partners action
const SHOW_EDIT_BUSSINESS_PARTNERS_MODAL = "SHOW_EDIT_BUSSINESS_PARTNERS_MODAL";
const HIDE_EDIT_BUSSINESS_PARTNERS_MODAL = "HIDE_EDIT_BUSSINESS_PARTNERS_MODAL";
const UPDATE_BUSSINESS_PARTNERS_MODAL_ATTEMPT =
  "UPDATE_BUSSINESS_PARTNERS_MODAL_ATTEMPT";
const UPDATE_BUSSINESS_PARTNERS_MODAL_SUCCESS =
  "UPDATE_BUSSINESS_PARTNERS_MODAL_SUCCESS";
const UPDATE_BUSSINESS_PARTNERS_MODAL_FAILED =
  "UPDATE_BUSSINESS_PARTNERS_MODAL_FAILED";
const ADD_BUSSINESS_PARTNER_INPUT_IN_PROFILE =
  "ADD_BUSSINESS_PARTNER_INPUT_IN_PROFILE";
const REMOVE_BUSSINESS_PARTNER_INPUT_IN_PROFILE =
  "REMOVE_BUSSINESS_PARTNER_INPUT_IN_PROFILE";
const DELETE_COMPANY_BUSSINESS_PARTNERS_ATTEMPT =
  "DELETE_COMPANY_BUSSINESS_PARTNERS_ATTEMPT";
const DELETE_COMPANY_BUSSINESS_PARTNERS_SUCCESS =
  "DELETE_COMPANY_BUSSINESS_PARTNERS_SUCCESS";
const DELETE_COMPANY_BUSSINESS_PARTNERS_FAILED =
  "DELETE_COMPANY_BUSSINESS_PARTNERS_FAILED";

// edit company bussiness partner action
const SHOW_UPDATE_BUSSINESS_PARTNER_MODAL =
  "SHOW_UPDATE_BUSSINESS_PARTNER_MODAL";
const HIDE_UPDATE_BUSSINESS_PARTNER_MODAL =
  "HIDE_UPDATE_BUSSINESS_PARTNER_MODAL";
const EDIT_BUSSINESS_PARTNER_MODAL_ATTEMPT =
  "EDIT_BUSSINESS_PARTNER_MODAL_ATTEMPT";
const EDIT_BUSSINESS_PARTNER_MODAL_SUCCESS =
  "EDIT_BUSSINESS_PARTNER_MODAL_SUCCESS";
const EDIT_BUSSINESS_PARTNER_MODAL_FAILED =
  "EDIT_BUSSINESS_PARTNER_MODAL_FAILED";

// upsert company subscription actions
const SHOW_COMPANY_SUBSCRIPTION_MODAL = "SHOW_COMPANY_SUBSCRIPTION_MODAL";
const HIDE_COMPANY_SUBSCRIPTION_MODAL = "HIDE_COMPANY_SUBSCRIPTION_MODAL";
const UPSERT_COMPANY_SUBSCRIPTION_ATTEMPT =
  "UPSERT_COMPANY_SUBSCRIPTION_ATTEMPT";
const UPSERT_COMPANY_SUBSCRIPTION_SUCCESS =
  "UPSERT_COMPANY_SUBSCRIPTION_SUCCESS";
const UPSERT_COMPANY_SUBSCRIPTION_FAILED = "UPSERT_COMPANY_SUBSCRIPTION_FAILED";

// update employee limit value
const UPDATE_EMPLOYEE_LIMIT_VALUE = "UPDATE_EMPLOYEE_LIMIT_VALUE";


// Fetch Admin users actions
const FETCH_ADMIN_USERS_ATTEMPT = "FETCH_ADMIN_USERS_ATTEMPT";
const FETCH_ADMIN_USERS_SUCCESS = "FETCH_ADMIN_USERS_SUCCESS";
const FETCH_ADMIN_USERS_FAILED = "FETCH_ADMIN_USERS_FAILED";

// Show/Hide Admin users modal actions
const SHOW_ADMIN_USER_MODAL = "SHOW_ADMIN_USER_MODAL";
const HIDE_ADMIN_USER_MODAL = "HIDE_ADMIN_USER_MODAL";

// Add Admin users actions
const CREATE_ADMIN_USER_ATTEMPT = "CREATE_ADMIN_USER_ATTEMPT";
const CREATE_ADMIN_USER_SUCCESS = "CREATE_ADMIN_USER_SUCCESS";
const CREATE_ADMIN_USER_FAILED = "CREATE_ADMIN_USER_FAILED";

// Delete Admin users actions
const DELETE_ADMIN_USER_ATTEMPT = "DELETE_ADMIN_USER_ATTEMPT";
const DELETE_ADMIN_USER_SUCCESS = "DELETE_ADMIN_USER_SUCCESS";
const DELETE_ADMIN_USER_FAILED = "DELETE_ADMIN_USER_FAILED";

// handle fetch admin user companies actions
const FETCH_ADMIN_USER_COMPANIES_ATTEMPT = "FETCH_ADMIN_USER_COMPANIES_ATTEMPT";
const FETCH_ADMIN_USER_COMPANIES_SUCCESS = "FETCH_ADMIN_USER_COMPANIES_SUCCESS";
const FETCH_ADMIN_USER_COMPANIES_FAILED = "FETCH_ADMIN_USER_COMPANIES_FAILED";

// handle fetch admin user employees actions
const FETCH_ADMIN_USER_EMPLOYEES_ATTEMPT = "FETCH_ADMIN_USER_EMPLOYEES_ATTEMPT";
const FETCH_ADMIN_USER_EMPLOYEES_SUCCESS = "FETCH_ADMIN_USER_EMPLOYEES_SUCCESS";
const FETCH_ADMIN_USER_EMPLOYEES_FAILED = "FETCH_ADMIN_USER_EMPLOYEES_FAILED";

// handle admin user privilages actions
const EDIT_ADMIN_USER_PRIVILEGES_ATTEMPT = "EDIT_ADMIN_USER_PRIVILEGES_ATTEMPT";
const EDIT_ADMIN_USER_PRIVILEGES_FAILED = "EDIT_ADMIN_USER_PRIVILEGES_FAILED";
const UPSERT_ADMIN_USER_PRIVILEGES_ATTEMPT =
  "UPSERT_ADMIN_USER_PRIVILEGES_ATTEMPT";
const UPSERT_ADMIN_USER_PRIVILEGES_FAILED =
  "UPSERT_ADMIN_USER_PRIVILEGES_FAILED";

// Handle admin user profile actions
const SHOW_ADMIN_USER_PROFILE_MODAL = "SHOW_ADMIN_USER_PROFILE_MODAL";
const HIDE_ADMIN_USER_PROFILE_MODAL = "HIDE_ADMIN_USER_PROFILE_MODAL";
const UPDATE_ADMIN_USER_PROFILE_ATTEMPT = "UPDATE_ADMIN_USER_PROFILE_ATTEMPT";
const UPDATE_ADMIN_USER_PROFILE_SUCCESS = "UPDATE_ADMIN_USER_PROFILE_SUCCESS";
const UPDATE_ADMIN_USER_PROFILE_FAILED = "UPDATE_ADMIN_USER_PROFILE_FAILED";

const SHOW_EDIT_ATT_REQUEST_MODAL = "SHOW_EDIT_ATT_REQUEST_MODAL";
const HIDE_EDIT_ATT_REQUEST_MODAL = "HIDE_EDIT_ATT_REQUEST_MODAL";
const EDIT_ATT_REQUEST_MODAL_ATTEMPT = "EDIT_ATT_REQUEST_MODAL_ATTEMPT";
const EDIT_ATT_REQUEST_MODAL_SUCCESS = "EDIT_ATT_REQUEST_MODAL_SUCCESS";
const EDIT_ATT_REQUEST_MODAL_FAILED = "EDIT_ATT_REQUEST_MODAL_FAILED";
const VIEW_ATT_REQUEST_MODAL_ATTEMPT = "VIEW_ATT_REQUEST_MODAL_ATTEMPT";
const VIEW_ATT_REQUEST_MODAL_SUCCESS = "VIEW_ATT_REQUEST_MODAL_SUCCESS";
const VIEW_ATT_REQUEST_MODAL_FAILED = "VIEW_ATT_REQUEST_MODAL_FAILED";

const SHOW_VIEW_ATT_REQUEST_MODAL = "SHOW_VIEW_ATT_REQUEST_MODAL";
const HIDE_VIEW_ATT_REQUEST_MODAL = "HIDE_VIEW_ATT_REQUEST_MODAL";
const ADD_VIOLATION_INPUT = "ADD_VIOLATION_INPUT";
const REMOVE_VIOLATION_INPUT = "REMOVE_VIOLATION_INPUT";

const FETCH_WORK_TIMINGS_LIST_ATTEMPT = "FETCH_WORK_TIMINGS_LIST_ATTEMPT";
const FETCH_WORK_TIMINGS_LIST_SUCCESS = "FETCH_WORK_TIMINGS_LIST_SUCCESS";
const FETCH_WORK_TIMINGS_LIST_FAILED = "FETCH_WORK_TIMINGS_LIST_FAILED";

const UPSERT_WORK_TIMINGS_ATTEMPT = "UPSERT_WORK_TIMINGS_ATTEMPT";
const UPSERT_WORK_TIMINGS_SUCCESS = "UPSERT_WORK_TIMINGS_SUCCESS";
const UPSERT_WORK_TIMINGS_FAILED = "UPSERT_WORK_TIMINGS_FAILED";

const EDIT_FETCH_WORK_TIMINGS_ATTEMPT = "EDIT_FETCH_WORK_TIMINGS_ATTEMPT";
const EDIT_FETCH_WORK_TIMINGS_SUCCESS = "EDIT_FETCH_WORK_TIMINGS_SUCCESS";
const EDIT_FETCH_WORK_TIMINGS_FAILED = "EDIT_FETCH_WORK_TIMINGS_FAILED";

const SHOW_WORK_TIMINGS_MODAL = "SHOW_WORK_TIMINGS_MODAL";
const DISMISS_WORK_TIMINGS_MODAL = "DISMISS_WORK_TIMINGS_MODAL";

const EXPORT_MONTHLY_REPORT_ATTEMPT = "EXPORT_MONTHLY_REPORT_ATTEMPT";
const EXPORT_MONTHLY_REPORT_SUCCESS = "EXPORT_MONTHLY_REPORT_SUCCESS";
const EXPORT_MONTHLY_REPORT_FAILED = "EXPORT_MONTHLY_REPORT_FAILED";

const EXPORT_YEARLY_REPORT_ATTEMPT = "EXPORT_YEARLY_REPORT_ATTEMPT";
const EXPORT_YEARLY_REPORT_SUCCESS = "EXPORT_YEARLY_REPORT_SUCCESS";
const EXPORT_YEARLY_REPORT_FAILED = "EXPORT_YEARLY_REPORT_FAILED";

const UPSERT_SICK_REQUEST_ATTEMPT = "UPSERT_SICK_REQUEST_ATTEMPT";
const UPSERT_SICK_REQUEST_SUCCESS = "UPSERT_SICK_REQUEST_SUCCESS";

const SHOW_EXPORT_MONTHLY_REPORT_MODAL = "SHOW_EXPORT_MONTHLY_REPORT_MODAL";
const HIDE_EXPORT_MONTHLY_REPORT_MODAL = "HIDE_EXPORT_MONTHLY_REPORT_MODAL";

const SHOW_EXPORT_YEARLY_REPORT_MODAL = "SHOW_EXPORT_YEARLY_REPORT_MODAL";
const HIDE_EXPORT_YEARLY_REPORT_MODAL = "HIDE_EXPORT_YEARLY_REPORT_MODAL";

// handle fetch activation attendance employee
const FETCH_ACTIVATION_ATTENDANCE_ATTEMPT =
  "FETCH_ACTIVATION_ATTENDANCE_ATTEMPT";

const FETCH_ACTIVATION_ATTENDANCE_SUCCESS =
  "FETCH_ACTIVATION_ATTENDANCE_SUCCESS";

const FETCH_ACTIVATION_ATTENDANCE_FAILED = "FETCH_ACTIVATION_ATTENDANCE_FAILED";

const RESET_ACTICATION_ATTENDANCE_FORM = "RESET_ACTICATION_ATTENDANCE_FORM";

const UPSERT_DOCUMENT_ATTEMPT = "UPSERT_DOCUMENT_ATTEMPT";
const UPSERT_DOCUMENT_SUCCESS = "UPSERT_DOCUMENT_SUCCESS";
const UPSERT_DOCUMENT_FAILED = "UPSERT_DOCUMENT_FAILED";
const SHOW_COMPANY_DOCUMENT_MODAL = "SHOW_COMPANY_DOCUMENT_MODAL";
const HIDE_COMPANY_DOCUMENT_MODAL = "HIDE_COMPANY_DOCUMENT_MODAL";

const STORE_DOCUMENTABLE_ATTEMPT = "STORE_DOCUMENTABLE_ATTEMPT";
const STORE_DOCUMENTABLE_SUCCESS = "STORE_DOCUMENTABLE_SUCCESS";
const STORE_DOCUMENTABLE_FAILED = "STORE_DOCUMENTABLE_FAILED";

const STORE_DOCUMENTABLE_ROW_ATTEMPT = "STORE_DOCUMENTABLE_ROW_ATTEMPT";
const ON_CHILD_INPUT_CHANGE_DOCUMENTS_MULTIPLE =
  "ON_CHILD_INPUT_CHANGE_DOCUMENTS_MULTIPLE";

const FETCH_DOCUMENTS_LIST_ATTEMPT = "FETCH_DOCUMENTS_LIST_ATTEMPT";
const FETCH_DOCUMENTS_LIST_SUCCESS = "FETCH_DOCUMENTS_LIST_SUCCESS";
const FETCH_DOCUMENTS_LIST_FAILED = "FETCH_DOCUMENTS_LIST_FAILED";

const SHOW_DOCUMENTS_EDIT = "SHOW_DOCUMENTS_EDIT";
const HIDE_DOCUMENTS_EDIT = "HIDE_DOCUMENTS_EDIT";
const ON_INPUT_RESET_WITH_VALUE = "ON_INPUT_RESET_WITH_VALUE";

// leaves breaks
const UPDATE_LEAVES_BREAKS_OPTIONS = "UPDATE_LEAVES_BREAKS_OPTIONS";
const SHOW_LEAVES_BREAKS_MODAL = "SHOW_LEAVES_BREAKS_MODAL";
const SET_LEAVES_BREAKS_SERVER_VALIDATION =
  "SET_LEAVES_BREAKS_SERVER_VALIDATION";
const RESET_LEAVES_BREAKS_FORM = "RESET_LEAVES_BREAKS_FORM";

// handle fetch email notfifcations settings
const FETCH_EMAIL_NOTFIFCATIONS_ATTEMPT = "FETCH_EMAIL_NOTFIFCATIONS_ATTEMPT";
const FETCH_EMAIL_NOTFIFCATIONS_SUCCESS = "FETCH_EMAIL_NOTFIFCATIONS_SUCCESS";
const FETCH_EMAIL_NOTFIFCATIONS_FAILED = "FETCH_EMAIL_NOTFIFCATIONS_FAILED";

// handle update email notfifcations settings
const UPDATE_EMAIL_NOTIFICATIONS_ATTEMPT = "UPDATE_EMAIL_NOTIFICATIONS_ATTEMPT";
const UPDATE_EMAIL_NOTIFICATIONS_SUCCESS = "UPDATE_EMAIL_NOTIFICATIONS_SUCCESS";
const UPDATE_EMAIL_NOTIFICATIONS_FAILED = "UPDATE_EMAIL_NOTIFICATIONS_FAILED";
const FETCH_ALL_WORK_TIME_OPTIONS = "FETCH_ALL_WORK_TIME_OPTIONS";

const ADD_NEW_WORK_TIME_CONFIG = "ADD_NEW_WORK_TIME_CONFIG";

const VIEW_WORK_TIME_ATTEMPT = "VIEW_WORK_TIME_ATTEMPT";
const VIEW_WORK_TIME_SUCCESS = "VIEW_WORK_TIME_SUCCESS";
const VIEW_WORK_TIME_FAILED = "VIEW_WORK_TIME_FAILED";

const DISMISS_VIEW_WORK_TIME = "DISMISS_VIEW_WORK_TIME";

const CLONE_WORK_TIME_ATTEMPT = "CLONE_WORK_TIME_ATTEMPT";
const CLONE_WORK_TIME_SUCCESS = "CLONE_WORK_TIME_SUCCESS";
const CLONE_WORK_TIME_FAILED = "CLONE_WORK_TIME_FAILED";

const DELETE_WORK_TIME_ATTEMPT = "DELETE_WORK_TIME_ATTEMPT";
const DELETE_WORK_TIME_SUCCESS = "DELETE_WORK_TIME_SUCCESS";
const DELETE_WORK_TIME_FAILED = "DELETE_WORK_TIME_FAILED";

const RESET_OLD_SIGN_IN_CONFIG_ACTION = "RESET_OLD_SIGN_IN_CONFIG_ACTION";

const REMOVE_SIGN_IN_CONFIG_ACTION = "REMOVE_SIGN_IN_CONFIG_ACTION";

// handle resend credentails actions
const RESEND_CREDENTAILS_ATTEMPT = "RESEND_CREDENTAILS_ATTEMPT";
const RESEND_CREDENTAILS_SUCCESS = "RESEND_CREDENTAILS_SUCCESS";
const RESEND_CREDENTAILS_FAILED = "RESEND_CREDENTAILS_FAILED";
// handle check password
const CHECK_PASSWORD_ATTEMPT = "CHECK_PASSWORD_ATTEMPT";
const CHECK_PASSWORD_SUCCESS = "CHECK_PASSWORD_SUCCESS";
const CHECK_PASSWORD_FAILED = "CHECK_PASSWORD_FAILED";
// handle fetching all managers
const FETCH_ALL_MANAGERS_ACTION = "FETCH_ALL_MANAGERS_ACTION";

const PUSHER_EMPLOYEE_BREAK_IN_OUT = "PUSHER_EMPLOYEE_BREAK_IN_OUT";

// handle fetch petty cash list
const FETCH_PETTY_CASH_LIST_ATTEMPT = "FETCH_PETTY_CASH_LIST_ATTEMPT";
const FETCH_PETTY_CASH_LIST_SUCCESS = "FETCH_PETTY_CASH_LIST_SUCCESS";
const FETCH_PETTY_CASH_LIST_FAILED = "FETCH_PETTY_CASH_LIST_FAILED";

// handle fetch employees for petty cash
const FETCH_PETTY_CASH_EMPLOYEES = "FETCH_PETTY_CASH_EMPLOYEES";

// handle fetch avaliable employees for petty cash filter
const FETCH_AVALIABLE_PETTY_CASH_EMPLOYEES_FILTER_OPTIONS =
  "FETCH_AVALIABLE_PETTY_CASH_EMPLOYEES_FILTER_OPTIONS";

// handle modify/edit petty cash balance modal actions
const SHOW_PETTY_CASH_BALANCE_MODAL = "SHOW_PETTY_CASH_BALANCE_MODAL";
const HIDE_PETTY_CASH_BALANCE_MODAL = "HIDE_PETTY_CASH_BALANCE_MODAL";
const UPSERT_PETTY_CASH_BALANCE_ATTEMPT = "UPSERT_PETTY_CASH_BALANCE_ATTEMPT";
const UPSERT_PETTY_CASH_BALANCE_SUCCESS = "UPSERT_PETTY_CASH_BALANCE_SUCCESS";
const UPSERT_PETTY_CASH_BALANCE_FAILED = "UPSERT_PETTY_CASH_BALANCE_FAILED";

// to fetch employee current balance
const FETCH_EMPLOYEE_CURRENT_BALANCE_ATTEMPT =
  "FETCH_EMPLOYEE_CURRENT_BALANCE_ATTEMPT";
const FETCH_EMPLOYEE_CURRENT_BALANCE_SUCCESS =
  "FETCH_EMPLOYEE_CURRENT_BALANCE_SUCCESS";
const FETCH_EMPLOYEE_CURRENT_BALANCE_FAILED =
  "FETCH_EMPLOYEE_CURRENT_BALANCE_FAILED";

// handle fetch company settings list
const FETCH_COMPANY_SETTINGS_LIST_ATTEMPT =
  "FETCH_COMPANY_SETTINGS_LIST_ATTEMPT";
const FETCH_COMPANY_SETTINGS_LIST_SUCCESS =
  "FETCH_COMPANY_SETTINGS_LIST_SUCCESS";
const FETCH_COMPANY_SETTINGS_LIST_FAILED = "FETCH_COMPANY_SETTINGS_LIST_FAILED";

// handle company settings modal actions
const SHOW_COMPANY_SETTINGS_MODAL = "SHOW_COMPANY_SETTINGS_MODAL";
const HIDE_COMPANY_SETTINGS_MODAL = "HIDE_COMPANY_SETTINGS_MODAL";
const UPSERT_COMPANY_SETTINGS_ATTEMPT = "UPSERT_COMPANY_SETTINGS_ATTEMPT";
const UPSERT_COMPANY_SETTINGS_SUCCESS = "UPSERT_COMPANY_SETTINGS_SUCCESS";
const UPSERT_COMPANY_SETTINGS_FAILED = "UPSERT_COMPANY_SETTINGS_FAILED";

// Delete company settings actions
const DELETE_COMPANY_SETTINGS_ATTEMPT = "DELETE_COMPANY_SETTINGS_ATTEMPT";
const DELETE_COMPANY_SETTINGS_SUCCESS = "DELETE_COMPANY_SETTINGS_SUCCESS";
const DELETE_COMPANY_SETTINGS_FAILED = "DELETE_COMPANY_SETTINGS_FAILED";

// handle fetch company expenses list
const FETCH_COMPANY_EXPENSE_ATTEMPT = "FETCH_COMPANY_EXPENSE_ATTEMPT";
const FETCH_COMPANY_EXPENSE_SUCCESS = "FETCH_COMPANY_EXPENSE_SUCCESS";
const FETCH_COMPANY_EXPENSE_FAILED = "FETCH_COMPANY_EXPENSE_FAILED";

// handle company expenses modal actions
const SHOW_COMPANY_EXPENSE_MODAL = "SHOW_COMPANY_EXPENSE_MODAL";
const HIDE_COMPANY_EXPENSE_MODAL = "HIDE_COMPANY_EXPENSE_MODAL";
const UPSERT_COMPANY_EXPENSE_ATTEMPT = "UPSERT_COMPANY_EXPENSE_ATTEMPT";
const UPSERT_COMPANY_EXPENSE_SUCCESS = "UPSERT_COMPANY_EXPENSE_SUCCESS";
const UPSERT_COMPANY_EXPENSE_FAILED = "UPSERT_COMPANY_EXPENSE_FAILED";

// handle fetch company expenses categories actions
const FETCH_COMPANY_EXPENSES_CATEGORIES = "FETCH_COMPANY_EXPENSES_CATEGORIES";

// handle fetch company expenses categories actions
const FETCH_COMPANY_EXPENSES_SUBCATEGORIES_ATTEMPT =
  "FETCH_COMPANY_EXPENSES_SUBCATEGORIES_ATTEMPT";
const FETCH_COMPANY_EXPENSES_SUBCATEGORIES_SUCCESS =
  "FETCH_COMPANY_EXPENSES_SUBCATEGORIES_SUCCESS";
const FETCH_COMPANY_EXPENSES_SUBCATEGORIES_FAILED =
  "FETCH_COMPANY_EXPENSES_SUBCATEGORIES_FAILED";

// handle fetch expense total balance actions
const FETCH_EXPENSE_TOTAL_BALANCE_ATTEMPT =
  "FETCH_EXPENSE_TOTAL_BALANCE_ATTEMPT";
const FETCH_EXPENSE_TOTAL_BALANCE_SUCCESS =
  "FETCH_EXPENSE_TOTAL_BALANCE_SUCCESS";
const FETCH_EXPENSE_TOTAL_BALANCE_FAILED = "FETCH_EXPENSE_TOTAL_BALANCE_FAILED";

// handle export company expenses actions
const EXPORT_COMPANY_EXPENSES_ATTEMPT = "EXPORT_COMPANY_EXPENSES_ATTEMPT";
const EXPORT_COMPANY_EXPENSES_SUCCESS = "EXPORT_COMPANY_EXPENSES_SUCCESS";
const EXPORT_COMPANY_EXPENSES_FAILED = "EXPORT_COMPANY_EXPENSES_FAILED";

// handle sub catgories options in expense form actions
const FETCH_EXPENSE_FORM_SUBCATGERORIES_ATTEMPT =
  "FETCH_EXPENSE_FORM_SUBCATGERORIES_ATTEMPT";
const FETCH_EXPENSE_FORM_SUBCATGERORIES_SUCCESS =
  "FETCH_EXPENSE_FORM_SUBCATGERORIES_SUCCESS";
const FETCH_EXPENSE_FORM_SUBCATGERORIES_FAILED =
  "FETCH_EXPENSE_FORM_SUBCATGERORIES_FAILED";

// handle currencies option in expense form actions
const FETCH_EXPENSE_FORM_CUREENCIES_ATTEMPT =
  "FETCH_EXPENSE_FORM_CUREENCIES_ATTEMPT";
const FETCH_EXPENSE_FORM_CUREENCIES_SUCCESS =
  "FETCH_EXPENSE_FORM_CUREENCIES_SUCCESS";
const FETCH_EXPENSE_FORM_CUREENCIES_FAILED =
  "FETCH_EXPENSE_FORM_CUREENCIES_FAILED";

// handle employee options in expense form actions
const FETCH_EXPENSE_FORM_EMPLOYEES_ATTEMPT =
  "FETCH_EXPENSE_FORM_EMPLOYEES_ATTEMPT";
const FETCH_EXPENSE_FORM_EMPLOYEES_SUCCESS =
  "FETCH_EXPENSE_FORM_EMPLOYEES_SUCCESS";
const FETCH_EXPENSE_FORM_EMPLOYEES_FAILED =
  "FETCH_EXPENSE_FORM_EMPLOYEES_FAILED";

// handle delete company expense actions
const DELETE_COMPANY_EXPENSE_ATTEMPT = "DELETE_COMPANY_EXPENSE_ATTEMPT";
const DELETE_COMPANY_EXPENSE_SUCCESS = "DELETE_COMPANY_EXPENSE_SUCCESS";
const DELETE_COMPANY_EXPENSE_FAILED = "DELETE_COMPANY_EXPENSE_FAILED";

// handle delete company expense attachment file actions
const DELETE_COMPANY_EXPENSE_ATTACHMENT_FILE_ATTEMPT =
  "DELETE_COMPANY_EXPENSE_ATTACHMENT_FILE_ATTEMPT";
const DELETE_COMPANY_EXPENSE_ATTACHMENT_FILE_SUCCESS =
  "DELETE_COMPANY_EXPENSE_ATTACHMENT_FILE_SUCCESS";
const DELETE_COMPANY_EXPENSE_ATTACHMENT_FILE_FAILED =
  "DELETE_COMPANY_EXPENSE_ATTACHMENT_FILE_FAILED";

// handle delete petty cash attachment file actions
const DELETE_PETTY_CASH_ATTACHMENT_FILE_ATTEMPT =
  "DELETE_PETTY_CASH_ATTACHMENT_FILE_ATTEMPT";
const DELETE_PETTY_CASH_ATTACHMENT_FILE_SUCCESS =
  "DELETE_PETTY_CASH_ATTACHMENT_FILE_SUCCESS";
const DELETE_PETTY_CASH_ATTACHMENT_FILE_FAILED =
  "DELETE_PETTY_CASH_ATTACHMENT_FILE_FAILED";

// handle fetch user petty cash list
const FETCH_USER_PETTY_CASH_ATTEMPT = "FETCH_USER_PETTY_CASH_ATTEMPT";
const FETCH_USER_PETTY_CASH_SUCCESS = "FETCH_USER_PETTY_CASH_SUCCESS";
const FETCH_USER_PETTY_CASH_FAILED = "FETCH_USER_PETTY_CASH_FAILED";

// to fetch user profile current balance actions
const FETCH_USER_PROFILE_CURRENT_BALANCE_ATTEMPT =
  "FETCH_USER_PROFILE_CURRENT_BALANCE_ATTEMPT";
const FETCH_USER_PROFILE_CURRENT_BALANCE_SUCCESS =
  "FETCH_USER_PROFILE_CURRENT_BALANCE_SUCCESS";
const FETCH_USER_PROFILE_CURRENT_BALANCE_FAILED =
  "FETCH_USER_PROFILE_CURRENT_BALANCE_FAILED";

// to fetch petty cash currencies actions
const FETCH_PETTY_CASH_CURRENCIES_ATTEMPT =
  "FETCH_PETTY_CASH_CURRENCIES_ATTEMPT";
const FETCH_PETTY_CASH_CURRENCIES_SUCCESS =
  "FETCH_PETTY_CASH_CURRENCIES_SUCCESS";
const FETCH_PETTY_CASH_CURRENCIES_FAILED = "FETCH_PETTY_CASH_CURRENCIES_FAILED";
const INIT_SUSPENSION_USER_ATTEMPT = "INIT_SUSPENSION_USER_ATTEMPT";
const INIT_SUSPENSION_USER_SUCCESS = "INIT_SUSPENSION_USER_SUCCESS";
const INIT_SUSPENSION_USER_FAILED = "INIT_SUSPENSION_USER_FAILED";
// handle Fetch Employee Documents

const FETCH_EMPLOYEE_DOCUMENTS_ATTEMPT = "FETCH_EMPLOYEE_DOCUMENTS_ATTEMPT";
const FETCH_EMPLOYEE_DOCUMENTS_SUCCESS = "FETCH_EMPLOYEE_DOCUMENTS_SUCCESS";
const FETCH_EMPLOYEE_DOCUMENTS_FAILED = "FETCH_EMPLOYEE_DOCUMENTS_FAILED";

// add user doc document

const ADD_USER_DOCUMENT_ATTEMPT = "ADD_USER_DOCUMENT_ATTEMPT";
const ADD_USER_DOCUMENT_SUCCESS = "ADD_USER_DOCUMENT_SUCCESS";
const ADD_USER_DOCUMENT_FAILED = "ADD_USER_DOCUMENT_FAILED";

const FETCH_UPSERT_DOC_MODAL_ATTEMPT = "FETCH_UPSERT_DOC_MODAL_ATTEMPT";
const FETCH_UPSERT_DOC_MODAL_SUCCESS = "FETCH_UPSERT_DOC_MODAL_SUCCESS";
const FETCH_UPSERT_DOC_MODAL_FAILED = "FETCH_UPSERT_DOC_MODAL_FAILED";
const HANDLE_ROW_DOCUMENT = "HANDLE_ROW_DOCUMENT";

const UPDATE_COMPANY_ROW_DOCS = "UPDATE_COMPANY_ROW_DOCS";

const DELETE_USER_DOCUMENT_ATTACHMENT_ATTEMPT =
  "DELETE_USER_DOCUMENT_ATTACHMENT_ATTEMPT";

const DELETE_USER_DOCUMENT_ATTACHMENT_SUCCESS =
  "DELETE_USER_DOCUMENT_ATTACHMENT_SUCCESS";

const DELETE_USER_DOCUMENT_ATTACHMENT_FAILED =
  "DELETE_USER_DOCUMENT_ATTACHMENT_FAILED";

const FETCH_REQUIRED_DOCUMENTS_ATTEMPT = "FETCH_REQUIRED_DOCUMENTS_ATTEMPT";
const FETCH_REQUIRED_DOCUMENTS_FAILED = "FETCH_REQUIRED_DOCUMENTS_FAILED";
const FETCH_REQUIRED_DOCUMENTS_SUCCESS = "FETCH_REQUIRED_DOCUMENTS_SUCCESS";

const SHOW_MAIN_MODAL_WITH_DATA = "SHOW_MAIN_MODAL_WITH_DATA";
const HIDE_MAIN_MODAL_WITH_DATA = "HIDE_MAIN_MODAL_WITH_DATA";

const FETCH_HISTORY_DOC_MODAL_ATTEMPT = "FETCH_HISTORY_DOC_MODAL_ATTEMPT";
const FETCH_HISTORY_DOC_MODAL_SUCCESS = "FETCH_HISTORY_DOC_MODAL_SUCCESS";
const FETCH_HISTORY_DOC_MODAL_FAILED = "FETCH_HISTORY_DOC_MODAL_FAILED";
// handle data view change for employees data
const CHANGE_TO_LIST_DATA_VIEW = "CHANGE_TO_LIST_DATA_VIEW";
const CHANGE_TO_GRID_DATA_VIEW = "CHANGE_TO_GRID_DATA_VIEW";

// handle export employee list modal actions
const SHOW_EXPORT_EMPLOYEE_LIST_MODAL = "SHOW_EXPORT_EMPLOYEE_LIST_MODAL";
const HIDE_EXPORT_EMPLOYEE_LIST_MODAL = "HIDE_EXPORT_EMPLOYEE_LIST_MODAL";
const EXPORT_EMPLOYEE_LIST_ATTEMPT = "EXPORT_EMPLOYEE_LIST_ATTEMPT";
const EXPORT_EMPLOYEE_LIST_SUCCESS = "EXPORT_EMPLOYEE_LIST_SUCCESS";
const EXPORT_EMPLOYEE_LIST_FAILED = "EXPORT_EMPLOYEE_LIST_FAILED";

// handle fetch employee currencies in modify balance actions
const FETCH_EMPLOYEE_OFFICE_CURRENCY_PETTY_CASH_ATTEMPT =
  "FETCH_EMPLOYEE_OFFICE_CURRENCY_PETTY_CASH_ATTEMPT";
const FETCH_EMPLOYEE_OFFICE_CURRENCY_PETTY_CASH_SUCCESS =
  "FETCH_EMPLOYEE_OFFICE_CURRENCY_PETTY_CASH_SUCCESS";
const FETCH_EMPLOYEE_OFFICE_CURRENCY_PETTY_CASH_FAILED =
  "FETCH_EMPLOYEE_OFFICE_CURRENCY_PETTY_CASH_FAILED";
const FETCH_SETUP_WIZARD_DATA_IN_AUTH = "FETCH_SETUP_WIZARD_DATA_IN_AUTH";
const FETCH_DASHBOARD_CURRENCIES_SUCCESS = "FETCH_DASHBOARD_CURRENCIES_SUCCESS";
const FETCH_TOTAL_EXPENSES_ATTEMPT = "FETCH_TOTAL_EXPENSES_ATTEMPT";
const FETCH_TOTAL_EXPENSES_SUCCESS = "FETCH_TOTAL_EXPENSES_SUCCESS";
const FETCH_TOTAL_EXPENSES_FAILED = "FETCH_TOTAL_EXPENSES_FAILED";
const FETCH_SUB_CATEGORY_EXPENSES_ATTEMPT =
  "FETCH_SUB_CATEGORY_EXPENSES_ATTEMPT";
const FETCH_SUB_CATEGORY_EXPENSES_SUCCESS =
  "FETCH_SUB_CATEGORY_EXPENSES_SUCCESS";
const FETCH_SUB_CATEGORY_EXPENSES_FAILED = "FETCH_SUB_CATEGORY_EXPENSES_FAILED";
const SET_IS_THIS_QUERY_TOTAL_EXPENSES = "SET_IS_THIS_QUERY_TOTAL_EXPENSES";

//handle fetch open payroll months actions
const FETCH_OPEN_PAYROLL_MONTHS_ATTEMPT = "FETCH_OPEN_PAYROLL_MONTHS_ATTEMPT";
const FETCH_OPEN_PAYROLL_MONTHS_SUCCESS = "FETCH_OPEN_PAYROLL_MONTHS_SUCCESS";
const FETCH_OPEN_PAYROLL_MONTHS_FAILED = "FETCH_OPEN_PAYROLL_MONTHS_FAILED";
const ADD_COMPANY_CURRENCIES = "ADD_COMPANY_CURRENCIES";
const SET_OFFICE_FLAG = "SET_OFFICE_FLAG";
const EDIT_OPEN_PAYROLL_MONTHS_OFFICES_ACCORDING_TO_SCOPE =
  "EDIT_OPEN_PAYROLL_MONTHS_OFFICES_ACCORDING_TO_SCOPE";
const SET_IS_EMPLOYEE_IMPORT_MODAL_OPEN = "SET_IS_EMPLOYEE_IMPORT_MODAL_OPEN";
const DOWNLOAD_EMPLOYEE_TEMPLATE_ATTEMPT = "DOWNLOAD_EMPLOYEE_TEMPLATE_ATTEMPT";
const DOWNLOAD_EMPLOYEE_TEMPLATE_SUCCESS = "DOWNLOAD_EMPLOYEE_TEMPLATE_SUCCESS";
const DOWNLOAD_EMPLOYEE_TEMPLATE_FAILED = "DOWNLOAD_EMPLOYEE_TEMPLATE_FAILED";
const SET_IS_EMPLOYEE_IMPORT_MODAL_ERRORS_OPEN =
  "SET_IS_EMPLOYEE_IMPORT_MODAL_ERRORS_OPEN";
const UPLOAD_BULK_EMPLOYEES_CSV_ATTEMPT = "UPLOAD_BULK_EMPLOYEES_CSV_ATTEMPT";
const UPLOAD_BULK_EMPLOYEES_CSV_SUCCESS = "UPLOAD_BULK_EMPLOYEES_CSV_SUCCESS";
const UPLOAD_BULK_EMPLOYEES_CSV_FAILED = "UPLOAD_BULK_EMPLOYEES_CSV_FAILED";
const UPLOAD_BULK_EMPLOYEES_CSV_REQUEST_FAILED =
  "UPLOAD_BULK_EMPLOYEES_CSV_REQUEST_FAILED";

const UPSERT_CUSTOM_REQUEST_ATTEMPT = "UPSERT_CUSTOM_REQUEST_ATTEMPT";
const UPSERT_CUSTOM_REQUEST_SUCCESS = "UPSERT_CUSTOM_REQUEST_SUCCESS";
const UPSERT_CUSTOM_REQUEST_FAILED = "UPSERT_CUSTOM_REQUEST_FAILED";
const FETCH_EMPLOYEES_TURNOVER_CHART_ATTEMPT =
  "FETCH_EMPLOYEES_TURNOVER_CHART_ATTEMPT";
const FETCH_EMPLOYEES_TURNOVER_CHART_SUCCESS =
  "FETCH_EMPLOYEES_TURNOVER_CHART_SUCCESS";
const FETCH_EMPLOYEES_TURNOVER_CHART_FAILED =
  "FETCH_EMPLOYEES_TURNOVER_CHART_FAILED";

const UPDATE_TOTAL_lIST_COUNT = "UPDATE_TOTAL_lIST_COUNT";

const FETCH_PLAN_ROLES_OPTS = "FETCH_PLAN_ROLES_OPTS";

const FETCH_ROLE_PRESET_PRIVILEGS_ATTEMPT =
  "FETCH_ROLE_PRESET_PRIVILEGS_ATTEMPT";
const FETCH_ROLE_PRESET_PRIVILEGS_SUCCESS =
  "FETCH_ROLE_PRESET_PRIVILEGS_SUCCESS";
const FETCH_ROLE_PRESET_PRIVILEGS_FAILED = "FETCH_ROLE_PRESET_PRIVILEGS_FAILED";

// handle Request Comments modal actions
const SHOW_REQUEST_COMMENTS_MODAL = "SHOW_REQUEST_COMMENTS_MODAL";
const HIDE_REQUEST_COMMENTS_MODAL = "HIDE_REQUEST_COMMENTS_MODAL";
const FETCH_REQUEST_COMMENTS_ATTEMPT = "FETCH_REQUEST_COMMENTS_ATTEMPT";
const FETCH_REQUEST_COMMENTS_SUCCESS = "FETCH_REQUEST_COMMENTS_SUCCESS";
const FETCH_REQUEST_COMMENTS_FAILED = "FETCH_REQUEST_COMMENTS_FAILED";
const UPSERT_REQUEST_COMMENT_ATTEMPT = "UPSERT_REQUEST_COMMENT_ATTEMPT";
const UPSERT_REQUEST_COMMENT_SUCCESS = "UPSERT_REQUEST_COMMENT_SUCCESS";
const UPSERT_REQUEST_COMMENT_FAILED = "UPSERT_REQUEST_COMMENT_FAILED";

// handle of fetch roles privilages preview modal actions
const SHOW_PREVIEW_ROLE_PRIVILEGES_MODAL = "SHOW_PREVIEW_ROLE_PRIVILEGES_MODAL";
const HIDE_PREVIEW_ROLE_PRIVILEGES_MODAL = "HIDE_PREVIEW_ROLE_PRIVILEGES_MODAL";
const FETCH_PREVIEW_ROLE_PRIVILEGES_ATTEMPT =
  "FETCH_PREVIEW_ROLE_PRIVILEGES_ATTEMPT";
const FETCH_PREVIEW_ROLE_PRIVILEGES_SUCCESS =
  "FETCH_PREVIEW_ROLE_PRIVILEGES_SUCCESS";
const FETCH_PREVIEW_ROLE_PRIVILEGES_FAILED =
  "FETCH_PREVIEW_ROLE_PRIVILEGES_FAILED";

// handle dashboard ui checkbox action
const HANDLE_DASHBOARD_UI_CHECKBOX_TOGGLE_ACTION =
  "HANDLE_DASHBOARD_UI_CHECKBOX_TOGGLE_ACTION";

// handle of penalties action
const FETCH_PENALTIES_LIST_SUCCESS = "FETCH_PENALTIES_LIST_SUCCESS";
const FETCH_PENALTIES_EMPLOYEES_FILTER = "FETCH_PENALTIES_EMPLOYEES_FILTER";

// handle fetch half work timing template actions
const FETCH_HALF_WORK_TIMING_TEMPLATE_DATA_ATTEMPT =
  "FETCH_HALF_WORK_TIMING_TEMPLATE_DATA_ATTEMPT";
const FETCH_HALF_WORK_TIMING_TEMPLATE_DATA_SUCCESS =
  "FETCH_HALF_WORK_TIMING_TEMPLATE_DATA_SUCCESS";
const FETCH_HALF_WORK_TIMING_TEMPLATE_DATA_FAILED =
  "FETCH_HALF_WORK_TIMING_TEMPLATE_DATA_FAILED";

// handle auto suggest holidays actions
const AUTO_SUGGEST_HOLIDAYS_ATTEMPT = "AUTO_SUGGEST_HOLIDAYS_ATTEMPT";
const AUTO_SUGGEST_HOLIDAYS_SUCCESS = "AUTO_SUGGEST_HOLIDAYS_SUCCESS";
const AUTO_SUGGEST_HOLIDAYS_FAILED = "AUTO_SUGGEST_HOLIDAYS_FAILED";

// handle auto suggest holidays actions
const ACCEPT_SUGGESTED_HOLIDAY_ATTEMPT = "ACCEPT_SUGGESTED_HOLIDAY_ATTEMPT";
const ACCEPT_SUGGESTED_HOLIDAY_SUCCESS = "ACCEPT_SUGGESTED_HOLIDAY_SUCCESS";
const ACCEPT_SUGGESTED_HOLIDAY_FAILED = "ACCEPT_SUGGESTED_HOLIDAY_FAILED";

// handle delete suggested holidays actions
const DELETE_SUGGESTED_HOLIDAYS_ATTEMPT = "DELETE_SUGGESTED_HOLIDAYS_ATTEMPT";
const DELETE_SUGGESTED_HOLIDAYS_SUCCESS = "DELETE_SUGGESTED_HOLIDAYS_SUCCESS";
const DELETE_SUGGESTED_HOLIDAYS_FAILED = "DELETE_SUGGESTED_HOLIDAYS_FAILED";

// handle delete salary config actions
const DELETE_SALARY_CONFIG_ATTEMPT = "DELETE_SALARY_CONFIG_ATTEMPT";
const DELETE_SALARY_CONFIG_SUCCESS = "DELETE_SALARY_CONFIG_SUCCESS";
const DELETE_SALARY_CONFIG_FAILED = "DELETE_SALARY_CONFIG_FAILED";

// handle upsert assignment actions
const TOGGLE_MODAL_DISPLAY = "TOGGLE_MODAL_DISPLAY";
const FETCH_ASSIGNMENT_FORM_ATTEMPT = "FETCH_ASSIGNMENT_FORM_ATTEMPT";
const FETCH_ASSIGNMENT_FORM_SUCCESS = "FETCH_ASSIGNMENT_FORM_SUCCESS";
const FETCH_ASSIGNMENT_FORM_FAILED = "FETCH_ASSIGNMENT_FORM_FAILED";

// Change assignment
const FETCH_CHANGE_ASSIGNMENTS = "FETCH_CHANGE_ASSIGNMENTS";

// handle upsert assignment actions
const UPSERT_ASSIGNMENT_SUCCESS = "UPSERT_ASSIGNMENT_SUCCESS";
const UPSERT_ASSIGNMENT_FAILED = "UPSERT_ASSIGNMENT_FAILED";

// handle fetch assignments
const FETCH_ASSIGNMENTS_ATTEMPT = "FETCH_ASSIGNMENTS_ATTEMPT";
const FETCH_ASSIGNMENTS_SUCCESS = "FETCH_ASSIGNMENTS_SUCCESS";
const FETCH_ASSIGNMENTS_FAILED = "FETCH_ASSIGNMENTS_FAILED";

const CHANGE_ASSIGNMENT_DATE_ACTION = "CHANGE_ASSIGNMENT_DATE_ACTION";

const DELETE_ASSIGNMENT_ATTEMPT = "DELETE_ASSIGNMENT_ATTEMPT";
const DELETE_ASSIGNMENT_SUCCESS = "DELETE_ASSIGNMENT_SUCCESS";
const DELETE_ASSIGNMENT_FAILED = "DELETE_ASSIGNMENT_FAILED";

const TOGGLE_ASSIGNMENT_PREVIEW_MODAL = "TOGGLE_ASSIGNMENT_PREVIEW_MODAL";

// const EDIT_ASSIGNMENT_ATTEMPT = "EDIT_ASSIGNMENT_ATTEMPT";
// const EDIT_ASSIGNMENT_SUCCESS = "EDIT_ASSIGNMENT_SUCCESS";
// const EDIT_ASSIGNMENT_FAILED = "EDIT_ASSIGNMENT_FAILED";
// handle fetch all office options
const FETCH_ALL_OFFICE_OPTIONS = "FETCH_ALL_OFFICE_OPTIONS";
const FETCH_ALL_WORK_TIMINGS_MENU = "FETCH_ALL_WORK_TIMINGS_MENU";

// handle fetch edit attendnace work timing options action
const FETCH_EDIT_ATTENDNACE_WORK_TIMINGS_OPTIONS =
  "FETCH_EDIT_ATTENDNACE_WORK_TIMINGS_OPTIONS";
const FETCH_EDIT_ATTENDNACE_WORK_TIMINGS_OPTIONS_SUCCESS =
  "FETCH_EDIT_ATTENDNACE_WORK_TIMINGS_OPTIONS_SUCCESS";
const FETCH_EDIT_ATTENDNACE_WORK_TIMINGS_OPTIONS_FAILED =
  "FETCH_EDIT_ATTENDNACE_WORK_TIMINGS_OPTIONS_FAILED";

// handle fetch edit attendnace locations options action
const FETCH_EDIT_ATTENDNACE_LOCATIONS_OPTIONS =
  "FETCH_EDIT_ATTENDNACE_LOCATIONS_OPTIONS";
const FETCH_EDIT_ATTENDNACE_LOCATIONS_OPTIONS_SUCCESS =
  "FETCH_EDIT_ATTENDNACE_LOCATIONS_OPTIONS_SUCCESS";
const FETCH_EDIT_ATTENDNACE_LOCATIONS_OPTIONS_FAILED =
  "FETCH_EDIT_ATTENDNACE_LOCATIONS_OPTIONS_FAILED";

// handle update edit attendnace locations actions
const UPDATE_ATTENDNACE_LOG_LOCATIONS_IDS =
  "UPDATE_ATTENDNACE_LOG_LOCATIONS_IDS";
const UPDATE_ATTENDNACE_LOG_LOCATIONS_IDS_SUCCESS =
  "UPDATE_ATTENDNACE_LOG_LOCATIONS_IDS_SUCCESS";
const UPDATE_ATTENDNACE_LOG_LOCATIONS_IDS_FAILED =
  "UPDATE_ATTENDNACE_LOG_LOCATIONS_IDS_FAILED";

// access levels actions

const FETCH_COMPANY_ACCESS_LEVELS_ATTEMPT =
  "FETCH_COMPANY_ACCESS_LEVELS_ATTEMPT";

const FETCH_COMPANY_ACCESS_LEVELS_SUCCESS =
  "FETCH_COMPANY_ACCESS_LEVELS_SUCCESS";

const FETCH_COMPANY_ACCESS_LEVELS_FAILED = "FETCH_COMPANY_ACCESS_LEVELS_FAILED";
const DISMISS_ADD_NEW_ACCESS_LEVEL_MODAL_ACTION =
  "DISMISS_ADD_NEW_ACCESS_LEVEL_MODAL_ACTION";

const UPSERT_ROLE_TO_COMPANY_ATTEMPT = "UPSERT_ROLE_TO_COMPANY_ATTEMPT";
const UPSERT_ROLE_TO_COMPANY_SUCCESS = "UPSERT_ROLE_TO_COMPANY_SUCCESS";
const UPSERT_ROLE_TO_COMPANY_FAILED = "UPSERT_ROLE_TO_COMPANY_FAILED";

const EDIT_ROLE_PRIVILEGES_ATTEMPT = "EDIT_ROLE_PRIVILEGES_ATTEMPT";
const EDIT_ROLE_PRIVILEGES_SUCCESS = "EDIT_ROLE_PRIVILEGES_SUCCESS";
const EDIT_ROLE_PRIVILEGES_FAILED = "EDIT_ROLE_PRIVILEGES_FAILED";

const DELETE_COMPANY_ROLE_ATTEMPT = "DELETE_COMPANY_ROLE_ATTEMPT";
const DELETE_COMPANY_ROLE_SUCCESS = "DELETE_COMPANY_ROLE_SUCCESS";
const DELETE_COMPANY_ROLE_FAILED = "DELETE_COMPANY_ROLE_FAILED";

// Attendance Adjust Sign Out Actions
const ADJUST_SIGNOUT_ATTEMPT = "ADJUST_SIGNOUT_ATTEMPT";
const ADJUST_SIGNOUT_FAILED = "ADJUST_SIGNOUT_FAILED";
const ADJUST_SIGNOUT_SUCCESS = "ADJUST_SIGNOUT_SUCCESS";

// handle fetch loans action
const FETCH_LOANS_LIST_SUCCESS = "FETCH_LOANS_LIST_SUCCESS";

// handle fetch my loans action
const FETCH_MY_LOANS_LIST_SUCCESS = "FETCH_MY_LOANS_LIST_SUCCESS";

// handle generate installment input action
const GENERATE_INSTALLMENT_INPUT = "GENERATE_INSTALLMENT_INPUT";

// handle loan modal actions
const SHOW_LOAN_MODAL = "SHOW_LOAN_MODAL";
const HIDE_LOAN_MODAL = "HIDE_LOAN_MODAL";
const ADD_INSTALLMENTS_INPUT = "ADD_INSTALLMENTS_INPUT";
const REMOVE_INSTALLMENTS_INPUT = "REMOVE_INSTALLMENTS_INPUT";
const SET_lOAN_CURRENCY_DUE_TO_SALARY_CONFIG =
  "SET_lOAN_CURRENCY_DUE_TO_SALARY_CONFIG";
const UPSERT_LOAN_ATTEMPT = "UPSERT_LOAN_ATTEMPT";
const UPSERT_LOAN_SUCCESS = "UPSERT_LOAN_SUCCESS";
const UPSERT_LOAN_FAILED = "UPSERT_LOAN_FAILED";
//handle notification drawer
const SHOW_NOTIFICATION_DRAWER = "SHOW_NOTIFICATION_DRAWER";
const HIDE_NOTIFICATION_DRAWER = "HIDE_NOTIFICATION_DRAWER";

//handle get notification's history
const GET_NOTIFICATION_HISTORY_ATTEMPT = "GET_NOTIFICATION_HISTORY_ATTEMPT";
const GET_NOTIFICATION_HISTORY_FAILED = "GET_NOTIFICATION_HISTORY_FAILED";
const GET_NOTIFICATION_HISTORY_SUCCESS = "GET_NOTIFICATION_HISTORY_SUCCESS";
const NOTIFICATION_HISTORY_RESET = "NOTIFICATION_HISTORY_RESET";
// handle change notification status to read
const CHECK_NOTIFICATION_READ_ATTEMPT = "CHECK_NOTIFICATION_READ_ATTEMPT";
const CHECK_NOTIFICATION_READ_SUCCESS = "CHECK_NOTIFICATION_READ_SUCCESS";
const CHECK_NOTIFICATION_READ_FAILED = "CHECK_NOTIFICATION_READ_FAILED";

// NOTIFICATIONS
const SET_NOTIFICATION_PAGE_ACTION = "SET_NOTIFICATION_PAGE_ACTION";

const SHOW_PENALTY_MODAL = "SHOW_PENALTY_MODAL";
const HIDE_PENALTY_MODAL = "HIDE_PENALTY_MODAL";

const SET_SALARY_CONFIG_CALCULATIONS_FIXED =
  "SET_SALARY_CONFIG_CALCULATIONS_FIXED";
const SET_SHOULD_EXIT_SCHEDULE_PAGE = "SET_SHOULD_EXIT_SCHEDULE_PAGE";

const RESET_MODAL_MESSAGE = "RESET_MODAL_MESSAGE";

const SET_SERVER_VALIDATION = "SET_SERVER_VALIDATION";

const ADD_OVERTIME_SETTING = "ADD_OVERTIME_SETTING";
const REMOVE_OVERTIME_SETTING = "REMOVE_OVERTIME_SETTING";
const RESET_OVERTIME_SETTINGS = "RESET_OVERTIME_SETTINGS";

const SET_FORM_SERVER_VALIDATION = "SET_FORM_SERVER_VALIDATION";

const TOGGLE_SIGN_IN_BREAKS_MODAL = "TOGGLE_SIGN_IN_BREAKS_MODAL";

const TOGGLE_LOGS_MODAL = "TOGGLE_LOGS_MODAL";

const ADJUST_SIGN_IN_OUT_IDS = "ADJUST_SIGN_IN_OUT_IDS";

const RESET_ASSIGNMENT_MODAL_MESSAGE = "RESET_ASSIGNMENT_MODAL_MESSAGE";

const ACCEPT_DAY_OFF_REQUEST_ATTEMPT = "ACCEPT_DAY_OFF_REQUEST_ATTEMPT";
const ACCEPT_DAY_OFF_REQUEST_COMPLETED = "ACCEPT_DAY_OFF_REQUEST_COMPLETED";

const ACCEPT_LOAN_REQUEST_ATTEMPT = "ACCEPT_LOAN_REQUEST_ATTEMPT";
const ACCEPT_LOAN_REQUEST_COMPLETED = "ACCEPT_LOAN_REQUEST_COMPLETED";

const TOGGLE_SET_TEMPORARY_PASSWORD_MODAL =
  "TOGGLE_SET_TEMPORARY_PASSWORD_MODAL";

const FETCH_COMPANY_CONTACTS_ATTEMPT = "FETCH_COMPANY_CONTACTS_ATTEMPT";
const FETCH_COMPANY_CONTACTS_SUCCESS = "FETCH_COMPANY_CONTACTS_SUCCESS";
const FETCH_COMPANY_CONTACTS_FAILED = "FETCH_COMPANY_CONTACTS_FAILED";

const actionTypes = {
  REFETCH_FROM_SAGE_TO_HOOK,
  RESET_REFETCH_FROM_SAGE_TO_HOOK,

  LOGING_IN_ATTEMPT,
  LOGED_IN_SUCCESS,
  LOGED_IN_FAILED,

  //fetch user Profile
  FETCH_PROFILE_SUCCESS,
  SET_OFFICE_CURRENCY,

  // Fetching User Requests

  CANCEL_REQUEST_ATTEMPT,
  CANCEL_REQUEST_SUCCESS,
  CANCEL_REQUEST_FAILED,

  // Handle show/hide actions for edit leaves modal
  SHOW_EDIT_LEAVES_MODAL,
  HIDE_EDIT_LEAVES_MODAL,

  //fetching History Records
  FETCH_ATTENDANCE_RECORDS_ATTEMPT,
  FETCH_ATTENDANCE_RECORDS_SUCCESS,
  FETCH_ATTENDANCE_RECORDS_FAILED,

  //INIT & EDIT Attendance Profile
  INIT_ATTENDANCE_PROFILE_ATTEMPT,
  INIT_ATTENDANCE_PROFILE_SUCCESS,
  INIT_ATTENDANCE_PROFILE_FAILED,

  FETCH_ATTENDANCE_PROFILE_ATTEMPT,
  FETCH_ATTENDANCE_PROFILE_SUCCESS,
  FETCH_ATTENDANCE_PROFILE_FAILED,
  ASSIGN_HLAF_ATTENDANCE_PROFILE,

  //INIT & EDIT EMPLOYEE
  EDIT_EMPLOYEE_ATTEMPT,
  EDIT_EMPLOYEE_SUCCESS,
  EDIT_EMPLOYEE_FAILED,

  INIT_EMPLOYEE_ATTEMPT,
  INIT_EMPLOYEE_SUCCESS,
  INIT_EMPLOYEE_FAILED,

  SET_SELECTED_EMPLOYEE_ATT_PROFILE,

  UPSERT_EMPLOYEE_ATTEMPT,
  UPDATE_EMPLOYEE_SUCCESS,
  UPDATE_EMPLOYEE_FAILED,

  RESET_EMPLOYEE_FORM,

  // handle on Form Input change
  ON_INPUT_CHANGE,
  ON_SELECT_INPUT_CHANGE,
  ON_INPUT_INCREMENT,
  ON_INPUT_DECREMENT,
  ON_CHECKBOX_INPUT_CHANGE,
  ON_CHECKBOX_BOOLEAN_INPUT_CHANGE,
  ON_RADIO_INPUT_CHANGE,
  ON_INPUT_RESET,
  ON_FORM_RESET,
  ON_APPEND_INPUT_CHANGE,

  // HANDLE fORM VALIDATION
  SET_INPUT_VALID,
  SET_INPUT_INVALID,

  //handle dataTable #sorting #paginate #filter

  SET_DATATABLE_SORTING,
  SET_DATATABLE_FILTER,
  SET_DATATABLE_PAGINATE,

  // handle Fetch Data for data table
  FETCH_ATTENDANCE_PROFILES_LIST_ATTEMPT,
  FETCH_ATTENDANCE_PROFILES_LIST_SUCCESS,
  FETCH_ATTENDANCE_PROFILES_LIST_FAILED,

  // handle create att Profile
  CREATE_ATTENDANCE_PROFILE_ATTEMPT,
  CREATE_ATTENDANCE_PROFILE_SUCCESS,
  CREATE_ATTENDANCE_PROFILE_FAILED,

  RESET_ATTENDANCE_PROFILE_FORM,

  // handle Fetch Data for data table
  FETCH_ALL_EMPLOYEES_ATTEMPT,
  FETCH_ALL_EMPLOYEES_SUCCESS,
  FETCH_ALL_EMPLOYEES_FAILED,
  //HANDLE EDIT EMPLOYEE MODAL,
  SET_EDIT_EMPLOYEE_OPEN,
  SET_EDIT_EMPLOYEE_CLOSED,

  // Handle Fetch employee Profile
  FETCH_EMPLOYEE_PROFILE_SUCCESS,

  // Handle Fetch employee attendance logs
  FETCH_EMPLOYEE_ATTENDANCE_LOGS_SUCCESS,
  FETCH_EMPLOYEE_ATTENDANCE_LOGS_FAILED,

  RESET_EMPLOYEE_PRFOILE_VIEW,

  PAGINATE_DATA_TABLE,
  //LOG Out
  LOG_OUT,
  // Handle Fetch All Offices
  FETCH_ALL_OFFICES_ATTEMPT,
  FETCH_ALL_OFFICES_SUCCESS,
  FETCH_ALL_OFFICES_FAILED,
  FETCH_ALL_DEPARTMENTS_ATTEMPT,
  FETCH_ALL_DEPARTMENTS_SUCCESS,
  FETCH_ALL_DEPARTMENTS_FAILED,
  FETCH_ALL_POSITIONS_ATTEMPT,
  FETCH_ALL_POSITIONS_SUCCESS,
  FETCH_ALL_POSITIONS_FAILED,

  FETCH_ALL_HOLIDAYS_ATTEMPT,
  FETCH_ALL_HOLIDAYS_SUCCESS,
  FETCH_ALL_HOLIDAYS_FAILED,

  FETCH_ALL_WORK_TIMINGS_OPTIONS,
  FETCH_ALL_EMPLOYEES_OPTIONS,
  FETCH_ALL_LOCATION_OPTIONS,
  SET_ALL_POSITION_OPTIONS,

  // Handle commission Date Range
  SET_COMMISSION_DATE_RANGE,

  // update lang & lat location
  UPDATE_LNG_LAT_LOCATION,

  FETCH_INBOUND_REQUESTS_ATTEMPT,
  FETCH_INBOUND_REQUESTS_SUCCESS,
  FETCH_INBOUND_REQUESTS_FAILED,

  UPDTAE_REQUESTS_EMPLOYEES_FILTER,

  ACCEPT_INBOUND_REQUEST_ATTEMPT,
  ACCEPT_INBOUND_REQUEST_SUCCESS,
  ACCEPT_INBOUND_REQUEST_FAILED,

  ACCEPT_OVERTIME_REQUEST_ATTEMPT,
  ACCEPT_OVERTIME_REQUEST_SUCCESS,
  ACCEPT_OVERTIME_REQUEST_FAILED,

  REJECT_INBOUND_REQUEST_ATTEMPT,
  REJECT_INBOUND_REQUEST_SUCCESS,
  REJECT_INBOUND_REQUEST_FAILED,

  ATTENDANCE_ACTION_MODAL_FAILED,
  ATTENDANCE_ACTION_MODAL_SUCCESS,
  TOGGLE_ATTENDANCE_ACTION_MODAL,

  SEND_NORMAL_REQUEST_ATTEMPT,

  SEND_UNPAID_REQUEST_ATTEMPT,

  SEND_EMERGENCY_REQUEST_ATTEMPT,

  SEND_PERMISSION_REQUEST_ATTEMPT,

  SEND_HALFDAY_REQUEST_ATTEMPT,

  SEND_HOME_EXCHANGE_REQUEST_ATTEMPT,

  SEND_HOME_FLEX_REQUEST_ATTEMPT,

  FETCH_HISTORY_LIST_ATTEMPT,
  FETCH_HISTORY_LIST_SUCCESS,
  FETCH_HISTORY_LIST_FAILED,

  SIGN_IN_INFO_ATTEMPT,

  SIGN_IN_ATTEMPT,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILED,

  DISMISS_HOME_SIGN_IN_ATTEMPT,
  DISMISS_HOME_SIGN_IN_SUCCESS,
  DISMISS_HOME_SIGN_IN_FAILED,

  SIGN_OUT_INFO_ATTEMPT,

  SIGN_OUT_ATTEMPT,
  SIGN_OUT_SUCCESS,
  SIGN_OUT_FAILED,

  SHOW_FORCE_SIGN_OUT_ALERT,
  HIDE_FORCE_SIGN_OUT_ALERT,

  SHOW_DISMISS_HOME_ALERT,
  HIDE_DISMISS_HOME_ALERT,

  REFRESH_SIGN_IN_OUT_ATTEMPT,
  REFRESH_SIGN_IN_OUT_SUCCESS,

  FETCH_ATTENDANCE_LOGS_ATTEMPT,
  FETCH_ATTENDANCE_LOGS_SUCCESS,
  FETCH_ATTENDANCE_LOGS_FAILED,

  UPDTAE_EMPLOYEES_FILTER_LOGS,

  FETCH_SUSPENSION_LOGS_ATTEMPT,
  FETCH_SUSPENSION_LOGS_SUCCESS,
  FETCH_SUSPENSION_LOGS_FAILED,

  FETCH_BALANCE_LOGS_ATTEMPT,
  FETCH_BALANCE_LOGS_SUCCESS,
  FETCH_BALANCE_LOGS_FAILED,

  TOGGLE_SUSPENSION_MODAL,
  HIDE_SUSPENSION_MODAL,

  UPDATE_SUSPENSION_ATTEMPT,
  UPDATE_SUSPENSION_SUCCESS,
  UPDATE_SUSPENSION_FAILED,
  ADD_EMPLOYEE_ID_TO_SUSPENSION,

  // handle adding managed employees to suspension modal
  MANAGED_EMPLOYEES_IN_SUSPENSION_MODAL,

  DELETE_SUSPENSION_ATTEMPT,
  DELETE_SUSPENSION_SUCCESS,
  DELETE_SUSPENSION_FAILED,

  FETCH_OVERTIME_LOGS_ATTEMPT,
  FETCH_OVERTIME_LOGS_SUCCESS,
  FETCH_OVERTIME_LOGS_FAILED,

  SHOW_DEPARTMENT_MODAL,
  HIDE_DEPARTMENT_MODAL,

  UPDATE_DEPARTMENT_ATTEMPT,
  UPDATE_DEPARTMENT_SUCCESS,
  UPDATE_DEPARTMENT_FAILED,

  CREATE_DEPARTMENT_ATTEMPT,
  CREATE_DEPARTMENT_SUCCESS,
  CREATE_DEPARTMENT_FAILED,

  DELETE_DEPARTMENT_ATTEMPT,
  DELETE_DEPARTMENT_SUCCESS,
  DELETE_DEPARTMENT_FAILED,

  SHOW_POSITION_MODAL,
  HIDE_POSITION_MODAL,

  UPDATE_POSITION_ATTEMPT,
  UPDATE_POSITION_SUCCESS,
  UPDATE_POSITION_FAILED,

  CREATE_POSITION_ATTEMPT,
  CREATE_POSITION_SUCCESS,
  CREATE_POSITION_FAILED,

  DELETE_POSITION_ATTEMPT,
  DELETE_POSITION_SUCCESS,
  DELETE_POSITION_FAILED,

  SHOW_HOLIDAYS_MODAL,
  HIDE_HOLIDAYS_MODAL,

  CREATE_HOLIDAYS_ATTEMPT,
  CREATE_HOLIDAYS_SUCCESS,
  CREATE_HOLIDAYS_FAILED,

  DELETE_HOLIDAYS_ATTEMPT,
  DELETE_HOLIDAYS_SUCCESS,
  DELETE_HOLIDAYS_FAILED,

  DELETE_USER_ATTEMPT,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILED,
  SHOW_CONFIRM_DELETE_MANAGER_MODAL,
  HIDE_CONFIRM_DELETE_MANAGER_MODAL,

  // handle fetch init delete manager actions
  FETCH_INIT_DELETE_MANAGER_ATTEMPT,
  FETCH_INIT_DELETE_MANAGER_SUCCESS,
  FETCH_INIT_DELETE_MANAGER_FAILED,

  // handle fetch init suspend manager actions
  FETCH_INIT_SUSPEND_MANAGER_ATTEMPT,
  FETCH_INIT_SUSPEND_MANAGER_SUCCESS,
  FETCH_INIT_SUSPEND_MANAGER_FAILED,

  // handle fetch replace by managers options actions
  FETCH_REPLACE_BY_MANAGERS_OPTIONS_ATTEMPT,
  FETCH_REPLACE_BY_MANAGERS_OPTIONS_SUCCESS,
  FETCH_REPLACE_BY_MANAGERS_OPTIONS_FAILED,

  ACTIVATE_USER_ATTEMPT,
  ACTIVATE_USER_SUCCESS,
  ACTIVATE_USER_FAILED,
  ADD_EMPLOYEE_ID_TO_ACTIVATION,
  TOGGLE_ACTIVATION_MODAL,

  // handle adding the activation/onboarding balance to net remaning balance action
  CALCULATE_NEW_NET_REMANING_LEAVE_BALANCE,

  EDIT_ATT_LOG_ATTEMPT,
  EDIT_ATT_LOG_SUCCESS,
  EDIT_ATT_LOG_FAILED,
  SHOW_EDIT_HISTORY_MODAL,
  HIDE_EDIT_HISTORY_MODAL,

  BALANCE_MODAL_ATTEMPT,
  BALANCE_MODAL_SUCCESS,
  BALANCE_MODAL_FAILED,
  SHOW_BALANCE_MODAL,
  HIDE_BALANCE_MODAL,
  UPDATE_OFFICE_LOCATION,
  UPDATE_EMPLOYEE_LOCATION,

  SHOW_OFFICE_LOCATION_MODAL,
  SHOW_EMPLOYEE_LOCATION_MODAL,
  HIDE_OFFICE_LOCATION_MODAL,
  RESET_OFFICE_LOCATION,
  SET_LOCATIONS_SERVER_VALIDATION,

  SHOW_OFFICE_HOLIDAY_MODAL,
  HIDE_OFFICE_HOLIDAY_MODAL,
  RESET_OFFICE_HOLIDAY,
  UPDATE_OFFICE_HOLIDAY,

  UPDATE_OBJECT_MULTI_SELECT,

  UPDATE_OFFICE_ATTEMPT,
  UPDATE_OFFICE_SUCCESS,
  UPDATE_OFFICE_FAILED,

  RESET_OFFICE_FORM,

  FETCH_OFFICE_ATTEMPT,
  FETCH_OFFICE_SUCCESS,
  FETCH_OFFICE_FAILED,

  // handle of set country currencies action
  SET_SELECTED_CONTURY_CURRENIESES,

  DELETE_OFFICE_ATTEMPT,
  DELETE_OFFICE_SUCCESS,
  DELETE_OFFICE_FAILED,

  SET_BALANCE_WEEK_RANG,
  UPDATE_ATTENDANCE_PROFILE_HOLIDAY,

  UPSERT_ATTENDANCE_PROFILE_ATTEMPT,
  UPSERT_ATTENDANCE_PROFILE_SUCCESS,
  UPSERT_ATTENDANCE_PROFILE_FAILED,

  UPDATE_ATTENDANCE_PROFILE_HOLIDAYS,

  FETCH_ALL_OFFICE_HOLIDAYS_ATTEMPT,
  FETCH_ALL_OFFICE_HOLIDAYS_SUCCESS,
  FETCH_ALL_OFFICE_HOLIDAYS_FAILED,

  DELETE_ATTENDANCE_PROFILE_ATTEMPT,
  DELETE_ATTENDANCE_PROFILE_SUCCESS,
  DELETE_ATTENDANCE_PROFILE_FAILED,

  CLONE_ATTENDANCE_PROFILE_ATTEMPT,
  CLONE_ATTENDANCE_PROFILE_SUCCESS,
  CLONE_ATTENDANCE_PROFILE_FAILED,

  FORGOT_PASSWORD_ATTEMPT,
  FORGOT_PASSWORD_FINISH,

  RESET_PASSWORD_ATTEMPT,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,

  FETCH_DASHBOARD_DEPARTMNETS,

  // handle fetch employee name on hover action
  FETCH_EMPLOYEE_NAME_ON_CLICK_ATTEMPT,
  FETCH_EMPLOYEE_NAME_ON_CLICK_SUCCESS,
  FETCH_EMPLOYEE_NAME_ON_CLICK_FAILED,

  // handle show/hide Employees Name modal actions
  SHOW_ATT_CHART_EMPLOYEES_NAME_MODAL,
  HIDE_ATT_CHART_EMPLOYEES_NAME_MODAL,

  UPDATE_OVERTIME_INPUT,

  // handle fetch total employee requests and claims
  FETCH_TOTAL_EMPLOYEE_REQUESTS,

  // handle fetch total employee requests
  FETCH_TOTAL_PENDING_REQUESTS,

  // handle fetch total employee claims
  FETCH_TOTAL_PENDING_CLAIMS,

  FETCH_ALL_DEPARTMENTS_BY_COMPANY,

  // Bonus form
  SHOW_BONUS_MODAL,
  HIDE_BONUS_MODAL,

  // Deductions form
  SHOW_DEDUCTIONS_MODAL,
  HIDE_DEDUCTIONS_MODAL,

  // claim request form
  SHOW_CLAIM_REQUEST_MODAL,
  HIDE_CLAIM_REQUEST_MODAL,

  // Accept claim request form
  SHOW_ACCEPT_CLAIM_REQUEST_MODAL,
  HIDE_ACCEPT_CLAIM_REQUEST_MODAL,

  // fetch users claims
  FETCH_USERS_CLAIMS_ATTEMPT,
  FETCH_USERS_CLAIMS_SUCCESS,
  FETCH_USERS_CLAIMS_FAILED,

  // fetch my profile claims

  // fetch users bonus
  FETCH_USERS_BONUS_ATTEMPT,
  FETCH_USERS_BONUS_SUCCESS,
  FETCH_USERS_BONUS_FAILED,

  // upsert bonus
  UPSERT_BONUS_ATTEMPT,
  UPSERT_BONUS_SUCCESS,
  UPSERT_BONUS_FAILED,

  // Edit Bonus
  EDIT_BONUS_ATTEMPT,

  // Delete Bonus
  DELETE_BONUS_ATTEMPT,
  DELETE_BONUS_SUCCESS,
  DELETE_BONUS_FAILED,

  // fetch users deductions
  FETCH_USERS_DEDUCTIONS_ATTEMPT,
  FETCH_USERS_DEDUCTIONS_SUCCESS,
  FETCH_USERS_DEDUCTIONS_FAILED,

  // upsert deductions
  UPSERT_DEDUCTION_ATTEMPT,
  UPSERT_DEDUCTION_SUCCESS,
  UPSERT_DEDUCTION_FAILED,

  // Edit deductions
  EDIT_DEDUCTIONS_ATTEMPT,

  // Delete Deduction
  DELETE_DEDUCTION_ATTEMPT,
  DELETE_DEDUCTION_SUCCESS,
  DELETE_DEDUCTION_FAILED,

  FETCH_EXTRA_USERS_FILTER,

  // Edit amount payroll list modal
  SHOW_EDIT_AMOUNT_MODAL,
  HIDE_EDIT_AMOUNT_MODAL,
  FETCH_DEDUCTION_AMOUNT_ATTEMPT,
  FETCH_DEDUCTION_AMOUNT_SUCCESS,

  // manage privilage modal
  EDIT_EMPLOYEE_PRIVILEGES_ATTEMPT,
  EDIT_EMPLOYEE_PRIVILEGES_FAILED,

  UPSERT_EMPLOYEE_PRIVILEGES_ATTEMPT,
  UPSERT_EMPLOYEE_PRIVILEGES_SUCCESS,
  UPSERT_EMPLOYEE_PRIVILEGES_FAILED,

  SHOW_MANAGE_PRIVILAGES_MODAL,
  HIDE_MANAGE_PRIVILAGES_MODAL,

  UPDATE_PRIVILEGES_RADIO,

  // fetch payroll report list
  FETCH_PAYROLL_REPORT_LIST_ATTEMPT,
  FETCH_PAYROLL_REPORT_LIST_SUCCESS,
  FETCH_PAYROLL_REPORT_LIST_FAILED,
  STOP_REPORT_LIST_LOADER,

  // fetch monthly report list
  FETCH_MONTHLY_REPORT_LIST_ATTEMPT,
  FETCH_MONTHLY_REPORT_LIST_SUCCESS,
  FETCH_MONTHLY_REPORT_LIST_FAILED,

  // fetch yearly report list
  FETCH_YEARLY_REPORT_LIST_ATTEMPT,
  FETCH_YEARLY_REPORT_LIST_SUCCESS,
  FETCH_YEARLY_REPORT_LIST_FAILED,

  // fetch payroll report list employees filter
  FETCH_PAYROLL_REPORT_EMPLOYEES_FILTER,

  // fetch payroll report list regulation filter
  FETCH_PAYROLL_REGULATION_OPTIONS_FILTER,

  // fetch payroll report list currencies filter
  FETCH_PAYROLL_REPORT_CURRENCIES_FILTER,

  // fetch payroll report list offices filter
  FETCH_PAYROLL_REPORT_OFFICES_FILTER,

  // UPSERT USER LOCALE LANG
  UPSERT_USER_LOCALE_ATTEMPT,
  UPSERT_USER_LOCALE_SUCCESS,
  UPSERT_USER_LOCALE_FAILED,
  // upsert claims request
  UPSERT_CLAIM_REQUEST_ATTEMPT,
  UPSERT_CLAIM_REQUEST_SUCCESS,
  UPSERT_CLAIM_REQUEST_FAILED,

  // upsert Employee claims request
  UPSERT_EMPLOYEE_CLAIM_REQUEST_ATTEMPT,
  UPSERT_EMPLOYEE_CLAIM_REQUEST_SUCCESS,
  UPSERT_EMPLOYEE_CLAIM_REQUEST_FAILED,

  // edit claim request
  EDIT_EMPLOYEE_CLAIM_REQUEST_ATTEMPT,

  // reject claim request action
  REJECT_CLAIM_REQUEST_ATTEMPT,

  // accept claim request action
  ACCEPT_CLAIM_REQUEST_ATTEMPT,

  // claim request action
  CLAIM_ACTION_REQUEST_SUCCESS,
  CLAIM_ACTION_REQUEST_FAILED,

  // cancel claim request action
  CANCEL_CLAIM_REQUEST_ATTEMPT,
  CANCEL_CLAIM_REQUEST_SUCCESS,
  CANCEL_CLAIM_REQUEST_FAILED,
  FETCH_PAYROLL_EXTRA_FIELDS_SUCCESS,
  FETCH_PAYROLL_CLOSED_MONTHS_SUCCESS,

  EDIT_PAYROLL_DEDUCTIONS_ATTEMPT,
  EDIT_PAYROLL_DEDUCTIONS_SUCCESS,
  EDIT_PAYROLL_DEDUCTIONS_FAILED,
  SUBMIT_PAYROLL_PAYMENT_ATTEMPT,
  SUBMIT_PAYROLL_PAYMENT_SUCCESS,
  SUBMIT_PAYROLL_PAYMENT_FAILED,

  REVERSE_PAYROLL_PAYMENT_ATTEMPT,
  REVERSE_PAYROLL_PAYMENT_SUCCESS,
  REVERSE_PAYROLL_PAYMENT_FAILED,

  FETCH_MY_PAYROLL_ATTEMPT,
  FETCH_MY_PAYROLL_SUCCESS,
  // SALARY CONFIG DETAILS

  CREATE_NEW_EMPLOYEE_SALARY_ATTEMPT,
  CREATE_NEW_EMPLOYEE_SALARY_SUCCESS,
  CREATE_NEW_EMPLOYEE_SALARY_FAILED,

  ON_CHILD_INPUT_CHANGE,
  ON_CHILD_CHECKBOX_CHANGE,
  ON_CHILD_INPUT_CHANGE_DOCUMENTS,
  ON_MULTI_INPUT_INDEX_CHANGE,
  RESET_SALARY_CONFIG_FORM,

  CREATE_ALLOWANCE_ATTEMPT,
  CREATE_ALLOWANCE_SUCCESS,
  CREATE_ALLOWANCE_FAILED,

  CREATE_DEDUCTIBLE_ATTEMPT,
  CREATE_DEDUCTIBLE_SUCCESS,
  CREATE_DEDUCTIBLE_FAILED,

  ADD_ALLOWANCE_INPUT,
  REMOVE_ALLOWANCE_INPUT,
  ADD_DEDUCTIBLE_INPUT,
  REMOVE_DEDUCTIBLE_INPUT,

  INIT_UPSERT_SALARY_CONFIG_FORM,
  INIT_UPSERT_SALARY_CONFIG_FORM_FAILED,

  SHOW_NEW_UPSERT_SALARY_CONFIG_FORM_SUCCESS,
  SHOW_EDIT_UPSERT_SALARY_CONFIG_FORM_SUCCESS,

  INIT_ACTIVATE_SALARY_CONFIG_SUCCESS,
  INIT_ACTIVATE_SALARY_CONFIG_FAILED,

  // handle fetch user payslip actions
  FETCH_USER_PAYSLIP_ATTEMPT,
  FETCH_USER_PAYSLIP_SUCCESS,
  FETCH_USER_PAYSLIP_FAILED,

  // Add Documents Modal
  SHOW_DOCUMENT_MODAL,
  HIDE_DOCUMENT_MODAL,

  // Edit Documents Modal
  SHOW_EDIT_DOCUMENT_MODAL,
  HIDE_EDIT_DOCUMENT_MODAL,

  // handle fetch holiday offices
  FETCH_HOLIDAY_OFFICES,

  // handle fetch holiday Attendance Profiles
  FETCH_HOLIDAY_ATT_PROFILE,

  INIT_OFFICE_ATTEMPT,
  INIT_OFFICE_SUCCESS,
  INIT_OFFICE_FAILED,
  RESET_OFFICE_PASSWORD,

  // handle accept overtime modal actions
  SHOW_OVERTIME_MODAL,
  HIDE_OVERTIME_MODAL,

  // handel fetch edit accepted overtime request data action
  FETCH_EDIT_ACCEPTED_OVERTIME_REQUEST_DATA_ATTEMPT,
  FETCH_EDIT_ACCEPTED_OVERTIME_REQUEST_DATA_SUCCESS,
  FETCH_EDIT_ACCEPTED_OVERTIME_REQUEST_DATA_FAILED,

  FETCH_EXPORT_PAYROLL_OPTIONS_ATTEMPT,
  FETCH_EXPORT_PAYROLL_OPTIONS_SUCCESS,
  FETCH_EXPORT_PAYROLL_OPTIONS_FAILED,
  DISMISS_EXPORT_PAYROLL_MODAL_ACTION,

  SUBMIT_EXPORT_PAYROLL_ATTEMPT,
  SUBMIT_EXPORT_PAYROLL_SUCCESS,
  SUBMIT_EXPORT_PAYROLL_FAILED,
  EXPORT_PAYSLIP_ACTION,
  EXPORT_PAYSLIP_SUCCESS,
  EXPORT_PAYSLIP_FAILED,

  VERIFY_USER_PHONE_ATTEMPT,
  VERIFY_USER_PHONE_SUCCESS,
  VERIFY_USER_PHONE_FAILED,

  SEND_PHONE_VERIFICATION_ATTEMPT,
  SEND_PHONE_VERIFICATION_SUCCESS,
  SEND_PHONE_VERIFICATION_FAILED,

  // handle email verfication actions
  VERIFY_USER_EMAIL_ATTEMPT,
  VERIFY_USER_EMAIL_SUCCESS,
  VERIFY_USER_EMAIL_FAILED,

  SEND_EMAIL_VERIFICATION_ATTEMPT,
  SEND_EMAIL_VERIFICATION_SUCCESS,
  SEND_EMAIL_VERIFICATION_FAILED,

  CHANGE_EMPLOYEE_DATA,
  PUSHER_EMPLOYEE_BREAK_IN_OUT,

  TOGGLE_MONTHLY_REPORT_CHECKBOX,
  TOGGLE_YEARLY_REPORT_CHECKBOX,

  // handle fetch all companies actions
  FETCH_ALL_COMPANIES_ATTEMPT,
  FETCH_ALL_COMPANIES_SUCCESS,
  FETCH_ALL_COMPANIES_FAILED,

  // Delete companies
  DELETE_COMPANIES_ATTEMPT,
  DELETE_COMPANIES_SUCCESS,
  DELETE_COMPANIES_FAILED,

  // acitivate companies action
  ACTIVATE_COMPANIES_ATTEMPT,
  ACTIVATE_COMPANIES_SUCCESS,
  ACTIVATE_COMPANIES_FAILED,

  // acitivate companies action
  SUSPEND_COMPANIES_ATTEMPT,
  SUSPEND_COMPANIES_SUCCESS,
  SUSPEND_COMPANIES_FAILED,

  // upsert company actions
  SHOW_COMPANY_MODAL,
  HIDE_COMPANY_MODAL,
  UPSERT_COMPANY_ATTEMPT,
  UPSERT_COMPANY_SUCCESS,
  UPSERT_COMPANY_FAILED,
  ADD_BUSSINESS_PARTNER_INPUT,
  REMOVE_BUSSINESS_PARTNER_INPUT,

  // handle fetch company account managers
  FETCH_COMPANY_ACCOUNT_MANAGERS,

  // handle fetch company subscription plans
  FETCH_SUBSCRIPTION_PLANS,

  // handle fetch company currency
  FETCH_COMPANY_CURRENCY,

  // Handle Fetch company Profile
  FETCH_COMPANY_PROFILE_ATTEMPT,
  FETCH_COMPANY_PROFILE_SUCCESS,
  FETCH_COMPANY_PROFILE_FAILED,
  RESET_COMPANY_PRFOILE_VIEW,

  // update company name action
  SHOW_EDIT_COMPANY_NAME_MODAL,
  HIDE_EDIT_COMPANY_NAME_MODAL,
  UPDATE_COMPANY_NAME_MODAL_ATTEMPT,
  UPDATE_COMPANY_NAME_MODAL_SUCCESS,
  UPDATE_COMPANY_NAME_MODAL_FAILED,

  // update company account managers action
  SHOW_EDIT_ACCOUNT_MANAGERS_MODAL,
  HIDE_EDIT_ACCOUNT_MANAGERS_MODAL,
  UPDATE_ACCOUNT_MANAGERS_MODAL_ATTEMPT,
  UPDATE_ACCOUNT_MANAGERS_MODAL_SUCCESS,
  UPDATE_ACCOUNT_MANAGERS_MODAL_FAILED,

  // update company bussiness partners action
  SHOW_EDIT_BUSSINESS_PARTNERS_MODAL,
  HIDE_EDIT_BUSSINESS_PARTNERS_MODAL,
  UPDATE_BUSSINESS_PARTNERS_MODAL_ATTEMPT,
  UPDATE_BUSSINESS_PARTNERS_MODAL_SUCCESS,
  UPDATE_BUSSINESS_PARTNERS_MODAL_FAILED,
  ADD_BUSSINESS_PARTNER_INPUT_IN_PROFILE,
  REMOVE_BUSSINESS_PARTNER_INPUT_IN_PROFILE,
  DELETE_COMPANY_BUSSINESS_PARTNERS_ATTEMPT,
  DELETE_COMPANY_BUSSINESS_PARTNERS_SUCCESS,
  DELETE_COMPANY_BUSSINESS_PARTNERS_FAILED,

  // upsert company subscription actions
  SHOW_COMPANY_SUBSCRIPTION_MODAL,
  HIDE_COMPANY_SUBSCRIPTION_MODAL,
  UPSERT_COMPANY_SUBSCRIPTION_ATTEMPT,
  UPSERT_COMPANY_SUBSCRIPTION_SUCCESS,
  UPSERT_COMPANY_SUBSCRIPTION_FAILED,

  // update employee limit value
  UPDATE_EMPLOYEE_LIMIT_VALUE,

  // Update company action
  UPDATE_COMPANY_ATTEMPT,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_FAILED,
  // Fetch Admin users actions
  FETCH_ADMIN_USERS_ATTEMPT,
  FETCH_ADMIN_USERS_SUCCESS,
  FETCH_ADMIN_USERS_FAILED,

  // Show/Hide Admin users modal actions
  SHOW_ADMIN_USER_MODAL,
  HIDE_ADMIN_USER_MODAL,

  // Add Admin users actions
  CREATE_ADMIN_USER_ATTEMPT,
  CREATE_ADMIN_USER_SUCCESS,
  CREATE_ADMIN_USER_FAILED,

  // Delete Admin users actions
  DELETE_ADMIN_USER_ATTEMPT,
  DELETE_ADMIN_USER_SUCCESS,
  DELETE_ADMIN_USER_FAILED,

  // handle fetch admin user companies actions
  FETCH_ADMIN_USER_COMPANIES_ATTEMPT,
  FETCH_ADMIN_USER_COMPANIES_SUCCESS,
  FETCH_ADMIN_USER_COMPANIES_FAILED,

  // handle fetch admin user employees actions
  FETCH_ADMIN_USER_EMPLOYEES_ATTEMPT,
  FETCH_ADMIN_USER_EMPLOYEES_SUCCESS,
  FETCH_ADMIN_USER_EMPLOYEES_FAILED,

  // handle admin user privilages actions
  EDIT_ADMIN_USER_PRIVILEGES_ATTEMPT,
  EDIT_ADMIN_USER_PRIVILEGES_FAILED,
  UPSERT_ADMIN_USER_PRIVILEGES_ATTEMPT,
  UPSERT_ADMIN_USER_PRIVILEGES_FAILED,

  // Handle admin user profile actions
  SHOW_ADMIN_USER_PROFILE_MODAL,
  HIDE_ADMIN_USER_PROFILE_MODAL,
  UPDATE_ADMIN_USER_PROFILE_ATTEMPT,
  UPDATE_ADMIN_USER_PROFILE_SUCCESS,
  UPDATE_ADMIN_USER_PROFILE_FAILED,

  SHOW_EDIT_ATT_REQUEST_MODAL,
  HIDE_EDIT_ATT_REQUEST_MODAL,
  EDIT_ATT_REQUEST_MODAL_ATTEMPT,
  EDIT_ATT_REQUEST_MODAL_SUCCESS,
  EDIT_ATT_REQUEST_MODAL_FAILED,
  VIEW_ATT_REQUEST_MODAL_ATTEMPT,
  VIEW_ATT_REQUEST_MODAL_SUCCESS,
  VIEW_ATT_REQUEST_MODAL_FAILED,

  SHOW_VIEW_ATT_REQUEST_MODAL,
  HIDE_VIEW_ATT_REQUEST_MODAL,
  ADD_VIOLATION_INPUT,
  REMOVE_VIOLATION_INPUT,

  FETCH_WORK_TIMINGS_LIST_ATTEMPT,
  FETCH_WORK_TIMINGS_LIST_SUCCESS,
  FETCH_WORK_TIMINGS_LIST_FAILED,

  UPSERT_WORK_TIMINGS_ATTEMPT,
  UPSERT_WORK_TIMINGS_SUCCESS,
  UPSERT_WORK_TIMINGS_FAILED,

  SHOW_WORK_TIMINGS_MODAL,
  DISMISS_WORK_TIMINGS_MODAL,

  EXPORT_MONTHLY_REPORT_ATTEMPT,
  EXPORT_MONTHLY_REPORT_SUCCESS,
  EXPORT_MONTHLY_REPORT_FAILED,

  EXPORT_YEARLY_REPORT_ATTEMPT,
  EXPORT_YEARLY_REPORT_SUCCESS,
  EXPORT_YEARLY_REPORT_FAILED,

  UPSERT_SICK_REQUEST_ATTEMPT,
  UPSERT_SICK_REQUEST_SUCCESS,

  SHOW_EXPORT_MONTHLY_REPORT_MODAL,
  HIDE_EXPORT_MONTHLY_REPORT_MODAL,

  SHOW_EXPORT_YEARLY_REPORT_MODAL,
  HIDE_EXPORT_YEARLY_REPORT_MODAL,

  // activation modal attendances
  FETCH_ACTIVATION_ATTENDANCE_ATTEMPT,
  FETCH_ACTIVATION_ATTENDANCE_SUCCESS,
  FETCH_ACTIVATION_ATTENDANCE_FAILED,

  RESET_ACTICATION_ATTENDANCE_FORM,

  UPSERT_DOCUMENT_ATTEMPT,
  UPSERT_DOCUMENT_SUCCESS,
  UPSERT_DOCUMENT_FAILED,
  SHOW_COMPANY_DOCUMENT_MODAL,
  HIDE_COMPANY_DOCUMENT_MODAL,

  STORE_DOCUMENTABLE_ATTEMPT,
  STORE_DOCUMENTABLE_SUCCESS,
  STORE_DOCUMENTABLE_FAILED,
  STORE_DOCUMENTABLE_ROW_ATTEMPT,
  ON_CHILD_INPUT_CHANGE_DOCUMENTS_MULTIPLE,

  FETCH_DOCUMENTS_LIST_ATTEMPT,
  FETCH_DOCUMENTS_LIST_SUCCESS,
  FETCH_DOCUMENTS_LIST_FAILED,

  SHOW_DOCUMENTS_EDIT,
  HIDE_DOCUMENTS_EDIT,
  ON_INPUT_RESET_WITH_VALUE,
  ON_RESET_DOCUMENTS_ROW,

  // leaves and breaks
  UPDATE_LEAVES_BREAKS_OPTIONS,
  SHOW_LEAVES_BREAKS_MODAL,
  SET_LEAVES_BREAKS_SERVER_VALIDATION,
  RESET_LEAVES_BREAKS_FORM,

  // handle fetch email notfifcations settings
  FETCH_EMAIL_NOTFIFCATIONS_ATTEMPT,
  FETCH_EMAIL_NOTFIFCATIONS_SUCCESS,
  FETCH_EMAIL_NOTFIFCATIONS_FAILED,

  // handle update email notfifcations settings
  UPDATE_EMAIL_NOTIFICATIONS_ATTEMPT,
  UPDATE_EMAIL_NOTIFICATIONS_SUCCESS,
  UPDATE_EMAIL_NOTIFICATIONS_FAILED,
  EDIT_FETCH_WORK_TIMINGS_ATTEMPT,
  EDIT_FETCH_WORK_TIMINGS_SUCCESS,
  EDIT_FETCH_WORK_TIMINGS_FAILED,

  FETCH_ALL_WORK_TIME_OPTIONS,
  ADD_NEW_WORK_TIME_CONFIG,

  VIEW_WORK_TIME_ATTEMPT,
  VIEW_WORK_TIME_SUCCESS,
  VIEW_WORK_TIME_FAILED,
  DISMISS_VIEW_WORK_TIME,

  CLONE_WORK_TIME_ATTEMPT,
  CLONE_WORK_TIME_SUCCESS,
  CLONE_WORK_TIME_FAILED,

  DELETE_WORK_TIME_ATTEMPT,
  DELETE_WORK_TIME_SUCCESS,
  DELETE_WORK_TIME_FAILED,

  RESET_OLD_SIGN_IN_CONFIG_ACTION,
  REMOVE_SIGN_IN_CONFIG_ACTION,

  // handle fetch petty cash list
  FETCH_PETTY_CASH_LIST_ATTEMPT,
  FETCH_PETTY_CASH_LIST_SUCCESS,
  FETCH_PETTY_CASH_LIST_FAILED,

  // handle fetch employees for petty cash
  FETCH_PETTY_CASH_EMPLOYEES,

  // handle fetch avaliable employees for petty cash filter
  FETCH_AVALIABLE_PETTY_CASH_EMPLOYEES_FILTER_OPTIONS,

  // handle modify/edit petty cash balance modal actions
  SHOW_PETTY_CASH_BALANCE_MODAL,
  HIDE_PETTY_CASH_BALANCE_MODAL,
  UPSERT_PETTY_CASH_BALANCE_ATTEMPT,
  UPSERT_PETTY_CASH_BALANCE_SUCCESS,
  UPSERT_PETTY_CASH_BALANCE_FAILED,

  // to fetch employee current balance
  FETCH_EMPLOYEE_CURRENT_BALANCE_ATTEMPT,
  FETCH_EMPLOYEE_CURRENT_BALANCE_SUCCESS,
  FETCH_EMPLOYEE_CURRENT_BALANCE_FAILED,

  // handle fetch company settings list
  FETCH_COMPANY_SETTINGS_LIST_ATTEMPT,
  FETCH_COMPANY_SETTINGS_LIST_SUCCESS,
  FETCH_COMPANY_SETTINGS_LIST_FAILED,

  // handle company settings modal actions
  SHOW_COMPANY_SETTINGS_MODAL,
  HIDE_COMPANY_SETTINGS_MODAL,
  UPSERT_COMPANY_SETTINGS_ATTEMPT,
  UPSERT_COMPANY_SETTINGS_SUCCESS,
  UPSERT_COMPANY_SETTINGS_FAILED,

  // Delete company settings actions
  DELETE_COMPANY_SETTINGS_ATTEMPT,
  DELETE_COMPANY_SETTINGS_SUCCESS,
  DELETE_COMPANY_SETTINGS_FAILED,

  // handle fetch company expenses list
  FETCH_COMPANY_EXPENSE_ATTEMPT,
  FETCH_COMPANY_EXPENSE_SUCCESS,
  FETCH_COMPANY_EXPENSE_FAILED,

  // handle company expenses modal actions
  SHOW_COMPANY_EXPENSE_MODAL,
  HIDE_COMPANY_EXPENSE_MODAL,
  UPSERT_COMPANY_EXPENSE_ATTEMPT,
  UPSERT_COMPANY_EXPENSE_SUCCESS,
  UPSERT_COMPANY_EXPENSE_FAILED,

  // handle fetch company expenses categories actions
  FETCH_COMPANY_EXPENSES_CATEGORIES,

  // handle fetch company expenses categories actions
  FETCH_COMPANY_EXPENSES_SUBCATEGORIES_ATTEMPT,
  FETCH_COMPANY_EXPENSES_SUBCATEGORIES_SUCCESS,
  FETCH_COMPANY_EXPENSES_SUBCATEGORIES_FAILED,

  // handle fetch expense total balance actions
  FETCH_EXPENSE_TOTAL_BALANCE_ATTEMPT,
  FETCH_EXPENSE_TOTAL_BALANCE_SUCCESS,
  FETCH_EXPENSE_TOTAL_BALANCE_FAILED,

  // handle export company expenses actions
  EXPORT_COMPANY_EXPENSES_ATTEMPT,
  EXPORT_COMPANY_EXPENSES_SUCCESS,
  EXPORT_COMPANY_EXPENSES_FAILED,

  // handle sub catgories options in expense form actions
  FETCH_EXPENSE_FORM_SUBCATGERORIES_ATTEMPT,
  FETCH_EXPENSE_FORM_SUBCATGERORIES_SUCCESS,
  FETCH_EXPENSE_FORM_SUBCATGERORIES_FAILED,

  // handle currencies option in expense form actions
  FETCH_EXPENSE_FORM_CUREENCIES_ATTEMPT,
  FETCH_EXPENSE_FORM_CUREENCIES_SUCCESS,
  FETCH_EXPENSE_FORM_CUREENCIES_FAILED,

  // handle employee options in expense form actions
  FETCH_EXPENSE_FORM_EMPLOYEES_ATTEMPT,
  FETCH_EXPENSE_FORM_EMPLOYEES_SUCCESS,
  FETCH_EXPENSE_FORM_EMPLOYEES_FAILED,

  // handle delete company expense actions
  DELETE_COMPANY_EXPENSE_ATTEMPT,
  DELETE_COMPANY_EXPENSE_SUCCESS,
  DELETE_COMPANY_EXPENSE_FAILED,

  // handle delete company expense attachment file actions
  DELETE_COMPANY_EXPENSE_ATTACHMENT_FILE_ATTEMPT,
  DELETE_COMPANY_EXPENSE_ATTACHMENT_FILE_SUCCESS,
  DELETE_COMPANY_EXPENSE_ATTACHMENT_FILE_FAILED,

  // handle fetch user petty cash list
  FETCH_USER_PETTY_CASH_ATTEMPT,
  FETCH_USER_PETTY_CASH_SUCCESS,
  FETCH_USER_PETTY_CASH_FAILED,

  // to fetch user profile current balance actions
  FETCH_USER_PROFILE_CURRENT_BALANCE_ATTEMPT,
  FETCH_USER_PROFILE_CURRENT_BALANCE_SUCCESS,
  FETCH_USER_PROFILE_CURRENT_BALANCE_FAILED,

  // to fetch petty cash currencies actions
  FETCH_PETTY_CASH_CURRENCIES_ATTEMPT,
  FETCH_PETTY_CASH_CURRENCIES_SUCCESS,
  FETCH_PETTY_CASH_CURRENCIES_FAILED,
  INIT_SUSPENSION_USER_ATTEMPT,
  INIT_SUSPENSION_USER_SUCCESS,
  INIT_SUSPENSION_USER_FAILED,
  HANDLE_ROW_DOCUMENT,

  UPDATE_COMPANY_ROW_DOCS,
  // handle resend credentails actions
  RESEND_CREDENTAILS_ATTEMPT,
  RESEND_CREDENTAILS_SUCCESS,
  RESEND_CREDENTAILS_FAILED,
  // handle check password
  CHECK_PASSWORD_ATTEMPT,
  CHECK_PASSWORD_SUCCESS,
  CHECK_PASSWORD_FAILED,
  // handle fetching all managers
  FETCH_ALL_MANAGERS_ACTION,

  // Fetch employee documents
  FETCH_EMPLOYEE_DOCUMENTS_ATTEMPT,
  FETCH_EMPLOYEE_DOCUMENTS_SUCCESS,
  FETCH_EMPLOYEE_DOCUMENTS_FAILED,

  ADD_USER_DOCUMENT_ATTEMPT,
  ADD_USER_DOCUMENT_SUCCESS,
  ADD_USER_DOCUMENT_FAILED,

  FETCH_UPSERT_DOC_MODAL_ATTEMPT,
  FETCH_UPSERT_DOC_MODAL_SUCCESS,
  FETCH_UPSERT_DOC_MODAL_FAILED,

  DELETE_USER_DOCUMENT_ATTACHMENT_ATTEMPT,
  DELETE_USER_DOCUMENT_ATTACHMENT_SUCCESS,
  DELETE_USER_DOCUMENT_ATTACHMENT_FAILED,

  FETCH_REQUIRED_DOCUMENTS_SUCCESS,
  FETCH_REQUIRED_DOCUMENTS_ATTEMPT,
  FETCH_REQUIRED_DOCUMENTS_FAILED,

  SHOW_MAIN_MODAL_WITH_DATA,
  HIDE_MAIN_MODAL_WITH_DATA,

  FETCH_HISTORY_DOC_MODAL_ATTEMPT,
  FETCH_HISTORY_DOC_MODAL_SUCCESS,
  FETCH_HISTORY_DOC_MODAL_FAILED,
  // edit company bussiness partner action
  SHOW_UPDATE_BUSSINESS_PARTNER_MODAL,
  HIDE_UPDATE_BUSSINESS_PARTNER_MODAL,
  EDIT_BUSSINESS_PARTNER_MODAL_ATTEMPT,
  EDIT_BUSSINESS_PARTNER_MODAL_SUCCESS,
  EDIT_BUSSINESS_PARTNER_MODAL_FAILED,

  // handle data view change for employees data
  CHANGE_TO_LIST_DATA_VIEW,
  CHANGE_TO_GRID_DATA_VIEW,

  // handle export employee list modal actions
  SHOW_EXPORT_EMPLOYEE_LIST_MODAL,
  HIDE_EXPORT_EMPLOYEE_LIST_MODAL,
  EXPORT_EMPLOYEE_LIST_ATTEMPT,
  EXPORT_EMPLOYEE_LIST_SUCCESS,
  EXPORT_EMPLOYEE_LIST_FAILED,

  // handle fetch employee currencies in modify balance actions
  FETCH_EMPLOYEE_OFFICE_CURRENCY_PETTY_CASH_ATTEMPT,
  FETCH_EMPLOYEE_OFFICE_CURRENCY_PETTY_CASH_SUCCESS,
  FETCH_EMPLOYEE_OFFICE_CURRENCY_PETTY_CASH_FAILED,

  // handle delete petty cash attachment file actions
  DELETE_PETTY_CASH_ATTACHMENT_FILE_ATTEMPT,
  DELETE_PETTY_CASH_ATTACHMENT_FILE_SUCCESS,
  DELETE_PETTY_CASH_ATTACHMENT_FILE_FAILED,
  FETCH_SETUP_WIZARD_DATA_IN_AUTH,
  // handle open payroll month
  FETCH_OPEN_PAYROLL_MONTHS_ATTEMPT,
  FETCH_OPEN_PAYROLL_MONTHS_SUCCESS,
  FETCH_OPEN_PAYROLL_MONTHS_FAILED,
  ADD_COMPANY_CURRENCIES,
  SET_OFFICE_FLAG,
  EDIT_OPEN_PAYROLL_MONTHS_OFFICES_ACCORDING_TO_SCOPE,
  // handle import employees in bulk

  UPDATE_TOTAL_lIST_COUNT,
  FETCH_PLAN_ROLES_OPTS,
  FETCH_ROLE_PRESET_PRIVILEGS_ATTEMPT,
  FETCH_ROLE_PRESET_PRIVILEGS_SUCCESS,
  FETCH_ROLE_PRESET_PRIVILEGS_FAILED,

  // handle of fetch roles privilages preview modal actions
  SHOW_PREVIEW_ROLE_PRIVILEGES_MODAL,
  HIDE_PREVIEW_ROLE_PRIVILEGES_MODAL,
  FETCH_PREVIEW_ROLE_PRIVILEGES_ATTEMPT,
  FETCH_PREVIEW_ROLE_PRIVILEGES_SUCCESS,
  FETCH_PREVIEW_ROLE_PRIVILEGES_FAILED,

  SET_IS_EMPLOYEE_IMPORT_MODAL_OPEN,
  DOWNLOAD_EMPLOYEE_TEMPLATE_ATTEMPT,
  DOWNLOAD_EMPLOYEE_TEMPLATE_SUCCESS,
  DOWNLOAD_EMPLOYEE_TEMPLATE_FAILED,
  SET_IS_EMPLOYEE_IMPORT_MODAL_ERRORS_OPEN,
  UPLOAD_BULK_EMPLOYEES_CSV_ATTEMPT,
  UPLOAD_BULK_EMPLOYEES_CSV_SUCCESS,
  UPLOAD_BULK_EMPLOYEES_CSV_FAILED,
  UPLOAD_BULK_EMPLOYEES_CSV_REQUEST_FAILED,

  UPSERT_CUSTOM_REQUEST_ATTEMPT,
  UPSERT_CUSTOM_REQUEST_SUCCESS,
  UPSERT_CUSTOM_REQUEST_FAILED,

  //handle expenses chart
  FETCH_DASHBOARD_CURRENCIES_SUCCESS,
  FETCH_TOTAL_EXPENSES_ATTEMPT,
  FETCH_TOTAL_EXPENSES_SUCCESS,
  FETCH_TOTAL_EXPENSES_FAILED,
  FETCH_SUB_CATEGORY_EXPENSES_ATTEMPT,
  FETCH_SUB_CATEGORY_EXPENSES_SUCCESS,
  FETCH_SUB_CATEGORY_EXPENSES_FAILED,
  SET_IS_THIS_QUERY_TOTAL_EXPENSES,

  // handle employees turnover Chart
  FETCH_EMPLOYEES_TURNOVER_CHART_ATTEMPT,
  FETCH_EMPLOYEES_TURNOVER_CHART_SUCCESS,
  FETCH_EMPLOYEES_TURNOVER_CHART_FAILED,

  // handle Request Comments modal actions
  SHOW_REQUEST_COMMENTS_MODAL,
  HIDE_REQUEST_COMMENTS_MODAL,
  FETCH_REQUEST_COMMENTS_ATTEMPT,
  FETCH_REQUEST_COMMENTS_SUCCESS,
  FETCH_REQUEST_COMMENTS_FAILED,
  UPSERT_REQUEST_COMMENT_ATTEMPT,
  UPSERT_REQUEST_COMMENT_SUCCESS,
  UPSERT_REQUEST_COMMENT_FAILED,

  // handle dashboard ui checkbox action
  HANDLE_DASHBOARD_UI_CHECKBOX_TOGGLE_ACTION,

  ...RequestRulesTypes,
  ...AdminActionTypes,
  ...RecruitmentActionTypes,
  ...companyWizardTypes,
  ...CheckInsActionTypes,

  // handle of penalties action
  FETCH_PENALTIES_LIST_SUCCESS,
  FETCH_PENALTIES_EMPLOYEES_FILTER,

  // handle fetch half work timing template actions
  FETCH_HALF_WORK_TIMING_TEMPLATE_DATA_ATTEMPT,
  FETCH_HALF_WORK_TIMING_TEMPLATE_DATA_SUCCESS,
  FETCH_HALF_WORK_TIMING_TEMPLATE_DATA_FAILED,

  // handle auto suggest holidays actions
  AUTO_SUGGEST_HOLIDAYS_ATTEMPT,
  AUTO_SUGGEST_HOLIDAYS_SUCCESS,
  AUTO_SUGGEST_HOLIDAYS_FAILED,

  // handle auto suggest holidays actions
  ACCEPT_SUGGESTED_HOLIDAY_ATTEMPT,
  ACCEPT_SUGGESTED_HOLIDAY_SUCCESS,
  ACCEPT_SUGGESTED_HOLIDAY_FAILED,

  // handle delete suggested holidays actions
  DELETE_SUGGESTED_HOLIDAYS_ATTEMPT,
  DELETE_SUGGESTED_HOLIDAYS_SUCCESS,
  DELETE_SUGGESTED_HOLIDAYS_FAILED,

  // handle delete salary config actions
  DELETE_SALARY_CONFIG_ATTEMPT,
  DELETE_SALARY_CONFIG_SUCCESS,
  DELETE_SALARY_CONFIG_FAILED,

  // handle upsert assignment actions
  TOGGLE_MODAL_DISPLAY,
  FETCH_ASSIGNMENT_FORM_ATTEMPT,
  FETCH_ASSIGNMENT_FORM_SUCCESS,
  FETCH_ASSIGNMENT_FORM_FAILED,

  // handle upsert assignment actions
  UPSERT_ASSIGNMENT_SUCCESS,
  UPSERT_ASSIGNMENT_FAILED,

  // handle fetch assignments

  FETCH_ASSIGNMENTS_ATTEMPT,
  FETCH_ASSIGNMENTS_SUCCESS,
  FETCH_ASSIGNMENTS_FAILED,

  CHANGE_ASSIGNMENT_DATE_ACTION,

  DELETE_ASSIGNMENT_ATTEMPT,
  DELETE_ASSIGNMENT_SUCCESS,
  DELETE_ASSIGNMENT_FAILED,
  TOGGLE_ASSIGNMENT_PREVIEW_MODAL,

  // Change assignment
  FETCH_CHANGE_ASSIGNMENTS,

  // handle fetch edit attendnace work timing options actions
  FETCH_EDIT_ATTENDNACE_WORK_TIMINGS_OPTIONS,
  FETCH_EDIT_ATTENDNACE_WORK_TIMINGS_OPTIONS_SUCCESS,
  FETCH_EDIT_ATTENDNACE_WORK_TIMINGS_OPTIONS_FAILED,

  // handle fetch edit attendnace locations options action
  FETCH_EDIT_ATTENDNACE_LOCATIONS_OPTIONS,
  FETCH_EDIT_ATTENDNACE_LOCATIONS_OPTIONS_SUCCESS,
  FETCH_EDIT_ATTENDNACE_LOCATIONS_OPTIONS_FAILED,

  // handle update edit attendnace locations actions
  UPDATE_ATTENDNACE_LOG_LOCATIONS_IDS,
  UPDATE_ATTENDNACE_LOG_LOCATIONS_IDS_SUCCESS,
  UPDATE_ATTENDNACE_LOG_LOCATIONS_IDS_FAILED,

  // Attendance Adjust Sign Out Actions
  ADJUST_SIGNOUT_ATTEMPT,
  ADJUST_SIGNOUT_FAILED,
  ADJUST_SIGNOUT_SUCCESS,

  // handle all office options actions
  FETCH_ALL_OFFICE_OPTIONS,
  FETCH_ALL_WORK_TIMINGS_MENU,

  // access levels actions

  FETCH_COMPANY_ACCESS_LEVELS_ATTEMPT,
  FETCH_COMPANY_ACCESS_LEVELS_SUCCESS,
  FETCH_COMPANY_ACCESS_LEVELS_FAILED,
  DISMISS_ADD_NEW_ACCESS_LEVEL_MODAL_ACTION,
  UPSERT_ROLE_TO_COMPANY_ATTEMPT,
  UPSERT_ROLE_TO_COMPANY_SUCCESS,
  UPSERT_ROLE_TO_COMPANY_FAILED,
  EDIT_ROLE_PRIVILEGES_ATTEMPT,
  EDIT_ROLE_PRIVILEGES_SUCCESS,
  EDIT_ROLE_PRIVILEGES_FAILED,
  DELETE_COMPANY_ROLE_ATTEMPT,
  DELETE_COMPANY_ROLE_SUCCESS,
  DELETE_COMPANY_ROLE_FAILED,

  // handle fetch loans action
  FETCH_LOANS_LIST_SUCCESS,

  // handle fetch my loans action
  FETCH_MY_LOANS_LIST_SUCCESS,

  // handle generate installment input action
  GENERATE_INSTALLMENT_INPUT,

  // handle loan modal actions
  SHOW_LOAN_MODAL,
  HIDE_LOAN_MODAL,
  ADD_INSTALLMENTS_INPUT,
  REMOVE_INSTALLMENTS_INPUT,
  SET_lOAN_CURRENCY_DUE_TO_SALARY_CONFIG,
  UPSERT_LOAN_ATTEMPT,
  UPSERT_LOAN_SUCCESS,
  UPSERT_LOAN_FAILED,

  // handle notification drawer
  SHOW_NOTIFICATION_DRAWER,
  HIDE_NOTIFICATION_DRAWER,

  // handle get notification's history
  GET_NOTIFICATION_HISTORY_ATTEMPT,
  GET_NOTIFICATION_HISTORY_SUCCESS,
  GET_NOTIFICATION_HISTORY_FAILED,
  NOTIFICATION_HISTORY_RESET,

  // handle check status for all notification to read
  CHECK_NOTIFICATION_READ_ATTEMPT,
  CHECK_NOTIFICATION_READ_SUCCESS,
  CHECK_NOTIFICATION_READ_FAILED,

  // NOTIFICATIONS
  SET_NOTIFICATION_PAGE_ACTION,

  UPDATE_VALUE,
  SET_SALARY_CONFIG_CALCULATIONS_FIXED,

  ADD_OVERTIME_SETTING,
  REMOVE_OVERTIME_SETTING,

  SHOW_PENALTY_MODAL,
  HIDE_PENALTY_MODAL,

  SET_SALARY_CONFIG_CALCULATIONS_FIXED,
  SET_SHOULD_EXIT_SCHEDULE_PAGE,
  RESET_MODAL_MESSAGE,
  SET_SERVER_VALIDATION,
  RESET_OVERTIME_SETTINGS,
  SET_FORM_SERVER_VALIDATION,
  TOGGLE_SIGN_IN_BREAKS_MODAL,
  TOGGLE_LOGS_MODAL,
  ADJUST_SIGN_IN_OUT_IDS,
  RESET_ASSIGNMENT_MODAL_MESSAGE,
  ACCEPT_DAY_OFF_REQUEST_ATTEMPT,
  ACCEPT_DAY_OFF_REQUEST_COMPLETED,
  ACCEPT_LOAN_REQUEST_ATTEMPT,
  ACCEPT_LOAN_REQUEST_COMPLETED,
  TOGGLE_SET_TEMPORARY_PASSWORD_MODAL,
  DISMISS_UPSERT_SALARY_CONFIG_FORM,

  FETCH_COMPANY_CONTACTS_ATTEMPT,
  FETCH_COMPANY_CONTACTS_SUCCESS,
  FETCH_COMPANY_CONTACTS_FAILED,
};

export default actionTypes;
