import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import EditWorkTimePreview from "../../Components/AttProfileSections/EditWorkTimePreview";
import HistoryEmployeeCard from "../../Components/HistoryEmployeeCard";
import EditAttHistoryModal from "../../Components/HistoryEmployeeCard/EditAttHistoryModal";
import EditAttRequestModal from "../../Components/HistoryEmployeeCard/EditAttRequestModal";
import EditCheckInsModal from "../../Components/HistoryEmployeeCard/EditCheckInsModal";
import ViewCheckInsOutsLocationMap from "../../Components/HistoryEmployeeCard/ViewCheckInsOutsLocationMap";
import AttRequestInfoModal from "../InboundRequests/AttRequestInfoModal";
import Loader from "../../Components/Loader";
import SignInBreaksModal from "../SignInBreaksModal";

const HistoryList = (props) => {
  const { t } = useTranslation();
  const {
    reducer = "user",
    listRef = "historyList",
    isMyProfileHistory = false,
  } = props;

  const signInBreaksModalIsOpen = useSelector((state) => state.super?.signInBreaksModal?.isOpen);
  const historyLogsData = useSelector((state) => state[reducer][listRef]?.data);
  const isPreviewModalLoading = useSelector(
    (state) => state.super.isViewWorkTimeLoading
  );
  const logsModalDataLoading = useSelector(
    (state) => state.super.logsModalDataLoading
  );

  return (
    <div>
      {!historyLogsData.length ? (
        <div className="w-100 text-center"> {t("no data available")} ... </div>
      ) : (
        <>
          {isPreviewModalLoading || logsModalDataLoading ? (
            <div className="loader_wrapper_style">
              <Loader />
            </div>
          ) : null}

          {historyLogsData?.map((employeeRecord) => (
            <HistoryEmployeeCard
              key={employeeRecord.id}
              employeeRecord={employeeRecord}
              isMyProfileHistory={isMyProfileHistory}
              isEmpProfileHistory={props?.isEmpProfileHistory}
            />
          ))}

          {signInBreaksModalIsOpen ? (
            <SignInBreaksModal refetch={props?.refetchList} />
          ) : null}

          {/* (Start) Edit Att History Modal */}
          <EditAttHistoryModal
            disableWindowReload={true}
            refetch={props?.refetchList}
          />
          <EditWorkTimePreview />
          {/* (End) Edit Att History Modal */}

          {/* (Start) Edit Att Request Modal */}
          <EditAttRequestModal
            isMyProfileHistory={isMyProfileHistory}
            isEmpProfileHistory={props?.isEmpProfileHistory}
          />
          {/* (End) Edit Att Request Modal */}

          {/* Start View Att Request modal */}
          <AttRequestInfoModal
            pageFlag={
              isMyProfileHistory
                ? "my_requests_history"
                : props?.isEmpProfileHistory
                ? "emp_profile_requests_history"
                : "history_logs"
            }
          />
          {/* End View Att Request modal */}

          {/* (Start) Edit Check ins Modal */}
          <EditCheckInsModal />
          {/* (End) Edit Check ins Modal */}

          {/* (Start) Check ins/outs location Modal */}
          <ViewCheckInsOutsLocationMap />
          {/* (End) Check ins/outs location Modal */}
        </>
      )}
    </div>
  );
};

export default HistoryList;