import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Divider from "@mui/material/Divider";

import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import {
  Eventcalendar,
  Datepicker,
  snackbar,
  setOptions,
  Popup,
  Button,
  Input,
  Textarea,
  formatDate,
  localeAr,
  localeEn,
} from "@mobiscroll/react";
// import axios from "axios";
import moment from "moment";
import Switch from "@mui/material/Switch";
import { Link } from "react-router-dom";
import { BSelect, RadioboxForm } from "form-builder";
import PersonOutline from "@mui/icons-material/PersonOutline";
import GroupIcon from "@mui/icons-material/Group";
import RoomIcon from "@mui/icons-material/Room";
import Loader from "../../Components/Loader";
import {
  fetchAssignmentFormAction,
  onFormResetAction,
  onInputResetAction,
} from "../../Store/Actions";
import { useLazyQuery, useQuery } from "@apollo/client";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";

import {
  calendarQuery,
  companyWeekStartDay,
  workCalendarFiltersQuery,
} from "../../Graphql/query";
import { useSelector, useDispatch } from "react-redux";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Groups3OutlinedIcon from "@mui/icons-material/Group";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOn";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { AddButton, ColorButton } from "../../Components/Buttons";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import HelperFns from "../../Helpers/HelperFns";
import UpsertAssignmentModal from "../../Containers/Assignments/UpsertAssignmentModal";
import { addEditDeleteAssignmentPrivileges } from "../../Constants";

setOptions({
  theme: "ios",
  themeVariant: "light",
});

const viewSettings = {
  timeline: {
    type: "day",
    size: 7,
    resolution: "day",
    eventList: true,
    rowHeight: "variable",
  },
};

const responsivePopup = {
  medium: {
    display: "center",
    width: 400,
    fullScreen: false,
    touchUi: false,
    showOverlay: false,
  },
};

const formName = "workCalendarForm";
const formNameValidation = "workCalendarFormValidation";
const formServerValidation = "workCalendarFormServerValidation";
const reducer = "shifts";
const colors = {
  ontime: "rgba(86, 171, 47, 1)",
  late: "#cc2b5e",
  absent: "#bdc3c7",
  "Leave Accepted": "#f8b195",
  "Leave Requested": "#f7db4f",
  "Not Signed In Yet": "#f5f7f9",
};

function Calendar() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [shifts, setShifts] = React.useState(null);
  const [viewType, setViewType] = React.useState("employees");
  const [firstDay, setFirstDay] = React.useState(moment());
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [resources, setResources] = React.useState([]);
  const [tempShift, setTempShift] = React.useState(null);
  const [start, startRef] = React.useState(null);
  const [end, endRef] = React.useState(null);
  const [min, setMinTime] = React.useState("");
  const [max, setMaxTime] = React.useState("");
  const [isOpen, setOpen] = React.useState(false);
  const [isEdit, setEdit] = React.useState(false);
  const [headerText, setHeader] = React.useState("");
  const [shiftDate, setDate] = React.useState([]);
  const [shiftNotes, setNotes] = React.useState("");
  const [windowHeight, setWindowHeight] = React.useState(window.innerHeight);

  const [filtersData, setFiltersData] = useState({});

  const formData = useSelector((state) => state.shifts.workCalendarForm);

  const [draftSchedulesCount, setDraftSchedulesCount] = useState(0);
  const timerRef = React.useRef(null);
  const [tooltipEvent, setTooltipEvent] = useState({});
  const [isEventOpen, setEventOpen] = useState(false);
  const [eventAnchor, setEventAnchor] = useState(null);
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const [getData, { loading: companyDataLoading, called }] = useLazyQuery(
    companyWeekStartDay,
    {
      onCompleted: (data) => {
        let firstDate = moment().day(data?.profile?.company?.week_start_day);
        if (firstDate >= moment()) {
          firstDate = firstDate.subtract(7, "days");
        }
        setFirstDay(firstDate);
        fetchCalendarData();
      },
    }
  );

  let { work_group_ids, work_places_ids, employees_ids, teams_ids } = formData;
  const setWindowDimensions = () => {
    setWindowHeight(window.innerHeight);
  };
  const formProps = {
    formName,
    formNameValidation,
    formServerValidation,
    formSubmitting: isSubmitting,
    reducer,
  };
  let teamPopupRef = useRef();
  useEffect(() => {
    window.addEventListener("resize", setWindowDimensions);
    return () => {
      window.removeEventListener("resize", setWindowDimensions);
    };
  }, []);
  useEffect(() => {
    let teamMenuHandler = (e) => {
      if (
        teamPopupRef.current !== null &&
        teamPopupRef.current !== undefined &&
        !teamPopupRef.current.contains(e.target)
      ) {
        setEventOpen(false);
      }
    };
    document.addEventListener("mousedown", teamMenuHandler);

    return () => {
      document.removeEventListener("mousedown", teamMenuHandler);
    };
  });
  const [fetchFiltersData, { loading: fetchFiltersDataLoading }] = useLazyQuery(
    workCalendarFiltersQuery,
    {
      onCompleted: (data) => {
        setFiltersData(data);
      },
    }
  );

  const [fetchCalendarData, { loading: fetchCalendarDataLoading }] =
    useLazyQuery(calendarQuery, {
      variables: {
        input: {
          start: moment(firstDay).format("YYYY-MM-DD"),
          end: moment(firstDay).add(6, "days").format("YYYY-MM-DD"),
          employees: formData?.employees_ids ?? [],
          work_teams: formData?.teams_ids ?? [],
          work_places: formData?.work_places_ids ?? [],
          work_groups_search: formData?.work_group_ids ?? [],
          type: viewType,
        },
        page_flag: "calendar",
        includeDrafts: HelperFns.checkPrivileges({
          Privileges: [Privilages.VIEW_DRAFTED_SCHEDULES],
          allowBP: true,
        }),
        skipCalendar: false,
      },
      onCompleted: (data) => {
        if (data?.calendar) {
          setShifts(data?.calendar?.results);
          loadResources(data?.calendar?.resources);
        }
        if (data?.draftSchedules) {
          setDraftSchedulesCount(data?.draftSchedules?.data?.length ?? 0);
        }
        setViewType(viewType);
        setIsLoading(false);
      },
    });

  useEffect(() => {
    // axios.get("http://mawared.be/company_data").then((data) => {
    //   setCompany(data.data);
    // });
    fetchFiltersData();
    fetchCalendarData({
      variables: {
        skipCalendar: true,
      },
    });
    return () => {
      dispatch(onFormResetAction(formName));
    };
  }, []);

  useEffect(() => {
    dispatch(onInputResetAction(formName, "employees_ids"));
    dispatch(onInputResetAction(formName, "work_places_ids"));
  }, [viewType]);

  const skipFirstRender = useDidUpdateEffect(() => {
    fetchCalendarData();
  }, [work_group_ids, work_places_ids, employees_ids, teams_ids]);

  const onPageLoading = (args) => {};
  const onEventClick = React.useCallback((args) => {
    const event = args.event;
    if (args.event.day_off == 1) {
      return;
    }
    if (event.request_data != null && event.request_data != "") {
      event.request_name = event.request_data.split("---")[0];
      event.request_status = parseInt(event.request_data.split("---")[1]);
      if (event.request_status == 1) {
        event.request_status = "Accepted";
      } else {
        event.request_status = "Pending";
      }
    }
    // if(event?.sign_in_data?.includes("Tardiness")){
    //     event.sign_in_data = "Late";
    // }
    setTooltipEvent(event);

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    setEventAnchor(args.domEvent.target);
    setEventOpen(true);
  }, []);

  const onEventHoverOut = React.useCallback(() => {
    timerRef.current = setTimeout(() => {
      setEventOpen(false);
    }, 200);
  }, []);

  const onMouseEnterEvent = React.useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  }, []);

  const onMouseLeaveEvent = React.useCallback(() => {
    timerRef.current = setTimeout(() => {
      setOpen(false);
    }, 200);
  }, []);

  const onCellClick = (event, inst) => {
    console.log(event);
    console.log(inst);
  };
  const saveEvent = React.useCallback(() => {
    const start = new Date(shiftDate[0]);
    const end = new Date(shiftDate[1]);
    const newEvent = {
      id: tempShift.id,
      title: formatDate("HH:mm", start) + " - " + formatDate("HH:mm", end),
      notes: shiftNotes,
      start: start,
      end: end,
      resource: tempShift.resource,
      slot: tempShift.slot,
    };
    if (isEdit) {
      // update the event in the list
      const index = shifts.findIndex((x) => x.id === tempShift.id);
      const newEventList = [...shifts];

      newEventList.splice(index, 1, newEvent);
      setShifts(newEventList);
    } else {
      // add the new event to the list
      setShifts([...shifts, newEvent]);
    }
    // close the popup
    setOpen(false);
  }, [isEdit, shifts, shiftNotes, tempShift, shiftDate]);

  const deleteEvent = React.useCallback(
    (event) => {
      setShifts(shifts.filter((item) => item.id !== event.id));
      setTimeout(() => {
        snackbar({
          button: {
            action: () => {
              setShifts((prevEvents) => [...prevEvents, event]);
            },
            text: "Undo",
          },
          message: "Event deleted",
        });
      });
    },
    [shifts]
  );

  const loadPopupForm = React.useCallback((event) => {
    setDate([event.start, event.end]);
    setNotes(event.notes);
  }, []);

  // handle popup form changes
  const notesChange = React.useCallback((ev) => {
    setNotes(ev.target.value);
  }, []);

  const onDeleteClick = React.useCallback(() => {
    deleteEvent(tempShift);
    setOpen(false);
  }, [deleteEvent, tempShift]);

  // scheduler options
  // const onEventClick = React.useCallback(
  //   (args) => {
  //     const event = args.event;
  //     console.log(event);
  //     const resource = resources.find((r) => {
  //       return r.id === event.resource;
  //     });
  //     setHeader(
  //       '<div className="employee-shifts-day">' +
  //         formatDate("DDDD", new Date(event.start)) +
  //         " ," +
  //         formatDate("DD MMMM YYYY", new Date(event.start)) +
  //         "</div>"
  //     );
  //     // setMinTime(event.slot === 1 ? '07:00' : '12:00');
  //     // setMaxTime(event.slot === 1 ? '13:00' : '18:00');
  //     setEdit(true);
  //     setTempShift({ ...event });
  //     // fill popup form with event data
  //     loadPopupForm(event);
  //     setOpen(true);
  //   },
  //   [loadPopupForm]
  // );

  const onEventCreated = React.useCallback(
    (args) => {
      const event = args.event;
      //const slot = slots.find((s) => { return s.id === event.slot });
      setHeader(
        '<div>New shift</div><div class="employee-shifts-day">' +
          formatDate("DDDD", new Date(event.start)) +
          "  ," +
          formatDate("DD MMMM YYYY", new Date(event.start)) +
          "</div>"
      );
      setEdit(false);
      // setMinTime(event.slot === 1 ? '07:00' : '12:00');
      // setMaxTime(event.slot === 1 ? '13:00' : '18:00');
      setTempShift(event);
      // fill popup form with event data
      loadPopupForm(event);
      // open the popup
      setOpen(true);
    },
    [loadPopupForm]
  );

  const onEventDeleted = React.useCallback(
    (args) => {
      deleteEvent(args.event);
    },
    [deleteEvent]
  );

  // popup options
  const popupButtons = React.useMemo(() => {
    if (isEdit) {
      return [
        "cancel",
        {
          handler: () => {
            saveEvent();
          },
          keyCode: "enter",
          text: "Save",
          cssClass: "mbsc-popup-button-primary",
        },
      ];
    } else {
      return [
        "cancel",
        {
          handler: () => {
            saveEvent();
          },
          keyCode: "enter",
          text: "Add",
          cssClass: "mbsc-popup-button-primary",
        },
      ];
    }
  }, [isEdit, saveEvent]);

  const onClose = React.useCallback(() => {
    if (!isEdit) {
      // refresh the list, if add popup was canceled, to remove the temporary event
      setShifts([...shifts]);
    }
    setOpen(false);
  }, [isEdit, shifts]);

  const extendDefaultEvent = React.useCallback((args) => {
    console.log("triggered");
    const d = args.start;
    const start = new Date(d.getFullYear(), d.getMonth(), d.getDate(), 7);
    const end = new Date(d.getFullYear(), d.getMonth(), d.getDate(), 13);

    return {
      // title: formatDate("HH:mm", start) + " - " + formatDate("HH:mm", end),
      title: "test-title",
      start: start,
      end: end,
      resource: args.resource,
    };
  }, []);

  const renderMyResource = (resource) => {
    return (
      <div className="employee-shifts-cont font-weight-bold">
        {viewType == "employees" ? (
          <div className="employee-shifts-name">
            {resource.type == "team" ? (
              <span>
                <Groups3OutlinedIcon />
                {resource.name.substring(0, 20)}
              </span>
            ) : resource?.id?.includes("emp") ? (
              <Link
                className="custom-link-style"
                to={`/employees/employee-profile/${
                  resource?.id?.split("-")[1]
                }`}
              >
                <PersonOutlineOutlinedIcon />
                {resource.name.substring(0, 20)}
              </Link>
            ) : (
              <span className="custom-link-style">
                <Groups3OutlinedIcon />
                {resource.name.substring(0, 20)}
              </span>
            )}
          </div>
        ) : (
          ""
        )}
        {viewType == "locations" ? (
          <div className="employee-shifts-name">
            <span>
              {" "}
              <LocationOnOutlinedIcon /> {resource.name}
            </span>
          </div>
        ) : (
          ""
        )}
        {viewType == "work_timings" ? (
          <div className="employee-shifts-name">
            <span>
              {" "}
              <AccessTimeIcon /> {resource.name}
            </span>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };
  const changeTab = (thisViewType) => {
    setShifts([]);
    setResources([]);
    setIsLoading(true);
    setViewType(thisViewType);
  };
  const myCustomResourceHeaderTemplate = () => {
    return (
      <>
        <div>{t(viewType)}</div>
      </>
    );
  };

  const dateChange = React.useCallback((args) => {
    setDate(args.value);
  }, []);

  const myScheduleEvent = React.useCallback((event) => {
    console.log(event);
    return (
      <div>
        {event.original.work_timing_names} <br />
        {event.original.first_two_users.substring(0, 20)} (
        {event.original.emp_count})
      </div>
    );
  }, []);
  // <div className="md-timeline-template-event" style={{borderColor: color, background: color}}>
  //           <div className="md-timeline-template-event-cont">
  //               <span className={"mbsc-icon mbsc-font-icon mbsc-icon-" + ev.taskType} style={{background: color}}></span>
  //               <span className="md-timeline-template-time" style={{color: color}}>{data.start}</span>
  //               <span className="md-timeline-template-title">{ev.work_timing_names}</span>
  //           </div>
  //       </div>
  const myColors = [
    {
      start: moment(),
      end: moment(),
      cssClass: "today-cell",
    },
  ];
  const renderEvent = (event) => {
    let event_class = "event-day md-timeline-template-event";
    if (
      event?.original?.request_data?.includes("Leave") &&
      parseInt(event?.original?.request_data?.split("---")[1]) == 1
    ) {
      event_class += " leave";
    } else if (event?.original?.request_data?.includes("Leave")) {
      event_class += " pending-leave";
    } else if (
      event?.original?.sign_in_data == null ||
      event?.original?.sign_in_data == ""
    ) {
      event_class += " no-signin";
    } else if (event?.original?.sign_in_data == "Ontime") {
      event_class += " ontime";
    } else if (event?.original?.sign_in_data == "Absent") {
      event_class += " absent";
    } else {
      event_class += " penalty";
    }

    let final_title = [];
    if (event?.original?.work_timing_names?.includes(",")) {
      event.original.work_timing_names
        .split(",")
        .map(function (work_timing, index) {
          final_title.push(
            <div dir="auto" className="single-worktiming  text-ellipsis">
              {work_timing?.substring(0, 37)}
            </div>
          );
        });
    } else {
      final_title = (
        <div dir="auto" className="single-worktiming  text-ellipsis">
          {event?.original?.work_timing_names?.substring(0, 37)}
        </div>
      );
    }
    return (
      <div className={event_class} key={parseInt(event.position.left)}>
        {viewType == "locations" ? (
          document.documentElement.lang?.includes("ar") ? (
            <div className="user_count user_count_rtl">
              <PersonOutlineOutlinedIcon /> {event.original.user_count ?? 0}{" "}
            </div>
          ) : (
            <div className="user_count">
              <PersonOutlineOutlinedIcon /> {event.original.user_count ?? 0}{" "}
            </div>
          )
        ) : (
          ""
        )}
        {viewType == "work_timings" ? (
          <div style={{ maxWidth: "95%" }}>
            <ListItemText
              primary={event?.original?.user_name.substring(0, 37)}
              secondary={event?.original?.location_name}
              primaryTypographyProps={{
                component: "span",
                style: {
                  display: "inline-block",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "100%",
                },
              }}
              secondaryTypographyProps={{
                component: "p",
                style: {
                  display: "block",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "100%",
                },
              }}
            />
          </div>
        ) : (
          ""
        )}
        {viewType != "work_timings" ? (
          <div className="md-timeline-template-event-cont">{final_title} </div>
        ) : (
          ""
        )}
      </div>
    );
    {
      /* return <div className="event-day">{event.original.work_timing_names} <br/><img src="/user-calendar.png" height={30} /> <span className="employee-count">{event.original.emp_count}</span></div> */
    }
  };

  const handleCreateAssignment = () => {
    dispatch(fetchAssignmentFormAction({ includeInputs: true }));
  };

  const loadResources = (resources) => {
    setResources(resources);
  };

  const renderDay = (day) => {
    return (
      <p className="calendar-date-text-style">
        {moment(day?.date).format("DD ddd")}
      </p>
    );
  };

  const renderHeader = () => {
    return (
      <div className="d-flex align-items-center justify-content-between w-100 p-3">
        <div></div>
        <div className="d-flex align-items-center justify-content-center">
          <button
            className="bg-none border-0 outline-none"
            onClick={handlePrevButton}
          >
            <ChevronLeft
              className="calendar-date-toggling-button"
              style={{ fontSize: 40 }}
            />
          </button>
          <p className="calendar-header-date-style">
            {moment(firstDay).format("MMM DD, YYYY")} -{" "}
            {moment(firstDay).add(6, "days").format("MMM DD, YYYY")}
          </p>
          <button
            className="bg-none border-0 outline-none"
            onClick={handleNextButton}
          >
            <ChevronRight
              className="calendar-date-toggling-button"
              style={{ fontSize: 40 }}
            />
          </button>
        </div>
        <div className="rounded overflow-hidden">
          <button
            className={`calendar-toggle-view-button ${
              viewType == "locations"
                ? "calendar-toggle-view-button-active"
                : ""
            }`}
            onClick={() => changeTab("locations")}
          >
            {t("locations")}
          </button>
          <button
            className={`calendar-toggle-view-button ${
              viewType == "employees"
                ? "calendar-toggle-view-button-active"
                : ""
            }`}
            onClick={() => changeTab("employees")}
          >
            {t("employees")}
          </button>
          <button
            className={`calendar-toggle-view-button ${
              viewType == "work_timings"
                ? "calendar-toggle-view-button-active"
                : ""
            }`}
            onClick={() => changeTab("work_timings")}
          >
            {t("work timings")}
          </button>
        </div>
      </div>
    );
  };

  const handlePrevButton = () => {
    setFirstDay((prev) => moment(prev).subtract(7, "days"));
  };

  const handleNextButton = () => {
    setFirstDay((prev) => moment(prev).add(7, "days"));
  };

  const handleFetchWithFilters = () => {
    !called && getData();
  };

  // if (companyDataLoading) {
  //   return (
  //     <div className="loader_wrapper_style">
  //       <Loader />
  //     </div>
  //   );
  // }

  return (
    <div className="work_calendar_page content px-5 position-relative">
      {isLoading || fetchFiltersDataLoading || companyDataLoading ? (
        <div className="loader_wrapper_style position-absolute">
          <Loader />
        </div>
      ) : null}

      {/* <div className="page_header"> */}

      <h1 className="page-title my-0, pb-0 d-flex align-items-baseline border-0">
        <HasPrivileges allowBP reqireMain={[Privilages.VIEW_DRAFTED_SCHEDULES]}>
          <Link to="/attendance?tab=work-calendar/drafts">
            {t("drafts")}: ({draftSchedulesCount})
          </Link>
        </HasPrivileges>
      </h1>
      {/* </div>/ */}

      <div className="my-1">
        <div className="d-flex mx-0 gap-10 row align-items-baseline">
          <div className="align-items-baseline px-0 col my-2">
            <RadioboxForm
              optionsContainerStyle="optionsContainerStyle"
              optionItemStyle="optionItemStyle"
              optionInputStyle=" "
              optionLabelStyle="optionLabelStyle"
              name="filter"
              {...formProps}
              options={[
                { label: "work groups", value: "workGroups" },
                { label: "employees", value: "employees" },
                { label: "work teams", value: "workTeams" },
              ]}
            />
            <BSelect
              {...formProps}
              dependOn="filter"
              dependancyType="equal"
              dependancyValue={["workGroups"]}
              name="work_group_ids"
              placeholder={t("work group")}
              keepDefaultStyle
              containerStyle="my-0"
              rootStyle="min-width-200"
              options={filtersData?.work_groups_i_manage ?? []}
              skipLocalization
              icon={<GroupIcon />}
              isMulti={true}
              onInterceptInputOnChange={handleFetchWithFilters}
            />
            <BSelect
              {...formProps}
              dependOn="filter"
              dependancyType="equal"
              dependancyValue={["employees"]}
              name="employees_ids"
              placeholder={t("employees")}
              keepDefaultStyle
              containerStyle="my-0"
              rootStyle="min-width-200"
              options={filtersData?.employees ?? []}
              skipLocalization
              isMulti={true}
              icon={<PersonOutline />}
              onInterceptInputOnChange={handleFetchWithFilters}
            />
            <BSelect
              {...formProps}
              dependOn="filter"
              dependancyType="equal"
              dependancyValue={["workTeams"]}
              name="teams_ids"
              keepDefaultStyle
              containerStyle="my-0"
              rootStyle="min-width-200"
              placeholder={t("work teams")}
              options={filtersData?.work_teams_i_manage ?? []}
              skipLocalization
              icon={<GroupIcon />}
              isMulti={true}
              onInterceptInputOnChange={handleFetchWithFilters}
            />
          </div>
          <div className="flex-grow-1 mx-2">
            <BSelect
              {...formProps}
              name="work_places_ids"
              label={t("work places")}
              keepDefaultStyle
              placeholder={t("select option")}
              options={filtersData?.work_places?.data ?? []}
              skipLocalization
              isMulti={true}
              icon={<RoomIcon />}
              onInterceptInputOnChange={handleFetchWithFilters}
            />
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between  mt-2">
        <div
          className="d-flex border rounded-sm fit-width gap-20 px-3"
          style={{ height: 40, borderColor: "rgba(0, 0, 0, 0.12)" }}
        >
          {Object.keys(colors).map((key, i) => (
            <div className="d-flex align-items-center gap-5 text-center">
              <span
                className="rounded-circle border border-dark"
                style={{ width: 10, height: 10, backgroundColor: colors[key] }}
              />
              <strong className="text-uppercase">{t(key)}</strong>
            </div>
          ))}
        </div>

        <div className="d-flex justify-content-end my-1">
          <div className="d-flex gap-10">
            <HasPrivileges
              allowBP
              reqireMain={addEditDeleteAssignmentPrivileges}
            >
              <AddButton
                prefix="Create"
                variant="outlined"
                label="assignment"
                onClick={handleCreateAssignment}
              />
            </HasPrivileges>
            <HasPrivileges
              allowBP
              reqireMain={[
                Privilages.PLAN_NEW_SCHEDULES_FOR_MANAGED_WORKGROUPS,
              ]}
            >
              <AddButton
                href
                prefix="Plan"
                label="new schedule"
                to="/work-schedules/form"
                component={Link}
              />
            </HasPrivileges>
          </div>
        </div>
      </div>

      {called ? (
        <>
          {isLoading || fetchCalendarDataLoading ? <Loader absolute /> : null}
          <Eventcalendar
            rtl={document.documentElement.lang?.includes("ar")}
            locale={
              document.documentElement.lang?.includes("ar")
                ? localeAr
                : localeEn
            }
            view={viewSettings}
            data={shifts}
            invalid={myColors}
            resources={resources}
            dragToCreate={false}
            dragToResize={false}
            dragToMove={false}
            clickToCreate={false}
            renderResource={renderMyResource}
            cssClass="md-employee-shifts"
            renderScheduleEvent={renderEvent}
            renderResourceHeader={myCustomResourceHeaderTemplate}
            onCellClick={onCellClick}
            height={windowHeight - 250}
            refDate={firstDay}
            selectedDate={firstDay}
            onPageLoading={onPageLoading}
            // slots={slots}
            // invalid={invalid}
            // extendDefaultEvent={extendDefaultEvent}
            onEventClick={onEventClick}
            // renderScheduleEventContent={myScheduleEvent}
            renderDay={renderDay}
            renderHeader={renderHeader}
            // onEventHoverIn={onEventHoverIn}
            // onEventHoverOut={onEventHoverOut}
          />

          <Popup
            scrollLock={false}
            focusTrap={false}
            display="anchored"
            isOpen={isEventOpen}
            anchor={eventAnchor}
            touchUi={false}
            showOverlay={false}
            contentPadding={false}
            closeOnOverlayClick={false}
            width={350}
            cssClass="md-tooltip"
          >
            <div ref={teamPopupRef}>
              <div className="md-tooltip-header">
                <span>{t(tooltipEvent.schedule_type)}</span>
                <button
                  className="close close-event"
                  onClick={() => setEventOpen(false)}
                >
                  x
                </button>
              </div>
              <div className="md-tooltip-info">
                <div>
                  {tooltipEvent?.sign_in_data != null &&
                  tooltipEvent?.sign_in_data != "" ? (
                    <div>
                      <h4>{t("Sign In")}</h4>

                      <ListItemText
                        primary={t(tooltipEvent?.sign_in_data?.toLowerCase())}
                        secondary={null}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {tooltipEvent?.request_data != null ? (
                    <div>
                      <h4>{t("Request")}</h4>
                      <ListItemText
                        primary={t(tooltipEvent.request_name)}
                        secondary={t(tooltipEvent.request_status)}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <div></div>
                  <LocationOnOutlinedIcon />{" "}
                  {tooltipEvent?.location_name
                    ? tooltipEvent?.location_name
                    : "None"}
                </div>

                {tooltipEvent?.shift_teams != "" &&
                  tooltipEvent?.shift_teams?.split(",").map((teamMember) => (
                    <div>
                      <ListItem
                        secondaryAction={
                          <IconButton
                            edge="end"
                            aria-label="delete"
                          ></IconButton>
                        }
                      >
                        <ListItemAvatar>
                          <Avatar>
                            <Groups3OutlinedIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={teamMember}
                          secondary={tooltipEvent?.user_position}
                        />
                      </ListItem>
                      <Divider />
                    </div>
                  ))}
                {tooltipEvent?.shift_users != "" ? (
                  tooltipEvent?.shift_users?.split(",").map((teamMember) => (
                    <div>
                      <ListItem
                        secondaryAction={
                          <IconButton
                            edge="end"
                            aria-label="delete"
                          ></IconButton>
                        }
                      >
                        <ListItemAvatar>
                          <Avatar>
                            <PersonOutlineOutlinedIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={teamMember}
                          secondary={tooltipEvent?.user_position}
                        />
                      </ListItem>
                      <Divider />
                    </div>
                  ))
                ) : (
                  <div>
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end" aria-label="delete"></IconButton>
                      }
                    >
                      <ListItemAvatar>
                        <Avatar>
                          <PersonOutlineOutlinedIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={tooltipEvent?.user_name}
                        secondary={tooltipEvent?.user_position}
                      />
                    </ListItem>
                    <Divider />
                  </div>
                )}

                {tooltipEvent?.schedule_type?.toLowerCase() == "shift" &&
                tooltipEvent?.alternative_normal_work_timing_names?.length >
                  0 ? ( // alternative work timings key does not exist now
                  <div className="mt-2">
                    <p className="m-0 sub-title">
                      {t("alternative worktimings")}
                    </p>
                    <hr className="m-0" />
                    {tooltipEvent?.alternative_normal_work_timing_names?.map(
                      (workTimingName) => (
                        <p className="m-0">{workTimingName}</p>
                      )
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          </Popup>
          <Popup
            scrollLock={false}
            display="bottom"
            fullScreen={true}
            contentPadding={false}
            headerText={headerText}
            buttons={popupButtons}
            isOpen={isOpen}
            onClose={onClose}
            responsive={responsivePopup}
            cssClass="employee-shifts-popup"
          >
            <div className="mbsc-form-group">
              <Input ref={startRef} dropdown={true} label="Shift start"></Input>
              <Input ref={endRef} dropdown={true} label="Shift end"></Input>
              <Datepicker
                select="range"
                controls={["time"]}
                startInput={start}
                endInput={end}
                display="anchored"
                showRangeLabels={false}
                touchUi={false}
                onChange={dateChange}
                value={shiftDate}
                stepMinute={30}
                timeWheels="|h:mm A|"
                minTime={min}
                maxTime={max}
              />
            </div>
            <div className="mbsc-form-group">
              <Textarea
                label="Notes"
                value={shiftNotes}
                onChange={notesChange}
              />
            </div>
            {isEdit && (
              <div className="mbsc-button-group">
                <Button
                  className="mbsc-button-block"
                  color="danger"
                  variant="outline"
                  onClick={onDeleteClick}
                >
                  Delete shift
                </Button>
              </div>
            )}
          </Popup>
        </>
      ) : (
        <div
          style={{ minHeight: "30vh", borderColor: "rgba(0, 0, 0, 0.12)" }}
          className="d-flex align-items-center justify-content-center p-4 mt-4 border"
        >
          <ColorButton
            onClick={getData}
            label={t("Draw Calendar")}
            size="large"
          />
        </div>
      )}
      <UpsertAssignmentModal fetchCalendarData={fetchCalendarData} />
    </div>
  );
}

export default Calendar;
