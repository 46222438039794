import moment from "moment";
import { validate } from "uuid";
import Privilages from "../../Constants/Privilages";
import Store from "../../Store";
import Swal from "sweetalert2";
import swal from "sweetalert";
import i18next from "i18next";
import {
  showToast as showToastAction,
  checkPasswordAttempt,
  showErrorToast,
  showSuccessToast,
} from "../../Store/Actions";
import { ADMIN_PRIVILEGES } from "../../Constants/Nav";
import Constants, {
  monthOptionDays,
  monthOptionFromNumberOfDays,
} from "../../Constants";
import * as Requests from "../../Constants/Requests";

import * as SettingsHelpers from "./Settings";
import * as AttendanceHelpers from "./Attendance";
import * as normalizeSharedForms from "./serializeSharedForms";
import PaymentFactors from "../../Constants/PaymentFactors";
import { RegulationTypes } from "../../Constants/RegulationTypes";
import { initState } from "../../Store/Reducers/SuperReducer";
import _ from "lodash";

export * from "./CRM";
export * from "./Settings";
export * from "./Attendance";
export * from "./serializeSharedForms";

// const FloatHoursToTime = (num, type = "h") => {
//   let hh =
//     moment.duration(num, type).hours() > 9
//       ? moment.duration(num, type).hours()
//       : "0" + moment.duration(num, type).hours();
//   let mm =
//     moment.duration(num, type).minutes() > 9
//       ? moment.duration(num, type).minutes()
//       : "0" + moment.duration(num, type).minutes();
//   return `${hh}:${mm}`;
// };

// const convertNumberToTime = (num) => {
//   let h = num * 60;
//   const hours = Math.floor(h / 60);
//   const minutes = h % 60;
//   return `${hours > 9 ? hours : "0" + hours}:${
//     minutes > 9 ? minutes : "0" + minutes
//   }`;
// };

/**
 * @typedef {object} MutationResponse
 * @property {object} response
 * @property {boolean} error
 * @property {boolean} serverError
 */

/**
 *
 * @returns {Promise<MutationResponse>}
 */
export const handleActionMutation = async (
  mutation,
  {
    variables,
    refetchQuery,
    refetchQueryVariables,
    isReturnUnion = false,
    disableSuccessToast = false,
    disableFailedToast = false,
    onCompleted,
  } = {}
) => {
  try {
    const response = await mutation({ variables });

    // Error handling in case of union type
    if (
      isReturnUnion &&
      response?.data?.[Object?.keys(response?.data)?.[0]]?.__typename ===
        "GeneralException"
    ) {
      if (!disableFailedToast) {
        Store.dispatch(
          showErrorToast(
            response?.data?.[Object?.keys(response?.data)?.[0]]?.message
          )
        );
      }

      return {
        response,
        error: true,
      };
    }

    if (response?.errors) {
      // check if it is a server validation error
      if (response?.errors?.[0]?.extensions?.category === "validation") {
        return {
          response,
          error: true,
          serverError: response?.errors?.[0]?.extensions?.validation,
        };
      }

      // if grapqhl error, access message directly else access the business reason
      const errorMessage =
        response?.errors?.[0]?.extensions?.category === "graphql"
          ? response?.errors?.[0]?.message
          : response?.errors?.[0]?.extensions?.reason??"some thine went wrong";

      Store.dispatch(
        showErrorToast(errorMessage?.replace(/(?:\r\n|\r|\n)/g, "<br>"))
      );

      return {
        response,
        error: true,
      };
    }

    if (refetchQuery != null) {
      refetchQuery(refetchQueryVariables);
    }

    if (!disableSuccessToast) {
      Store.dispatch(showSuccessToast());
    }

    if (onCompleted) onCompleted();

    return {
      response,
      error: false,
    };
  } catch (error) {
    Store.dispatch(showErrorToast(error?.message));
  }
};

const timeToHours = (time) => {
  if (Boolean(time)) {
    if (time.split(":").length == 2) {
      const h = Number(time.split(":")[0]);
      const m = Number(time.split(":")[1] / 60);
      return h + m;
    }
    const h = Number(time.split(":")[0]);
    const m = Number(time.split(":")[1] / 60);
    const s = Number(time.split(":")[2] / 3600);
    return h + m + s;
  }
  return 0;
};

const timeToMinutes = (time) => {
  if (Boolean(time)) {
    if (time.split(":").length == 2) {
      const h = Number(time.split(":")[0]) * 60;
      const m = Number(time.split(":")[1]);
      return h + m;
    }
    const h = Number(time.split(":")[0]) * 60;
    const m = Number(time.split(":")[1]);
    const s = Number(time.split(":")[2] / 60);
    return h + m + s;
  }
  return 0;
};

const hoursToTime = (hours, includeSeconds = true) => {
  if (Boolean(hours)) {
    const totalSec = Math.ceil(Number(hours) * 3600);
    const h = parseInt(totalSec / 3600);
    const m = parseInt(totalSec / 60) % 60;
    const s = totalSec % 60;

    if (includeSeconds) {
      return `${h < 10 ? `0${h}` : h}:${m < 10 ? `0${m}` : m}:${
        s < 10 ? `0${s}` : s
      }`;
    }

    return `${h < 10 ? `0${h}` : h}:${m < 10 ? `0${m}` : m}`;
  }

  return `00:00:00`;
};

const minutesToTime = (minutes, includeSeconds = true) => {
  if (Boolean(minutes)) {
    const h = parseInt(minutes / 60);
    const m = parseInt(minutes % 60);
    const s = parseInt(minutes * 60) % 60;

    if (includeSeconds) {
      return `${h < 10 ? `0${h}` : h}:${m < 10 ? `0${m}` : m}:${
        s < 10 ? `0${s}` : s
      }`;
    }

    return `${h < 10 ? `0${h}` : h}:${m < 10 ? `0${m}` : m}`;
  }

  return `00:00:00`;
};

const secToTime = (seconds) => {
  if (Boolean(seconds)) {
    const h = parseInt(seconds / 3600);
    const m = parseInt(seconds / 60) % 60;
    const s = seconds % 60;

    return `${h < 10 ? `0${h}` : h}:${m < 10 ? `0${m}` : m}:${
      s < 10 ? `0${s}` : s
    }`;
  }

  return `00:00:00`;
};

const getTimeDifference = (to, from) => {
  if (to && from) {
    let Mminutes = Math.floor(
      moment(`${to}`, "HH:mm").diff(moment(`${from}`, "HH:mm"), "minutes") % 60
    );
    let Mhours = moment(`${to}`, "HH:mm").diff(
      moment(`${from}`, "HH:mm"),
      "hours"
    );
    let diff = timeToHours(to) - timeToHours(from);

    return diff < 0 ? hoursToTime(diff + 24, false) : hoursToTime(diff, false);
    let hours = Mhours > 9 ? `${Mhours}` : `0${Mhours}`;
    let minutes = Mminutes > 9 ? `${Mminutes}` : `0${Mminutes}`;

    return hours + ":" + minutes;
  }
  return null;
};

const serializedFetchedAttProfile = ({
  dayOffExceptionConfig,
  holidayExceptionConfig,
  check_in_distance_setting,
  checkInSetting,
  ...data
}) => {
  const { id, ...dayOffException } = dayOffExceptionConfig || { id: null };
  let fetchedData = {
    ...data,

    // day off config inputs
    ...normalizeSharedForms.serializeFetchedDayOffException(dayOffException),

    permission_leaves_break_setting: data?.permission_leaves_break_setting?.id,
    weekends: data.weekends ? data.weekends.split(",") : [],
    holidays: data?.holiday_profile?.holidays ?? [],
    attendance_profile_work_timing_input:
      data.attendance_profile_work_timing_input?.map(
        ({
          work_timing,
          first_half_work_timing,
          second_half_work_timing,
          ...restWorktime
        }) => ({
          ...restWorktime,
          work_timing_id: work_timing?.id,
          normal_work_timing_name: work_timing?.name,
          first_half_work_timing_id: first_half_work_timing?.id,
          second_half_work_timing_id: second_half_work_timing?.id,
          first_half_work_timing_name: first_half_work_timing?.name,
          second_half_work_timing_name: second_half_work_timing?.name,
        })
      ),
    old_attendance_profile_work_timing_input:
      data.attendance_profile_work_timing_input?.map(
        ({
          work_timing,
          first_half_work_timing,
          second_half_work_timing,
          ...restWorktime
        }) => ({
          ...restWorktime,
          work_timing_id: work_timing?.id,
          normal_work_timing_name: work_timing?.name,
          first_half_work_timing_id: first_half_work_timing?.id,
          second_half_work_timing_id: second_half_work_timing?.id,
          first_half_work_timing_name: first_half_work_timing?.name,
          second_half_work_timing_name: second_half_work_timing?.name,
        })
      ),
    allow_customize_check_ins: Number(
      Boolean(data.checkInForm || data.checkOutForm)
    ),
    check_in_form_id: data.checkInForm?.id,
    check_out_form_id: data.checkOutForm?.id,

    metres: check_in_distance_setting?.toString(),

    check_in_setting_id: checkInSetting?.id,
    check_in_settings_as_work_timing_sign_in_settings: !!!checkInSetting
      ? 1
      : 0,
    reuiqre_facial_recognition:
      checkInSetting?.face_recognition_in ||
      checkInSetting?.face_recognition_out
        ? 1
        : 0,
    employee_can_check_in_from: checkInSetting?.workplace_setting,
    check_in_specific_work_laces: checkInSetting?.workPlacesIn ?? [],
    apply_same_setting_when_checking_out:
      checkInSetting?.workplace_setting_identical ? 1 : 0,
    employee_can_check_out_from: checkInSetting?.workplace_setting_signout,
    check_out_specific_work_laces: checkInSetting?.workPlacesOut ?? [],

    // holiday config inputs
    ...normalizeSharedForms.serializeFetchedHolidayException({
      ...data,
      ...holidayExceptionConfig,
    }),
  };
  return fetchedData;
};

const serializeFetchedWorkTime = ({ break_work_timing_setting, ...data }) => {
  let minor_tardiness_range =
      data.minor_tardiness_range && data.apply_minor_tardiness
        ? moment(data.sign_in_end_time, "HH:mm")
            .add(timeToHours(data.minor_tardiness_range ?? 0), "hours")
            .format("HH:mm")
        : "",
    max_lateness_permissibility = data.apply_minor_tardiness
      ? moment(data.sign_in_end_time, "HH:mm")
          .add(
            Math.ceil(
              (
                timeToHours(data.max_lateness_permissibility ?? 0) * 60
              )?.toFixed(4)
            ),
            "minutes"
          )
          .format("HH:mm")
      : "",
    major_tardiness_range =
      data.major_tardiness_range && data.apply_major_tardiness
        ? moment(minor_tardiness_range, "HH:mm")
            .add(
              Math.ceil(
                (timeToHours(data.major_tardiness_range ?? 0) * 60)?.toFixed(4)
              ),
              "minutes"
            )
            .format("HH:mm")
        : "",
    major_tardiness_range1 =
      data.major_tardiness_range1 && data.apply_major_tardiness1
        ? moment(major_tardiness_range, "HH:mm")
            .add(
              Math.ceil(
                (timeToHours(data.major_tardiness_range1 ?? 0) * 60)?.toFixed(4)
              ),
              "minutes"
            )
            .format("HH:mm")
        : "",
    major_tardiness_range2 =
      data.major_tardiness_range2 && data.apply_major_tardiness2
        ? moment(major_tardiness_range1, "HH:mm")
            .add(
              Math.ceil(
                (timeToHours(data.major_tardiness_range2 ?? 0) * 60)?.toFixed(4)
              ),
              "minutes"
            )
            .format("HH:mm")
        : "",
    major_tardiness_range3 =
      data.major_tardiness_range3 && data.apply_major_tardiness3
        ? moment(major_tardiness_range2, "HH:mm")
            .add(
              Math.ceil(
                (timeToHours(data.major_tardiness_range3 ?? 0) * 60)?.toFixed(4)
              ),
              "minutes"
            )
            .format("HH:mm")
        : "",
    major_tardiness_range4 =
      data.major_tardiness_range4 && data.apply_major_tardiness4
        ? moment(major_tardiness_range3, "HH:mm")
            .add(
              Math.ceil(
                (timeToHours(data.major_tardiness_range4 ?? 0) * 60)?.toFixed(4)
              ),
              "minutes"
            )
            .format("HH:mm")
        : "",
    major_tardiness_range5 =
      data.major_tardiness_range5 && data.apply_major_tardiness5
        ? moment(major_tardiness_range4, "HH:mm")
            .add(
              Math.ceil(
                (timeToHours(data.major_tardiness_range5 ?? 0) * 60)?.toFixed(4)
              ),
              "minutes"
            )
            .format("HH:mm")
        : "",
    major_tardiness_range6 =
      data.major_tardiness_range6 && data.apply_major_tardiness6
        ? moment(major_tardiness_range5, "HH:mm")
            .add(
              Math.ceil(
                (timeToHours(data.major_tardiness_range6 ?? 0) * 60)?.toFixed(4)
              ),
              "minutes"
            )
            .format("HH:mm")
        : "",
    major_tardiness_range7 =
      data.major_tardiness_range7 && data.apply_major_tardiness7
        ? moment(major_tardiness_range6, "HH:mm")
            .add(
              Math.ceil(
                (timeToHours(data.major_tardiness_range7 ?? 0) * 60)?.toFixed(4)
              ),
              "minutes"
            )
            .format("HH:mm")
        : "",
    major_tardiness_range8 =
      data.major_tardiness_range8 && data.apply_major_tardiness8
        ? moment(major_tardiness_range7, "HH:mm")
            .add(
              Math.ceil(
                (timeToHours(data.major_tardiness_range8 ?? 0) * 60)?.toFixed(4)
              ),
              "minutes"
            )
            .format("HH:mm")
        : "",
    major_tardiness_range9 =
      data.major_tardiness_range9 && data.apply_major_tardiness9
        ? moment(major_tardiness_range8, "HH:mm")
            .add(
              Math.ceil(
                (timeToHours(data.major_tardiness_range9 ?? 0) * 60)?.toFixed(4)
              ),
              "minutes"
            )
            .format("HH:mm")
        : "",
    major_tardiness_range10 =
      data.major_tardiness_range10 && data.apply_major_tardiness10
        ? moment(major_tardiness_range9, "HH:mm")
            .add(
              Math.ceil(
                (timeToHours(data.major_tardiness_range10 ?? 0) * 60)?.toFixed(
                  4
                )
              ),
              "minutes"
            )
            .format("HH:mm")
        : "",
    time_allowed_before_sign_in = moment(data.sign_in_start_time, "HH:mm")
      .subtract(
        Math.ceil(
          (timeToHours(data.time_allowed_before_sign_in ?? 0) * 60)?.toFixed(4)
        ),
        "minutes"
      )
      .format("HH:mm");

  const minor_violations = !data?.minor_violations?.length
    ? []
    : data?.tardiness_type_sign_in == "time_interval"
    ? data?.minor_violations?.map((violation) => ({
        ...violation,
        deduct_minutes_by: "minutes",
      }))
    : [
        {
          ...data?.minor_violations[0],
          deduct_minutes_by:
            data?.minor_violations[0]?.quantity_unit == "minutes"
              ? "minutes"
              : "fixed",
        },
      ];

  const major_violations = !data?.major_violations?.length
    ? []
    : data?.tardiness_type_sign_in == "time_interval"
    ? data?.major_violations?.map((violation) => ({
        ...violation,
        deduct_minutes_by: "minutes",
      }))
    : [
        {
          ...data?.major_violations[0],
          deduct_minutes_by:
            data?.major_violations[0]?.quantity_unit == "minutes"
              ? "minutes"
              : "fixed",
        },
      ];

  const major_violations1 = !data?.major_violations1?.length
    ? []
    : data?.tardiness_type_sign_in == "time_interval"
    ? data?.major_violations1?.map((violation) => ({
        ...violation,
        deduct_minutes_by: "minutes",
      }))
    : [
        {
          ...data?.major_violations1[0],
          deduct_minutes_by:
            data?.major_violations1[0]?.quantity_unit == "minutes"
              ? "minutes"
              : "fixed",
        },
      ];

  const major_violations2 = !data?.major_violations2?.length
    ? []
    : data?.tardiness_type_sign_in == "time_interval"
    ? data?.major_violations2?.map((violation) => ({
        ...violation,
        deduct_minutes_by: "minutes",
      }))
    : [
        {
          ...data?.major_violations2[0],
          deduct_minutes_by:
            data?.major_violations2[0]?.quantity_unit == "minutes"
              ? "minutes"
              : "fixed",
        },
      ];

  const major_violations3 = !data?.major_violations3?.length
    ? []
    : data?.tardiness_type_sign_in == "time_interval"
    ? data?.major_violations3?.map((violation) => ({
        ...violation,
        deduct_minutes_by: "minutes",
      }))
    : [
        {
          ...data?.major_violations3[0],
          deduct_minutes_by:
            data?.major_violations3[0]?.quantity_unit == "minutes"
              ? "minutes"
              : "fixed",
        },
      ];

  const major_violations4 = !data?.major_violations4?.length
    ? []
    : data?.tardiness_type_sign_in == "time_interval"
    ? data?.major_violations4?.map((violation) => ({
        ...violation,
        deduct_minutes_by: "minutes",
      }))
    : [
        {
          ...data?.major_violations4[0],
          deduct_minutes_by:
            data?.major_violations4[0]?.quantity_unit == "minutes"
              ? "minutes"
              : "fixed",
        },
      ];

  const major_violations5 = !data?.major_violations5?.length
    ? []
    : data?.tardiness_type_sign_in == "time_interval"
    ? data?.major_violations5?.map((violation) => ({
        ...violation,
        deduct_minutes_by: "minutes",
      }))
    : [
        {
          ...data?.major_violations5[0],
          deduct_minutes_by:
            data?.major_violations5[0]?.quantity_unit == "minutes"
              ? "minutes"
              : "fixed",
        },
      ];

  const major_violations6 = !data?.major_violations6?.length
    ? []
    : data?.tardiness_type_sign_in == "time_interval"
    ? data?.major_violations6?.map((violation) => ({
        ...violation,
        deduct_minutes_by: "minutes",
      }))
    : [
        {
          ...data?.major_violations6[0],
          deduct_minutes_by:
            data?.major_violations6[0]?.quantity_unit == "minutes"
              ? "minutes"
              : "fixed",
        },
      ];

  const major_violations7 = !data?.major_violations7?.length
    ? []
    : data?.tardiness_type_sign_in == "time_interval"
    ? data?.major_violations7?.map((violation) => ({
        ...violation,
        deduct_minutes_by: "minutes",
      }))
    : [
        {
          ...data?.major_violations7[0],
          deduct_minutes_by:
            data?.major_violations7[0]?.quantity_unit == "minutes"
              ? "minutes"
              : "fixed",
        },
      ];

  const major_violations8 = !data?.major_violations8?.length
    ? []
    : data?.tardiness_type_sign_in == "time_interval"
    ? data?.major_violations8?.map((violation) => ({
        ...violation,
        deduct_minutes_by: "minutes",
      }))
    : [
        {
          ...data?.major_violations8[0],
          deduct_minutes_by:
            data?.major_violations8[0]?.quantity_unit == "minutes"
              ? "minutes"
              : "fixed",
        },
      ];

  const major_violations9 = !data?.major_violations9?.length
    ? []
    : data?.tardiness_type_sign_in == "time_interval"
    ? data?.major_violations9?.map((violation) => ({
        ...violation,
        deduct_minutes_by: "minutes",
      }))
    : [
        {
          ...data?.major_violations9[0],
          deduct_minutes_by:
            data?.major_violations9[0]?.quantity_unit == "minutes"
              ? "minutes"
              : "fixed",
        },
      ];

  const major_violations10 = !data?.major_violations10?.length
    ? []
    : data?.tardiness_type_sign_in == "time_interval"
    ? data?.major_violations10?.map((violation) => ({
        ...violation,
        deduct_minutes_by: "minutes",
      }))
    : [
        {
          ...data?.major_violations10[0],
          deduct_minutes_by:
            data?.major_violations10[0]?.quantity_unit == "minutes"
              ? "minutes"
              : "fixed",
        },
      ];

  let fetchedData = {
    ...data,
    ...break_work_timing_setting,
    maximum_break_session_duration:
      break_work_timing_setting?.maximum_break_session_duration
        ? Math.ceil(
            timeToHours(
              break_work_timing_setting?.maximum_break_session_duration
            ) * 60
          )
        : null,
    maximum_break_duration: break_work_timing_setting?.maximum_break_duration
      ? Math.ceil(
          timeToHours(break_work_timing_setting?.maximum_break_duration) * 60
        )
      : null,
    sign_in_reminder: +Boolean(data?.sign_in_reminder_time),
    // end_of_day: "00:00",
    work_hours: timeToHours(data.work_hours),
    sign_out_cuttoff_time:
      timeToHours(data.work_hours) - timeToHours(data.sign_out_cuttoff_time),
    min_overtime_cuttoff: timeToMinutes(data.min_overtime_cuttoff),
    max_overtime_per_day: timeToHours(data.max_overtime_per_day),

    max_lateness_permissibility,
    minor_tardiness_range,
    major_tardiness_range,
    major_tardiness_range1,
    major_tardiness_range2,
    major_tardiness_range3,
    major_tardiness_range4,
    major_tardiness_range5,
    major_tardiness_range6,
    major_tardiness_range7,
    major_tardiness_range8,
    major_tardiness_range9,
    major_tardiness_range10,

    overtime_limit: +data.overtime_limit,
    same_policies: +data.same_policies,

    annual_leave_hours_sign_in: data?.annual_leave_hours_sign_in,
    annual_leave_hours_sign_out: data?.annual_leave_hours_sign_in,

    time_allowed_before_sign_in,
    minor_violations_deduct_as:
      data?.minor_violations[0]?.quantity_unit ?? "days",
    major_violations_deduct_as:
      data?.major_violations[0]?.quantity_unit ?? "days",
    major_violations_1_deduct_as:
      data?.major_violations1[0]?.quantity_unit ?? "days",
    major_violations_2_deduct_as:
      data?.major_violations2[0]?.quantity_unit ?? "days",
    major_violations_3_deduct_as:
      data?.major_violations3[0]?.quantity_unit ?? "days",
    major_violations_4_deduct_as:
      data?.major_violations4[0]?.quantity_unit ?? "days",
    major_violations_5_deduct_as:
      data?.major_violations5[0]?.quantity_unit ?? "days",
    major_violations_6_deduct_as:
      data?.major_violations6[0]?.quantity_unit ?? "days",
    major_violations_7_deduct_as:
      data?.major_violations7[0]?.quantity_unit ?? "days",
    major_violations_8_deduct_as:
      data?.major_violations8[0]?.quantity_unit ?? "days",
    major_violations_9_deduct_as:
      data?.major_violations9[0]?.quantity_unit ?? "days",
    major_violations_10_deduct_as:
      data?.major_violations10[0]?.quantity_unit ?? "days",
    absent_violations_deduct_unit:
      data?.absent_violations[0]?.quantity_unit ?? "days",
    early_violations_deduct_unit:
      data?.early_sign_out_violations[0]?.quantity_unit ?? "days",
    missing_violations_deduct_unit:
      data?.missing_sign_out_violations[0]?.quantity_unit ?? "days",
    apply_missing_sign_out: data.apply_missing_sign_out ? 1 : 0,
    missing_sign_out_reset_period: data.apply_missing_sign_out
      ? data.missing_sign_out_reset_period
      : "month",
    missing_sign_out_deduction_type: data.apply_missing_sign_out
      ? data.missing_sign_out_deduction_type
      : "Leaves",

    minor_violations,
    major_violations,
    major_violations1,
    major_violations2,
    major_violations3,
    major_violations4,
    major_violations5,
    major_violations6,
    major_violations7,
    major_violations8,
    major_violations9,
    major_violations10,

    record_actual: Boolean(data?.overtime_type === "default"),
  };

  return fetchedData;
};

const serializedSubmitedAttProfile = (data) => {
  const allow_work_on_day_off =
    data?.weekends?.length && data?.allow_work_on_day_off ? true : false;

  let submittedData = {
    id: data?.id,
    name: data?.name,
    profile_type: data?.profile_type,
    allow_multiple_check_ins: data?.allow_multiple_check_ins,
    week_start_day: data?.week_start_day,
    weekends: data?.weekends,

    permission_leaves_break_setting_id: data?.permission_leaves_break_setting,
    default_office_holiday_profile: data?.default_office_holiday_profile,
    holidays: data?.holidays?.map((holiday) =>
      validate(holiday.id)
        ? {
            ...holiday,
            id: null,
          }
        : holiday
    ),
    attendance_profile_work_timing_input:
      data?.attendance_profile_work_timing_input
        .map(
          ({
            id,
            work_timing_id,
            start,
            first_half_work_timing_id,
            second_half_work_timing_id,
            allow_half_day,
          }) => ({
            work_timing_id,
            id,
            start,
            allow_half_day,
            ...(!!allow_half_day
              ? { first_half_work_timing_id, second_half_work_timing_id }
              : {}),
          })
        )
        .reverse(),

    check_in_form_id: data?.check_in_form_id,
    check_out_form_id: data?.check_out_form_id,

    // Day off exception
    ...normalizeSharedForms.serializeUpsertDayOffException({
      ...data,
      allow_work_on_day_off,
      workplace_setting_identical: 1,
      workplace_setting: "work_timing_location",
      workplace_setting_signout: "work_timing_location",
    }),

    check_in_distance_setting: !!data?.metres ? parseFloat(data?.metres) : 0,
    checkin_setting:
      data?.check_in_settings_as_work_timing_sign_in_settings ||
      !data?.allow_multiple_check_ins
        ? null
        : {
            id: data?.check_in_setting_id,
            face_recognition_check_in: !!data?.reuiqre_facial_recognition,
            face_recognition_check_out: !!data?.reuiqre_facial_recognition,

            workplace_setting: data?.employee_can_check_in_from,

            additional_work_places:
              data?.employee_can_check_in_from == "DEFAULT"
                ? data?.check_in_specific_work_laces
                : [],

            workplace_setting_identical:
              data?.apply_same_setting_when_checking_out,

            workplace_setting_signout:
              data?.apply_same_setting_when_checking_out
                ? data?.employee_can_check_in_from
                : data?.employee_can_check_out_from,

            additional_work_places_signout:
              data?.apply_same_setting_when_checking_out
                ? data?.employee_can_check_in_from == "DEFAULT"
                  ? data?.check_in_specific_work_laces
                  : []
                : data?.employee_can_check_out_from == "DEFAULT"
                ? data?.check_out_specific_work_laces
                : [],
          },

    // holiday exception
    ...normalizeSharedForms.serializeUpsertHolidayException({
      ...data,
      allow_work_on_day_off,
      holiday_workplace_setting_identical: 1,
      holiday_workplace_setting: "work_timing_location",
      holiday_workplace_setting_signout: "work_timing_location",
    }),
  };

  return submittedData;
};
const lastSignOutTierCalculator = (data) => {
  let last_signout_tier = "";
  for (let i = 10; i > 0; i--) {
    if (data?.["allow_early_signout" + i] == 1) {
      last_signout_tier = i;
      return last_signout_tier;
    }
  }
  return last_signout_tier;
};

const handleViolations = (
  violations = [],
  type,
  deduct_as,
  deduct_from = "",
  isEarlySignOut = false
) => {
  if (type === "minutes") {
    const { deduct_minutes_by, ...violation } = violations[0];
    return [
      {
        ...violation,
        order: "1",
        ...(isEarlySignOut
          ? {
              quantity_unit: "minutes",
            }
          : {
              quantity_unit:
                violations[0]?.deduct_minutes_by == "minutes"
                  ? "minutes"
                  : deduct_from === "Leaves"
                  ? "days"
                  : deduct_as,
            }),
      },
    ];
  } else {
    return violations.map(({ deduct_minutes_by, ...violation }, order) => ({
      ...violation,
      order: `${++order}`,
      quantity_unit: deduct_as,
    }));
  }
};

const serializedSubmitedWorkTiming = ({
  minor_violations_deduct_as,
  major_violations_deduct_as,
  major_violations_1_deduct_as,
  major_violations_2_deduct_as,
  major_violations_3_deduct_as,
  major_violations_4_deduct_as,
  major_violations_5_deduct_as,
  major_violations_6_deduct_as,
  major_violations_7_deduct_as,
  major_violations_8_deduct_as,
  major_violations_9_deduct_as,
  major_violations_10_deduct_as,
  absent_violations_deduct_unit,
  early_violations_deduct_unit,
  missing_violations_deduct_unit,
  getActiveAttendanceProfiles,
  annual_leave_hours_sign_in,
  annual_leave_hours_sign_out,
  sign_in_reminder,
  sign_in_reminder_time,
  weight,
  consider_permissibility_hours,
  override_break_setting = false,
  allow_break = false,
  deduct_from_work_hours = false,
  break_type = "flexible",
  start_break = "manual",
  can_end_break = true,
  break_interval = [],
  allow_limit_total_break = false,
  break_work_timing_setting_id = null,
  number_of_allowed_break_sessions_per_day = null,
  maximum_break_duration = "",
  maximum_break_session_duration = "",
  overtime_type = "default",
  overtime_value = 0,
  record_actual,
  ...data
}) => {
  let lastSignOutTier = lastSignOutTierCalculator(data);
  const noBreakLimits =
    (allow_break &&
      break_type?.toLowerCase() === "fixed" &&
      start_break?.toLowerCase() === "auto" &&
      !can_end_break) ||
    (start_break === "auto" && !can_end_break);
  const allowLimitTotalBreak =
    start_break === "auto" && !can_end_break ? false : allow_limit_total_break;
  let break_work_timing_setting = override_break_setting
    ? {
        id: break_work_timing_setting_id,
        allow_break,
        deduct_from_work_hours,
        break_type,
        start_break,
        can_end_break,
        break_interval,
        allow_limit_total_break: allowLimitTotalBreak,
        number_of_allowed_break_sessions_per_day:
          !noBreakLimits ||
          (number_of_allowed_break_sessions_per_day && allow_break) ||
          !allowLimitTotalBreak
            ? number_of_allowed_break_sessions_per_day
            : null,
        maximum_break_session_duration:
          !noBreakLimits ||
          (maximum_break_duration && allow_break) ||
          !allowLimitTotalBreak
            ? hoursToTime(Number(maximum_break_session_duration) / 60)
            : null,
        maximum_break_duration:
          !noBreakLimits ||
          (maximum_break_duration && allow_break) ||
          !allowLimitTotalBreak
            ? hoursToTime(Number(maximum_break_duration) / 60)
            : null,
      }
    : null;
  let submittedData = {
    ..._.omit(data, [
      "allow_work_timing_assigment",
      "offices",
      "departments",
      "positions",
      "employees",
    ]),
    override_break_setting,
    break_work_timing_setting,
    sign_in_reminder_time: sign_in_reminder ? sign_in_reminder_time : null,
    weight: +weight,
    work_hours: hoursToTime(data.work_hours),
    annual_leave_hours_sign_in: parseFloat(
      annual_leave_hours_sign_in || annual_leave_hours_sign_out
    ),
    min_overtime_cuttoff: minutesToTime(data.min_overtime_cuttoff),
    max_overtime_per_day: hoursToTime(data.max_overtime_per_day),
    max_lateness_permissibility: data.apply_minor_tardiness
      ? getTimeDifference(
          data.max_lateness_permissibility,
          data.sign_in_end_time
        )
      : "00:00",
    minor_tardiness_range:
      data.minor_tardiness_range && data.sign_in_end_time
        ? getTimeDifference(data.minor_tardiness_range, data.sign_in_end_time)
        : "0:00",
    major_tardiness_range:
      data.minor_tardiness_range && data.major_tardiness_range
        ? getTimeDifference(
            data.major_tardiness_range,
            data.minor_tardiness_range
          )
        : "0:00",
    major_tardiness_range1:
      data.major_tardiness_range && data.major_tardiness_range1
        ? getTimeDifference(
            data.major_tardiness_range1,
            data.major_tardiness_range
          )
        : "0:00",
    major_tardiness_range2:
      data.major_tardiness_range1 && data.major_tardiness_range2
        ? getTimeDifference(
            data.major_tardiness_range2,
            data.major_tardiness_range1
          )
        : "0:00",
    major_tardiness_range3:
      data.major_tardiness_range2 && data.major_tardiness_range3
        ? getTimeDifference(
            data.major_tardiness_range3,
            data.major_tardiness_range2
          )
        : "0:00",
    major_tardiness_range4:
      data.major_tardiness_range3 && data.major_tardiness_range4
        ? getTimeDifference(
            data.major_tardiness_range4,
            data.major_tardiness_range3
          )
        : "0:00",
    major_tardiness_range5:
      data.major_tardiness_range4 && data.major_tardiness_range5
        ? getTimeDifference(
            data.major_tardiness_range5,
            data.major_tardiness_range4
          )
        : "0:00",
    major_tardiness_range6:
      data.major_tardiness_range5 && data.major_tardiness_range6
        ? getTimeDifference(
            data.major_tardiness_range6,
            data.major_tardiness_range5
          )
        : "0:00",
    major_tardiness_range7:
      data.major_tardiness_range6 && data.major_tardiness_range7
        ? getTimeDifference(
            data.major_tardiness_range7,
            data.major_tardiness_range6
          )
        : "0:00",
    major_tardiness_range8:
      data.major_tardiness_range7 && data.major_tardiness_range8
        ? getTimeDifference(
            data.major_tardiness_range8,
            data.major_tardiness_range7
          )
        : "0:00",
    major_tardiness_range9:
      data.major_tardiness_range8 && data.major_tardiness_range9
        ? getTimeDifference(
            data.major_tardiness_range9,
            data.major_tardiness_range8
          )
        : "0:00",
    major_tardiness_range10:
      data.major_tardiness_range9 && data.major_tardiness_range10
        ? getTimeDifference(
            data.major_tardiness_range10,
            data.major_tardiness_range9
          )
        : "0:00",
    sign_out_cuttoff_time: !+data.allow_early_signout
      ? hoursToTime(data.work_hours)
      : hoursToTime(
          +data.work_hours -
            +(
              data?.work_hours -
              data?.["early_signout_range_to" + lastSignOutTier] / 60
            )
        ),
    time_allowed_before_sign_in: getTimeDifference(
      data.sign_in_start_time,
      data.time_allowed_before_sign_in
    ),
    consider_permissibility_hours:
      moment(data.sign_in_start_time, "HH:mm").format("A") == "AM" &&
      moment(data.time_allowed_before_sign_in, "HH:mm").format("A") == "PM"
        ? consider_permissibility_hours
        : false,
    absent_violations: data.absent_violations.map((violation, order) => ({
      ...violation,
      order: `${++order}`,
      quantity_unit: absent_violations_deduct_unit,
    })),

    // Tardiness Violation
    minor_violations: data.apply_minor_tardiness
      ? handleViolations(
          data.minor_violations,
          data?.tardiness_type_sign_in,
          minor_violations_deduct_as,
          data?.late_tardiness_penalty_type
        )
      : [],
    major_violations: data.apply_major_tardiness
      ? handleViolations(
          data.major_violations,
          data?.tardiness_type_sign_in,
          major_violations_deduct_as,
          data?.late_tardiness_penalty_type
        )
      : [],
    major_violations1: data.apply_major_tardiness1
      ? handleViolations(
          data.major_violations1,
          data?.tardiness_type_sign_in,
          major_violations_1_deduct_as,
          data?.late_tardiness_penalty_type
        )
      : [],
    major_violations2: data.apply_major_tardiness2
      ? handleViolations(
          data.major_violations2,
          data?.tardiness_type_sign_in,
          major_violations_2_deduct_as,
          data?.late_tardiness_penalty_type
        )
      : [],
    major_violations3: data.apply_major_tardiness3
      ? handleViolations(
          data.major_violations3,
          data?.tardiness_type_sign_in,
          major_violations_3_deduct_as,
          data?.late_tardiness_penalty_type
        )
      : [],
    major_violations4: data.apply_major_tardiness4
      ? handleViolations(
          data.major_violations4,
          data?.tardiness_type_sign_in,
          major_violations_4_deduct_as,
          data?.late_tardiness_penalty_type
        )
      : [],
    major_violations5: data.apply_major_tardiness5
      ? handleViolations(
          data.major_violations5,
          data?.tardiness_type_sign_in,
          major_violations_5_deduct_as,
          data?.late_tardiness_penalty_type
        )
      : [],
    major_violations6: data.apply_major_tardiness6
      ? handleViolations(
          data.major_violations6,
          data?.tardiness_type_sign_in,
          major_violations_6_deduct_as,
          data?.late_tardiness_penalty_type
        )
      : [],
    major_violations7: data.apply_major_tardiness7
      ? handleViolations(
          data.major_violations7,
          data?.tardiness_type_sign_in,
          major_violations_7_deduct_as,
          data?.late_tardiness_penalty_type
        )
      : [],
    major_violations8: data.apply_major_tardiness8
      ? handleViolations(
          data.major_violations8,
          data?.tardiness_type_sign_in,
          major_violations_8_deduct_as,
          data?.late_tardiness_penalty_type
        )
      : [],
    major_violations9: data.apply_major_tardiness9
      ? handleViolations(
          data.major_violations9,
          data?.tardiness_type_sign_in,
          major_violations_9_deduct_as,
          data?.late_tardiness_penalty_type
        )
      : [],
    major_violations10: data.apply_major_tardiness10
      ? handleViolations(
          data.major_violations10,
          data?.tardiness_type_sign_in,
          major_violations_10_deduct_as,
          data?.late_tardiness_penalty_type
        )
      : [],

    // Early Violation
    early_sign_out_violations: data.allow_early_signout
      ? handleViolations(
          data.early_sign_out_violations,
          data?.tardiness_type_sign_out,
          early_violations_deduct_unit,
          null,
          true
        )
      : [],
    early_sign_out_violations1: data.allow_early_signout1
      ? handleViolations(
          data.early_sign_out_violations1,
          data?.tardiness_type_sign_out,
          early_violations_deduct_unit,
          null,
          true
        )
      : [],
    early_sign_out_violations2: data.allow_early_signout2
      ? handleViolations(
          data.early_sign_out_violations2,
          data?.tardiness_type_sign_out,
          early_violations_deduct_unit,
          null,
          true
        )
      : [],
    early_sign_out_violations3: data.allow_early_signout3
      ? handleViolations(
          data.early_sign_out_violations3,
          data?.tardiness_type_sign_out,
          early_violations_deduct_unit,
          null,
          true
        )
      : [],
    early_sign_out_violations4: data.allow_early_signout4
      ? handleViolations(
          data.early_sign_out_violations4,
          data?.tardiness_type_sign_out,
          early_violations_deduct_unit,
          null,
          true
        )
      : [],
    early_sign_out_violations5: data.allow_early_signout5
      ? handleViolations(
          data.early_sign_out_violations5,
          data?.tardiness_type_sign_out,
          early_violations_deduct_unit,
          null,
          true
        )
      : [],
    early_sign_out_violations6: data.allow_early_signout6
      ? handleViolations(
          data.early_sign_out_violations6,
          data?.tardiness_type_sign_out,
          early_violations_deduct_unit,
          null,
          true
        )
      : [],
    early_sign_out_violations7: data.allow_early_signout7
      ? handleViolations(
          data.early_sign_out_violations7,
          data?.tardiness_type_sign_out,
          early_violations_deduct_unit,
          null,
          true
        )
      : [],
    early_sign_out_violations8: data.allow_early_signout8
      ? handleViolations(
          data.early_sign_out_violations8,
          data?.tardiness_type_sign_out,
          early_violations_deduct_unit,
          null,
          true
        )
      : [],
    early_sign_out_violations9: data.allow_early_signout9
      ? handleViolations(
          data.early_sign_out_violations9,
          data?.tardiness_type_sign_out,
          early_violations_deduct_unit,
          null,
          true
        )
      : [],
    early_sign_out_violations10: data.allow_early_signout10
      ? handleViolations(
          data.early_sign_out_violations10,
          data?.tardiness_type_sign_out,
          early_violations_deduct_unit,
          null,
          true
        )
      : [],

    missing_sign_out_violations: data.apply_missing_sign_out
      ? data.missing_sign_out_violations.map((violation, order) => ({
          ...violation,
          order: `${++order}`,
          quantity_unit: missing_violations_deduct_unit,
        }))
      : [],

    limit_access: {
      offices: data?.allow_work_timing_assigment ? [] : [...data?.offices],
      departments: data?.allow_work_timing_assigment
        ? []
        : [...data?.departments],
      positions: data?.allow_work_timing_assigment ? [] : [...data?.positions],
      employees: data?.allow_work_timing_assigment ? [] : [...data?.employees],
    },

    overtime_value: record_actual ? 0 : parseInt(overtime_value),
    overtime_type: record_actual ? "default" : overtime_type,
    // early_signout_action:
    // data.early_signout_action == 1 ? "Notify Managers" : "No Notification",
  };

  return submittedData;
};

const serializedEditEmployee = (data) => {
  let editedData = {
    ...data,
    office_id: data?.office?.id,
    office_name: data?.office?.name,
    department_id: data?.department?.id,
    manager_id: data?.manager?.id,
    att_profile_id: data?.attendance_profile?.id,
    // role_id: data?.role?.id,
    position_id: data?.position?.id,
    copied_managers: data?.copied_managers.map((manager) => manager.id),
    home_days: data?.home_days ? data?.home_days.split(",") : [],
    company_id: data?.company?.id,
  };
  return editedData;
};

const getIntials = (name) => {
  if (name) {
    let fullname = name.trim().split(" ");
    if (isStringRTL(fullname)) {
      return `${fullname[0][0]}.${fullname[fullname.length - 1][0]}`;
    }
    return `${fullname[0][0]}${fullname[fullname.length - 1][0]}`;
  }
};

const parseValidatorErrors = (errors) => {
  return Object.fromEntries(
    Object.keys(errors).map((key) => [[key], errors[key].toString()])
  );
};

const checkPrivileges = ({ privileges = [], allowBP = false, scope }) => {
  const main = Store?.getState()?.auth?.userProfile?.prviliege_ids;
  const allScope =
    Store?.getState()?.auth?.userProfile?.prviliege_ids_with_all_employee_scope;
  const managedScope =
    Store?.getState()?.auth?.userProfile
      ?.prviliege_ids_with_managed_employee_scope;

  const planPrivilegs =
    Store?.getState()?.auth?.userProfile?.company?.activeSubscription?.plan?.privileges?.map(
      (privilege) => privilege?.id
    );

  if (
    privileges?.some((prev) => ADMIN_PRIVILEGES?.indexOf(prev) !== -1) &&
    privileges?.some((prev) => main?.indexOf(prev) !== -1)
  ) {
    return true;
  }

  if (
    (allowBP &&
      main?.includes(Privilages.BUSINESS_PARTNER_PRIVILEGES) &&
      !privileges.length) ||
    (privileges.length &&
      allowBP &&
      main?.includes(Privilages.BUSINESS_PARTNER_PRIVILEGES) &&
      privileges?.some((prev) => planPrivilegs?.indexOf(prev) !== -1))
  ) {
    return true;
  } else {
    if (
      planPrivilegs?.length &&
      privileges?.some((prev) => planPrivilegs?.indexOf(prev) !== -1)
    ) {
      switch (scope) {
        case "all":
          if (allScope?.some((prev) => privileges?.indexOf(prev) !== -1)) {
            return true;
          }
          return false;
        case "managed":
          if (managedScope?.some((prev) => privileges?.indexOf(prev) !== -1)) {
            return true;
          }
          return false;
        default:
          if (main?.some((prev) => privileges?.indexOf(prev) !== -1)) {
            return true;
          }
          return false;
      }
    }
  }
  return false;
};
export const checkCompanyPrivileges = ({ privileges = [] }) => {
  const planPrivilegs =
    Store?.getState()?.auth?.userProfile?.company?.activeSubscription?.plan?.privileges?.map(
      (privilege) => privilege?.id
    );

  if (privileges?.some((prev) => planPrivilegs?.indexOf(prev) !== -1)) {
    return true;
  }
  return false;
};

const isManagedByAuth = (manager, copiedManagers = []) => {
  let allManagers = [manager, ...copiedManagers];
  const { userProfile } = Store?.getState()?.auth;
  return allManagers.includes(userProfile?.id);
};

const downloadFile = (uri) => {
  var link = document.createElement("a");
  link.href = uri;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const previewFile = (uri) => {
  window.open(uri, '_blank', 'noopener,noreferrer');
};

const localizeFns = (arg) => {
  let locale = i18next.getFixedT(Store?.getState()?.auth?.userProfile?.lng);
  return locale(arg);
};

const serializedFetchedLogs = ({ data, paginatorInfo }) => {
  let listData = data?.length
    ? data?.map((record) => ({
        ...record,
        employee: {
          ...record?.employee,

          attendance_profile: {
            ...record?.employee?.attendance_profile,
            sign_in_req:
              record?.employee?.attendance_profile?.attendance_profile_work_timings?.filter(
                (workTime) =>
                  moment(record?.date).isSameOrAfter(workTime?.start) &&
                  moment(record?.date).isSameOrBefore(workTime?.end ?? 1e15)
              )[0]?.work_timing?.sign_in_req,
          },
        },
      }))
    : [];

  return { data: listData, paginatorInfo };
};

function isStepValid(stepInputs, objectServerValidation) {
  for (let key in objectServerValidation) {
    for (let i = 0; i < stepInputs.length; i++) {
      let matchText = new RegExp(stepInputs[i], "g");
      console.log(key, stepInputs[i], key?.match(matchText));
      if (key?.match(matchText)) {
        return false;
      }
    }
  }
  return true;
}

const checkPassword = (
  textMessage,
  htmlContent,
  confirmBtnMessage,
  customSwalContainerStyle,
  callbackAction
) => {
  Swal.fire({
    title: localizeFns("are you sure"),
    text: localizeFns(textMessage), // for text content
    html: htmlContent, // for html content like <br> and any other tag and its recommended that localization is handled server side
    icon: "warning",
    className: "swal-warning-style",
    input: "password",
    inputPlaceholder: localizeFns("Type your password"),
    showCancelButton: true,
    confirmButtonColor: "#ff6a6a",
    confirmButtonText: localizeFns(confirmBtnMessage),
    cancelButtonText: localizeFns("Cancel"),
    reverseButtons: true,
    customClass: {
      // for custom style
      container: customSwalContainerStyle,
    },
    preConfirm: function (password) {
      if (password.length) {
        return password;
      } else {
        return false;
      }
    },
  }).then(({ isConfirmed, value }) => {
    console.log(isConfirmed, value);
    if (isConfirmed) {
      Store.dispatch(checkPasswordAttempt(value, () => callbackAction));
    }
  });
};

const getCountryShortName = (option) => {
  if (option?.country?.short_name) {
    return `${option?.name} (${option?.country?.short_name})`;
  } else {
    return option?.name;
  }
};

const isStringRTL = (s) => {
  var ltrChars =
      "A-Za-z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u02B8\u0300-\u0590\u0800-\u1FFF" +
      "\u2C00-\uFB1C\uFDFE-\uFE6F\uFEFD-\uFFFF",
    rtlChars = "\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC",
    rtlDirCheck = new RegExp("^[^" + ltrChars + "]*[" + rtlChars + "]");

  return rtlDirCheck.test(s);
};

const getBasicPrivileges = (parentPrivilege) => {
  let basicPrivilegesArray = [];
  const handlePrivilege = (privilege) => {
    if (privilege?.privilege_type_id == 1) {
      basicPrivilegesArray?.push(privilege?.id);
      if (privilege?.children?.length > 0) {
        privilege?.children?.forEach((childPrivilege) => {
          handlePrivilege(childPrivilege);
        });
      } else {
        return;
      }
    }
  };
  handlePrivilege(parentPrivilege);
  return basicPrivilegesArray;
};

const getAllBasicPrivileges = (allPrivileges) => {
  let basicPrivilegesArray = [];
  const handlePrivilege = (privilege) => {
    if (privilege?.privilege_type_id == 1) {
      basicPrivilegesArray?.push(privilege?.id);
      if (privilege?.children?.length > 0) {
        privilege?.children?.forEach((childPrivilege) => {
          handlePrivilege(childPrivilege);
        });
      } else {
        return;
      }
    }
  };
  allPrivileges?.forEach((privilege) => handlePrivilege(privilege));
  return basicPrivilegesArray;
};

const getDayOfTheWeek = (date, type, outputDateFormat) => {
  let inputDateFormat = "DD-MM-YYYY";
  switch (type) {
    case "first":
      return moment(date, inputDateFormat)
        .startOf("week")
        .add(1, "days")
        .format(outputDateFormat ?? "DD-MM-YYYY");
    case "last":
      return moment(date, inputDateFormat)
        .endOf("week")
        .add(1, "days")
        .format(outputDateFormat ?? "DD-MM-YYYY");
  }
};

const filterUsersBy = (filterBy, users = [], filterWith = []) => {
  switch (filterBy) {
    case "department":
      return users?.filter((user) =>
        filterWith?.includes(user?.department?.id)
      );
    case "office":
      return users?.filter((user) => filterWith?.includes(user?.office?.id));
    case "active":
      return users?.filter((user) => user?.active);
  }
};

const formatWorkTiming = (time, type) => {
  switch (type) {
    case "time_range":
      return moment(time, "h:mm:ss").format("h:mm a");
      break;
    case "hours":
      return moment(time, "h:mm:ss").format("h");
      break;
    default:
      return;
  }
};

/**
 * Show Toast
 * @param  {String} type Toast type ["success" | "error" | "info" | "warning"]
 * @param  {String} msg Toast message
 * @param  {Number} duration Toast show duration (default = 3000)
 */
export const showToast = (type, msg, translate = false, duration = 3000) => {
  Store.dispatch(showToastAction({ type, msg, duration, translate }));
};

export const openSwalConfirm = (cb, customOptions) => {
  const { title, text, skipLocalize } = customOptions || {};
  swal({
    title: localizeFns(title ?? "are you sure"),
    text: skipLocalize ? text : localizeFns(text ?? "defaut_warning_messsage"),
    icon: "warning",
    className: "swal-warning-style",
    dangerMode: true,
    buttons: [localizeFns("Cancel"), localizeFns("OK")],
  }).then(cb);
};

const serializeSubmitSalaryConfig = () => {};

const orderOvertimeInterval = (ovt = []) => {
  return ovt?.sort((a, b) => a?.order - b?.order);
};

const serializeSubmitHourlyRating = (salaryConfigForm) => {
  if (salaryConfigForm?.apply_hourly_rate_on_every_day) {
    return salaryConfigForm?.Everyday?.map((rate, i, data) => ({
      from: data?.length > 1 ? (i == 0 ? "00:00" : data[i - 1]?.to) : "00:00",
      to: data?.length > 1 && i !== data.length - 1 ? rate?.to : "00:00",
      rph: rate.rate?.toString(),
      day: null,
      // order: i + 1,
      // ...rate,
      // id: rate?.id ?? null,
    }));
  } else {
    return Constants.Days?.map((day) =>
      salaryConfigForm?.[day]?.map((rate, i, data) => ({
        from: data?.length > 1 ? (i == 0 ? "00:00" : data[i - 1]?.to) : "00:00",
        to: data?.length > 1 && i !== data.length - 1 ? rate?.to : "00:00",
        rph: rate?.rate?.toString(),
        day,
        // ...rate,
        // id: rate?.id ?? null,
        // order: i + 1,
      }))
    )
      ?.flat(Infinity)
      ?.filter((rate) => !!rate);
  }
};

const serializeFetchedHourlyRating = (rates) => {
  let hourlyRates = {};
  rates?.forEach((rate) => {
    if (hourlyRates[rate?.day]) {
      hourlyRates[rate?.day]?.push(rate);
    } else {
      hourlyRates[rate?.day ?? "Everyday"] = [rate];
    }
  });
  Object.entries(hourlyRates).forEach(
    ([key, val]) => (hourlyRates[key] = val.sort((a, b) => a?.order - b?.order))
  );

  return hourlyRates;
};

const serializePaymentMethodInput = (input) => {
  if (!Boolean(input?.has_payment_method)) {
    return null;
  }
  switch (input?.payment_method) {
    case "cash":
      return {
        user_payment_id: input?.user_payment_id ? input?.user_payment_id : null,
        user_id: input?.user_id,
        payment_method: input?.payment_method,
      };
    case "bank transfer":
      return {
        user_payment_id: input?.user_payment_id ? input?.user_payment_id : null,
        user_id: input?.user_id ? input?.user_id : null,
        payment_method: input?.payment_method,
        bank_transfer: {
          bank_id: input?.bank_id,
          account_name: input?.account_name,
          account_number: input?.account_number,
          iban: input?.iban,
        },
      };
    case "prepaid card":
      return {
        user_payment_id: input?.user_payment_id ? input?.user_payment_id : null,
        user_id: input?.user_id,
        payment_method: input?.payment_method,
        prepaid_card: {
          prepaid_card_id: input?.prepaid_card_id,
          card_number: input?.card_number,
        },
      };
    case "phone wallet":
      return {
        user_payment_id: input?.user_payment_id ? input?.user_payment_id : null,
        user_id: input?.user_id,
        payment_method: input?.payment_method,
        phone_wallet: {
          phone_wallet_id: input?.phone_wallet_id,
          code: input?.code,
          number: input?.number,
          country_short_name: input?.country_short_name,
        },
      };
    default:
      return;
  }
};

const canCancelRequest = (request) => {
  const { taken, req_day, time_to, progress_indicator } = request;

  if (taken || progress_indicator) {
    return false;
  } else {
    if (req_day) {
      return moment(req_day, "YYYY-MM-DD") > moment();
    } else {
      return moment(time_to, "YYYY-MM-DD hh:mm:ss") > moment();
    }
  }
};

const getOrderSuffix = (order) => {
  switch (order) {
    case 1:
      return "st";

    case 2:
      return "nd";

    case 3:
      return "rd";

    default:
      return "th";
  }
};

const filterApprovalLayers = (approvalLayers) => {
  let result = [];

  for (let i = 0; i < approvalLayers?.length; i++) {
    let layer = approvalLayers[i];

    result.push(layer);

    if (!layer.acceptor) {
      break;
    }
  }

  return result;
};

const generateYears = (min, max) => {
  let years = [];

  for (let i = max; i >= min; i--) {
    years.push({ label: i, value: i });
  }

  return years;
};

const getMonthDaysOptions = (month /*format -> MM */) => {
  let dayOptions = [];

  const numberOfDaysInMonth = moment(month, "MM").daysInMonth();

  for (let i = 1; i <= numberOfDaysInMonth; i++) {
    let day = i < 10 ? `0${i}` : i;

    dayOptions.push({ key: day, value: day });
  }

  return dayOptions;
};

export const filterSalaryCofigPercentageOptions = (
  options,
  payment_interval
) => {
  return options?.filter((option) => {
    // return true if it's custom days (because it exists in both monthly and weekly intervals)
    if (option?.value == "5") {
      return true;
    }

    if (payment_interval == "weekly") {
      return option?.type?.toLowerCase() == "week";
    } else {
      return option?.type?.toLowerCase() == "month";
    }
  });
};

export const decrementNumberAtEnd = (inputString) => {
  // Use a regular expression to match the number at the end of the string
  var regex = /(\d+)$/;

  // Use the replace method with a callback function to increment the matched number
  var result = inputString.replace(regex, function (match, group1) {
    // Convert the matched number to a number and increment it by 1
    var incrementedNumber = parseInt(group1, 10) - 1;
    // Return the incremented number to replace the matched part in the string
    return incrementedNumber;
  });

  return result;
};

export const getReqData = (options, ref) => {
  let availableRequestsOptions = options?.filter(
    (opt) =>
      ![Requests.EMERGENCY_LEAVES, Requests.EMERGENCY_HALF_DAY].includes(opt.id)
  );

  if (ref === "edit_att") {
    const obj = {
      [Requests.NORMAL_LEAVES]: "Normal",
      [Requests.UNPAID_LEAVES]: "Unpaid",
      [Requests.SICK_LEAVES]: "Sick",
      [Requests.BEREAVEMENT]: "Bereavement",
      [Requests.MATERNITY_PATERNITY_LEAVE]: "Maternity_Paternity",
      [Requests.HAJJ_LEAVE]: "Hajj",
      [Requests.STUDY_LEAVE]: "Study",
      [Requests.SABBATICAL_LEAVE]: "Sabbatical",
      [Requests.MARRIAGE_LEAVE]: "Marriage",
      [Requests.In_Lieu_Of_Work_Day_Leave]: "In_Lieu_Of_Work_Day",
      [Requests.MILITARY_CALL_LEAVE]: "Military_Call",
    };

    availableRequestsOptions = availableRequestsOptions.reduce((acc, curr) => {
      if (Object.keys(obj).includes(curr.id))
        return [...acc, { ...curr, id: obj[curr.id] }];

      return acc;
    }, []);
  }

  return {
    availableRequestsOptions,
    reqFlags: {
      isEmgNormal: !!options.find(
        (opt) => opt?.id === Requests.EMERGENCY_LEAVES
      ),
      isEmgHalf: !!options.find(
        (opt) => opt?.id === Requests.EMERGENCY_HALF_DAY
      ),
      ...(ref === "edit_att" && {
        isHome: !!options.find((opt) => opt?.id === Requests.HOME),
        isUnpaidLeave: !!options.find(
          (opt) => opt?.id === Requests.UNPAID_LEAVES
        ),
        isNormalLeave: !!options.find(
          (opt) => opt?.id === Requests.NORMAL_LEAVES
        ),
        isUnpaidHalf: !!options.find(
          (opt) => opt?.id === Requests.UNPAID_HALF_DAY
        ),
        isNormalHalf: !!options.find(
          (opt) => opt?.id === Requests.NORMAL_HALF_DAY
        ),
      }),
    },
  };
};

export const getOrdinalTranslation = (number, word, gender = "m") => {
  const isArabic = i18next.language.includes("ar"),
    maxNum = isArabic ? 10 : 3,
    key = number <= maxNum ? number : maxNum + 1,
    gen = i18next.language.includes("ar") ? `.${gender}` : "";

  return i18next.t(`ordinals${gen}.${key}`, {
    count: number,
    word: i18next.t(word),
  });
};

export const safeJsonParse = (val, fallbackVal = null) => {
  try {
    return JSON.parse(val);
  } catch {
    return fallbackVal;
  }
};

export const normalizeSalaryConfigInput = (
  userId,
  salaryConfigForm = {},
  from
  // companySalaryConfigPresets = [],
  // monthOptions
) => {
  const penaltyConfiguration = salaryConfigForm?.penaltyConfiguration;

  const bonusConfiguration = salaryConfigForm?.bonusConfiguration;

  const overtimeConfiguration = salaryConfigForm?.overtimeConfiguration;

  const unpaidConfiguration = salaryConfigForm?.unpaidConfiguration;

  const totalGross = () => {
    return salaryConfigForm?.salary_allowances
      ?.map((allowance) =>
        allowance.payment_factor_id == PaymentFactors.FIXED_AMOUNT
          ? +allowance.value
          : allowance.payment_factor_id == PaymentFactors.BASE_PERCENTAGE
          ? +(+allowance.value * +salaryConfigForm.base_salary) / 100
          : 0
      )
      .reduce((acc, curr) => acc + curr, +salaryConfigForm.base_salary)
      .toString();
  };

  const totalNet = () => {
    return salaryConfigForm?.salary_deductibles
      ?.map((deductible) =>
        deductible.payment_factor_id == PaymentFactors.FIXED_AMOUNT ||
        deductible.payment_factor_id ==
          PaymentFactors.PAYMENT_FACTOR_ACCORDING_TO_CUSTOM_EQUATION ||
        deductible.payment_factor_id == PaymentFactors.PERCENT_OF_INSURED_SALARY
          ? +deductible.value
          : deductible.payment_factor_id == PaymentFactors.BASE_PERCENTAGE
          ? +(+deductible.value * +salaryConfigForm?.base_salary) / 100
          : deductible.payment_factor_id == PaymentFactors.GROSS_PERCENTAGE
          ? +(+deductible.value * totalGross()) / 100
          : 0
      )
      .reduce(
        (acc, curr) => acc - curr,
        totalGross() -
          +salaryConfigForm?.socialDeduction -
          +salaryConfigForm?.medicalDeduction -
          +salaryConfigForm?.taxDeduction
      )
      .toString();
  };

  const details = {
    type:
      salaryConfigForm?.calculate_salary_by === "payment_interval"
        ? "Fulltime"
        : salaryConfigForm?.calculate_salary_by === "daily"
        ? "Day"
        : "Hour",

    typeConfiguration:
      salaryConfigForm?.calculate_salary_by === "payment_interval"
        ? {
            salary: salaryConfigForm.base_salary,
            grossSalary: totalGross(),
            netSalary: totalNet(),
            overtimeConfiguration: {
              paymentFactor:
                overtimeConfiguration?.paymentFactor ==
                PaymentFactors.FIXED_AMOUNT
                  ? "Fixed"
                  : overtimeConfiguration?.paymentFactor ==
                    PaymentFactors.BASE_PERCENTAGE
                  ? "Percent of base salary"
                  : overtimeConfiguration?.paymentFactor ==
                    PaymentFactors.GROSS_PERCENTAGE
                  ? "Percent of gross salary"
                  : overtimeConfiguration?.paymentFactor ==
                    PaymentFactors.NET_PERCENTAGE
                  ? "Percent of net salary"
                  : null,
              value: overtimeConfiguration?.amount,
              // if overtime settings array has more than one then it has a factor for each one so we should not send the parent factor
              factor: !overtimeConfiguration?.advancedOvertimeSettings?.length // there's no advanced overtime settings
                ? overtimeConfiguration?.factor
                : null,

              periods: overtimeConfiguration?.advancedOvertimeSettings?.length // there's advanced overtime settings
                ? overtimeConfiguration?.advancedOvertimeSettings?.map(
                    (overtimeSetting, index) => ({
                      from:
                        index === 0
                          ? overtimeSetting?.from
                          : overtimeConfiguration?.advancedOvertimeSettings?.[
                              index - 1
                            ]?.to,
                      to: overtimeSetting?.to,
                      factor: overtimeSetting?.factor,
                    })
                  )
                : [],
            },
          }
        : salaryConfigForm?.calculate_salary_by === "daily"
        ? {
            rpd: salaryConfigForm?.rate_per_day?.toString(), // rate per day
            overtimeConfiguration: {
              paymentFactor:
                overtimeConfiguration?.paymentFactor ==
                PaymentFactors.FIXED_AMOUNT
                  ? "Fixed"
                  : overtimeConfiguration?.paymentFactor ==
                    PaymentFactors.BASE_PERCENTAGE
                  ? "Percent of base salary"
                  : overtimeConfiguration?.paymentFactor ==
                    PaymentFactors.GROSS_PERCENTAGE
                  ? "Percent of gross salary"
                  : overtimeConfiguration?.paymentFactor ==
                    PaymentFactors.NET_PERCENTAGE
                  ? "Percent of net salary"
                  : null,
              value: overtimeConfiguration?.amount,
              // if overtime settings array has more than one then it has a factor for each one so we should not send the parent factor
              factor: !overtimeConfiguration?.advancedOvertimeSettings?.length // there's no advanced overtime settings
                ? overtimeConfiguration?.factor
                : null,

              periods: overtimeConfiguration?.advancedOvertimeSettings?.length // there's advanced overtime settings
                ? overtimeConfiguration?.advancedOvertimeSettings?.map(
                    (overtimeSetting, index) => ({
                      from:
                        index === 0
                          ? overtimeSetting?.from
                          : overtimeConfiguration?.advancedOvertimeSettings?.[
                              index - 1
                            ]?.to,
                      to: overtimeSetting?.to,
                      factor: overtimeSetting?.factor,
                    })
                  )
                : [],
            },
          }
        : {
            allHours: salaryConfigForm?.calculate_all_work_hours,

            hppl: salaryConfigForm?.hours_per_paid_leave?.toString(), //Hours per paid leave

            rpph: salaryConfigForm?.paid_leave_hourly_rate?.toString(), //Rate per paid hour

            overtimeConfiguration: {
              paymentFactor: null, // null -> because when hourly, user only enters a factor
              value: null, // null -> because when hourly, user only enters a factor
              // if overtime settings array has more than one then it has a factor for each one so we should not send the parent factor
              factor: !overtimeConfiguration?.advancedOvertimeSettings?.length // there's no advanced overtime settings
                ? overtimeConfiguration?.factor
                : null,

              periods: overtimeConfiguration?.advancedOvertimeSettings?.length // there's advanced overtime settings
                ? overtimeConfiguration?.advancedOvertimeSettings?.map(
                    (overtimeSetting, index) => ({
                      from:
                        index === 0
                          ? overtimeSetting?.from
                          : overtimeConfiguration?.advancedOvertimeSettings?.[
                              index - 1
                            ]?.to,
                      to: overtimeSetting?.to,
                      factor: overtimeSetting?.factor,
                    })
                  )
                : [],
            },
            periods: serializeSubmitHourlyRating(salaryConfigForm), // salary configuration periods in case of hourly
          },

    penaltyConfiguration: {
      paymentFactor:
        penaltyConfiguration?.paymentFactor == PaymentFactors.FIXED_AMOUNT
          ? "Fixed"
          : penaltyConfiguration?.paymentFactor ==
            PaymentFactors.BASE_PERCENTAGE
          ? "Percent of base salary"
          : penaltyConfiguration?.paymentFactor ==
            PaymentFactors.GROSS_PERCENTAGE
          ? "Percent of gross salary"
          : penaltyConfiguration?.paymentFactor == PaymentFactors.NET_PERCENTAGE
          ? "Percent of net salary"
          : null,

      // if it's custom days send the number of days in the same key (problem with editing)
      monthDays:
        penaltyConfiguration?.paymentFactor == PaymentFactors.FIXED_AMOUNT
          ? null
          : penaltyConfiguration?.monthOption == "1" // send null in case of calendar month
          ? null
          : penaltyConfiguration?.monthOption == "5"
          ? penaltyConfiguration?.customDays
          : monthOptionDays[+penaltyConfiguration?.monthOption],
      value:
        penaltyConfiguration?.paymentFactor == PaymentFactors.FIXED_AMOUNT
          ? penaltyConfiguration?.amount
          : null,

      oneHour: salaryConfigForm?.one_hour_penalty,
    },

    unpaidConfiguration: {
      paymentFactor:
        unpaidConfiguration?.paymentFactor == PaymentFactors.FIXED_AMOUNT
          ? "Fixed"
          : unpaidConfiguration?.paymentFactor == PaymentFactors.BASE_PERCENTAGE
          ? "Percent of base salary"
          : unpaidConfiguration?.paymentFactor ==
            PaymentFactors.GROSS_PERCENTAGE
          ? "Percent of gross salary"
          : unpaidConfiguration?.paymentFactor == PaymentFactors.NET_PERCENTAGE
          ? "Percent of net salary"
          : null,
      monthDays:
        unpaidConfiguration?.paymentFactor == PaymentFactors.FIXED_AMOUNT
          ? null
          : unpaidConfiguration?.monthOption == "1" // send null in case of calendar month
          ? null
          : unpaidConfiguration?.monthOption == "5"
          ? unpaidConfiguration?.customDays
          : monthOptionDays[+unpaidConfiguration?.monthOption],

      value:
        unpaidConfiguration?.paymentFactor == PaymentFactors.FIXED_AMOUNT
          ? unpaidConfiguration?.amount
          : null,
    },

    bonusConfiguration: {
      paymentFactor:
        bonusConfiguration?.paymentFactor == PaymentFactors.FIXED_AMOUNT
          ? "Fixed"
          : bonusConfiguration?.paymentFactor == PaymentFactors.BASE_PERCENTAGE
          ? "Percent of base salary"
          : bonusConfiguration?.paymentFactor == PaymentFactors.GROSS_PERCENTAGE
          ? "Percent of gross salary"
          : bonusConfiguration?.paymentFactor == PaymentFactors.NET_PERCENTAGE
          ? "Percent of net salary"
          : null,
      monthDays:
        bonusConfiguration?.paymentFactor == PaymentFactors.FIXED_AMOUNT
          ? null
          : bonusConfiguration?.monthOption == "1" // send null in case of calendar month
          ? null
          : bonusConfiguration?.monthOption == "5"
          ? bonusConfiguration?.customDays
          : monthOptionDays[+bonusConfiguration?.monthOption],
      value:
        bonusConfiguration?.paymentFactor == PaymentFactors.FIXED_AMOUNT
          ? bonusConfiguration?.amount
          : null,
    },

    allowanceConfiguration:
      salaryConfigForm?.salary_allowances?.map((allowance) => ({
        id: allowance?.allowance_id,
        paymentFactor:
          allowance?.payment_factor_id == PaymentFactors.FIXED_AMOUNT
            ? "Fixed"
            : "Percent of base salary",
        value: allowance?.value,
        payableOn: [
          ...(allowance?.not_payable_on_absent_days ? [] : ["ABSENT"]),
          ...(allowance?.not_payable_on_paid_days ? [] : ["UNPAID"]),
          ...(allowance?.not_payable_on_un_paid_days ? [] : ["PAID"]),
        ],
        monthDays: null, // static null and should be removed
      })) ?? [],

    deductionConfiguration:
      salaryConfigForm?.salary_deductibles?.map((deductible) => ({
        id: deductible?.deductible_id,
        paymentFactor:
          deductible?.payment_factor_id == PaymentFactors.FIXED_AMOUNT
            ? "Fixed"
            : deductible?.payment_factor_id == PaymentFactors.BASE_PERCENTAGE
            ? "Percent of base salary"
            : "Percent of gross salary",
        value: deductible?.value,
        monthDays: null, // static null and should be removed
      })) ?? [],

    socialDeduction:
      salaryConfigForm?.regulation_type_id == RegulationTypes.MAWARED_CUSTOM // only send the value if custom because user enters a value otherwise backend will calculate it's value
        ? salaryConfigForm?.socialDeduction
        : null,

    medicalDeduction: salaryConfigForm?.medicalDeduction,

    taxDeduction:
      salaryConfigForm?.regulation_type_id == RegulationTypes.MAWARED_CUSTOM // only send the value if custom because user enters a value otherwise backend will calculate it's value
        ? salaryConfigForm?.taxDeduction
        : null,

    currencyId: +salaryConfigForm.currency_id,

    temp: +salaryConfigForm?.temporary_employee,

    taxRegulation: {
      typeId:
        salaryConfigForm.salary_management_type == 1
          ? null
          : +salaryConfigForm?.regulation_type_id,

      taxInfo:
        salaryConfigForm.salary_management_type == 1
          ? null
          : +salaryConfigForm?.tax_info_id,
    },
  };

  return {
    originalConfiguration: !!salaryConfigForm?.id
      ? salaryConfigForm?.originalFrom
      : null,
    user_id: !!userId ? +userId : null,
    from,
    type:
      salaryConfigForm.salary_management_type == 1 ? "External" : "Internal",
    payment:
      salaryConfigForm?.payment_interval == "weekly" ? "Weekly" : "Monthly",

    details:
      salaryConfigForm.salary_management_type == 1
        ? undefined
        : {
            fulltimeType: details?.type === "Fulltime" ? details : null,
            dayType: details?.type === "Day" ? details : null,
            hourType: details?.type === "Hour" ? details : null,
          },

    ...(salaryConfigForm.salary_management_type == 0 && salaryConfigForm?.create
      ? {
          preset_id: null,
          name: salaryConfigForm?.name, // preset name
        }
      : salaryConfigForm.salary_management_type == 0 && salaryConfigForm?.update
      ? {
          preset_id: salaryConfigForm?.company_salary_config_preset_id,
        }
      : {}),
  };
};

export const normalizeFetchedSalaryConfigInput = (salaryConfig) => {
  const result = {
    ..._.cloneDeep(initState.salaryConfigForm),
    id: salaryConfig?.id,
    regulation_type_id: salaryConfig?.details?.taxRegulation?.typeId,
    tax_info_id: salaryConfig?.details?.taxRegulation?.taxInfo,
    // one_hour_bonus: salaryConfig?.details?.bonusConfiguration?.oneHour,
    one_hour_penalty: salaryConfig?.details?.penaltyConfiguration?.oneHour,
    currency_id: salaryConfig?.details?.currency?.id ?? "",
    payment_interval: salaryConfig?.payment?.toLowerCase(),
    temporary_employee: +salaryConfig?.details?.temp,
    rate_per_day: +salaryConfig?.details?.typeConfiguration?.rpd,
    apply_hourly_rate_on_every_day:
      salaryConfig?.details?.typeConfiguration?.periods?.length &&
      salaryConfig?.details?.typeConfiguration?.periods?.every(
        (period) => !!!period?.day
      )
        ? 1
        : 0,
    ...serializeFetchedHourlyRating(
      salaryConfig?.details?.typeConfiguration?.periods?.map((period) => ({
        from: period?.from,
        to: period?.to,
        rate: period?.rph,
        day: period?.day,
      }))
    ),
    hours_per_paid_leave: +salaryConfig?.details?.typeConfiguration?.hppl,
    paid_leave_hourly_rate: +salaryConfig?.details?.typeConfiguration?.rpph,
    base_salary: salaryConfig?.details?.typeConfiguration?.salary ?? "",
    calculate_salary_by:
      salaryConfig?.details?.type == "Fulltime" ||
      salaryConfig?.details?.type == "Parttime"
        ? "payment_interval"
        : salaryConfig?.details?.type == "Day"
        ? "daily"
        : "hourly",
    // based_on: userSalaryConfigDetail?.calculate_salary_by,
    calculate_all_work_hours:
      salaryConfig?.details?.typeConfiguration?.allHours,
    canEditStartAt: !!salaryConfig?.canEdit_from,
    canDelete: salaryConfig?.canDelete,
    start_at: salaryConfig?.from,
    originalFrom: salaryConfig?.from,
    monthYear: !!salaryConfig?.from
      ? moment(salaryConfig?.from, "YYYY-MM-DD")?.format("MM-YYYY")
      : null,
    preserved_start_at: salaryConfig?.from,
    week_start: salaryConfig?.from,
    salary_management_type: salaryConfig?.type == "External" ? 1 : 0,
    salary_allowances: salaryConfig?.details?.allowanceConfiguration?.map(
      ({
        id,
        paymentFactor,
        value,
        // name,
        payableOn,
      }) => ({
        id,
        value,
        payment_factor_id:
          paymentFactor == "Fixed"
            ? PaymentFactors.FIXED_AMOUNT
            : paymentFactor == "Percent of base salary"
            ? PaymentFactors.BASE_PERCENTAGE
            : paymentFactor == "Percent of gross salary"
            ? PaymentFactors.GROSS_PERCENTAGE
            : PaymentFactors.NET_PERCENTAGE,
        allowance_id: id,
        not_payable_on_paid_days: payableOn?.includes("PAID") ? false : true,
        not_payable_on_un_paid_days: payableOn?.includes("UNPAID")
          ? false
          : true,
        not_payable_on_absent_days: payableOn?.includes("ABSENT")
          ? false
          : true,
      })
    ),
    salary_deductibles: salaryConfig?.details?.deductionConfiguration?.map(
      ({ id, value, paymentFactor }) => ({
        id,
        value,
        payment_factor_id:
          paymentFactor == "Fixed"
            ? PaymentFactors.FIXED_AMOUNT
            : paymentFactor == "Percent of base salary"
            ? PaymentFactors.BASE_PERCENTAGE
            : paymentFactor == "Percent of gross salary"
            ? PaymentFactors.GROSS_PERCENTAGE
            : PaymentFactors.NET_PERCENTAGE,
        deductible_id: id,
      })
    ),

    penaltyConfiguration: {
      paymentFactor:
        salaryConfig?.details?.penaltyConfiguration?.paymentFactor == "Fixed"
          ? PaymentFactors.FIXED_AMOUNT
          : salaryConfig?.details?.penaltyConfiguration?.paymentFactor ==
            "Percent of base salary"
          ? PaymentFactors.BASE_PERCENTAGE
          : salaryConfig?.details?.penaltyConfiguration?.paymentFactor ==
            "Percent of gross salary"
          ? PaymentFactors.GROSS_PERCENTAGE
          : PaymentFactors.NET_PERCENTAGE,

      monthOption: !!!salaryConfig?.details?.penaltyConfiguration?.monthDays
        ? "1"
        : monthOptionFromNumberOfDays(
            +salaryConfig?.details?.penaltyConfiguration?.monthDays
          ),
      amount: salaryConfig?.details?.penaltyConfiguration?.value,
      customDays: salaryConfig?.details?.penaltyConfiguration?.monthDays,
    },

    bonusConfiguration: {
      paymentFactor:
        salaryConfig?.details?.bonusConfiguration?.paymentFactor == "Fixed"
          ? PaymentFactors.FIXED_AMOUNT
          : salaryConfig?.details?.bonusConfiguration?.paymentFactor ==
            "Percent of base salary"
          ? PaymentFactors.BASE_PERCENTAGE
          : salaryConfig?.details?.bonusConfiguration?.paymentFactor ==
            "Percent of gross salary"
          ? PaymentFactors.GROSS_PERCENTAGE
          : PaymentFactors.NET_PERCENTAGE,

      monthOption: !!!salaryConfig?.details?.bonusConfiguration?.monthDays
        ? "1"
        : monthOptionFromNumberOfDays(
            +salaryConfig?.details?.bonusConfiguration?.monthDays
          ),
      amount: salaryConfig?.details?.bonusConfiguration?.value,
      customDays: salaryConfig?.details?.bonusConfiguration?.monthDays,
    },

    unpaidConfiguration: {
      paymentFactor:
        salaryConfig?.details?.unpaidConfiguration?.paymentFactor == "Fixed"
          ? PaymentFactors.FIXED_AMOUNT
          : salaryConfig?.details?.unpaidConfiguration?.paymentFactor ==
            "Percent of base salary"
          ? PaymentFactors.BASE_PERCENTAGE
          : salaryConfig?.details?.unpaidConfiguration?.paymentFactor ==
            "Percent of gross salary"
          ? PaymentFactors.GROSS_PERCENTAGE
          : PaymentFactors.NET_PERCENTAGE,

      monthOption: !!!salaryConfig?.details?.unpaidConfiguration?.monthDays
        ? "1"
        : monthOptionFromNumberOfDays(
            +salaryConfig?.details?.unpaidConfiguration?.monthDays
          ),
      amount: salaryConfig?.details?.unpaidConfiguration?.value,
      customDays: salaryConfig?.details?.unpaidConfiguration?.monthDays,
    },

    overtimeConfiguration: {
      paymentFactor:
        salaryConfig?.details?.typeConfiguration?.overtimeConfiguration
          ?.paymentFactor == "Fixed"
          ? PaymentFactors.FIXED_AMOUNT
          : salaryConfig?.details?.typeConfiguration?.overtimeConfiguration
              ?.paymentFactor == "Percent of base salary"
          ? PaymentFactors.BASE_PERCENTAGE
          : salaryConfig?.details?.typeConfiguration?.overtimeConfiguration
              ?.paymentFactor == "Percent of gross salary"
          ? PaymentFactors.GROSS_PERCENTAGE
          : PaymentFactors.NET_PERCENTAGE,

      amount:
        salaryConfig?.details?.typeConfiguration?.overtimeConfiguration?.value,

      factor:
        salaryConfig?.details?.typeConfiguration?.overtimeConfiguration?.factor,

      advancedOvertimeSettings:
        salaryConfig?.details?.typeConfiguration?.overtimeConfiguration?.periods?.map(
          (overtimeSetting) => ({
            from: overtimeSetting?.from,
            to: overtimeSetting?.to,
            factor: overtimeSetting?.factor,
          })
        ),
    },

    socialDeduction: salaryConfig?.details?.socialDeduction,
    medicalDeduction: salaryConfig?.details?.medicalDeduction,
    taxDeduction: salaryConfig?.details?.taxDeduction,

    // state_id: taxInfo?.state_id,
  };

  return result;
};

export const calculateAllowance = (allowances, baseSalary) => {
  let total = 0;
  allowances.forEach((allowance) => {
    if (allowance?.paymentFactor == "Fixed") {
      total += +allowance?.value;
    } else if (allowance?.paymentFactor == "Percent of base salary") {
      total += (+allowance?.value / 100) * +baseSalary;
    }
  });

  return total;
};

export const calculateDeductibles = (deducitbles, baseSalary, grossSalary) => {
  let total = 0;
  deducitbles.forEach((deductible) => {
    if (deductible?.paymentFactor == "Fixed") {
      total += +deductible?.value;
    } else if (deductible?.paymentFactor == "Percent of base salary") {
      total += (+deductible?.value / 100) * +baseSalary;
    } else if (deductible?.paymentFactor == "Percent of gross salary") {
      total += (+deductible?.value / 100) * +grossSalary;
    }
  });

  return total;
};

export const getLateKeys = () => {
  let latkeys = ["Minor Tardiness", "Major Tardiness"];
  for (let i = 1; i < 11; i++) {
    latkeys.push(`Major Tardiness${i}`);
  }
  return latkeys;
};

export default {
  getOrdinalTranslation,
  safeJsonParse,
  getReqData,
  getTimeDifference,
  timeToHours,
  hoursToTime,
  serializedFetchedAttProfile,
  serializedSubmitedAttProfile,
  serializedEditEmployee,
  getIntials,
  parseValidatorErrors,
  checkPrivileges,
  checkCompanyPrivileges,
  downloadFile,
  previewFile,
  localizeFns,
  serializedSubmitedWorkTiming,
  serializeFetchedWorkTime,
  serializedFetchedLogs,
  isStepValid,
  checkPassword,
  getCountryShortName,
  isManagedByAuth,
  isStringRTL,
  getBasicPrivileges,
  getAllBasicPrivileges,
  getDayOfTheWeek,
  filterUsersBy,
  formatWorkTiming,
  secToTime,
  serializeSubmitSalaryConfig,
  orderOvertimeInterval,
  canCancelRequest,
  serializeFetchedHourlyRating,
  serializeSubmitHourlyRating,
  showToast,
  ...SettingsHelpers,
  ...AttendanceHelpers,
  getOrderSuffix,
  filterApprovalLayers,
  ...normalizeSharedForms,
  generateYears,
  getMonthDaysOptions,
  serializePaymentMethodInput,
};
