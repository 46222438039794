import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  initSalaryConfig,
  deleteSalaryConfigAction,
} from "../../Store/Actions";
import moment from "moment";
import Privilages from "../../Constants/Privilages";
import {
  calculateAllowance,
  calculateDeductibles,
  openSwalConfirm,
} from "../../Helpers/HelperFns";

import IconButtonWithTooltip, {
  EditIconButton,
  RemoveIconButton,
  PreviewIconButton,
} from "../../Components/IconButtonWithTooltip";
import DataTable from "react-data-table-component";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import { Visibility } from "@mui/icons-material";
import { useMutation } from "@apollo/client";
import { DELETE_USER_SALARY_CONFIG } from "../../Graphql/mutation";

const SalaryConfiguration = ({
  salaryConfig,
  handleShowSalaryConfigModal,
  office_id,
  userId,
  refetch = () => {},
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [attemptDeleteUserSalaryConfig, { loading }] = useMutation(
    DELETE_USER_SALARY_CONFIG,
    {
      variables: {
        userId: +salaryConfig?.user_id,
        from: salaryConfig?.from,
      },
      onCompleted: () => {
        refetch();
      },
    }
  );

  const baseSalary = salaryConfig?.details?.typeConfiguration?.salary;

  const totalAllowances = calculateAllowance(
    salaryConfig?.details?.allowanceConfiguration ?? [],
    baseSalary
  );

  const grossSalary = +baseSalary + totalAllowances;

  const totalDeductibles =
    calculateDeductibles(
      salaryConfig?.details?.deductionConfiguration ?? [],
      baseSalary,
      grossSalary
    ) +
    +salaryConfig?.details?.socialDeduction +
    +salaryConfig?.details?.medicalDeduction +
    +salaryConfig?.details?.taxDeduction;

  // Constants
  const isInternal = salaryConfig?.type === "Internal";
  const columns = [
    {
      grow: 0.7,
      cell: ({ from, to }) => (
        <>
          {from != null ? (
            <>{moment(from).format("Do MMM YYYY")}</>
          ) : (
            <>{t("now")}</>
          )}
          {" - "}
          {to != null ? (
            <>{moment(to).format("Do MMM YYYY")}</>
          ) : (
            <>{t("now")}</>
          )}
        </>
      ),
    },
    {
      omit: isInternal,
      cell: ({ type }) => (
        <div className="font-weight-bold text-uppercase px-4">
          {type === "External"
            ? `${t("Manage Employee Payroll Externally")} (${t(
                salaryConfig?.payment
              )})`
            : ""}

          {type === "Suspend" ? t("suspended") : ""}
        </div>
      ),
    },
    {
      omit: !isInternal,
      cell: ({ payment }) => <Cell label={t("interval")} txt={t(payment)} />,
    },
    {
      omit: !isInternal,
      cell: ({ details = {} }) => (
        <>
          {details?.type === "Day" ? (
            <Cell
              label={t("rate per day")}
              txt={getCost(details?.typeConfiguration?.rpd)}
            />
          ) : null}

          {details?.type === "Hour" ? (
            <Cell
              label={t("rate per hour")}
              txt={getCost(
                details?.typeConfiguration?.periods?.reduce(
                  (acc, val) => acc + +val?.rph,
                  0
                )
              )}
            />
          ) : null}

          {details?.type === "Fulltime" || details?.type === "Parttime" ? (
            <Cell
              label={t("base_salary")}
              txt={getCost(+details?.typeConfiguration?.salary)}
            />
          ) : null}
        </>
      ),
    },
    {
      omit: !isInternal,
      cell: ({ details = {} }) => (
        <Cell label={t("allowances")} txt={getCost(totalAllowances)} />
      ),
    },
    {
      omit: !isInternal,
      cell: ({ details = {} }) => (
        <Cell label={t("deductibles")} txt={getCost(totalDeductibles)} />
      ),
    },
    {
      grow: 0.7,
      style: { display: "flex", justifyContent: "flex-end" },
      cell: ({ canEdit, canDelete, to }) => (
        <div className="d-flex align-items-center gap-10">
          <HasPrivileges
            allowBP
            reqireMain={[Privilages.ADD_EMPLOYEE_SALARY_CONFIGURATION]}
          >
            {canEdit ? (
              <EditIconButton onClick={handleEditSalaryConfig} />
            ) : null}

            {canDelete && to === null ? (
              <RemoveIconButton onClick={handleDeleteSalaryConfig} />
            ) : null}
          </HasPrivileges>

          {isInternal ? (
            <IconButtonWithTooltip
              label="Preview"
              icon={<Visibility />}
              onClick={() => handleShowSalaryConfigModal(salaryConfig)}
            />
          ) : null}
        </div>
      ),
    },
  ];

  /* ↓ Helpers ↓ */

  const getCost = (cost) => {
    return cost + " " + t(salaryConfig?.details?.currency?.name);
  };

  const userCountryId = useSelector(
    (state) => state?.super?.selectedEmployeeProfile?.office?.country?.id
  );

  // handle edit salary config
  const handleEditSalaryConfig = () => {
    dispatch(initSalaryConfig(office_id, false, salaryConfig, userCountryId));
  };

  // handle delete salary config
  const handleDeleteSalaryConfig = () => {
    openSwalConfirm((willDelete) => {
      if (willDelete) {
        attemptDeleteUserSalaryConfig();
        // dispatch(deleteSalaryConfigAction(salaryConfig?.id));
      }
    });
  };

  return (
    <div className="card-container my-2 px-1">
      <DataTable noHeader noTableHead columns={columns} data={[salaryConfig]} />
    </div>
  );
};

const Cell = ({ label, txt }) => (
  <b className="d-flex gap-10">
    <span className="gray-color">{label}</span>
    {txt}
  </b>
);

export default SalaryConfiguration;
