import React from "react";
import { useDispatch } from "react-redux";
import { useMutation } from "@apollo/client";
import {
  deleteLeavesBreaksSettingMutation,
  cloneLeavesBreaksSettingMutation,
} from "../../Graphql/mutation";
import { showLeavesBreaksModal } from "../../Store/Actions";
import { useTranslation } from "react-i18next";
import HelperFns from "../../Helpers/HelperFns";

// Privileges
import Privilages from "../../Constants/Privilages";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import { handleActionMutation } from "../../Helpers/HelperFns";

// Ui
import Swal from "sweetalert2";

// Icons
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import {
  RemoveIconButton,
  CopyIconButton,
  EditIconButton,
} from "../IconButtonWithTooltip";

const LeavesBreaksCard = ({ data, setIsModalOpen, fetchLeavesBreaks }) => {
  const { t } = useTranslation();

  const [deleteLeavesBreaksSetting] = useMutation(
    deleteLeavesBreaksSettingMutation
  );
  const [cloneLeavesBreaksSetting] = useMutation(
    cloneLeavesBreaksSettingMutation
  );

  const dispatch = useDispatch();

  const handleEdit = () => {
    dispatch(showLeavesBreaksModal(data));

    setIsModalOpen(true);
  };

  const handleClone = () => {
    handleActionMutation(cloneLeavesBreaksSetting, {
      variables: {
        id: data.id,
      },
      refetchQuery: fetchLeavesBreaks,
    });
  };

  const handleDelete = () => {
    Swal.fire({
      icon: "warning",
      title: t("are you sure"),
      text: t("defaut_warning_messsage"),
      showConfirmButton: true,
      confirmButtonText: t("Yes"),
      confirmButtonColor: "#7cd1f9",
      showDenyButton: true,
      denyButtonText: t("No"),
      showCancelButton: false,
      customClass: {
        popup: "swal-warning-style",
        icon: "swal-icon",
        denyButton: "order-2",
        confirmButton: "order-3",
      },
    }).then((values) => {
      if (values.isConfirmed) {
        handleActionMutation(deleteLeavesBreaksSetting, {
          variables: {
            id: data.id,
          },
          refetchQuery: fetchLeavesBreaks,
        });
      }
    });
  };

  return (
    <div className="leaves_breaks_card row align-items-center">
      <h1 className="leaves_breaks_card_name col-md-2">
        <PersonOutlineIcon className="name_icon" />
        <span dir={HelperFns.isStringRTL(data?.name) ? "rtl" : "ltr"}>
          {data?.name}
        </span>
      </h1>

      {/* Leaves */}
      <div className="leaves_breaks_card_leaves col-md-3">
        <div className="row">
          <span className="col-md-3">
            {data?.allow_annual_leaves ? data?.allowed_annual_leaves : t("N/A")}
          </span>
          <span className="col-md-3">
            {data?.allowed_emergency_leaves ?? t("N/A")}
          </span>
          <span className="col-md-3">
            {data?.allow_sick_leaves ? data?.allowed_sick_leaves : t("N/A")}
          </span>
          <span className="col-md-3">
            {data?.allow_unpaid_leaves ? t("Allowed") : t("N/A")}
          </span>
        </div>
      </div>

      {/* Permissions */}
      <div className="leaves_breaks_card_permissions col-md-3">
        <div className="row">
          <span className="col-md-4">
            {data?.allow_permissions ? data?.min_permission_unit : t("N/A")}
          </span>
          <span className="col-md-4">
            {data?.allow_permissions ? data?.max_units_per_day : t("N/A")}
          </span>
          <span className="col-md-4">
            {data?.allow_permissions ? data?.max_units_per_month : t("N/A")}
          </span>
        </div>
      </div>

      {/* Breaks */}
      <div className="leaves_breaks_card_breaks col-md-3">
        <div className="row align-items-center">
          <span className="col-md-3">
            {data.allow_break
              ? data?.deduct_from_work_hours
                ? t("Yes")
                : t("No")
              : t("N/A")}
          </span>
          <span className="col-md-3">
            {data.allow_break
              ? data?.number_of_allowed_break_sessions_per_day
              : t("N/A")}
          </span>
          <span className="col-md-3">
            {data.allow_break ? data?.maximum_break_session_duration : t("N/A")}
          </span>
          <span className="col-md-3">
            {data.allow_break ? data?.maximum_break_duration : t("N/A")}
          </span>
        </div>
      </div>

      <div className="leaves_breaks_card_actions col-md-1">
        <HasPrivileges
          reqireMain={[Privilages.ADD_EDIT_CLONE_LEAVE_BREAK_PROFILES]}
          allowBP
          avalibleOnExpire={false}
        >
          <EditIconButton onClick={handleEdit} />
          <CopyIconButton onClick={handleClone} />
        </HasPrivileges>

        <HasPrivileges
          reqireMain={[Privilages.DELETE_LEAVE_BREAK_PROFILES]}
          allowBP
          avalibleOnExpire={false}
        >
          <RemoveIconButton onClick={handleDelete} />
        </HasPrivileges>
      </div>
    </div>
  );
};

export default LeavesBreaksCard;
