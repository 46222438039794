import gql from "graphql-tag";

/*
  Plans
*/

export const storePlanMutation = gql`
  mutation storePlan(
    $name: String
    $package_id: Int
    $currency_id: Int
    $period: String
    $duration: Int
    $type: String
    $pricing: String
    $configuration: JSON
  ) {
    payment_tracking_store_plan(
      name: $name
      package_id: $package_id
      currency_id: $currency_id
      period: $period
      duration: $duration
      type: $type
      pricing: $pricing
      configuration: $configuration
    ) {
      id
    }
  }
`;

export const changePlanMutation = gql`
  mutation changePlan(
    $id: Int
    $name: String
    $package_id: Int
    $currency_id: Int
    $period: String
    $duration: Int
    $type: String
    $pricing: String
    $configuration: JSON
  ) {
    payment_tracking_store_plan(
      id: $id
      name: $name
      package_id: $package_id
      currency_id: $currency_id
      period: $period
      duration: $duration
      type: $type
      pricing: $pricing
      configuration: $configuration
    ) {
      id
    }
  }
`;

export const setPlanStatusMutation = gql`
  mutation setPlanStatus($id: Int, $status: String) {
    payment_tracking_set_plan_status(id: $id, status: $status)
  }
`;

/*
  Subscriptions
*/

export const storeSubscriptionMutation = gql`
  mutation storeSubscription(
    $users: Int
    $from: String
    $prorate: Int
    $plan_id: Int
    $duration: Int
    $company_id: Int
    $country_id: Int
    $taxation: String
    $promotions: JSON
    $generate_invoices: Int
  ) {
    payment_tracking_store_subscription(
      from: $from
      users: $users
      prorate: $prorate
      plan_id: $plan_id
      duration: $duration
      taxation: $taxation
      promotions: $promotions
      company_id: $company_id
      country_id: $country_id
      generate_invoices: $generate_invoices
    ) {
      id
    }
  }
`;

export const changeSubscriptionMutation = gql`
  mutation changeSubscription(
    $id: Int
    $users: Int
    $from: String
    $prorate: Int
    $plan_id: Int
    $duration: Int
    $company_id: Int
    $country_id: Int
    $taxation: String
    $promotions: JSON
    $generate_invoices: Int
  ) {
    payment_tracking_change_subscription(
      id: $id
      from: $from
      users: $users
      prorate: $prorate
      plan_id: $plan_id
      duration: $duration
      taxation: $taxation
      promotions: $promotions
      company_id: $company_id
      country_id: $country_id
      generate_invoices: $generate_invoices
    ) {
      id
    }
  }
`;

/*
  Subscription Profile
*/

export const changeSubscriptionPlanMutation = gql`
  mutation changeSubscriptionPlan(
    $company_id: Int
    $from: String
    $json: JSON
  ) {
    payment_tracking_change_subscription(
      json: $json
      from: $from
      company_id: $company_id
      actionType: "CHANGE_PLAN"
    )
  }
`;

export const changeSubscriptionTiersMutation = gql`
  mutation changeSubscriptionTiers(
    $company_id: Int
    $from: String
    $json: JSON
  ) {
    payment_tracking_change_subscription(
      json: $json
      from: $from
      company_id: $company_id
      actionType: "CHANGE_TIER"
    )
  }
`;

export const endSubscriptionMutation = gql`
  mutation endSubscription($company_id: Int, $from: String, $json: JSON) {
    payment_tracking_change_subscription(
      json: $json
      from: $from
      actionType: "END"
      company_id: $company_id
    )
  }
`;

export const endCreateSubscriptionMutation = gql`
  mutation endCreateSubscription($company_id: Int, $from: String, $json: JSON) {
    payment_tracking_change_subscription(
      json: $json
      from: $from
      actionType: "CREATE"
      company_id: $company_id
    )
  }
`;

/*
  Payments
*/

export const changePaymentStatusMutation = gql`
  mutation changePaymentStatus($id: [Int], $status: String) {
    payment_tracking_set_payment_status(id: $id, type: $status)
  }
`;

export const editPaymentMutation = gql`
  mutation editPayment(
    $id: Int
    $method: JSON
    $applyTax: Int
    $amount: String
    $due_date: String
    $taxAmount: String
  ) {
    payment_tracking_change_payment(
      id: $id
      amount: $amount
      method: $method
      applyTax: $applyTax
      due_date: $due_date
      taxAmount: $taxAmount
    ) {
      id
    }
  }
`;

export const payInvoiceMutation = gql`
  mutation payInvoice(
    $id: Int
    $file: Upload
    $method: JSON
    $amount: String
    $received_at: String
    $collector_id: String
  ) {
    payment_tracking_pay(
      id: $id
      file: $file
      method: $method
      receivedAmount: $amount
      received_at: $received_at
      collector_id: $collector_id
    )
  }
`;

export const editInvoiceMutation = gql`
  mutation editInvoice(
    $id: Int
    $items: [JSON]
    $terms: String
    $method: JSON
    $due_date: String
  ) {
    payment_tracking_change_invoice(
      id: $id
      terms: $terms
      items: $items
      method: $method
      due_date: $due_date
    ) {
      id
    }
  }
`;
